import { combineReducers } from "redux";

import authReducer from "./auth/authReducer";
import commonReducer from "./commonStore/commonReducer";
import PrismicReducer from "./prismic/prismicReducer";
import RequestReducer from "./request/requestReducer";
import RequestTypeSubtypeReducer from "./requestTypeSubtype/requestTypeSubtypeReducer";
import SettingsReducer from "./settings/settingsReducer";
import ToasterReducer from "./toaster/toasterReducer";
import UserReducer from "./user/userReducer";

const appReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  prismic: PrismicReducer,
  request: RequestReducer,
  settings: SettingsReducer,
  user: UserReducer,
  requestDropdown: RequestTypeSubtypeReducer,
  toast: ToasterReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
