import React, { useEffect, useState } from "react";
import { Heading, Text } from "@leaseplan/ui";
import styled from "styled-components";
import { Spacing } from "../utils/theme";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLabel } from "../utils/prismicUtils";
import { getRequestCount } from "../store/request/requestApi";
import { showTutorialData } from "../store/commonStore/commonActionCreators";
import { useSelector, useDispatch, connect } from "react-redux";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { isEmpty } from "lodash";
import eventLog from "../config/firebase";
import { VIEW_TUTORIAL } from "../helpers/logEvents";
const Header = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("pageNumber") || 1;
  const pageSize = searchParams.get("pageSize") || 20;
  const orderField = searchParams.get("orderField");
  const order = searchParams.get("order") || "desc";
  const driverName = searchParams.get("driverName");
  const subject = searchParams.get("subject");
  const status = searchParams.get("status");
  const statusArray = status?.split(",").filter((i) => !isEmpty(i));
  const vehicleNumber = searchParams.get("vehicleNumber");
  const clientUnitNumber = searchParams.get("clientUnitNumber");
  const vin = searchParams.get("vin");
  const requestType = searchParams.get("requestType") || "all";
  const createUserName = searchParams.get("createUserName");
  const search = searchParams.get("search");
  const createDateStart =
    searchParams.get("createDateStart") &&
    decodeURIComponent(searchParams.get("createDateStart"));
  const createDateEnd =
    searchParams.get("createDateEnd") &&
    decodeURIComponent(searchParams.get("createDateEnd"));
  const caseNumber = searchParams.get("caseNumber");
  const readStatus = searchParams.get("readStatus");
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const { showTutorial } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { count } = useSelector((state) => state.common);

  const countProp = count ? count : props.count;

  const updateSearchQuery = (obj) => {
    setSearchParams({
      pageNumber,
      pageSize: pageSize <= 200 ? pageSize : 20,
      ...(orderField &&
        order && {
          orderField,
          order,
        }),
      ...(driverName && { driverName }),
      ...(subject && { subject }),
      ...(statusArray?.length > 0 && {
        status: statusArray,
      }),
      ...(vehicleNumber && { vehicleNumber }),
      ...(clientUnitNumber && { clientUnitNumber }),
      ...(vin && { vin }),
      ...(requestType && { requestType }),
      ...(readStatus && { readStatus }),
      ...(search && { search }),
      ...(createDateStart && { createDateStart }),
      ...(createDateEnd && { createDateEnd }),
      ...(caseNumber && { caseNumber }),
      ...obj,
    });
  };

  const showToolTip = (status) => {
    status &&
      eventLog({
        eventName: VIEW_TUTORIAL,
      });
    dispatch(showTutorialData({ type: !showTutorial }));
  };
  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );

  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );
  return (
    <HeaderContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Heading size="s">
          {getLabel("workspace_heading", "ePlan Workspace", prismicData)}
        </Heading>
        {clientSettingValue !== "T" && (
          <TutorialIcon
            onMouseEnter={() => showToolTip(true)}
            onMouseLeave={() => showToolTip(false)}
          />
        )}
      </div>
      {countProp && (
        <LeftContents>
          {/* <TextWrapper style={{cursor:'auto'}} unread="unread">
            <BoldText size="m" color="petrolBlue">
              {countProp?.unread}
            </BoldText>
            <Text size="xs" color="petrolBlue">
              {getLabel("unread_count_title", "Unread", prismicData)}
            </Text>
          </TextWrapper> */}
          <TextWrapper
            onClick={() =>
              updateSearchQuery({
                pageNumber: 1,
                status: ["New", "Sending"].reduce(
                  (acc, item, index) => `${item},${acc}`,
                  ""
                ),
                tab: 1,
              })
            }
          >
            <BoldText size="m" color="petrolBlue">
              {countProp?.newStatus}
            </BoldText>
            <Text size="xs" color="petrolBlue">
              {getLabel("new_request_count_title", "New", prismicData)}
            </Text>
          </TextWrapper>
          <TextWrapper
            onClick={() =>
              updateSearchQuery({
                pageNumber: 1,
                status: "In Progress",
                tab: 1,
              })
            }
          >
            <BoldText size="m" color="petrolBlue">
              {countProp?.inProgress}
            </BoldText>
            <Text size="xs" color="petrolBlue">
              {getLabel(
                "inprogress_request_count_title",
                "In Progress",
                prismicData
              )}
            </Text>
          </TextWrapper>
          <TextWrapper
            onClick={() => {
              updateSearchQuery({
                pageNumber: 1,
                status: "Completed",
                tab: 2,
              });
            }}
          >
            <BoldText size="m" color="petrolBlue">
              {countProp?.completed}
            </BoldText>
            <Text size="xs" color="petrolBlue">
              {getLabel(
                "completed_request_count_title",
                "Completed",
                prismicData
              )}
            </Text>
          </TextWrapper>
        </LeftContents>
      )}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  margin: ${Spacing.xxl} ${Spacing.xl} ${Spacing.xs} ${Spacing.xxl};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  @media (min-width: 600px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;
const LeftContents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TextWrapper = styled.div`
  margin-right: ${Spacing.l};
  cursor: pointer;
  text-decoration: ${(props) => props.unread !== "unread" && "underline"}
};

`;

const BoldText = styled(Text)`
  font-weight: bold;
  margin-right: ${Spacing.xxxs};
`;

const TutorialIcon = styled(HelpOutlineIcon)`
  margin-top: -${Spacing.s};
  margin-left: ${Spacing.l};
  color: #a6a6a6;
  cursor: pointer;
`;
