import {
	PRISMIC_FETCH_REQUEST,
	PRISMIC_FETCH_SUCCESS,
	PRISMIC_FETCH_FAIL,
} from './prismicActions';

const initialState = {
	isLoading: null,
	prismicData: null,
	error: null,
};

const PrismicReducer = (state = initialState, action) => {
	switch (action.type) {
		case PRISMIC_FETCH_REQUEST:
			return {
				...state,
				isLoading: true,
				error: {},
			};
		case PRISMIC_FETCH_SUCCESS:
			return {
				...state,
				isLoading: false,
				prismicData: action.payload,
				error: {},
			};
		case PRISMIC_FETCH_FAIL:
			return {
				...state,
				isLoading: false,
				prismicData: {},
				error: action.payload || {},
			};

		default:
			return state;
	}
};

export default PrismicReducer;

export const PrismicSelector = (state) => {
	const { prismicData, error } = state.prismic;
	return {
		prismicData,
		error,
	};
};
