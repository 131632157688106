import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import { Colors } from "../../utils/theme";

const ItemSelect = ({ onClickCheckBox, uniqueID, selectedIncidents }) => {
  const onClickItem = (e) => {
    e.stopPropagation();
    onClickCheckBox(uniqueID);
  };

  return (
    <CustomCheckbox
      size="medium"
      checked={selectedIncidents[uniqueID] || false}
      onClick={onClickItem}
      sx={{
        "&.Mui-checked": {
          color: Colors.petrolBlue,
        },
      }}
    />
  );
};

export default ItemSelect;

const CustomCheckbox = styled(Checkbox)`
  color: ${Colors.lightGrey};
  padding: 0;
  :hover {
    color: ${Colors.petrolBlue};
    background-color: transparent;
  }
`;
