export const Colors = {
  bloodOrange: "#ee4c14",
  lightOrange: "#ed8b00",
  midOrange: "#f06400",
  mist: "#f6f8fb",
  aquaBlue: "#00b1af",
  freshGreen: "#a9c90e",
  grassGreen: "#009a17",
  lightGreen: "#4AFF71",
  petrolBlue: "#004a5d",
  petrolBlue80: "#336e7d",
  petrolBlue60: "#66929e",
  petrolBlue40: "#99b7b3",
  petrolBlue20: "#ccdbdf",
  black: "#000000",
  thunder: "#2d2d2d",
  steel: "#545656",
  steel80: "#767878",
  steel60: "#989a9a",
  steel40: "#bbbbbb",
  steel20: "#dddddd",
  gallery: "#eeeeee",
  sand: "#f6f6f6",
  white: "#ffffff",
  success: "#72f57e",
  lightSuccess: "#dde99f",
  error: "#e7401e",
  lightDanger: "#fad9d2",
  darkRed: "#a62929",
  lightRed: "#F3E0DF",
  chatSender: "#99e0df",
  chatReceiver: "#eeeeee",
  tableHeader: "#fafafa",
  viewedRow: "#f5f8fb",
  cancelled: "#bdbdbd",
  completed: "#41ad32",
  lightGrey: "#d4d4d4",
  textInput: "#8c8c8c",
  disabled: "#dadada",
  statusBackground: "#fbe8cc",
};

export const Spacing = {
  xxxs: "0.25rem", //4px
  xxs: "0.5rem", //8px
  xs: "0.625rem", //10px
  s: "0.75rem", //12px
  m: "1rem", //16px
  l: "1.25rem", //20px
  xl: "1.5rem", //24px
  xxl: "1.875rem", //30px
  xxxl: "2.5rem",
  xxxxxl: "5.5rem", //40px
};

export const ConvertSize = (x) => {
  return `${Math.round((x / 16) * 1000) / 1000}rem`;
};
