import React from "react";
import { createRoot } from 'react-dom/client';
import "@leaseplan/ui/fonts/LeasePlan.css";
// import './index.css';
import App from "./App";
import GlobalStyles from "./GlobalStyles";
import { Provider } from "react-redux";
import store from "./store/store";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';



/* A way to render the app in a different way. */
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.Fragment>
  <GlobalStyles />
  <Provider store={store}>
    <App />
  </Provider>
</React.Fragment>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();