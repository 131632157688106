import React, { useState } from "react";

import Select from "@mui/material/Select";
import styled from "styled-components";
import { Colors, ConvertSize, Spacing } from "../utils/theme";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import uuid from "react-uuid";

import { getLabel } from "../utils/prismicUtils";
import "./Selectors.scss";
const Selectors = (props) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    props.onChange(value);
  };
  const loadMoreFunc = () => {
    props.loadMore();
  };
  return (
    <>
      {props.isSmartSearch ? (
        <>
          <SmartSearch className="customScroller">
            {props.isDriverAssignedVehicle ? (
              <Count onClick={loadMoreFunc}>
                {" "}
                {props.data?.length}{" "}
                {getLabel("count_text", "results found", prismicData)}
              </Count>
            ) : null}
            {props.data && props.data.length > 0 ? (
              <table cellspacing="0">
                {props.isVehicle === true ? (
                  <>
                    <thead>
                      <tr>
                        <th width="25%">
                          {getLabel("search_vehicle", "Vehicle", prismicData)}
                        </th>
                        {props.isDriverAssignedVehicle ? (
                          <th width="5%">
                            {" "}
                            {getLabel(
                              "search_vehicle_status",
                              "Status",
                              prismicData
                            )}
                          </th>
                        ) : null}
                        <th width="15%">
                          {getLabel("search_eplan_unit", "ePlan#", prismicData)}
                        </th>
                        <th width=" 15%">
                          {" "}
                          {getLabel(
                            "search_client_unit",
                            "Client Unit#",
                            prismicData
                          )}
                        </th>
                        <th width="15%">
                          {" "}
                          {getLabel("vin_title", "VIN", prismicData)}
                        </th>
                        {props.showDriverName ? (
                          <th width=" 25%">
                            {" "}
                            {getLabel(
                              "search_driver_name",
                              "Driver Name",
                              prismicData
                            )}
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                  </>
                ) : null}
                {props.isDriver === true ? (
                  <thead>
                    <tr>
                      <th width="15%">
                        {" "}
                        {getLabel("search_driver_id", "Driver ID", prismicData)}
                      </th>
                      <th width="15%">
                        {" "}
                        {getLabel(
                          "search_driver_name",
                          "Driver Name",
                          prismicData
                        )}
                      </th>
                      <th width=" 15%">
                        {getLabel(
                          "search_driver_location",
                          "Location",
                          prismicData
                        )}
                      </th>
                      <th width=" 15%">
                        {getLabel("mail_title", "Email", prismicData)}
                      </th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>
                  {props.data?.map(props.renderItem)}
                  {props.loadMore && (
                    <LoadMoreData>
                      {props.loadingMore == true ? (
                        <Loading type="downloadCSV" />
                      ) : (
                        <Load onClick={loadMoreFunc}>
                          {" "}
                          {getLabel("load_more_text", "Load More", prismicData)}
                        </Load>
                      )}
                    </LoadMoreData>
                  )}
                </tbody>
              </table>
            ) : (
              <table>
                <div style={{ margin: "10px" }}>
                  {getLabel(
                    "no_result_found_text",
                    "No Results Found.",
                    prismicData
                  )}
                </div>
              </table>
            )}
          </SmartSearch>{" "}
        </>
      ) : null}
      {props.isDropdown && (
        <Select
          multiple={props.isMultiple}
          native
          value={props.value}
          inputProps={{
            id: "select-multiple-native",
          }}
          style={{ width: "100%" }}
          onChange={handleChangeMultiple}
        >
          {props.data.map((name) => (
            <option key={uuid()} value={name}>
              {name}
            </option>
          ))}
        </Select>
      )}
    </>
  );
};
export default Selectors;
const SmartSearch = styled.div`
  background-color: ${Colors.white};
  border-radius: ${Spacing.xxxs};
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
  max-height: ${ConvertSize(205)};
  width: 100%;
  overflow: auto;
  font-family: LeasePlan;
`;

const Load = styled.p`
  width: fit-content;
  cursor: pointer;
  color: ${Colors.midOrange};
  font-weight: bold;
  text-decoration: underline;
  margin
`;
const Count = styled.p`
  width: fit-content;
  cursor: pointer;
  color: ${Colors.midOrange};
  font-weight: bold;
  font-size: 10px;
  margin: 5px;
  margin-top: 10px;
`;

const LoadMoreData = styled.div`
  margin-left: ${Spacing.s};
  margin-top: ${Spacing.xxs};
  margin-bottom: ${Spacing.xxs};
  display: flex;
  align-items: center;
`;

const TextContrainer = styled.div`
  margin-left: ${Spacing.s};
  margin-top: ${Spacing.xxs};
  justify-content: space-between;
  margin-right: ${Spacing.s};
  display: flex;
`;
