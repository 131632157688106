import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Grid,
  Textarea,
  GridItem,
  TextInput,
  Text,
  Button as LPbutton,
  CloseIcon,
} from "@leaseplan/ui";
import { useFormik } from "formik";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MailOutlineRounded from "@mui/icons-material/MailOutlineRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button } from "@mui/material";
import Path from "./../../assets/images/Path.svg";
import dompurify from "dompurify";
import _ from "lodash";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import Select from "@mui/material/Select";
import Selectors from "../Selectors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { hideSidePanel } from "../../store/commonStore/commonActionCreators";
import { connect, useSelector, useDispatch } from "react-redux";
import Search from "./Search";
import Comment from "./Comment";
import Upload from "./UploadDetails";
import FileUpload from "./FileUpload";
import { useState } from "react";
import { isEmpty } from "../../utils/stringFunctions";
import { getLabel } from "../../utils/prismicUtils";
import {
  createRequest,
  getDrivers,
  getVehicles,
  uploadAttachments,
} from "../../store/request/requestApi";
import { LEGACY_URL } from "../../utils/constants";
import NavigateConfirmation from "./LinkedRequest/NavigateConfirmation";
import { showToaster } from "../../store/toaster/toasterActions";
import SelectedVehicleCard from "./SelectedEntityCard";
import SelectedDriverCard from "./SelectedDriverCard";
import externalIcon from "../../assets/images/external.png";
import ExternalLink from "./ExternalLink.jsx";
const sanitizer = dompurify.sanitize;

const QuickLinksPanel = (props) => {
  const dispatch = useDispatch();
  function hideSidePanel() {
    props.hideSidePanel();
  }
  const { quickLinktype, type } = useSelector((state) => state.common);
  const clientNumber = useSelector((state) => state.user.userData.clientNumber);

  const onSubmit = async (results) => {
    const {
      description,
      type,
      subType,
      driverNumber,
      vehicleNumber,
      parentId,
    } = results;

    const requestBody = {
      description,
      type,
      subType,
      driverNumber,
      vehicleNumber,
      parentId,
    };
    props.hideSidePanel();
    if (quickLinks[0]?.subcategory?.actionAction == "EXTERNAL") {
      window.open(quickLinks[0]?.subcategory?.actionLink, "_blank");
    } else if (quickLinks[0]?.subcategory?.actionAction == "EPLAN") {
      if (quickLinks[0]?.subcategory?.actionText1) {
        const params1 = quickLinks[0]?.subcategory?.actionText1.replace(
          "[DRIVER]",
          driverNumber
        );
        const params2 = params1.replace("[CLIENT]", clientNumber);
        const params3 = params2.replace("[VEHICLE]", vehicleNumber);
        // console.log(
        //   `${quickLinks[0]?.subcategory?.actionLink}?${params3}`,
        //   LEGACY_URL
        // );
        // window.opener.postMessage(
        //   `${quickLinks[0]?.subcategory?.actionLink}?${params3}`,
        //   LEGACY_URL
        // );
        // dispatch(
        //   showToaster({
        //     type: "WARNING",
        //     message: "Please restore the Legacy window to continue",
        //   })
        // );
        window.open(
          LEGACY_URL + `${quickLinks[0]?.subcategory?.actionLink}?${params3}`,
          "_eplan"
        );
      } else {
        // window.opener.postMessage(
        //   `${quickLinks[0]?.subcategory?.actionLink}`,
        //   LEGACY_URL
        // );

        // dispatch(
        //   showToaster({
        //     type: "WARNING",
        //     message: "Please restore the Legacy window to continue",
        //   })
        // );
        window.open(
          LEGACY_URL + `${quickLinks[0]?.subcategory?.actionLink}`,
          "_eplan"
        );
      }
    }
  };
  const [types, setTypes] = useState([]);

  const [names, setNames] = useState([]);
  const [searchText, setSearchText] = useState("");

  const validate = (results) => {
    const errors = {};
    if (quickLinks[0]?.subcategory?.actionDisplay === "DRIVER") {
      if (isEmpty(results.driverNumber)) {
        errors.driver = "Driver is Required";
      }
    }
    if (quickLinks[0]?.subcategory?.actionDisplay === "VEHICLE") {
      if (isEmpty(results.vehicleNumber)) {
        errors.vehicle = "Vehicle is Required";
      }
    }
    return errors;
  };

  const { values, touched, errors, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        type: types[0],
        subType: null,
        description: null,
        driverNumber: null,
        driver: null,
        vehicleNumber: null,
        vehicle: null,
        parentId: null,
      },
      onSubmit,
      validate,
    });

  const [searchedDrivers, setSearchedDrivers] = useState([]);
  const [searchedVehicles, setSearchedVehicles] = useState([]);
  const [isLoadingSearch, setLoadingSearch] = useState(false);
  const [showSearchedDrivers, setShowSearchedDrivers] = useState(false);
  const [showSearchedVehicles, setShowSearchedVehicles] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const [uploadedData, setUploadedData] = useState([]);
  const [displaySelectedEntity, setDisplaySelectedEntity] = useState(false);
  const [displaySelectedDriver, setDisplaySelectedDriver] = useState(false);
  const [searchVehicleDisable, setSearchVehicleDisable] = useState(false);
  const [searchDriverDisable, setSearchDriverDisable] = useState(false);
  const [selectedVehicleRecord, setSelectedVehicleRecord] = useState([]);
  const [selectedDriverRecord, setSelectedDriverRecord] = useState([]);
  const [selectedVehicleData, setSelectedVehicleData] = useState({});
  const [selectedDriverData, setSelectedDriverData] = useState({});
  const [loadMoreEnable, setLoadMoreEnable] = useState(false);
  const [searchedVehiclesCurrentPage, setSearchedVehiclesCurrentPage] =
    useState(1);
  const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  const quickLinks = useSelector((state) => {
    return state.requestDropdown.quicklinks.filter((val) => {
      if (
        val?.subcategory?.actionText2?.toLowerCase() ===
        quickLinktype.toLowerCase()
      ) {
        return val;
      }
    });
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSidePanel = async () => {
    if (values?.vehicle?.length > 0) {
      setAnchorEl(true);
    } else if (values?.driver?.length > 0) {
      setAnchorEl(true);
    } else {
      props.hideSidePanel();
    }
  };
  useEffect(() => {
    try {
      if (quickLinks.length) {
        setTypes([quickLinks[0]?.category?.categoryDescription]);
        setNames([quickLinks[0]?.subcategory?.categoryDescription]);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#044a5d",
      },
    },
  });

  const onFileUpload = (e) => {
    setUploadedData(e);
  };

  const onChangeRequestType = (value) => {
    setFieldValue("type", value);
    setDisplaySelectedEntity(false);
  };

  const onChangeRequestSubType = (value) => {
    setFieldValue("subType", value);
    setDisplaySelectedEntity(false);
  };

  const initialRender = useRef(true);
  const prevDriver = useRef(null);
  const prevVehicle = useRef(null);
  const loadMoreRef = useRef(null);
  useEffect(() => {
    if (!initialRender.current) {
      if (values.driver !== prevDriver.current) {
        prevDriver.current = values.driver;
        if (values.driver.length > 0) {
          props.showPopupWarning("description", true);
          onSearchDriver(values.driver);
        } else {
          props.showPopupWarning("description", false);
        }
        return;
      }
      if (values.vehicle !== prevVehicle.current) {
        prevVehicle.current = values.vehicle;
        if (values.vehicle.length > 0) {
          onSearchVehicle(values.vehicle);
          props.showPopupWarning("description", true);
        } else {
          props.showPopupWarning("description", false);
        }
        return;
      }
    }
    initialRender.current = false;
  }, [debouncedValue]);

  const onSearchDriver = _.debounce(async (text, pageNumber) => {
    setSearchText(text);
    if (pageNumber) {
      loadMoreRef.current = true;
    }
    const res = await getDrivers({
      assignedvehicle: values.vehicleNumber,
      keyword: text,
      pageNumber: pageNumber ? pageNumber : searchedVehiclesCurrentPage,
    });
    if (res.data.drivers && res.data.drivers.length == 6) {
      setSearchedDrivers(
        res.data.currentPage == 1
          ? res.data.drivers.slice(0, -1)
          : [...searchedDrivers, ...res.data.drivers.slice(0, -1)]
      );
    } else {
      setSearchedDrivers(
        res.data.currentPage == 1
          ? res.data.drivers
          : [...searchedDrivers, ...res.data.drivers]
      );
    }
    setShowSearchedDrivers(true);
    setLoadingSearch(false);
    loadMoreRef.current = false;
  }, 1000);

  const onSearchVehicle = _.debounce(async (text, pageNumber) => {
    setSearchText(text);
    if (pageNumber) {
      loadMoreRef.current = true;
    }

    const res = await getVehicles({
      assigneddriver: values.driverNumber,
      keyword: text,
      pageNumber: pageNumber ? pageNumber : searchedVehiclesCurrentPage,
    });

    if (res.data.vehicles && res.data.vehicles.length == 6) {
      if (res.data.vehicles.length == 6) {
        setLoadMoreEnable(true);
      } else {
        setLoadMoreEnable(false);
      }
      setSearchedVehicles(
        res.data.currentPage == 1
          ? res.data.vehicles.slice(0, -1)
          : [...searchedVehicles, ...res.data.vehicles.slice(0, -1)]
      );
    } else {
      if (res.data.vehicles.length == 6) {
      } else {
        setLoadMoreEnable(false);
      }
      setSearchedVehicles(
        res.data.currentPage == 1
          ? res.data.vehicles
          : [...searchedVehicles, ...res.data.vehicles]
      );
    }

    setShowSearchedVehicles(true);
    setLoadingSearch(false);
    loadMoreRef.current = false;
  }, 1000);

  const searchDriverData = _.debounce(() => {
    setSearchedVehiclesCurrentPage(1);
    setDebouncedValue(values.driver);
  }, 1500);

  const searchVehicleData = _.debounce(() => {
    setSearchedVehiclesCurrentPage(1);
    setDebouncedValue(values.vehicle);
  }, 1500);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: Colors.midOrange,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Colors.midOrange,
      padding: "10px",
      paddingRight: "25px",
      paddingLeft: "25px",
      fontSize: "12px",
    },
  }));

  const MailIcon = ({ title }) => {
    return (
      <IconsWrapper>
        <BootstrapTooltip title={title} arrow placement="top">
          <MailOutlineRounded htmlColor={Colors.steel60} />
        </BootstrapTooltip>
      </IconsWrapper>
    );
  };

  var reg = new RegExp(searchText, "gi");

  const renderDriverItems = (item, index) => {
    let replacekey = "";
    // return (
    // 	<TextContainer onClick={onClickDriver(item)}>
    // 		<Text size="s">{`${item.firstName} ${item.lastName}`}</Text>
    // 		<MailIcon title={item.email} />
    // 	</TextContainer>
    // );
    return (
      <tr onClick={onClickDriver(item)} class="tablerow">
        {/* <td>
					{boldText(item.vehicleYear)}
					{boldText(item.vehicleMake)}
					{boldText(item.vehicleModel)}
				</td> */}
        {/* <td class="searchCell-body">
					<img src={getDriverIcon(item.status)}></img>
					{`${item.driverId} `}
				</td>
				<td class="searchCell-body">{`${item.firstName} ${item.lastName}`}</td>
				<td class="searchCell-body"> {`${item.city}, ${item.state}`}</td>
				<img class="arrow" src={Path}></img> */}
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `<img src=${getDriverIcon(item.status)} />
            ${item.driverId.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`
            ),
          }}
        >
          {/* <img src={getDriverIcon(item.status)} />
          {_getStyledSuggestion(item.driverId.toString(), searchText)} */}
        </td>

        <td
          dangerouslySetInnerHTML={{
            __html: `${item.firstName.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
            ${item.lastName.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.firstName, searchText)}{" "}
          {_getStyledSuggestion(item.lastName, searchText)} */}
        </td>
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.city.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
            ${item.state.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`,
          }}
        >
          {/* {" "}
          {_getStyledSuggestion(item.city, searchText)}{" "}
          {_getStyledSuggestion(item.state, searchText)} */}
        </td>
      </tr>
    );
  };
  const undoActionOnVehicle = () => {
    selectedVehicleRecord.pop();
    setSelectedVehicleData(selectedVehicleRecord[0]);
    setFieldValue(
      "vehicle",
      `${selectedVehicleRecord[0].vehicleNumber} - ${selectedVehicleRecord[0].vehicleYear} ${selectedVehicleRecord[0].vehicleMake} ${selectedVehicleRecord[0].vehicleModel}`
    );
    setFieldValue(
      "vehicleNumber",
      selectedVehicleRecord[0].vehicleNumber?.toString()
    );
  };
  const undoActionOnDriver = () => {
    selectedDriverRecord.pop();
    setSelectedDriverData(selectedDriverRecord[0]);
    setFieldValue(
      "driver",
      `${selectedDriverRecord[0].firstName} ${selectedDriverRecord[0].lastName}`
    );
    setFieldValue(
      "driverNumber",
      selectedDriverRecord[0].driverNumber?.toString()
    );
  };
  const getDriverIcon = (status) => {
    switch (status) {
      case "ASSIGNED":
        return prismicData?.assigned?.url;
      case "HOUSEHOLD":
        return prismicData?.household?.url;
      case "UNASSIGNED":
        return prismicData?.unassigned?.url;
      case "TERMINATED":
        return prismicData?.terminated?.url;
    }
  };
  const getVehicleIcon = (status) => {
    switch (status) {
      case "O":
        return prismicData?.client_owned?.url;
      case "F":
        return prismicData?.fleet_management?.url;
      case "R":
        return prismicData?.fleet_management?.url;
      case "B":
        return prismicData?.leased_on_fleet_management?.url;
      case "S":
        return prismicData?.pnd?.url;
      case "P":
        return prismicData?.sale_in_progress?.url;
      case "N":
        return prismicData?.service_only?.url;
      default:
        return prismicData?.leased?.url;
    }
  };
  const showVehicles = () => {
    setShowSearchedVehicles(true);
  };
  const showDrivers = () => {
    setShowSearchedDrivers(true);
  };
  const deleteSearchText = (type) => {
    if (type == "vehicle") {
      // setFieldValue("vehicle", "");
      // setFieldValue("vehicleNumber", "");
      setShowSearchedVehicles(true);
      setSearchVehicleDisable(false);
      // setSelectedVehicleData({});
      setDisplaySelectedEntity(true);
    } else if (type == "driver") {
      // setFieldValue("driver", "");
      // setFieldValue("driverNumber", "");
      setShowSearchedDrivers(true);
      // setSelectedDriverData({});
      setSearchDriverDisable(false);
      setDisplaySelectedDriver(true);
    }
  };
  function canDisplayLink() {
    if (quickLinks[0]?.subcategory?.actionDisplay === "VEHICLE") {
      {
        if (
          quickLinks[0]?.subcategory?.actionDisplay === "VEHICLE" &&
          displaySelectedEntity === true
        )
          return true;
        else return false;
      }
    } else return true;
  }
  const clearSelectedEntityCard = (type) => {
    if (type == "vehicle") {
      setDisplaySelectedEntity(false);
      setSelectedVehicleRecord([]);
      setFieldValue("vehicle", "");
      setFieldValue("vehicleNumber", "");
      setShowSearchedVehicles(true);
      setSearchVehicleDisable(false);
      setSelectedVehicleData({});
    } else if (type == "driver") {
      setDisplaySelectedDriver(false);
      setSelectedDriverRecord([]);
      setFieldValue("driver", "");
      setFieldValue("driverNumber", "");
      setShowSearchedDrivers(true);
      setSearchDriverDisable(false);
      setSelectedDriverData({});
      setDisplaySelectedDriver(false);
    }
  };

  const onSearchClear = (field) => {
    if (field == "driver") {
      setFieldValue("driver", "");
    } else {
      setFieldValue("vehicle", "");
    }
  };
  const renderVehicleItems = (item, index) => {
    return (
      <tr onClick={onClickVehicle(item)} class="tablerow">
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `<img src=${getVehicleIcon(item.vehicleType)} />
        		${item.vehicleYear?.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
              ${item.vehicleMake?.toString().replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}
                ${item.vehicleModel?.toString().replace(reg, function (str) {
                  return "<b>" + str + "</b>";
                })}`
            ),
          }}
        >
          {/* <img src={getVehicleIcon(item.vehicleType?.toString())} />
          {_getStyledSuggestion(item.vehicleYear?.toString(), searchText)}{" "}
          {_getStyledSuggestion(item.vehicleMake?.toString(), searchText)}{" "}
          {_getStyledSuggestion(item.vehicleModel?.toString(), searchText)}{" "} */}
        </td>
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.vehicleNumber
              ?.toString()
              .replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.vehicleNumber?.toString(), searchText)} */}
        </td>
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.clientUnitNumber
              ?.toString()
              .replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.clientUnitNumber?.toString(), searchText)} */}
        </td>{" "}
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `...${item.vin
                ?.slice(-8)
                .toString()
                .split(new RegExp(regEscape(searchText), "ig"))
                .join("<b>" + searchText.toUpperCase() + "</b>")} <br> `
            ),
          }}
        ></td>
        {/* {" "} */}
        {/* {_getStyledSuggestion(item.vin?.toString(), searchText)} */}
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.driverName?.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.driverName, searchText)} */}
        </td>
        <img class="arrow" src={Path}></img>
      </tr>
    );
  };

  const onClickDriver = (driver) => () => {
    setSelectedDriverRecord((arr) => [...arr, driver]);
    setSelectedDriverData(driver);
    setFieldValue("driver", `${driver.firstName} ${driver.lastName}`);
    setFieldValue("driverNumber", driver.driverNumber?.toString());
    setShowSearchedDrivers(false);
    if (driver) setDisplaySelectedDriver(true);
    else setDisplaySelectedDriver(false);
  };

  const onClickVehicle = (vehicle) => () => {
    setSelectedVehicleRecord((arr) => [...arr, vehicle]);

    setFieldValue(
      "vehicle",
      `${vehicle.vehicleNumber} - ${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
    );
    setFieldValue("vehicleNumber", vehicle.vehicleNumber?.toString());
    setShowSearchedVehicles(false);
    setSearchVehicleDisable(false);
    setSelectedVehicleData(vehicle);
    if (vehicle) setDisplaySelectedEntity(true);
    else setDisplaySelectedEntity(false);
  };

  const onChangeComment = ({ value }) => {
    setFieldValue("description", value);
  };

  const loadMoreSearch = (type) => {
    setSearchedVehiclesCurrentPage(searchedVehiclesCurrentPage + 1);
    if (type == 1) {
      onSearchVehicle(values.vehicle, searchedVehiclesCurrentPage + 1);
    } else {
      onSearchDriver(values.driver, searchedVehiclesCurrentPage + 1);
    }
  };

  return (
    <DrawerInsideContainer>
      <Grid direction="column" alignContent="center">
        <Header>
          <CloseIconButton onClick={() => handleSidePanel()} />
        </Header>
        <CustomText color="petrolBlue" size="xl" fontWeight="bold">
          {quickLinktype}
        </CustomText>
        <CustomText color="petrolBlue" size="s" fontWeight="bold">
          {getLabel("request_type_title", "Request Type", prismicData)}
          <span style={{ color: "red" }}>*</span>
        </CustomText>
        <Selectors
          isMultiple={false}
          data={types}
          fontWeight="bold"
          isDropdown
          value={values.type}
          onChange={onChangeRequestType}
        />

        <CustomText color="petrolBlue" size="s" fontWeight="bold">
          {getLabel("request_sub_type_title", "Request Sub Type", prismicData)}
          <span style={{ color: "red" }}>*</span>
        </CustomText>
        <Selectors
          isMultiple={false}
          data={names}
          isDropdown
          value={values.subType}
          onChange={onChangeRequestSubType}
        />
        {quickLinks[0]?.subcategory?.actionDisplay === "DRIVER" ? (
          <>
            <CustomText color="petrolBlue" size="s" fontWeight="bold">
              {getLabel("select_driver_title", "Select Driver", prismicData)}
              <span style={{ color: "red" }}>*</span>
            </CustomText>
            <CustomTextHint color="petrolBlue" size="s">
              {getLabel(
                "search_driver_by",
                "Search for driver's name, ID, email, or class",
                prismicData
              )}
            </CustomTextHint>
            <Search
              type="sidePanel"
              placeHolder={getLabel(
                "search_for_driver_hint",
                "Search for driver",
                prismicData
              )}
              name="driver"
              onChange={(e) => {
                handleChange(e);
                setLoadingSearch(true);
                e.nativeEvent.inputType == "insertFromPaste"
                  ? setDebouncedValue(e.target.value)
                  : setDebouncedValue("");
              }}
              value={values.driver}
              error={touched.driver && errors.driver}
              searchLoad={isLoadingSearch && values.driver.length > 0}
              onKeyDown={searchDriverData}
              prismicData={prismicData}
              deleteSearchText={deleteSearchText}
              openSearchResult={() => showDrivers()}
              onSearchClear={() => onSearchClear("driver")}
            />
            {showSearchedDrivers &&
              !isEmpty(values.driver) &&
              !isLoadingSearch && (
                <Selectors
                  isMultiple={true}
                  isDriver={true}
                  data={searchedDrivers}
                  isSmartSearch
                  renderItem={renderDriverItems}
                  loadingMore={
                    loadMoreRef.current == null ? true : loadMoreRef.current
                  }
                  loadMore={searchedDrivers.length > 6 ? loadMoreSearch : null}
                />
              )}
          </>
        ) : null}
        {displaySelectedDriver === true ? (
          <SelectedDriverCard
            selectedDriver={selectedDriverData}
            name="driver"
            driverIcon={getDriverIcon}
            displayCard={displaySelectedDriver}
            deleteSearchText={clearSelectedEntityCard}
            enableUndo={selectedDriverRecord.length > 1 ? true : false}
            undoDriver={undoActionOnDriver}
          />
        ) : null}
        {quickLinks[0]?.subcategory?.actionDisplay === "VEHICLE" ? (
          <>
            <CustomText color="petrolBlue" size="s" fontWeight="bold">
              {getLabel("select_vehicle_title", "Select Vehicle", prismicData)}
              <span style={{ color: "red" }}>*</span>
            </CustomText>
            <CustomTextHint color="petrolBlue" size="s">
              {getLabel(
                "search_vehicle_by",
                "Search for vehicle make, model, VIN, unit #, ePlan #, or driver  name",
                prismicData
              )}
            </CustomTextHint>
            <Search
              type="sidePanel"
              placeHolder={getLabel(
                "search_for_vehicle_hint",
                "Search for vehicle",
                prismicData
              )}
              name="vehicle"
              onChange={(e) => {
                handleChange(e);
                setLoadingSearch(true);
                e.nativeEvent.inputType == "insertFromPaste"
                  ? setDebouncedValue(e.target.value)
                  : setDebouncedValue("");
              }}
              value={values.vehicle}
              deleteSearchText={deleteSearchText}
              error={touched.vehicle && errors.vehicle}
              searchLoad={isLoadingSearch && values.vehicle.length > 0}
              onKeyDown={searchVehicleData}
              prismicData={prismicData}
              openSearchResult={() => showVehicles()}
              onSearchClear={() => onSearchClear("vehicle")}
            />
            {showSearchedVehicles &&
              !isEmpty(values.vehicle) &&
              !isLoadingSearch && (
                <Selectors
                  isMultiple={true}
                  isVehicle={true}
                  data={searchedVehicles}
                  isSmartSearch
                  renderItem={renderVehicleItems}
                  loadingMore={
                    loadMoreRef.current == null ? true : loadMoreRef.current
                  }
                  loadMore={
                    loadMoreEnable == true ? () => loadMoreSearch(1) : null
                  }
                />
              )}
          </>
        ) : null}
        {displaySelectedEntity === true ? (
          <SelectedVehicleCard
            selectedVehicle={selectedVehicleData}
            name="vehicle"
            vehicleIcon={getVehicleIcon}
            displayCard={displaySelectedEntity}
            deleteSearchText={clearSelectedEntityCard}
            enableUndo={selectedVehicleRecord.length > 1 ? true : false}
            undoVehicle={undoActionOnVehicle}
          />
        ) : null}

        <ExternalLink handleSubmit={handleSubmit} />
        {/* 
        <ButtonWrapper>
          <CancelButton
            onClick={() => handleSidePanel()}
            variant="outlined"
            color="secondary"
          >
            {getLabel("cancel_button_title", "Cancel", prismicData)}
          </CancelButton>
          {!canDisplayLink() && (
            <ContinueButton onClick={handleSubmit}>Continue</ContinueButton>
          )}
        </ButtonWrapper> */}
      </Grid>
      <NavigateConfirmation
        anchorEl={anchorEl}
        open={anchorEl}
        handleClose={handleClose}
        handleHideSidePanel={hideSidePanel}
        isLinkedReq={false}
      />
    </DrawerInsideContainer>
  );
};

const DrawerInsideContainer = styled.div`
  margin: ${Spacing.xxl};
  margin-top: ${Spacing.s};
`;
const CustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.m};
`;
const CustomTextHint = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${ConvertSize(-6)};
`;

const ContinueButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  margin-top: ${Spacing.m};
`;

const Image = styled.img`
  height: ${ConvertSize(16)};
  width: ${ConvertSize(16)};
  margin-right: ${ConvertSize(2)};
  margin-top: ${ConvertSize(2)};
  align-self: center;
`;
const Divider = styled.div`
  width: 1,
  background-color: ${Colors.petrolBlue};
  border: ${ConvertSize(1)} solid #eeeeee;
  margin-left: ${ConvertSize(20)};
  align-self: center;
  height:${ConvertSize(68)}
`;

const CancelButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  margin-top: ${Spacing.m};
  border: 1px solid ${Colors.petrolBlue};
  color: ${Colors.petrolBlue};
  :hover {
    color: ${Colors.white};
    background-color: ${Colors.petrolBlue};
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

const Wrapper = styled.div`
  margin: ${Spacing.xxl};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;
const ButtonWrapper = styled.div`
  margin: ${Spacing.xxl};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${Colors.gallery};
  margin-top: ${Spacing.m};
  margin-left: -${Spacing.m};
  margin-right: -${Spacing.m};
`;
const AttachmentText = styled.label`
  color: ${Colors.steel40};
  margin-right: ${Spacing.xl};
  cursor: pointer;
`;

const TextArea = styled(Textarea)`
  margin-left: ${ConvertSize(2)};
  margin-bottom: ${Spacing.xxs};
  border-color: ${Colors.cancelled};
  border-radius: 4px;
`;

const Header = styled.div`
  height: ${Spacing.xl};
  margin-left: -${Spacing.m};
  margin-right: -${Spacing.m};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseIconButton = styled(CloseIcon)`
  cursor: pointer;
  color: ${Colors.midOrange};
  font-size: ${ConvertSize(25)};
  margin-right: ${Spacing.xs};
  margin-top: ${Spacing.s};
`;

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(QuickLinksPanel);
