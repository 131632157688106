import error from "../../assets/images/close.png";
import warning from "../../assets/images/warning.png";
import info from "../../assets/images/info.png";
import acknowledgement from "../../assets/images/allok.png";

export const SHOW_TOASTER = 'SHOW_TOASTER';
export const HIDE_TOASTER = 'HIDE_TOASTER';

export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const INFO = 'INFO';
export const ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT';

export const ERROR_ICON = error;
export const WARNING_ICON = warning;
export const INFO_ICON = info;
export const ACKNOWLEDGEMENT_ICON = acknowledgement;

export const ERROR_TITLE = 'Something Went Wrong';
export const WARNING_TITLE = 'Warning - Needs Attention';
export const INFO_TITLE = 'Informational Toaster';
export const ACKNOWLEDGEMENT_TITLE = 'Proposal Submitted';

export const ERROR_COLOR = '#e7401e';
export const WARNING_COLOR = '#ee8c00';
export const INFO_COLOR = '#00b1af';
export const ACKNOWLEDGEMENT_COLOR = '#009a17';
