import React from "react";
import styled from "styled-components";
import { CloseIcon } from "@leaseplan/ui";
import { Colors, ConvertSize, Spacing } from "../../../utils/theme";
import { getLabel } from "../../../utils/prismicUtils";
import { useSelector } from "react-redux";

const CancelledDuplicate = ({ onClose }) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  return (
    <CancelledConatiner>
      <TextContainter>
        <TextTitle>
          {getLabel("pending_request_count_title", "Pending", prismicData)}
        </TextTitle>
        <Text>
          {getLabel(
            "pending_status_awaiting",
            "Awaiting your Feedback",
            prismicData
          )}
        </Text>
      </TextContainter>
      <IconContainter>
        <CloseIconButton onClick={onClose} />
      </IconContainter>
    </CancelledConatiner>
  );
};

export default CancelledDuplicate;

const CancelledConatiner = styled.div`
  background-color: ${Colors.statusBackground};
  padding: ${Spacing.xxxs};
  height: ${ConvertSize(40)};
`;

const TextTitle = styled.p`
  font-size: ${ConvertSize(12)};
  color: ${Colors.petrolBlue};
  font-weight: bold;
`;
const Text = styled.p`
  font-size: ${ConvertSize(12)};
  color: ${Colors.petrolBlue};
`;

const CloseIconButton = styled(CloseIcon)`
  cursor: pointer;
  color: ${Colors.midOrange};
  font-size: ${ConvertSize(25)};
  margin-right: ${Spacing.xs};
`;
const TextContainter = styled.div`
  width: 100%;
  height: ${ConvertSize(40)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const IconContainter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: ${ConvertSize(40)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
