import React, { useState } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import { Colors, Spacing } from "../../utils/theme";
import Select from "@mui/material/Select";
import Selectors from "../Selectors";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NewRequest from "./NewRequest";
import NewLinkedRequest from "./LinkedRequest/NewLinkedRequest";
import CancelRequest from "./CancelRequest";
import RequestTicket from "./RequestTicket/Chat";
import { useSelector } from "react-redux";
import {
  hideSidePanel,
  refreshTableData,
} from "../../store/commonStore/commonActionCreators";
import { connect, useDispatch } from "react-redux";
import QuickLinksPanel from "./QuickLinksPanel";
import NavigateConfirmation from "./LinkedRequest/NavigateConfirmation";
import { hideToaster } from "../../store/toaster/toasterActions";
const SidePanel = (props) => {
  const dispatch = useDispatch();
  const testing = useSelector((state) => state.common);
  const { panelType, isRead, refreshData } = useSelector(
    (state) => state.common
  );
  const [anchorElReq, setAnchorElReq] = useState(false);
  const [files, setFiles] = useState(false);
  const [description, setDescription] = useState(false);

  const showPopupWarning = (e, value) => {
    if (e == "description") {
      setDescription(value);
    } else if (e == "files") {
      setFiles(value);
    }
  };
  const handleReqClose = () => {
    setAnchorElReq(null);
  };
  const openReq = Boolean(anchorElReq);
  let panelView = null;
  if (panelType === "NewRequest") {
    panelView = <NewRequest showPopupWarning={showPopupWarning} />;
  } else if (panelType === "NewLinkedRequest") {
    panelView = <NewLinkedRequest showPopupWarning={showPopupWarning} />;
  } else if (panelType === "Ticket") {
    panelView = (
      <RequestTicket
        showPopupWarning={showPopupWarning}
        isRead={isRead}
        status={props.common.status}
      />
    );
  } else if (panelType === "onSubmit") {
  } else if (panelType === "quickLinks") {
    panelView = <QuickLinksPanel showPopupWarning={showPopupWarning} />;
  } else if (panelType === "CancelRequest") {
    panelView = <CancelRequest />;
  }
  function hideSidePanel() {
    dispatch(hideToaster());
    if (files || description) {
      setAnchorElReq(true);
    } else {
      props.hideSidePanel();
    }
  }
  return (
    <>
      <NavigateConfirmation
        anchorEl={anchorElReq}
        open={anchorElReq}
        handleClose={handleReqClose}
        handleHideSidePanel={hideSidePanel}
        isLinkedReq={false}
        status={props.common.status}
      />

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            overflowY: "scroll",
            height: "100vh",
            paddingBottom: "10%",
            "@media (min-width: 768px)": {
              width: "50%",
              paddingBottom: "5%",
            },
            "@media (min-width: 1024px)": {
              width: "35%",
              paddingBottom: "0",
            },
          },
        }}
        anchor="right"
        open={true}
        onClose={hideSidePanel}
      >
        <DrawerInsideContainer>{panelView}</DrawerInsideContainer>
      </Drawer>
    </>
  );
};

const DrawerInsideContainer = styled.div`
  //   margin: ${Spacing.xxl};
`;
const Wrapper = styled.div`
  margin: ${Spacing.xxl};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(SidePanel);
