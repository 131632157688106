import React from "react";
import styled from "styled-components";
import { Colors, ConvertSize } from "../../utils/theme";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Text } from "@leaseplan/ui";

const CategoryTooltip = ({ category, read, showTutorial, isLinked }) => {
  const testString = /Eplan/gi;

  return (
    <>
      {category && category.length > (isLinked ? 17 : 19) ? (
        <NewConatiner showTutorial={showTutorial}>
          <Text size="xs" fontWeight={!read && "bold"} color="petrolBlue">
            {(category.charAt(0).toUpperCase() + category.slice(1)).replace(
              testString,
              "ePlan"
            )}
          </Text>
        </NewConatiner>
      ) : (
        <NewConatinerWithoutHover showTutorial={showTutorial}>
          <Text size="xs" fontWeight={!read && "bold"} color="petrolBlue">
            {(category.charAt(0).toUpperCase() + category.slice(1)).replace(
              testString,
              "ePlan"
            )}
          </Text>
        </NewConatinerWithoutHover>
      )}
    </>
  );
};

export default CategoryTooltip;

const NewConatiner = styled.div`
  display: flex;

  white-space: nowrap;
  transform: ${(props) => !props.showTutorial && `translateX(0)`};
  transition: ${(props) => !props.showTutorial && `3s`};

  :hover {
    transform: ${(props) =>
      !props.showTutorial && `translateX(calc(200px - 220%))`};
  }
`;
const NewConatinerWithoutHover = styled.div`
  display: flex;

  white-space: nowrap;
  transform: ${(props) => !props.showTutorial && `translateX(0)`};
  transition: ${(props) => !props.showTutorial && `1s`};
`;
