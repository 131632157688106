export const PRISMIC_FETCH_REQUEST = 'PRISMIC_FETCH_REQUEST';
export const PRISMIC_FETCH_SUCCESS = 'PRISMIC_FETCH_SUCCESS';
export const PRISMIC_FETCH_FAIL = 'PRISMIC_FETCH_FAIL';

export const prismicFetchRequest = () => ({
	type: PRISMIC_FETCH_REQUEST,
});

export const prismicFetchSuccess = (data) => ({
	type: PRISMIC_FETCH_SUCCESS,
	payload: data,
});

export const prismicFetchFail = (error) => ({
	type: PRISMIC_FETCH_FAIL,
	payload: error,
});
