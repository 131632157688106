import React, { useState } from "react";
import { Textarea, Text, Button as LPbutton } from "@leaseplan/ui";
import { Colors, Spacing, ConvertSize } from "../../utils/theme";
import { Button } from "@mui/material";
import styled from "styled-components";
import { ThemeProvider } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RichTextEditor from "react-rte";
import { getLabel } from "../../utils/prismicUtils";
import { useSelector } from "react-redux";

import "./SelectedEntityCard.scss";
const SelectedDriverCard = (props) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  return (
    <>
      {props && props.selectedDriver && props.displayCard === true ? (
        <>
          <CustomText color="petrolBlue" size="s" fontWeight="bold">
            {getLabel("selected_driver_title", "Selected Driver", prismicData)}
            {props.enableUndo === true ? (
              <>
                <span class="update">
                  {" "}
                  {getLabel(
                    "selection_updated_title",
                    "Selected Updated.",
                    prismicData
                  )}
                </span>{" "}
                &nbsp;
                <span class="undo" onClick={() => props.undoDriver()}>
                  {getLabel("undo_title", "Undo", prismicData)}
                </span>
              </>
            ) : null}
          </CustomText>

          <div class="selectedEntity">
            <span
              class="removeText"
              onClick={() => props.deleteSearchText(props.name)}
            >
              {getLabel("remove_title", "Remove", prismicData)}
            </span>
            <div style={{ display: "flex" }}>
              {" "}
              <div>
                <span class="vehicleData1">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("search_driver_id", "Driver ID", prismicData)}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedDriver.driverId}`}
                  </Text>
                </span>
                <span class="vehicleData1">
                  {" "}
                  <Text
                    style={{
                      letterSpacing: "0px !important",
                      fontSize: "12px",
                    }}
                    color="petrolBlue"
                    size="s"
                    className="removespacing"
                  >
                    {getLabel("search_driver_name", "Driver Name", prismicData)}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedDriver.firstName} ${props.selectedDriver.lastName} `}{" "}
                  </Text>
                </span>
                <span class="vehicleData1">
                  {" "}
                  <Text
                    style={{
                      fontSize: "12px",
                      letterSpacing: "0px!important",
                    }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("search_driver_class", "Class", prismicData)}{" "}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedDriver.classCodeDescription} `}
                  </Text>
                </span>
              </div>
              <hr class="vertical" color="#eeeeee" style={{ margin: "15px" }} />
              <div>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("search_driver_status", "Status", prismicData)}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    <img
                      src={props.driverIcon(props.selectedDriver.status)}
                      style={{ marginRight: "5px" }}
                    />
                    {`${props.selectedDriver.status} `}
                  </Text>
                </span>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel(
                      "search_driver_location",
                      "Location",
                      prismicData
                    )}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedDriver.address1} ${props.selectedDriver.address2}`}
                    <br />
                    {`${props.selectedDriver.city} ${props.selectedDriver.state} ${props.selectedDriver.zipCode}`}
                  </Text>
                </span>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel(
                      "mail_title",
                      "Email",
                      prismicData
                    )}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedDriver.email}`}
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SelectedDriverCard;
const CustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.s};
  font-size: 12px;
`;
