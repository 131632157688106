import React, { useState } from "react";
import { Textarea, Text, Button as LPbutton } from "@leaseplan/ui";
import { Colors, Spacing, ConvertSize } from "../../utils/theme";
import { Button } from "@mui/material";
import styled from "styled-components";
import { ThemeProvider } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RichTextEditor from "react-rte";
import { getLabel } from "../../utils/prismicUtils";
import { useSelector } from "react-redux";
import "./SelectedEntityCard.scss";
const SelectedVehicleCard = (props) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  return (
    <>
      {props && props.selectedVehicle && props.displayCard === true ? (
        <>
          <CustomText color="petrolBlue" size="s" fontWeight="bold">
            {getLabel(
              "selected_vehicle_title",
              "Selected Vehicle",
              prismicData
            )}
            {props.enableUndo === true ? (
              <>
                <span class="update">
                  {" "}
                  {getLabel(
                    "selection_updated_title",
                    "Selected Updated.",
                    prismicData
                  )}
                </span>{" "}
                &nbsp;
                <span class="undo" onClick={() => props.undoVehicle()}>
                  {getLabel("undo_title", "Undo", prismicData)}
                </span>
              </>
            ) : null}
          </CustomText>

          <div class="selectedEntity">
            <span
              class="removeText"
              onClick={() => props.deleteSearchText(props.name)}
            >
              {getLabel("remove_title", "Remove", prismicData)}{" "}
            </span>
            <div style={{ display: "flex" }}>
              {" "}
              <div>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("vehicle_title", "Vehicle", prismicData)}{" "}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedVehicle.vehicleYear} ${props.selectedVehicle.vehicleMake} ${props.selectedVehicle.vehicleModel}`}
                  </Text>
                </span>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel(
                      "search_eplan_unit_title",
                      "eplan #",
                      prismicData
                    )}{" "}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedVehicle.vehicleNumber}`}
                  </Text>
                </span>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("search_driver_id", "Driver ID", prismicData)}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedVehicle.driverId} `}{" "}
                  </Text>
                </span>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("vin_title", "VIN", prismicData)}{" "}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {props.selectedVehicle.vin.substring(0, 9)}
                    <span style={{ fontWeight: "bold" }}>
                      {props.selectedVehicle.vin.slice(-8)}
                    </span>
                  </Text>
                </span>
              </div>
              <hr class="vertical" color="#eeeeee" style={{ margin: "20px" }} />
              <div>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("search_driver_status", "Status", prismicData)}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    <img
                      src={props.vehicleIcon(props.selectedVehicle.vehicleType)}
                      style={{ marginRight: "5px" }}
                    />
                    {`${props.selectedVehicle.vehicleStatusDescription} `}
                  </Text>
                </span>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel(
                      "search_client_unit_title",
                      "Client Unit #",
                      prismicData
                    )}{" "}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedVehicle.clientUnitNumber} `}
                  </Text>
                </span>
                <span class="vehicleData">
                  {" "}
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                  >
                    {getLabel("search_driver_name", "Driver Name", prismicData)}{" "}
                  </Text>
                  <Text
                    style={{ fontSize: "12px" }}
                    color="petrolBlue"
                    size="s"
                    fontWeight="bold"
                  >
                    {`${props.selectedVehicle.driverName} `}{" "}
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SelectedVehicleCard;
const CustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.s};
  font-size: 12px;
`;
