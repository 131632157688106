import React, { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GlobalToaster from "../components/ErrorHandling/GlobalToaster";
import QuickLinks from "../components/QuickLinks";
import styled from "styled-components";
import RequestsCard from "../components/requests/RequestsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  getClientSettings,
} from "../store/settings/settingsActionsCreators";
import { getRequestTypeSubtypeDetails } from "../store/requestTypeSubtype/requestTypeSubtypeThunk";
import { getUserDetails } from "../store/user/userThunk";
import { showSidePanel } from "../store/commonStore/commonActions";
import { useLocation } from "react-router-dom";
import { getRequestCount } from "../store/request/requestApi";
import { setUserIdAndProperties } from "../config/firebase";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getLabel } from "../utils/prismicUtils";
import {
  applyFilters,
  refreshCountData,
} from "../store/commonStore/commonActionCreators";

const Home = () => {
  const dispatch = useDispatch();
  const [toasts, setToasts] = useState([]);
  const [isShown, setPanelShowed] = useState(false);
  const [displayFeedbackButton, setDisplayFeedbackButton] = useState(true);
  const toasterData = useSelector((state) => state.toast);
  const [count, setCount] = useState(null);
  const [paramRedirect, setParamRedirect] = useState(null);
  const [paramRequestType, setParamRequestType] = useState(null);
  const prismicData = useSelector((state) => state.prismic.prismicData);

  const clientNumber = useSelector(
    (state) => state?.user?.userData?.clientNumber
  );
  const userData = useSelector((state) => state.user.userData);
  const types = useSelector((state) => state?.requestDropdown?.requestDropdown);
  const location = useLocation();

  useEffect(() => {
    userData && !userData?.loading && setUserIdAndProperties();
  }, [userData]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getRequestCount();
        setCount(res.data);
        dispatch(refreshCountData());
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    if (!clientNumber && !types) {
      dispatch(getUserDetails());
      dispatch(getSettings());
      dispatch(getClientSettings());
      dispatch(getRequestTypeSubtypeDetails());
    }
    setToasts(toasterData);

    if (paramRedirect == null) {
      setParamRedirect(location?.state?.redirect);
    }
    if (paramRequestType == null) {
      setParamRequestType(location?.state?.requestType);
    }
    if (types && !isShown) {
      if (paramRedirect != null) {
        if (paramRedirect == "NewRequest") {
          if (paramRedirect == "NewRequest") {
            if (
              userData?.role != "CA" &&
              userData?.role != "CS" &&
              userData?.role != "FS" &&
              userData?.role != "LS" &&
              sessionStorage.getItem("v") != "true"
            ) {
              dispatch(showSidePanel({ type: "NewRequest" }));
            }
          }
        } else if (paramRedirect.startsWith("Ticket")) {
          dispatch(
            showSidePanel({
              type: "Ticket",
              requestId: paramRedirect.substring(6),
              requestType: paramRequestType,
            })
          );
        } else if (
          paramRedirect.startsWith("InProgress") ||
          paramRedirect.startsWith("New")
        ) {
          dispatch(
            applyFilters({
              tab: 1,
              pageNumber: 1,
              status: paramRedirect.startsWith("InProgress")
                ? ["In Progress"]
                : ["New", "Sending"].reduce(
                    (acc, item, index) => `${item},${acc}`,
                    ""
                  ),
            })
          );
        }
        setPanelShowed(true);
      }
    }
  }, [toasterData, clientNumber, types, paramRedirect]);
  return (
    <HomeContainer>
      <TopNav iconVisible={true} count={count} />
      <Header count={count} />
      <GlobalToaster messages={toasts} />
      <QuickLinks />
      <RequestsCard />
      <Footer />
      {displayFeedbackButton ? (
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            right: "0px",
            padding: "10px",
            backgroundColor: "#004a5d",
            color: "white",
            fontSize: "10px",
            fontWeight: "bold",
            borderRadius: " 10px 10px 0 0",
            fontFamily: "LeasePlan",
          }}
          //  onClick={() =>{  window.open('https://eplan.ideas.aha.io/ideas/new')}}
          display={displayFeedbackButton}
        >
          <TextWrapper
            onClick={() => {
              window.open(
                getLabel(
                  "feedback_survey_link",
                  "https://eplan.ideas.aha.io/ideas/new",
                  prismicData
                )
              );
            }}
          >
            Feedback Survey
          </TextWrapper>
          <CloseIcon
            onClick={() => setDisplayFeedbackButton(false)}
            style={{
              color: "white",
              marginLeft: "7px !important",
              marginTop: "-5px",
              marginBottom: "-5px",
            }}
            fontSize="small"
          />
        </div>
      ) : null}
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  width: 100%;
`;
const TextWrapper = styled.span`
  cursor: pointer;
`;
