// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import store from "../store/store";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
const analytics = getAnalytics(initializeApp(firebaseConfig));
// const analytics = {}
const getUserData = () => {
  const { user } = store.getState();
  return user;
};
export const setUserIdAndProperties = async () => {
  try {
    const user = getUserData();
    const userId = user?.userData?.userId;
    const client_number = user?.userData?.clientNumber;
    const username = user?.userData?.userName;
    if (userId && client_number) {
      await setUserId(analytics, userId);
      await setUserProperties(analytics, {
        user_id: userId, // Not used for filter (as of now)
        client_id: client_number,
        userid: username,
      });
    }
  } catch (err) {
    console.log("setUserIdAndProperties-err", err);
  }
};

/*  Log a custom event with optional params. */
const logEventFirebase = async (eventObj) => {
  const { eventName = null, additionalParams = {} } = eventObj;
  try {
    const user = getUserData();

    if (
      eventName &&
      eventName !== null &&
      eventName !== undefined &&
      user?.isGetUserDataFail === false &&
      user?.loading === false
    ) {
      const analyticsData = {
        ...user?.userData,
        ...additionalParams,
        env: process.env.REACT_APP_CURRENT_ENV,
      };
      if (process.env.REACT_APP_COLLECT_ANALYTICS)
        await logEvent(analytics, eventName, analyticsData);
    }
  } catch (err) {
    console.log("logEvent-err", err);
  }
};

export default logEventFirebase;
