import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spacing, ConvertSize } from "../../utils/theme";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Grid,
  GridItem,
  Text,
  Select,
  Button as LPbutton,
} from "@leaseplan/ui";
import { getLabel } from "../../utils/prismicUtils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const ExternalLinks = (props) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);
  return (
    <ExternalLink>
      <LinkInner>
        <LinkContainer>
          <Text size="xs" color="petrolBlue" fontWeight="bold">
            {getLabel(
              "external_request_title",
              "External request",
              prismicData
            )}
          </Text>
          <Text size="xs" color="petrolBlue">
            {getLabel(
              "external_request_description",
              "This request will be processed on our legacy site. Click continue to proceed.",
              prismicData
            )}
          </Text>
        </LinkContainer>

        <ExternalContinueButton size="s" onClick={props.handleSubmit}>
          Continue{" "}
          <OpenInNewIcon style={{ marginLeft: "10px", fontSize: "20px" }} />
        </ExternalContinueButton>
      </LinkInner>
    </ExternalLink>
  );
};

export default ExternalLinks;
const ExternalLink = styled.div`
  margin-top: ${Spacing.xxs};
  display: flex;
  height: ${ConvertSize(94)};
  background-color: #fed8b1;
  border: ${ConvertSize(1)} solid #ed8b00;
`;
const LinkInner = styled.div`
  align-self: center;
  flex-direction: row;
  display: flex;
  height: 100%;
  margin-left: 30px;
  width: 100%;
`;
const LinkContainer = styled.div`
  align-self: center;
  flex-direction: column;
  display: flex;
  // margin-left: 30px;
  width: 60%;
`;
const ExternalContinueButton = styled(LPbutton)`
  align-self: flex-end;
  margin: auto;
  padding: 10px 25px;
`;
