import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Button } from "@leaseplan/ui";

import xcircle from "../../assets/images/xcircle.svg";
import { getLabel } from "../../utils/prismicUtils";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import { logFunctionError } from "../../config/errorLog";

const Unauthorizedaccess = (props) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  const onRefreshWindow = () => {
    try{
    window.location.reload();
    }catch(e){
      logFunctionError(e);
    }
  };

  return (
    <UnAuthContainer>
      <Container>
        <Icon src={xcircle} />
        <HeadingText marginTop={Spacing.xl}>
          {getLabel(
            "invalid_access_heading_1",
            "Sorry, Workspace is",
            prismicData
          )}
        </HeadingText>
        <HeadingText marginBottom={Spacing.xl}>
          {getLabel("invalid_access_heading_2", "currently unavailable.", prismicData)}
        </HeadingText>
        <NormalText>
          {getLabel(
            "invalid_access_msg_1",
            "We couldn't connect to Workspace just now. Our",
            prismicData
          )}
        </NormalText>
        <NormalText>
          {getLabel(
            "invalid_access_msg_2",
            "team has been notified of the error.",
            prismicData
          )}
        </NormalText>
        <NormalText marginTop={Spacing.m} marginBottom={Spacing.m}>
          {getLabel(
            "invalid_access_msg_3",
            "Please try refreshing now or check back later.",
            prismicData
          )}
        </NormalText>
        <CloseButton onClick={onRefreshWindow}>
          {getLabel("invalid_access_refresh_button", "Refresh", prismicData)}
        </CloseButton>

        <ErrorText marginTop={Spacing.m} marginBottom={Spacing.m}>
         Error Code: {props.errorMessage}
        </ErrorText>
      </Container>
    </UnAuthContainer>
  );
};
export default Unauthorizedaccess;

const UnAuthContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  margin-top: 8%;
`;

const HeadingText = styled.span`
  color: ${Colors.petrolBlue};
  font-weight: bold;
  font-size: ${ConvertSize(28)};
  text-align: center;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const NormalText = styled.p`
  color: ${Colors.petrolBlue};
  font-size: ${ConvertSize(18)};
  text-align: center;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const ErrorText = styled.p`
width: 366px;
height: 20px;
font-family: LeasePlan;
font-size: 16px;
color: #989a9a;
text-decoration: none solid rgb(152, 154, 154);
text-align: center;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;
const CloseButton = styled(Button)`
  margin-top: ${Spacing.l};
`;

const Icon = styled.img`
  height: ${ConvertSize(100)};
`;
