import server from "../../config/server";

export const getRequestDropdownDetails = async () => {
  const res = await server.get("/support/request/request-type");
  return res;
};

export const getQuicklinksDetails = async () => {
  const res = await server.get(
    "/support/request/request-type/quicklink"
  );
  return res;
};
