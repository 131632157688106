import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";

const initialState = {};

const middlewares = [thunk];

const composeEnhancers = composeWithDevTools({ realtime: true });

const store = createStore(
  rootReducer,
  initialState,
  //applyMiddleware(...middlewares)
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
