import { getRequestCount } from "../request/requestApi";
import * as CommonActions from "./commonActions";

export const showSidePanel = (panelType) => async (dispatch) => {
  dispatch(CommonActions.showSidePanel(panelType));
};

export const hideSidePanel = () => async (dispatch) => {
  dispatch(CommonActions.hideSidePanel());
};
export const setLegacyCommand = (data) => async (dispatch) => {
  dispatch(CommonActions.setLegacyCommand(data));
};
export const refreshTableData = (panelType) => async (dispatch) => {
  dispatch(CommonActions.refreshTableData(panelType));
  const res = await getRequestCount();
  dispatch(CommonActions.countRefresh(res.data));
};
export const showTutorialData = (panelType) => async (dispatch) => {
  dispatch(CommonActions.showTutorialData(panelType));
};

export const refreshCountData = () => async (dispatch) => {
  const res = await getRequestCount();
  dispatch(CommonActions.countRefresh(res.data));
};
export const applyFilters = (data) => async (dispatch) => {
  dispatch(CommonActions.applyFilters(data));
};
