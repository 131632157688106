import server from "../../config/server";

export const login = async (user) => {
  try {
    const res = await server.post("/login", user);
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const validateToken = async (token) => {
  const res = await server.get(`/token?id_token=${token}`);
  return res;
};

export const getNewSession = async (token, refreshToken) => {
  console.log(
    `************************************ Generating new session at ${new Date()} *********************************`
  );
  try {
    const res = await server.get(
      `token?refresh_token=${refreshToken}&id_token=${token}`
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const logout = async (refreshToken) => {
  const res = await server.get(`/logout?refresh_token=${refreshToken}`);
  return res;
};
