import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text } from "@leaseplan/ui";
import TablePagination from "@mui/material/TablePagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useSelector, useDispatch } from "react-redux";
import { getLabel } from "../../utils/prismicUtils";
import moment from "moment";
import "moment-timezone";
import { refreshTableData } from "../../store/commonStore/commonActionCreators";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import eventLog from '../../config/firebase';
import { PAGINATION_SETTINGS } from "../../helpers/logEvents";
const Pagination = ({
  pageNumber,
  pageSize,
  updateSearchQuery,
  removeSearchQuery,
}) => {
  const {
    totalRecords,
    totalPages,
    currentPage,
    recordsInPage,
    refreshDate,
    loading,
  } = useSelector((state) => state.request);
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const onPressPage = (page) => () => {
    updateSearchQuery({
      pageNumber: page,
    });
  };

  const onChangeDropdown = (value) => {
    eventLog({
      eventName:PAGINATION_SETTINGS,
      additionalParams:{
        settingChange:"Number of row changed",
        value
      }
    });
    updateSearchQuery({
      pageSize: value,
      pageNumber: 1,
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);

  useEffect(() => {
    if (pageNumber % rowsPerPage === 0) {
      setPage(parseInt(pageNumber / rowsPerPage) - 1);
    } else {
      setPage(parseInt(pageNumber / rowsPerPage));
    }
  }, [pageSize, rowsPerPage,currentPage]);
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(refreshTableData({ type: false, loading: true }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labelDisplayedRows = ({ from, to, count }) => {
    return (
      <div>
        {Array(to > from && to - from + 1)
          ?.fill(0)
          ?.map((i, index) => {
            return (
              <CustomText
                size="xs"
                color="petrolBlue"
                active={pageNumber == from + index}
                style={{ marginRight: Spacing.l, cursor: "pointer" }}
                onClick={onPressPage(from + index)}
              >
                {from + index}
              </CustomText>
            );
          })}
      </div>
    );
  };

  const ActionsComponent = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
    ...props
  }) => {
    const [pageNo, setPageNo] = useState(currentPage);

    useEffect(() => {
      setPageNo(currentPage);
    }, [currentPage]);

    const onChangePage = (e) => {
      setPageNo(e.target.value);
    };

    const onPressEnter = (e) => {
      eventLog({
        eventName:PAGINATION_SETTINGS,
        additionalParams:{
          settingChange:"Entered Page Number"
        }
      });
      if (pageNo == "" || pageNo < 1) {
        updateSearchQuery({
          pageNumber: 1,
        });
        onPageChange(e, 0);
      } else if (pageNo >= 1 && pageNo <= totalPages) {
        updateSearchQuery({
          pageNumber: pageNo,
        });
        onPageChange(e, pageNo - 1);
      } else {
        updateSearchQuery({
          pageNumber: totalPages,
        });
        onPageChange(e, totalPages - 1);
      }
    };

    const handleFirstPageButtonClick = (event) => {
      eventLog({
        eventName:PAGINATION_SETTINGS,
        additionalParams:{
          settingChange:"First Page Button Clicked"
        }
      });
      onPageChange(event, 0);
      updateSearchQuery({
        pageNumber: 1,
      });
    };

    const handleBackButtonClick = (event) => {
      eventLog({
        eventName:PAGINATION_SETTINGS,
        additionalParams:{
          settingChange:"Back Button Clicked"
        }
      });
      onPageChange(event, page - 1);
      updateSearchQuery({
        pageNumber: currentPage - 1,
      });
    };

    const handleNextButtonClick = (event) => {
      eventLog({
        eventName:PAGINATION_SETTINGS,
        additionalParams:{
          settingChange:"Next Button Clicked"
        }
      });
      onPageChange(event, page + 1);
      updateSearchQuery({
        pageNumber: currentPage + 1,
      });
    };

    const handleLastPageButtonClick = (event) => {
      eventLog({
        eventName:PAGINATION_SETTINGS,
        additionalParams:{
          settingChange:"Last Page Button Clicked"
        }
      });
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      updateSearchQuery({
        pageNumber: totalPages,
      });
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0 || loading}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0 || loading}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <Text size="xs" color="petrolBlue" style={{ marginRight: Spacing.xs }}>
          Page
        </Text>
        <form onSubmit={onPressEnter}>
          <CustomTextInput
            style={{ textAlign: "center" }}
            value={pageNo}
            onChange={onChangePage}
          />
          <input type="submit" hidden />
        </form>
        <Text size="xs" color="petrolBlue" style={{ marginLeft: Spacing.xs }}>
          of
        </Text>
        <Text
          size="xs"
          color="petrolBlue"
          style={{ marginLeft: Spacing.xs, marginRight: Spacing.xs }}
        >
          {totalPages}
        </Text>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1 || loading}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1 || loading}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    );
  };

  const renderComponent = (props) => {
    return (
      <>
        {recordsInPage > 0 ? (
          <Container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* {props.children.props.children[3]} */}
              {props.children.props.children[4]}
            </div>

            <NoOfItems>
              <Text size="xs" color="petrolBlue">
                {getLabel("last_update_message", "Last Refreshed", prismicData)}
                &nbsp; at &nbsp;&nbsp;&nbsp;
                {moment(refreshDate)
                  .tz("America/New_York")
                  .format(`MM/DD/YYYY HH:mm:ss z`)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Text>{" "}
              <Text size="xs" color="petrolBlue">
                Displaying{" "}
                {currentPage > 1 ? (currentPage - 1) * pageSize + 1 : 1} -{" "}
                {currentPage > 1
                  ? (currentPage - 1) * pageSize + recordsInPage
                  : recordsInPage}{" "}
                of {totalRecords}{" "}
              </Text>
              <Separator />
              <div>
                <Text size="xs" color="petrolBlue">
                  Show
                </Text>
                <CustomSelect
                  value={pageSize}
                  onChange={(event) => onChangeDropdown(event.target.value)}
                >
                  {pageSize != 20 &&
                    pageSize != 50 &&
                    pageSize != 100 &&
                    pageSize != 200 && (
                      <option value={pageSize}>{pageSize}</option>
                    )}
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                </CustomSelect>
              </div>
            </NoOfItems>
          </Container>
        ) : (
          <Containers>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <NoOfItems>
                <Text size="xs" color="petrolBlue">
                  {getLabel(
                    "last_update_message",
                    "Last Refreshed",
                    prismicData
                  )}
                  &nbsp; at &nbsp;&nbsp;&nbsp;
                  {moment(refreshDate)
                    .tz("America/New_York")
                    .format(`MM/DD/YYYY HH:mm:ss z`)}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>{" "}
              </NoOfItems>
            </div>
          </Containers>
        )}
      </>
    );
  };

  return (
    <Container>
      {currentPage && (
        <TablePagination
          component={renderComponent}
          count={totalPages}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage="Show"
          ActionsComponent={ActionsComponent}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              color: "green",
            },
          }}
        />
      )}
    </Container>
  );
};

export default Pagination;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.xs};
  padding-left: ${Spacing.xs};
  padding-right: ${Spacing.xs};
  width: 100%;
  margin-bottom: ${Spacing.xxxs};
  background-color: white;
  @media (min-width: 1025px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
const Containers = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.xs};
  padding-left: ${Spacing.xs};
  padding-right: ${Spacing.xs};
  width: 100%;
  margin-bottom: ${Spacing.xxxs};
  background-color: white;
  @media (min-width: 1025px) {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
`;

const CustomText = styled(Text)`
  margin-left: ${Spacing.xs};
  font-size: ${ConvertSize(11)};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
`;

const CustomTextInput = styled.input`
  margin-left: ${Spacing.xxxs};
  border-radius: ${Spacing.xxxs};
  border: 1px solid ${Colors.cancelled};
  color: ${Colors.petrolBlue};
  padding: ${Spacing.xxxs};
  height: ${ConvertSize(34)};
  font-size: ${ConvertSize(14)};
  width: 40px;
  :focus {
    outline: 0;
  }
`;

const CustomSelect = styled.select`
  margin-left: ${Spacing.s};
  padding: ${Spacing.xxs};
  border-radius: ${Spacing.xxxs};
  border-color: ${Colors.cancelled};
  :focus {
    outline: 0;
  }
`;

const NoOfItems = styled.div`
  margin-top: ${Spacing.m};
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }
`;

const Separator = styled.div`
  background-color: white;
  height: ${Spacing.m};
  @media (min-width: 768px) {
    margin-left: ${Spacing.xs};
    margin-right: ${Spacing.xs};
    width: 2px;
    background-color: ${Colors.cancelled};
  }
`;
