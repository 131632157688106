import React from "react";
import { Heading, Text } from "@leaseplan/ui";
import styled from "styled-components";
import { Spacing, ConvertSize } from "../../utils/theme";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { getLabel } from "../../utils/prismicUtils";
import { useSelector } from "react-redux";
import failedIcon from "../../assets/images/failed.svg";
import Loading from "../Loading";
const Upload = (props) => {
  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  const prismicData = useSelector((state) => state.prismic.prismicData);
  function deleteFiles(id) {
    props.deleteFile(id);
  }

  const checkUploaded=(name)=>{
    let isUploaded=false
    props?.loaderList?.map(fileInfo=>{
      if(fileInfo.fileName===name){
        isUploaded=true
      }
    })
    return isUploaded
  }

  return (
    <>
      <SubHead color="petrolBlue" size="s" fontWeight="bold">
        {getLabel("slider_files", "Files", prismicData)}
      </SubHead>
      <LineSeparator />
      {props?.details?.length > 0 ? (
        props?.details?.map((i,index) => {
          return (
            <FlexWrapper>
              <SubHeadText color="petrolBlue">
                <FlexWrapper>
                  <FileIcon /> {i?.name}{" "}
                </FlexWrapper>
                <FileSize>{bytesToSize(i?.size)}</FileSize>
              </SubHeadText>
              {(!checkUploaded(i?.name)) || props.deleteFileID===index? (
                <Loading type="downloadCSV" />
              ) : (
                <img style={{
                 ...(props.deleteFileID!==null && props.deleteFileID!==index && {filter:"grayscale(1)",cursor:"none"})
                }} src={failedIcon} onClick={() => props.deleteFileID===null &&  deleteFiles(i)} />
              )}
            </FlexWrapper>
          );
        })
      ) : (
        <SubText>
          {getLabel(
            "slider_havent_uploaded_file",
            "You haven’t uploaded any files.",
            prismicData
          )}
        </SubText>
      )}
    </>
  );
};

export default Upload;
const FileIcon = styled(InsertDriveFileOutlinedIcon)`
  width: 12px !important;
  height: 12px !important;
  color: #2d2d2d;
  margin-right: 10px;
`;
const DownloadIcon = styled(DownloadForOfflineOutlinedIcon)`
  width: 18px !important;
  height: 18px !important;
  color: #fa7913;
  margin-right: 10px;
`;

const LineSeparator = styled.hr`
  height: 1px;
  background: #eeeeee;
  border: none;
  margin-top: ${Spacing.xxs};
  margin-bottom: ${Spacing.s};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubHead = styled(Text)`
  margin-top: 10px;
  display: block;
`;

const SubHeadText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  font-size: ${ConvertSize(12)};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
const FileSize = styled.span`
  margin-right: ${Spacing.s};
`;
const SubText = styled(Text)`
  font-size: ${ConvertSize(10)};
`;
