import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import { useSelector} from "react-redux";
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import { getLabel } from "../utils/prismicUtils";
import styled from "styled-components";

function PaperComponent(props) {
    
  return (
   
      <Paper {...props}  style={{"width":"70%",'maxWidth': '800px'}}/>
  );
}

const SystemInformation = (props) => {
  const [open, setOpen] = React.useState(props.open);
  const prismicData = useSelector((state) => state.prismic.prismicData);
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
  } else {
    browserName = "No browser detection";
  }
  let screenSize = `${window.screen.availWidth} * ${window.screen.availHeight}`;


  return (
    <div>
     
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperComponent={PaperComponent}
        style={{ "fontFamily": "LeasePlan"}}
      >
        <DialogTitleComponent>
          
          {getLabel("system_information_title", "System Information", prismicData)}
          <Button  style={{float:'right'}} onClick={props.handleClose}>
            <DisabledByDefaultTwoToneIcon style={{color:'black'}}/>
          </Button>
        </DialogTitleComponent>
        <DialogContent
        style={{ cursor: 'move',backgroundColor:'lightgray' ,padding:'5px'}}>
          <DialogContentText>
            <MainTable >
            <table style={{"width":"100%"}}>
 <tr>
    <td></td>
    <td style={{fontWeight:'bold',color:"white",backgroundColor:'black'}}>  {getLabel("current_system_settings_title", "Current System Settings", prismicData)}</td>
    <td style={{fontWeight:'bold',color:"white",backgroundColor:'black'}}>{getLabel("minimum_system_settings_title", "Minimum System Settings", prismicData)}</td>
  </tr>
  <tr>
    <td style={{fontWeight:'bold',color:"black"}}>{getLabel("browser_type_and_version_title", "Browser Type and Version", prismicData)}</td>
    <td>{browserName}</td>
    <td>{getLabel("browser_type_and_version_value", "Firefox or Microsoft Internet Explorer", prismicData)}</td>
  </tr>
  <tr>
    <td style={{fontWeight:'bold',color:"black"}}>{getLabel("color_depth_title", "Color Depth", prismicData)}</td>
    <td>{window.screen.colorDepth}</td>
    <td>{getLabel("color_depth_value", "8-bit or higher", prismicData)}</td>
  </tr>
  <tr>
    <td style={{fontWeight:'bold',color:"black"}}>{getLabel("java_enabled_title", "Java Enabled", prismicData)}</td>
    <td>{navigator.javaEnabled()==true?'Yes':'No'}</td>
    <td>{getLabel("java_enabled_value", "Yes", prismicData)}</td>
  </tr>
  <tr>
    <td style={{fontWeight:'bold',color:"black"}}>{getLabel("cookies_enabled_title", "Cookies Enabled", prismicData)}</td>
    <td>{navigator.cookieEnabled==true?'Yes':'No'}</td>
    <td>{getLabel("cookies_enabled_value", "Yes", prismicData)}</td>
  </tr>
  <tr>
    <td style={{fontWeight:'bold',color:"black"}}>{getLabel("screen_resolution_title", "Screen Resolution", prismicData)}</td>
    <td>{screenSize}</td>
    <td>{getLabel("screen_resolution_value", "1024 * 768", prismicData)}</td>
  </tr>
</table>
            </MainTable>
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}
export default SystemInformation;

const DialogTitleComponent = styled(DialogTitle)`
  cursor: move;
  background-color:lightgray;
  max-height:40px ;
  padding:5px !important;
  font-family:LeasePlan !important; 
  font-weight:bold !important   ;
`;
const MainTable = styled.div`
border:1px solid black;
padding:15px ;
margin:1px;
background-color:white;
width:100%;
`;