import {
  FETCH_SETTINGS_START,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAIL,
  FETCH_CLIENT_SETTINGS_FAIL,
  FETCH_CLIENT_SETTINGS_START,
  FETCH_CLIENT_SETTINGS_SUCCESS,
} from "./settingsConstants";

const initialState = {
  loading: false,
  settings: null,
  clientSettings: null,
  error: null,
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
    case FETCH_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CLIENT_SETTINGS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLIENT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientSettings: action.payload,
      };
    case FETCH_CLIENT_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
