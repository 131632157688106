import React, { useEffect, useState } from "react";
import { Heading, Text, Button } from "@leaseplan/ui";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import { Spacing, ConvertSize, Colors } from "../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { getLabel } from "../utils/prismicUtils";
import { useIdleTimer } from "react-idle-timer";
import { showToaster } from "../store/toaster/toasterActions";
import { logout, getNewSession } from "../store/auth/authThunk";
import ClockIcon from "../assets/images/clock.svg";
import SystemInformation from "./SystemInformation";
import eventLog from "../config/firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PRIVACY_STATEMENT, SYSTEM_INFORMATION } from "../helpers/logEvents";
import { logFunctionError } from "../config/errorLog";
const PROMPT_TIMEOUT_SECONDS = 60 * 15; // Seconds
const TIMEOUT_SECONDS = 60 * 105; // Seconds

const Footer = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [countDown, setCoundDown] = useState(PROMPT_TIMEOUT_SECONDS); // Seconds
  const [timeout, setTimeout] = useState(TIMEOUT_SECONDS); // Seconds
  const [countDownInterval, setCoundDownInterval] = useState(null);

  const onLogout = () => {
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    localStorage.removeItem("setActiveTime");
    localStorage.removeItem("timeoutPopup");
    onSetShowModal(false);
    dispatch(logout());
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const [open, setOpen] = React.useState(false);

  const handleViewClose = () => {
    setOpen(false);
  };
  const onContinue = () => {
    const idToken = sessionStorage.getItem("idToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    localStorage.removeItem("timeoutPopup");
    dispatch(getNewSession(idToken, refreshToken));
    reset();
    const activeTime = new Date();
    activeTime.setHours(activeTime.getHours() + 2);
    localStorage.setItem("setActiveTime", activeTime);
    onSetShowModal(false);

    console.log("Session Updated");
  };

  const onSetShowModal = (show) => {
    show = !!show;
    setShowSessionModal(show);
    if (show) {
      const interval = setInterval(() => {
        if (countDown > 0) {
          setCoundDown((prevCountDown) => prevCountDown - 1);
        }
      }, 1000);
      setCoundDownInterval(interval);
    } else {
      if (countDownInterval) {
        clearInterval(countDownInterval);
      }
      setCoundDown(PROMPT_TIMEOUT_SECONDS);
    }
  };

  useEffect(() => {
    console.log(countDown);
    try {
      if (countDown == 0 && countDownInterval) {
        onLogout();
      }
    } catch (error) {
      console.log(error);
      logFunctionError(error);
    }
  }, [countDown]);

  useEffect(() => {
    setOpen(sessionStorage.getItem("v") == "true" ? true : false);
  }, [sessionStorage.getItem("v")]);

  const getTimerMinutes = (countDown) => {
    const minutes = Math.floor(countDown / 60);
    return minutes > 9 ? minutes : "0" + minutes;
  };

  const getTimerSeconds = (countDown) => {
    const seconds = Math.floor(countDown % 60);
    return seconds > 9 ? seconds : "0" + seconds;
  };

  const handleOnIdle = () => {
    onLogout();
  };

  const handleOnActive = () => {
    getRemainingTime();
  };

  const handleOnAction = () => {
    if (!localStorage.getItem("timeoutPopup") && document.hidden) {
      const activeTime = new Date();
      activeTime.setHours(activeTime.getHours() + 2);
      localStorage.setItem("setActiveTime", activeTime);
    }

    !localStorage.getItem("timeoutPopup") && reset();

    console.log("Session Active On Movement");
  };
  const handleOnPrompt = () => {
    !showSessionModal && onSetShowModal(true);
    console.log(showSessionModal);
    console.log("Session Idle");
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: 1000 * timeout,
    promptTimeout: 1000 * PROMPT_TIMEOUT_SECONDS,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    onPrompt: handleOnPrompt,
    debounce: 500,
  });
  document.addEventListener(
    "visibilitychange",
    function () {
      if (!document.hidden && localStorage.getItem("setActiveTime")) {
        const maxActiveTime = new Date(localStorage.getItem("setActiveTime"));
        var promptTime = new Date(maxActiveTime);
        promptTime.setMinutes(maxActiveTime.getMinutes() - 15);
        const currentTime = new Date();
        if (currentTime > maxActiveTime) {
          onLogout();
        } else if (currentTime > promptTime) {
          setTimeout(0);
          localStorage.setItem("timeoutPopup", true);
        }
      }
    },
    false
  );

  return (
    <FooterContainer>
      <ModalWrapper open={showSessionModal}>
        <ModalBox>
          <Icon src={ClockIcon} />
          <HeadingText marginTop={Spacing.l} marginBottom={Spacing.xxl}>
            {getLabel(
              "session_popup_heading_1",
              "Session expiration",
              prismicData
            )}
          </HeadingText>
          <NormalText>
            {`${getLabel(
              "session_popup_msg_1",
              "Your session is set to expire in",
              prismicData
            )} `}
            <span style={{ fontWeight: "bold" }}>{`${getTimerMinutes(
              countDown
            )}:${getTimerSeconds(countDown)} minutes`}</span>
            .
          </NormalText>
          <NormalText>
            {getLabel(
              "session_popup_msg_2",
              "What would you like to do?",
              prismicData
            )}
          </NormalText>
          <ButtonContainer>
            <LogOutButton onClick={onLogout} size="s" variant="secondary">
              {getLabel(
                "session_popup_logout_button",
                "LOG OUT NOW",
                prismicData
              )}
            </LogOutButton>
            <ContinueButton onClick={onContinue} size="s">
              {getLabel(
                "session_popup_continue_button",
                "CONTNIUE WORKING",
                prismicData
              )}
            </ContinueButton>
          </ButtonContainer>
        </ModalBox>
      </ModalWrapper>

      <Text size="s" color="petrolBlue">
        ©{getLabel("footer_leaseplan_title", "LPUSA", prismicData)}
        {" " + new Date().getFullYear()}{" "}
      </Text>
      {userData?.lastLoginDate && (
        <LastLogin>
          {getLabel("last_login_attempt", "last login attempt", prismicData)}:{" "}
          {userData?.lastLoginDate}
        </LastLogin>
      )}

      <LeftContents>
        <TextWrapper
          onClick={() => {
            eventLog({
              eventName: SYSTEM_INFORMATION,
            });
            setOpenDialog(true);
          }}
        >
          <Text size="s" color="petrolBlue">
            {getLabel(
              "footer_system_information_title",
              "Footer System Information Title",
              prismicData
            )}{" "}
          </Text>
        </TextWrapper>
        <TextWrapper
          onClick={() => {
            eventLog({
              eventName: PRIVACY_STATEMENT,
            });
            window.open(
              getLabel(
                "privacy_statement_url",
                "https://www.wheels.com/public/lpusa-llc-privacy-policy/",
                prismicData
              )
            );
          }}
        >
          <Text size="s" color="petrolBlue">
            {getLabel(
              "footer_privacy_system_title",
              "footer privacy system title",
              prismicData
            )}{" "}
          </Text>
        </TextWrapper>
      </LeftContents>
      {openDialog == true && (
        <SystemInformation open={openDialog} handleClose={handleClose} />
      )}
      <Dialog
        open={open}
        onClose={handleViewClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogInner>
          <DialogTitle id="alert-dialog-title">
            {getLabel("view_only_heading", "View Only Mode", prismicData)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {getLabel(
                "view_only_warning_text",
                "You are not allowed to do any changes",
                prismicData
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </DialogInner>
      </Dialog>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  //margin: ${Spacing.xxxl} ${Spacing.xxl} ${Spacing.xs} ${Spacing.xxl};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  background: white;
  height: ${ConvertSize(62)};
  padding: 0px ${Spacing.l};
  @media (min-width: 600px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;
const LeftContents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TextWrapper = styled.div`
  margin-right: ${Spacing.l};
  cursor: pointer;
`;
const LastLogin = styled(Text)`
  color: #bdbdbd;
  font-size: 12px;
`;

const ModalWrapper = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBox = styled.div`
  max-width: ${ConvertSize(600)};
  max-height: ${ConvertSize(500)};
  background-color: ${Colors.white};
  border: 0;
  border-radius: 6px;
  outline-width: 0;
  padding: ${Spacing.xxl} ${Spacing.xxxl};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`;

const HeadingText = styled.span`
  color: ${Colors.petrolBlue};
  font-weight: bold;
  font-size: ${ConvertSize(22)};
  text-align: center;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const NormalText = styled.p`
  color: ${Colors.petrolBlue};
  font-size: ${ConvertSize(14)};
  text-align: center;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const Icon = styled.img`
  height: ${ConvertSize(70)};
`;

const ButtonContainer = styled.div`
  display: flxe;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${Spacing.xxl};
`;

const DialogInner = styled.div`
  padding: 10px;
`;

const ContinueButton = styled(Button)`
  font-size: ${ConvertSize(12)};
  padding: ${Spacing.s} ${Spacing.m};
`;

const LogOutButton = styled(Button)`
  margin-right: ${Spacing.s};
  font-size: ${ConvertSize(12)};
  border: 1px solid ${Colors.petrolBlue};
  color: ${Colors.petrolBlue};
  padding: ${Spacing.s} ${Spacing.m};
  :hover {
    color: ${Colors.white};
    background-color: ${Colors.petrolBlue};
  }
`;
