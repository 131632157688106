import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, Grid, GridItem } from "@leaseplan/ui";
import { login } from "../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import TopNav from "../components/TopNav";
import Loading from "../components/Loading";
import { showSidePanel } from "../store/commonStore/commonActionCreators";
const SSO = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    loading,
    isLoginFail,
    idToken,
    accessToken,
    refreshToken,
  } = useSelector((state) => state.auth);

  const [searchParams, setSearchParams] = useSearchParams();
  const [userid, setUserID] = useState(null);
  const [clientno, setClientNo] = useState(null);

  useEffect(() => {
    console.log(isAuthenticated,"ISAUTHENTICATED");
    if (isAuthenticated) {
      sessionStorage.setItem("idToken", idToken);
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      navigate("/", { replace: true, state: { redirect: searchParams.get("redirect"), requestType:searchParams.get("requestType") } });

    }
    // dispatch(
    //   login({
    //     userId: decodeURIComponent(searchParams.get("userId")),
    //     clientNo: decodeURIComponent(searchParams.get("clientId")),
    //   })
    // );
  }, [isAuthenticated]);

  return (
    <>
      <TopNav iconVisible={false} />
      {loading ? (
        <Loading />
      ) : (
        <LoginContainer>
          <Grid>
            {isLoginFail && (
              <GridItem span={12}>
                <Text color="errorDark">Invalid credentials</Text>
              </GridItem>
            )}
          </Grid>
        </LoginContainer>
      )}
    </>
  );
};

export default SSO;

const LoginContainer = styled.div`
  height: 100vh;
  width: 70%;
  padding-top: 6vh;
  margin: auto;
  @media (min-width: 780px) {
    width: 50%;
  }
  @media (min-width: 1025px) {
    width: 30%;
  }
`;
