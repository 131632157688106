import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Text, Button, Color } from "@leaseplan/ui";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Colors } from "../../utils/theme";
import { getLabel } from "../../utils/prismicUtils";

const FilterMenu = ({
  anchorEl,
  handleClose,
  open,
  filterData,
  updateSearchQuery,
  removeSearchQuery,
  statusArray,
  prismicData,
}) => {
  const [isChecked, setIsChecked] = useState({});
  const [isCheckedApplied, setIsCheckedApplied] = useState(false);

  const onClickCheckBox = (uniqueId) => () => {
    setIsChecked((prev) => {
      return { ...prev, [uniqueId]: !(prev[uniqueId] || false) };
    });
  };
  const onClickClear = () => {
    removeSearchQuery("status");
    handleClose();
  };

  const handleCloseView = () => {
    if (!isCheckedApplied) {
      setIsChecked({});
    }
    handleClose();
  };

  useEffect(() => {
    if (open) {
      statusArray?.length > 0 &&
        setIsChecked(
          statusArray?.reduce((acc, item) => {
            return { ...acc, [item]: true };
          }, {})
        );
    }
  }, [open]);
  useEffect(() => {
    if (statusArray === undefined || statusArray?.length == 0) {
      setIsChecked({});
    }
  }, [statusArray]);
  useEffect(() => {
    if (!isCheckedApplied) {
      setIsChecked({});
    }
  }, [isCheckedApplied]);

  useEffect(() => {
    if (Object.values(isChecked).find((i) => i) == undefined) {
      // removeSearchQuery("status");
      handleClose();
    }
  }, [isChecked]);

  const isAnyOneSelected = Object.values(isChecked).find((i) => i);

  const onApplyFilter = () => {
    const checkedValues = Object.entries(isChecked).filter((i) => i[1]);
    setIsCheckedApplied(true);
    const query = checkedValues.reduce(
      (acc, item, index) => `${item[0]},${acc}`,
      ""
    );

    updateSearchQuery({
      pageNumber: 1,
      status: query,
    });
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleCloseView}
      PaperProps={{
        elevation: 0,
        sx: {
          padding: "10px 20px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <FormGroup>
        {filterData?.map((item) => {
          return (
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={isChecked[item] || false}
                  onChange={onClickCheckBox(item)}
                  size="small"
                  sx={{
                    "&.Mui-checked": {
                      color: Colors.petrolBlue,
                    },
                  }}
                />
              }
              label={
                <Text size="xs" color="petrolBlue">
                  {item}
                </Text>
              }
            />
          );
        })}
      </FormGroup>
      <span style={{ float: "right", marginTop: "15px" }}>
        <CustomText
          color="petrolBlue"
          size="xs"
          fontWeight="bold"
          style={{ marginRight: "20px" }}
          onClick={onClickClear}
        >
          Clear All
        </CustomText>
        <Button
          variant="secondary"
          size="s"
          disabled={!isAnyOneSelected}
          onClick={onApplyFilter}
          style={{
            color: isAnyOneSelected ? Colors.petrolBlue : Colors.lightGrey,
            maxHeight: "22px",
            minWidth: "80px",
            borderColor: isAnyOneSelected
              ? Colors.petrolBlue
              : Colors.lightGrey,
          }}
        >
          {getLabel("apply_filter_title", "Apply Filter", prismicData)}
        </Button>
      </span>
    </Menu>
  );
};

export default FilterMenu;
const CustomText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;
const CustomCheckbox = styled(Checkbox)`
  color: ${Colors.lightGrey};
  padding: 5px;
  :hover {
    color: ${Colors.petrolBlue};
    background-color: transparent;
  }
`;
