import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Spacing, Colors, ConvertSize } from "../../utils/theme";
import { Text, Button } from "@leaseplan/ui";
import Search from "../Search";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { showSidePanel } from "../../store/commonStore/commonActionCreators";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import Loading from "../Loading";
import {
  getRequestDownload,
  getSearchResults,
  getRequests,
} from "../../store/request/requestApi";
import Tutorial from "../Tutorial";
import { getLabel } from "../../utils/prismicUtils";
import GlobalFilterMenu from "./GlobalFilterMenu";
import dompurify from "dompurify";
import { getMmDdYY } from "../../utils/calender";
import "./RequestHeader.scss";
import eventLog from "../../config/firebase";
import {
  GLOBAL_SEARCH,
  ON_CLICK_DOWNLOAD,
  ON_CLICK_SEARCH,
} from "../../helpers/logEvents";
import { logFunctionError } from "../../config/errorLog";
import ToggleButtonView from "./ToggleButton";
import Switch from "@mui/material/Switch";
import { UnreadSwitch } from "./UnreadSwitch";

const RequestHeader = ({
  updateSearchQuery,
  removeSearchQuery,
  search,
  orderField,
  order,
  statusArray,
  driverName,
  vehicleNumber,
  clientUnitNumber,
  vin,
  createUserName,
  createDateStart,
  createDateEnd,
  caseNumber,
  totalRecords,
  tabValue,
  repliedDateEnd,
  repliedDateStart,
  categoryArray,
  subcategoryArray,
  requestType,
  readStatus,
}) => {
  const dispatch = useDispatch();
  const tutorial = useRef();
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filterBar, setFilterBar] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchDataShow, setSearchDataShow] = useState(false);
  const [searchDataClear, setSearchDataClear] = useState(false);
  const [searchedHeader, setSearchedHeader] = useState([]);
  const [unReadToggle, setUnReadToggle] = useState(false);
  const types = useSelector((state) => state.requestDropdown.requestDropdown);

  const openFilter = Boolean(anchorElFilter);

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          document.body.style.overflow = "initial";
          setSearchDataShow(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const sanitizer = dompurify.sanitize;
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const settingsData = useSelector((state) => state.settings.settings);
  const userData = useSelector((state) => state.user.userData);
  const { showTutorial } = useSelector((state) => state.common);
  const { count } = useSelector((state) => state.common);
  const canCreateNewRequest =
    settingsData?.functionSetting["eplanignite/workspace/case/create"];

  const onPressFilter = (event) => {
    const filteredData = [
      "New",
      "In Progress",
      "Completed",
      "Pending",
      "Cancelled",
    ];
    setFilterData(filteredData);
    setAnchorElFilter(event.currentTarget);
  };
  const onPressButton = () => {
    if (canCreateNewRequest) {
      dispatch(showSidePanel({ type: "NewRequest" }));
    }
  };
  const handleChange = debounce(async (e) => {
    const globalSearch = await getRequests({
      search: e.target.value,
      ignorePagination: true,
      pageNumber: 1,
      pageSize: 2,
      ...{
        status:
          statusArray?.length > 0
            ? statusArray
            : tabValue == "1"
            ? ["New", "In Progress", "Pending", "Sending", "In Review"]
            : ["Completed", "Cancelled"],
      },
      ...(createUserName && { createUserName }),
      ...(statusArray?.length > 0 && {
        status: statusArray,
      }),
      ...(vin && { vin }),
      ...(requestType && { requestType }),
      ...(readStatus && { readStatus }),
      ...(vehicleNumber && { vehicleNumber }),
      ...(clientUnitNumber && { clientUnitNumber }),
      ...(driverName && { driverName }),
      ...(createDateStart && { createDateStart }),
      ...(createDateEnd && { createDateEnd }),
      ...(repliedDateStart && { repliedDateStart }),
      ...(repliedDateEnd && { repliedDateEnd }),
      ...(categoryArray?.length > 0 && {
        category: categoryArray,
      }),
      ...(subcategoryArray?.length > 0 && {
        subCategory: subcategoryArray,
      }),
    });
    eventLog({
      eventName: GLOBAL_SEARCH,
    });
    setSearchData({
      data: globalSearch.data.requests,
      value: e.target.value,
    });
    setSearchLoading(false);
    setSearchDataClear(false);

    document.body.style.overflow = "hidden";
    if (e.target.value == "") {
      removeSearchQuery("search");
    }
  }, 1500);

  const onCancel = () => {
    removeSearchQuery(["search"]);
    setSearchDataShow(false);
    document.body.style.overflow = "initial";
    setSearchData(false);
  };
  const handleIconClick = () => {
    if (searchData.value) {
      document.body.style.overflow = "hidden";
      setSearchDataShow(true);
    }
  };

  const [isDocumentLoading, setLoading] = useState(false);
  const onClickDownload = async () => {
    setLoading(true);
    try {
      const res = await getRequestDownload("csv", {
        ...(orderField &&
          order && {
            orderField,
            order,
          }),
        ...(driverName && { driverName }),
        ...{
          status:
            statusArray?.length > 0
              ? statusArray
              : tabValue == "1"
              ? ["New", "In Progress", "Pending", "Sending", "In Review"]
              : ["Completed", "Cancelled"],
        },
        ...(vehicleNumber && { vehicleNumber }),
        ...(clientUnitNumber && { clientUnitNumber }),
        ...(vin && { vin }),
        ...(requestType && { requestType }),
        ...(createUserName && { createUserName }),
        ...(search && { search }),
        ...(readStatus && { readStatus }),
        ...(createDateStart && { createDateStart }),
        ...(createDateEnd && { createDateEnd }),
        ...(caseNumber && { caseNumber }),
        ...(repliedDateStart && { repliedDateStart }),
        ...(repliedDateEnd && { repliedDateEnd }),
        ...(categoryArray?.length > 0 && {
          category: categoryArray,
        }),
        ...(subcategoryArray?.length > 0 && {
          subCategory: subcategoryArray,
        }),
      });
      eventLog({
        eventName: ON_CLICK_DOWNLOAD,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "RequestList.csv");
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      logFunctionError(e);
    }
  };
  const [x, setX] = useState();
  const [y, setY] = useState();

  useEffect(() => {
    setX(tutorial.current.offsetLeft);
    setY(tutorial.current.offsetTop);
  }, [showTutorial]);

  useEffect(() => {
    if (
      statusArray ||
      driverName ||
      vehicleNumber ||
      clientUnitNumber ||
      vin ||
      createUserName ||
      caseNumber ||
      categoryArray ||
      subcategoryArray ||
      createDateStart ||
      createDateEnd ||
      repliedDateStart ||
      repliedDateEnd
    ) {
      setFilterBar(true);
    }

    if (
      (statusArray?.length == 0 || statusArray == undefined) &&
      (categoryArray?.length == 0 || categoryArray == undefined) &&
      (subcategoryArray?.length == 0 || subcategoryArray == undefined) &&
      !statusArray &&
      !categoryArray &&
      !subcategoryArray &&
      driverName == null &&
      vehicleNumber == null &&
      clientUnitNumber == null &&
      vin == null &&
      caseNumber == null &&
      createUserName == null &&
      createDateStart == null &&
      createDateEnd == null &&
      repliedDateStart == null &&
      repliedDateEnd == null
    ) {
      setFilterBar(false);
      searchDataClear && setSearchDataClear(true);
    }
  }, [
    categoryArray,
    subcategoryArray,
    statusArray,
    driverName,
    vehicleNumber,
    clientUnitNumber,
    vin,
    createUserName,
    caseNumber,
    createDateStart,
    repliedDateStart,
    createDateEnd,
    repliedDateEnd,
    requestType,
  ]);

  const updateGlobalFilter = (type, name, key) => {
    if (type == "status") {
      var query = "";
      statusArray?.reduce((acc, item) => {
        return { ...acc, [item]: false };
      });
      statusArray.splice(statusArray.indexOf(key), 1);

      statusArray.forEach((element) => {
        query += `${element},`;
      });
      updateSearchQuery({
        pageNumber: 1,
        status: query,
      });
    } else if (type == "category") {
      var query = "";
      let dataremoved = categoryArray.splice(categoryArray.indexOf(name), 1);
      categoryArray.forEach((element) => {
        query += `${element};`;
      });

      if (subcategoryArray && subcategoryArray.length > 0) {
        let subCaterequid;
        let index;
        let filtercatarr = [];
        let filtersubcatarr = [];

        filtercatarr = types.categories.filter((item) =>
          categoryArray.includes(item.categoryDescription)
        );
        filtercatarr?.map((items, i) => {
          types["subcategory_" + items.requestCategoryUid]?.map((item) => {
            filtersubcatarr.push(item.categoryDescription);
          });
        });

        subcategoryArray = filtersubcatarr.filter((item) =>
          subcategoryArray.includes(item)
        );
        const subcategoryquery = subcategoryArray.join(";");

        updateSearchQuery({
          pageNumber: 1,
          category: query,
          subCategory: subcategoryquery,
        });
      } else {
        updateSearchQuery({
          pageNumber: 1,
          category: query,
        });
      }
    } else if (type == "subCategory") {
      var query = "";
      subcategoryArray.splice(subcategoryArray.indexOf(key), 1);
      subcategoryArray.forEach((element) => {
        query += `${element};`;
      });
      updateSearchQuery({
        pageNumber: 1,
        subCategory: query,
      });
    } else if (type == "submittedDate") {
      removeSearchQuery(["createDateStart", "createDateEnd"]);
    } else if (type == "repliedDate") {
      removeSearchQuery(["repliedDateStart", "repliedDateEnd"]);
    } else {
      removeSearchQuery(type);
      searchedHeader?.length == 1 && setSearchDataClear(true);
      var newSearchData = [];
      searchedHeader &&
        searchedHeader?.length > 1 &&
        searchedHeader.forEach((e) => {
          if (e == name) {
          } else {
            newSearchData.push(e);
          }
        });
      setSearchedHeader(newSearchData);
    }
  };
  const onClickClear = () => {
    categoryArray?.reduce((acc, item) => {
      return { ...acc, [item]: false };
    });
    statusArray?.reduce((acc, item) => {
      return { ...acc, [item]: false };
    });
    subcategoryArray?.reduce((acc, item) => {
      return { ...acc, [item]: false };
    });
    removeSearchQuery([
      "status",
      "driverName",
      "vehicleNumber",
      "clientUnitNumber",
      "vin",
      "createUserName",
      "caseNumber",
      "category",
      "subCategory",
      "createDateStart",
      "createDateEnd",
      "repliedDateStart",
      "repliedDateEnd",
    ]);
    setSearchDataClear(true);
    setFilterBar(false);
  };
  const onClickSearch = (item, search) => () => {
    if (searchedHeader && searchedHeader.indexOf(search) === -1) {
      setSearchedHeader([...searchedHeader, search]);
    }
    if (search == "Driver") {
      updateSearchQuery({ pageNumber: 1, driverName: item });
    } else if (search == "ePlan Unit") {
      updateSearchQuery({ pageNumber: 1, vehicleNumber: item });
    } else if (search == "Client Unit") {
      updateSearchQuery({ pageNumber: 1, clientUnitNumber: item });
    } else if (search == "VIN") {
      updateSearchQuery({ pageNumber: 1, vin: item });
    } else if (search == "Requested By") {
      updateSearchQuery({ pageNumber: 1, createUserName: item });
    } else if (search == "Reference ID") {
      updateSearchQuery({ pageNumber: 1, caseNumber: item });
    }

    eventLog({
      eventName: ON_CLICK_SEARCH,
      additionalParams: {
        search,
      },
    });
    setSearchDataShow(false);
    document.body.style.overflow = "initial";
  };
  const regEscape = (v) => v && v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  var reg = new RegExp(regEscape(searchData?.value), "gi");

  const searchInput = React.useRef(null);

  useOutsideAlerter(searchDataShow && searchInput);

  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );

  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );
  return (
    <>
      <HeaderContainer>
        <SearchWrapper>
          <div style={{ width: ConvertSize(180) }}>
            <Text color="petrolBlue" size="l" fontWeight="bold">
              {tabValue == "1"
                ? `${getLabel(
                    "open_request_heading",
                    "Open Requests",
                    prismicData
                  )}`
                : `${getLabel(
                    "close_request_heading",
                    "Closed Requests",
                    prismicData
                  )}`}
            </Text>
          </div>
          <div ref={searchInput} style={{ width: "70%" }}>
            <Search
              placeHolder="Search for request"
              onChange={(e) => {
                handleChange(e);
                setSearchDataShow(true);
                setSearchLoading(true);
                document.body.style.overflow =
                  e.target.value.length == 0 && "initial";
              }}
              search={search}
              onCancel={onCancel}
              searchDataClear={searchDataClear}
              iconClick={handleIconClick}
              width={ConvertSize(300)}
            />
            {searchDataShow &&
              (searchLoading ? (
                <SearchResult>
                  <p>
                    <small
                      className="loading"
                      style={{ display: "flex", color: "#ee4c14" }}
                    >
                      Loading{" "}
                    </small>
                  </p>
                </SearchResult>
              ) : (
                searchData.value && (
                  <SearchResult>
                    {searchData &&
                      searchData.data.map((e, key) => {
                        return (
                          <>
                            {e?.caseNumber && (
                              <div>
                                {key == 0 && (
                                  <SearchResultHead>
                                    <SearchHeadInner>
                                      {getLabel(
                                        "table_column_incident_number_title",
                                        "Reference ID",
                                        prismicData
                                      )}
                                    </SearchHeadInner>
                                    <SearchHeadApply
                                      onClick={onClickSearch(
                                        searchData.value,
                                        "Reference ID"
                                      )}
                                    >
                                      {getLabel(
                                        "apply_filter_title",
                                        "Apply",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadApply>
                                  </SearchResultHead>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                  <SearchInnerData
                                    onClick={onClickSearch(
                                      e.caseNumber,
                                      "Reference ID"
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(
                                        `${e.caseNumber
                                          .toString()
                                          .replace(reg, function (str) {
                                            return "<b>" + str + "</b>";
                                          })} <br> `
                                      ),
                                    }}
                                  ></SearchInnerData>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {searchData &&
                      searchData.data.map((e, key) => {
                        return (
                          <>
                            {e?.vehicle?.clientUnitNumber && (
                              <div>
                                {key == 0 && (
                                  <SearchResultHead>
                                    <SearchHeadInner>
                                      {getLabel(
                                        "table_column_client_unit_title",
                                        "Client Unit",
                                        prismicData
                                      )}
                                    </SearchHeadInner>
                                    <SearchHeadApply
                                      onClick={onClickSearch(
                                        searchData.value,
                                        "Client Unit"
                                      )}
                                    >
                                      {getLabel(
                                        "apply_filter_title",
                                        "Apply",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadApply>
                                  </SearchResultHead>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                  <SearchInnerData
                                    onClick={onClickSearch(
                                      e.vehicle.clientUnitNumber,
                                      "Client Unit"
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(
                                        `${e.vehicle.clientUnitNumber
                                          .toString()
                                          .replace(reg, function (str) {
                                            return "<b>" + str + "</b>";
                                          })} <br> `
                                      ),
                                    }}
                                  ></SearchInnerData>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {searchData &&
                      searchData.data.map((e, key) => {
                        return (
                          <>
                            {e?.vehicle?.vehicleNumber && (
                              <div>
                                {key == 0 && (
                                  <SearchResultHead>
                                    <SearchHeadInner>
                                      {getLabel(
                                        "table_column_eplan_unit_title",
                                        "ePlan Unit",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadInner>
                                    <SearchHeadApply
                                      onClick={onClickSearch(
                                        searchData.value,
                                        "ePlan Unit"
                                      )}
                                    >
                                      {getLabel(
                                        "apply_filter_title",
                                        "Apply",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadApply>
                                  </SearchResultHead>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                  <SearchInnerData
                                    onClick={onClickSearch(
                                      e.vehicle.vehicleNumber,
                                      "ePlan Unit"
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(
                                        `${e.vehicle.vehicleNumber
                                          .toString()
                                          .replace(reg, function (str) {
                                            return "<b>" + str + "</b>";
                                          })} <br> `
                                      ),
                                    }}
                                  ></SearchInnerData>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {searchData &&
                      searchData.data.map((e, key) => {
                        return (
                          <>
                            {e?.vehicle?.vin && (
                              <div>
                                {key == 0 && (
                                  <SearchResultHead>
                                    <SearchHeadInner>
                                      {getLabel(
                                        "table_column_vin_number_title",
                                        "VIN",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadInner>
                                    <SearchHeadApply
                                      onClick={onClickSearch(
                                        searchData.value,
                                        "VIN"
                                      )}
                                    >
                                      {getLabel(
                                        "apply_filter_title",
                                        "Apply",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadApply>
                                  </SearchResultHead>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                  <SearchInnerData
                                    onClick={onClickSearch(
                                      e.vehicle.vin,
                                      "VIN"
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(
                                        `${e.vehicle.vin
                                          .toString()
                                          .split(
                                            new RegExp(
                                              regEscape(searchData.value),
                                              "ig"
                                            )
                                          )
                                          .join(
                                            "<b>" +
                                              searchData.value.toUpperCase() +
                                              "</b>"
                                          )} <br> `
                                      ),
                                    }}
                                  ></SearchInnerData>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {searchData &&
                      searchData.data.map((e, key) => {
                        return (
                          <>
                            {e?.driver?.name && (
                              <div>
                                {key == 0 && (
                                  <SearchResultHead>
                                    <SearchHeadInner>
                                      {" "}
                                      {getLabel(
                                        "table_column_driver_title",
                                        "Driver",
                                        prismicData
                                      )}
                                    </SearchHeadInner>
                                    <SearchHeadApply
                                      onClick={onClickSearch(
                                        searchData.value,
                                        "Driver"
                                      )}
                                    >
                                      {getLabel(
                                        "apply_filter_title",
                                        "Apply",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadApply>
                                  </SearchResultHead>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                  <SearchInnerData
                                    onClick={onClickSearch(
                                      e.driver.name,
                                      "Driver"
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(
                                        `${e.driver.name
                                          .toString()
                                          .replace(reg, function (str) {
                                            return "<b>" + str + "</b>";
                                          })} <br> `
                                      ),
                                    }}
                                  ></SearchInnerData>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {searchData &&
                      searchData.data.map((e, key) => {
                        return (
                          <>
                            {e?.createUserName && (
                              <div>
                                {key == 0 && (
                                  <SearchResultHead>
                                    <SearchHeadInner>
                                      {" "}
                                      {getLabel(
                                        "requested_by_search_header_title",
                                        "Requested by",
                                        prismicData
                                      )}
                                    </SearchHeadInner>
                                    <SearchHeadApply
                                      onClick={onClickSearch(
                                        searchData.value,
                                        "Requested By"
                                      )}
                                    >
                                      {getLabel(
                                        "apply_filter_title",
                                        "Apply",
                                        prismicData
                                      )}{" "}
                                    </SearchHeadApply>
                                  </SearchResultHead>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                  <SearchInnerData
                                    onClick={onClickSearch(
                                      e.createUserName,
                                      "Requested By"
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(
                                        `${e.createUserName
                                          .toString()
                                          .replace(reg, function (str) {
                                            return "<b>" + str + "</b>";
                                          })} <br> `
                                      ),
                                    }}
                                  ></SearchInnerData>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}

                    {searchData &&
                      searchData?.data[0]?.createUserName?.length ==
                        undefined &&
                      searchData?.data[0]?.driver?.name?.length == undefined &&
                      searchData?.data[0]?.caseNumber?.length == undefined &&
                      searchData?.data[0]?.vehicle?.clientUnitNumber?.length ==
                        undefined &&
                      searchData?.data[0]?.vehicle?.vehicleNumber?.length ==
                        undefined &&
                      searchData?.data[0]?.vehicle?.vin?.length ==
                        undefined && (
                        <p>
                          <small>
                            {getLabel(
                              "no_result_found_text",
                              "No Results Found.",
                              prismicData
                            )}
                          </small>
                        </p>
                      )}
                  </SearchResult>
                )
              ))}
          </div>
        </SearchWrapper>
        <CenterContents>
          <ToggleButtonView />
          <UnReadSwitchContainer>
            <UnreadSwitch
              checked={unReadToggle || readStatus}
              onChange={() => {
                //this value is determined based on the params
                const newUnReadToggle =
                  readStatus && !unReadToggle ? true : unReadToggle;
                !newUnReadToggle
                  ? updateSearchQuery({
                      pageNumber: 1,
                      readStatus: "unread",
                    })
                  : removeSearchQuery("readStatus");
                setUnReadToggle(!newUnReadToggle);
              }}
            />
            <Text size="xxs" color="petrolBlue">
              {getLabel("unread_only_title", "Unread only", prismicData)}
            </Text>
            {count && (
              <Count>
                {" "}
                {tabValue === "1"
                  ? count?.unreadForOpenTab
                  : count?.unreadForCloseTab}
              </Count>
            )}
          </UnReadSwitchContainer>
        </CenterContents>

        <LeftContents>
          <IconButtons>
            <ContentWrapper onClick={onClickDownload}>
              <CustomText color="petrolBlue">
                {getLabel(
                  "table_header_download_icon_title",
                  "Download",
                  prismicData
                )}
              </CustomText>
              {isDocumentLoading ? (
                <Loading type="downloadCSV" />
              ) : (
                <DownloadIcon
                  sx={{ fontSize: 16 }}
                  htmlColor={Colors.petrolBlue}
                />
              )}
            </ContentWrapper>

            {/* <ContentWrapper>
              <CustomText color="petrolBlue">
                {getLabel(
                  "table_header_view_type_icon_title",
                  "View",
                  prismicData
                )}
              </CustomText>
              <GridViewIcon
                sx={{ fontSize: 16 }}
                htmlColor={Colors.petrolBlue}
              />
            </ContentWrapper> */}
            {/* 
            <ContentWrapper onClick={(e) => onPressFilter(e)}>
              <CustomText color="petrolBlue">
                {getLabel("filter_icon", "Filter", prismicData)}
              </CustomText>
              <FilterAltIcon
                sx={{ fontSize: 16 }}
                htmlColor={
                  (statusArray && statusArray?.length !== 0) ||
                  createDateStart ||
                  createDateEnd
                    ? Colors.midOrange
                    : Colors.petrolBlue
                }
              />
            </ContentWrapper> */}
          </IconButtons>
          <div
            className="newRequest"
            // style={{ position: "absolute", marginTop: "-229px", right: "30px" }}
            ref={tutorial}
          >
            {canCreateNewRequest &&
            userData?.role != "CA" &&
            userData?.role != "CS" &&
            userData?.role != "FS" &&
            userData?.role != "LS" &&
            sessionStorage.getItem("v") != "true" ? (
              <>
                {clientSettingValue !== "T" && (
                  <CustomButton onClick={() => onPressButton()}>
                    {getLabel(
                      "table_header_new_request_button_title",
                      "New Request",
                      prismicData
                    )}
                  </CustomButton>
                )}

                {showTutorial && clientSettingValue !== "T" && (
                  <Tutorial
                    x={20}
                    y={15}
                    w={130}
                    place="right"
                    head={getLabel(
                      "new_request_tutorial_title",
                      "Quick Links",
                      prismicData
                    )}
                    description={getLabel(
                      "New Requests",
                      "Do you have a request? Click on New Request to get started. We are excited to help you manage your fleet.",
                      prismicData
                    )}
                  />
                )}
              </>
            ) : null}
          </div>
        </LeftContents>
      </HeaderContainer>
      {filterBar && (
        <Text
          color="petrolBlue"
          size="m"
          fontWeight="bold"
          style={{ marginRight: "15px" }}
        >
          {`${totalRecords} Results`}
        </Text>
      )}
      {categoryArray &&
        categoryArray.map((e) => (
          <FilterItem>
            {`I need help with: "${e}"`}
            <CloseFilterIcon onClick={() => updateGlobalFilter("category", e)}>
              <CloseIcon sx={{ fontSize: 11 }} />
            </CloseFilterIcon>
          </FilterItem>
        ))}{" "}
      {subcategoryArray &&
        subcategoryArray.map((e) => (
          <FilterItem>
            {`Reason for request: "${e}"`}
            <CloseFilterIcon
              onClick={() => updateGlobalFilter("subCategory", e)}
            >
              <CloseIcon sx={{ fontSize: 11 }} />
            </CloseFilterIcon>
          </FilterItem>
        ))}
      {createDateStart && createDateEnd && (
        <FilterItem>
          {`Submitted Range: "${getMmDdYY(createDateStart)}"-"${getMmDdYY(
            createDateEnd
          )}"`}
          <CloseFilterIcon
            onClick={() => updateGlobalFilter("submittedDate", createDateStart)}
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {repliedDateStart && repliedDateEnd && (
        <FilterItem>
          {`Last response: "${getMmDdYY(repliedDateStart)}"-"${getMmDdYY(
            repliedDateEnd
          )}"`}

          <CloseFilterIcon
            onClick={() => updateGlobalFilter("repliedDate", createDateStart)}
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {statusArray &&
        statusArray.map((e) => (
          <FilterItem>
            {`Status: "${e}"`}
            <CloseFilterIcon onClick={() => updateGlobalFilter("status", e)}>
              <CloseIcon sx={{ fontSize: 11 }} />
            </CloseFilterIcon>
          </FilterItem>
        ))}
      {caseNumber && (
        <FilterItem>
          {`Reference ID: "${caseNumber}"`}
          <CloseFilterIcon
            onClick={() =>
              updateGlobalFilter("caseNumber", "Reference ID", caseNumber)
            }
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {createUserName && (
        <FilterItem>
          {`Requested By: "${createUserName}"`}
          <CloseFilterIcon
            onClick={() =>
              updateGlobalFilter(
                "createUserName",
                "Requested By",
                createUserName
              )
            }
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {vehicleNumber && (
        <FilterItem>
          {`ePlan Unit: "${vehicleNumber}"`}
          <CloseFilterIcon
            onClick={() =>
              updateGlobalFilter("vehicleNumber", "ePlan Unit", vehicleNumber)
            }
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {clientUnitNumber && (
        <FilterItem>
          {`Client Unit: "${clientUnitNumber}"`}
          <CloseFilterIcon
            onClick={() =>
              updateGlobalFilter(
                "clientUnitNumber",
                "Client Unit",
                clientUnitNumber
              )
            }
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {vin && (
        <FilterItem>
          {`VIN: "${vin}"`}
          <CloseFilterIcon
            onClick={() => updateGlobalFilter("vin", "VIN", vin)}
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {driverName && (
        <FilterItem>
          {`Driver: "${driverName}"`}
          <CloseFilterIcon
            onClick={() =>
              updateGlobalFilter("driverName", "Driver", driverName)
            }
          >
            <CloseIcon sx={{ fontSize: 11 }} />
          </CloseFilterIcon>
        </FilterItem>
      )}
      {filterBar && (
        <Text
          color="petrolBlue"
          size="s"
          fontWeight="bold"
          style={{ marginLeft: "15px", cursor: "pointer" }}
          onClick={onClickClear}
        >
          <u>{getLabel("clear_all_title", "Clear All", prismicData)}</u>
        </Text>
      )}
      <GlobalFilterMenu
        anchorEl={anchorElFilter}
        open={openFilter}
        handleClose={handleCloseFilter}
        filterData={filterData}
        updateSearchQuery={updateSearchQuery}
        removeSearchQuery={removeSearchQuery}
        statusArray={statusArray}
        createDateStart={createDateStart}
        createDateEnd={createDateEnd}
      />
    </>
  );
};

export default RequestHeader;

const HeaderContainer = styled.div`
  padding-bottom: ${Spacing.xs};
  display: block;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1025px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;
const SearchWrapper = styled.div`
  display: flex;
  width: 41%;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) and (min-width: 600px) {
    width: 80%;
  }
  @media only screen and (max-width: 992px) and (min-width: 768px) {
    width: 65%;
  }
`;
const LeftContents = styled.div`
  display: block;
  justify-content: space-between;
  margin: ${Spacing.xs} 0;
  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
const CenterContents = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 45%;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  @media only screen and (max-width: 768px) and (min-width: 600px) {
    width: 100%;
  }

  @media only screen and (max-width: 992px) and (min-width: 768px) {
    width: 100%;
  }
`;
const ContentWrapper = styled.div`
  margin-right: ${Spacing.m};
  display: flex;
  background: #fafafa;
  padding: ${Spacing.xxxs} ${Spacing.xs};
  border-radius: ${Spacing.xxxs};
  align-items: center;
  cursor: pointer;
`;

const IconButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomText = styled(Text)`
  margin-right: ${Spacing.xxs};
  height: fit-content;
  display: inherit;
  font-size: ${ConvertSize(11)};
`;

const CustomButton = styled(Button)`
  margin-top: ${Spacing.xs};
  font-size: ${Spacing.xs};
  width: 100%;
  min-height: ${ConvertSize(34)};
  border-radius: ${Spacing.xxl};
  font-weight: bold;
  line-height: 0;

  @media (min-width: 600px) {
    width: ${ConvertSize(160)};
    margin-top: 0;
  }
`;
const FilterItem = styled.span`
  background: ${Colors.petrolBlue};

  margin-top: ${Spacing.xxs};
  margin-bottom: ${Spacing.xs};
  margin-right: ${Spacing.xxxs};
  margin-left: ${Spacing.xs};
  font-size: ${Spacing.xs};
  border-radius: 30px;
  color: white;
  display: inline-block;
  padding-top: ${ConvertSize(5)};
  padding-bottom: ${ConvertSize(5)};
  padding-left: ${ConvertSize(12)};
  padding-right: ${ConvertSize(22)};
  text-align: center;
  position: relative;
  cursor: default;
`;
const CloseFilterIcon = styled.span`
  position: absolute;
  right: 6px;
  cursor: pointer;
`;
const SearchResultHead = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SearchHeadInner = styled.div`
  font-size: 10px;
  color: #989a9a;
  text-decoration: none solid rgb(152, 154, 154);
`;

const SearchHeadApply = styled.div`
  font-size: 10px;
  color: #ee4c14;
  text-decoration: underline;
  cursor: pointer;
`;
const SearchInnerData = styled.div`
  font-size: 12px;
  color: #004a5d;
  text-decoration: none solid rgb(0, 74, 93);
  cursor: pointer;
`;
const SearchResult = styled.div`
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
  background: white;
  padding: 14px;
  position: absolute;
  margin-left: 26px;
  z-index: 1;
  width: 14%;
`;

const UnReadSwitchContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 9.375rem;
`;

const Count = styled.div`
  background-color: ${Colors.petrolBlue};
  border-radius: 50%;
  font-size: ${Spacing.xs};
  color: ${Colors.white};
  padding: 3px 6px;
  margin-left: 6px;
`;
