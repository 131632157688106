export const FETCH_REQUESTS_START = "FETCH_REQUESTS_START";
export const FETCH_REQUESTS_SUCCESS = "FETCH_REQUESTS_SUCCESS";
export const FETCH_REQUESTS_FAIL = "FETCH_REQUESTS_FAIL";

export const FETCH_FILEUPLOAD_START = "FETCH_FILEUPLOAD_START";
export const FETCH_FILEUPLOAD_SUCCESS = "FETCH_FILEUPLOAD_SUCCESS";
export const FETCH_FILEUPLOAD_FAIL = "FETCH_FILEUPLOAD_FAIL";
export const STORE_FILE_ID = "STORE_FILE_ID";
export const fetchRequestStart = () => {
  return {
    type: FETCH_REQUESTS_START,
  };
};

export const fetchRequestSuccess = (res) => {
  return {
    type: FETCH_REQUESTS_SUCCESS,
    requests: res.requests,
    totalRecords: res.totalRecords,
    currentPage: res.currentPage,
    totalPages: res.totalPages,
    recordsInPage: res.recordsInPage,
  };
};

export const fetchRequestFail = (err) => {
  return {
    type: FETCH_REQUESTS_FAIL,
    payload: err,
  };
};

export const fetchFileUploadStart = () => {
  return {
    type: FETCH_FILEUPLOAD_START,
  };
};

export const fetchFileUploadSuccess = (res) => {
  return {
    type: FETCH_FILEUPLOAD_SUCCESS,
    fileId: res.data,
  };
};

export const fetchFileUploadFail = (err) => {
  return {
    type: FETCH_FILEUPLOAD_FAIL,
    payload: err,
  };
};

export const storeFileIds = (files) => {
  return {
    type: STORE_FILE_ID,
    payload: files
  }
}
