import React, { useEffect } from "react";
import styled from "styled-components";
import { Colors, Spacing, ConvertSize } from "../utils/theme";

const Tutorial = (props) => {
  return (
    <TutorialWrapper
      x={props.x}
      y={props.y}
      place={props.place}
      mt={props.mt}
      w={props.w}
    >
      <TutorialHead color="petrolBlue">{props.head}</TutorialHead>
      <TutorialDescription>{props.description}</TutorialDescription>
    </TutorialWrapper>
  );
};
const TutorialWrapper = styled.div`
  & {
    position: absolute;
    background: white;
    ${({ w }) => `width: ${w}%; `}
    padding: 15px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
    ${({ mt }) => `margin-top: ${ConvertSize(mt)}; `}
    ${({ y }) => `top: ${ConvertSize(y + 30)}; `}
    ${({ x, place }) =>
      place == "right"
        ? `
        right: ${ConvertSize(x + 10)}
`
        : `left: ${ConvertSize(x + 10)}`}
  }
  &:before {
    content: "";
    position: absolute;
    width: 10px;
    aspect-ratio: 1;
    background-color: white;
    top: -5px;
    transform: translateX(-50%) rotate(45deg);
    ${({ place }) =>
      place == "right"
        ? `
      right: 10%
`
        : `left: 10%;`}
  }
`;
const TutorialHead = styled.h3`
  font-size: ${ConvertSize(13)};
  color: #004a5d;
  text-decoration: none solid rgb(0, 74, 93);
`;
const TutorialDescription = styled.p`
  font-size: ${ConvertSize(11)};
  color: #004a5d;
  text-decoration: none solid rgb(0, 74, 93);
  margin-top: ${Spacing.xxs};
  font-weight: 200;
  line-height: 1.1;
`;

export default Tutorial;
