export const EXTERNAL_REQUEST = "external_request";
export const CREATE_NEW_REQUEST = "creat_new_request";
export const CATEGORY_CHANGE = "category_change";
export const SUBCATEGORY_CHANGE = "subcategory_change";
export const DRIVER_SEARCH = "driver_search";
export const VEHICLE_SEARCH = "vehicle_search";
export const COMMENT_ADDED = "comment_added";
export const ON_CLICK_DOWNLOAD = "on_click_download";
export const ON_CLICK_SEARCH = "on_click_search";
export const UPLOAD_ATTACHMENTS = "upload_attachments";
export const REMOVE_ATTACHMENTS = "remove_attachments";
export const GLOBAL_SEARCH = "global_search";
export const UNDO_DRIVER = "undo_driver";
export const UNDO_VEHICLE = "undo_vehicle";
export const REMOVE_SELECTED_DRIVER = "remove_selected_driver";
export const REMOVE_SELECTED_VEHICLE = "undo_selected_vehicle";
export const OPEN_TAB_ACTIVE = "open_tab_active";
export const CLOSE_TAB_ACTIVE = "close_tab_active";
export const CLICKED_CANCEL_REQUEST = "clicked_cancel_request";
export const TABLE_COLUMN_OPERATION = "table_column_operation";
export const PENDING_REQUEST = "pending_request";
export const VIEW_TUTORIAL = "view_tutorial";
export const OPEN_REQUEST = "open_request";
export const PREVIEW_ATTACHMENT = "preview_attachment";
export const DOWNLOAD_ATTACHMENT = "download_attachment";
export const SYSTEM_INFORMATION = "opened_system_information";
export const PRIVACY_STATEMENT = "privacy_statement";
export const PAGINATION_SETTINGS = "pagination_settings"