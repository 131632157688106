import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Document, Page } from "react-pdf";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { Colors, Spacing, ConvertSize } from "../../../utils/theme";
import FileViewer from "react-file-viewer";
import { read, writeFileXLSX } from "xlsx";
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import uuid from "react-uuid";
set_cptable(cptable);
var XLSX = require("xlsx");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PreviewModal(props) {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [excel, setExcel] = useState(null);
  const [excelKeys, setExcelKeys] = useState(null);
  const [excelSheet, setExcelSheet] = useState([]);
  const [currentExcelSheet, setCurrentExcelSheet] = useState(0);
  const [pdfUrl,setPdfUrl] = useState(null);
  const [textUrl,setTextUrl] =useState(null)
  const excelExtensions = ['xls','xlsx'];
  useEffect(() => {
    const fileUploadAsync = async () => {
      const extension=props.previewAttachments?.subData?.fileExtension?.toLowerCase()
      setOpen(true);
      if (
        excelExtensions.includes(extension)
      ) {
        const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${
          props.previewAttachments && props.previewAttachments?.data?.data
        }`;
        const data = await (await fetch(url)).arrayBuffer();
        /* data is an ArrayBuffer */
        const workbook = XLSX.read(data);
        const worksheet =
          workbook.Sheets[workbook.SheetNames[currentExcelSheet]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const head = [];
        jsonData.length!==0 && Object.keys(jsonData[0]).map((e) => head.push({ field: e }));
        setExcel(jsonData);
        setExcelKeys(head);
        setExcelSheet(workbook.SheetNames);
      }
      if(extension === "pdf" || 
         extension === "txt"){
        const blob = new Blob([props.previewAttachments?.data?.data], { type:extension==='pdf'? `application/pdf`:'text/plain' });
        const url = URL.createObjectURL(blob);
        extension === "pdf" ? setPdfUrl(url):setTextUrl(url);
      }
    };
    fileUploadAsync();
  }, [props.previewAttachments, currentExcelSheet]);

  return (
    <div>
      <Button></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ height: "90%", width: "90%", overflow: "scroll" }}
        >
          <CustomCloseIcon onClick={handleClose} />
          {(props.previewAttachments &&
            props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
              "png") ||
          props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
            "jpeg" ||
          props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
            "jpg" ? (
            <img
              src={`data:image/png;base64,${props.previewAttachments?.data?.data}`}
              alt=""
              width="100%"
            />
          ) : (
            <>
              {props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
                "pdf" && (
                <iframe
                  src={pdfUrl}
                  height="100%"
                  width="100%"
                ></iframe>
              )}
              {props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
                "txt" && (
                <iframe
                  src={textUrl}
                  height="100%"
                  width="100%"
                ></iframe>
              )}
              {props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
                "csv" && (
                <FileViewer
                  fileType={"csv"}
                  filePath={`data:${
                    props.previewAttachments?.subData?.contentType
                  };base64,${
                    props.previewAttachments &&
                    props.previewAttachments?.data?.data
                  }`}
                />
              )}

              {props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
                "docx" && (
                <FileViewer
                  fileType={"docx"}
                  filePath={`data:${
                    props.previewAttachments?.subData?.contentType
                  };base64,${
                    props.previewAttachments &&
                    props.previewAttachments?.data?.data
                  }`}
                />
              )}

              {props.previewAttachments?.subData?.fileExtension?.toLowerCase() ==
                "doc" && (
                <FileViewer
                  fileType={"doc"}
                  filePath={`data:${
                    props.previewAttachments?.subData?.contentType
                  };base64,${
                    props.previewAttachments &&
                    props.previewAttachments?.data?.data
                  }`}
                />
              )}
              {excelExtensions.includes(props.previewAttachments?.subData?.fileExtension?.toLowerCase())
                 && (
                <>
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "80vh", width: "100%" }}
                  >
                    <AgGridReact
                      rowData={excel}
                      columnDefs={excelKeys}
                    ></AgGridReact>
                  </div>
                  <TableSelect
                    name="cars"
                    id="cars"
                    onChange={(e) => setCurrentExcelSheet(e.target.value)}
                  >
                    {excelSheet.map((e, key) =>
                      key == 0 ? (
                        <option value={key} selected key={uuid()}>
                          {e}
                        </option>
                      ) : (
                        <option value={key} key={uuid()}>
                          {e}
                        </option>
                      )
                    )}
                  </TableSelect>
                </>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}

const CustomCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: ${Spacing.xxxs};
  top: ${Spacing.xxxs};
  background: #fa7913;
  color: white;
  border-radius: 50%;
  padding: ${ConvertSize(2)};
  cursor: pointer;
`;

const TableSelect = styled.select`
  margin-top: 10px;
  border: 1px solid;
  padding: 3px;
`;
