import React from "react";
import styled from "styled-components";
import { Colors, ConvertSize } from "../../utils/theme";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Text } from "@leaseplan/ui";

const SubCategoryTooltip = ({ subCat, read, showTutorial }) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: Colors.midOrange,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Colors.midOrange,
    },
  }));
  return (
    <>
      {subCat && subCat.length >= 23 ? (
        <NewConatiner showTutorial={showTutorial}>
          <Text size="xs" fontWeight={!read && "bold"} color="petrolBlue">
            {subCat}
          </Text>
        </NewConatiner>
      ) : (
        <NewConatinerWithoutHover showTutorial={showTutorial}>
          <Text size="xs" fontWeight={!read && "bold"} color="petrolBlue">
            {subCat}
          </Text>
        </NewConatinerWithoutHover>
      )}
    </>
  );
};

export default SubCategoryTooltip;
const NewConatiner = styled.div`
  display: flex;

  white-space: nowrap;
  transform: ${(props) => !props.showTutorial && `translateX(0)`};
  transition: ${(props) => !props.showTutorial && `3s`};

  :hover {
    transform: ${(props) =>
      !props.showTutorial && `translateX(calc(30px - 120%))`};
  }
`;
const NewConatinerWithoutHover = styled.div`
  display: flex;

  white-space: nowrap;
  transform: ${(props) => !props.showTutorial && `translateX(0)`};
  transition: ${(props) => !props.showTutorial && `1s`};
`;
