import axios from "axios";
import { HOST_URL } from "../utils/constants";
import store from "../store/store";
import { getErrorMessage } from "../utils/stringFunctions";
import { showToaster } from "../store/toaster/toasterActions";
import * as AuthApi from "../store/auth/authApi";
import * as AuthActions from "../store/auth/authActions";
import { logout } from "../store/auth/authThunk";
import InvalidAccess from "../components/ErrorHandling/invalidAccess";
import { logError, logFunctionError } from "./errorLog";
import { getLabel } from "../utils/prismicUtils";
let RETRY_COUNT = 0;
/* Creating a new instance of axios with the baseURL, timeout and headers. */
const server = axios.create({
  baseURL: HOST_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Request-System": "eplanui",
  },
});
const prismicData = store?.getState()?.prismic?.prismicData;

/* This is intercepting the request and adding the access token to the header. */
server.interceptors.request.use((request) => {
  try {
    const authState = {
      idToken: sessionStorage.getItem("idToken"),
      accessToken: sessionStorage.getItem("accessToken"),
      refreshToken: sessionStorage.getItem("refreshToken"),
    };
    if (authState?.accessToken) {
      request.headers["Authorization"] = authState?.accessToken;
    }
    return request;
  } catch (e) {
    logFunctionError(e);
    console.log(e, "Interceptor error");
  }
});

try {
  /* This is intercepting the response and checking if the response is 401 or 403 or 500. If it is 401 or
  403 or 500, it is trying to get a new session and retrying the request. */
  server.interceptors.response.use(
    function (response) {
      RETRY_COUNT = 0;
      return response;
    },
    async (err) => {
      const authState = {
        idToken: sessionStorage.getItem("idToken"),
        accessToken: sessionStorage.getItem("accessToken"),
        refreshToken: sessionStorage.getItem("refreshToken"),
      };
      let originalRequest = err.config;
      !originalRequest.url.includes("logger") && logError(err);
      if (
        (err.response.status === 401 ||
          err.response.status === 403 ||
          err.response.status === 400 ||
          err.response.status === 500) &&
        RETRY_COUNT < 3
      ) {
        RETRY_COUNT++;
        return new Promise(async (resolve, reject) => {
          try {
            if (authState?.idToken && authState?.refreshToken) {
              const res = await AuthApi.getNewSession(
                sessionStorage.getItem("idToken"),
                sessionStorage.getItem("refreshToken")
              );
              sessionStorage.setItem("idToken", res.data.idToken);
              sessionStorage.setItem("accessToken", res.data.accessToken);
              sessionStorage.setItem("refreshToken", res.data.refreshToken);
              store.dispatch(AuthActions.login(res.data));
              server.defaults.headers.common["Authorization"] =
                res.data.accessToken;
              originalRequest.headers["Authorization"] = res.data.accessToken;
              if (
                !(
                  originalRequest.url.includes("token?id_token") ||
                  originalRequest.url.includes("logout?refresh_token")
                )
              ) {
                originalRequest.method === "put" &&
                  (originalRequest.headers["Content-Type"] =
                    "application/octet-stream");
                resolve(server(originalRequest));
              } else {
                reject(err);
              }
            } else {
              store.dispatch(logout());
              reject(err);
            }
          } catch (e) {
            console.log(e);
            store.dispatch(logout());
            reject(e);
            logFunctionError(e);
          }
        });
      } else {
        try {
          let code = err?.response?.data.messages[0]?.code;
          if (code && code == "QUEUE_REQUIRED") {
            return code;
          }
          if (code && code == "FailedException") {
            store.dispatch(
              showToaster({
                type: "ERROR",
                message: err?.response?.data.messages[0]?.text
                  ? err?.response?.data.messages[0]?.text
                  : "",
              })
            );
            return code;
          }
          if (code && code.startsWith("ec=")) {
            code = "ec=";
          }
          if (code && code.startsWith("vc=")) {
            code = "vc=";
          }
          if (code && code !== "BAD_JWT") {
            if (err?.response?.data.messages[0]?.code === "USER_NOT_FOUND") {
              store.dispatch(
                AuthActions.loginFail(err?.response?.data?.messages[0]?.code)
              );
            }
            if (
              err?.response?.data.messages[0]?.code ===
                "SF_USER_CONTACT_ID_NOT_FOUND" ||
              err?.response?.data.messages[0]?.code ===
                "SF_DRIVER_CONTACT_ID_NOT_FOUND"
            ) {
              let text = err?.response?.data.messages[0]?.text;
              const errorMessage = getErrorMessage(
                err?.response?.data.messages[0]?.code
              );
              store.dispatch(
                showToaster({
                  type: "ERROR",
                  message: err?.response?.data.messages[0]?.code.startsWith(
                    "ec="
                  )
                    ? errorMessage.replace(
                        "XXXerrorCodeXXX",
                        err?.response?.data?.messages[0]?.code
                      )
                    : Number.isInteger(parseInt(text))
                    ? errorMessage + text
                    : "",
                })
              );
            }

            const errorMessage = getErrorMessage(code);
            store.dispatch(
              showToaster({
                type: "ERROR",
                message: code.startsWith("ec=")
                  ? errorMessage.replace(
                      "XXXerrorCodeXXX",
                      err?.response?.data?.messages[0]?.code
                    )
                  : errorMessage,
              })
            );
          } else {
            // store.dispatch(logout());
            store.dispatch(AuthActions.loginFail());
            return Promise.reject(err);
          }
        } catch (error) {
          store.dispatch(
            showToaster({
              type: "ERROR",
              message: getLabel(
                "error_message_for_api_fails",
                "Error message",
                prismicData
              ),
            })
          );
          console.log(error);
        }
      }
    }
  );
} catch (e) {
  console.log(e);
}

export default server;
