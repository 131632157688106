import React, { useDebugValue, useEffect, useState } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SSO from "./pages/SSO";
import PrivateRoute from "./components/PrivateRoute";
import { history } from "./helpers/history";
import { useDispatch, useSelector } from "react-redux";
import SidePanel from "./components/SidePanel/SidePanel";
import { fetchPrismicData } from "./store/prismic/prismicActionCreators";
import { validateToken } from "./store/auth/authThunk";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Unauthorizedaccess from "./components/ErrorHandling/Unauthorizedaccess";
import { login, logout } from "./store/auth/authThunk";
import Loading from "./components/Loading";
import InvalidAccess from "./components/ErrorHandling/invalidAccess";
import { logFunctionError } from "./config/errorLog";
import { showSidePanel } from "./store/commonStore/commonActions";
import {
  applyFilters,
  setLegacyCommand,
} from "./store/commonStore/commonActionCreators";

const userStr = "?userId=";
const clientStr = "clientId=";
const tokenStr = "token=";
const cltOnFleetStr = "cltOnFleetView=";

const App = () => {
  const searchParams = history.location.search;
  const dispatch = useDispatch();
  const { isSidePanelShown, filters } = useSelector((state) => state.common);
  const userData = useSelector((state) => state.user.userData);
  const { isAuthenticated, loading, isLoginFail, errorMessage } = useSelector(
    (state) => state.auth
  );

  function validate() {
    const idToken = sessionStorage.getItem("idToken");
    if (idToken) {
      dispatch(validateToken(idToken));
    } else {
      dispatch(logout());
    }
  }

  function logoutBeforeClose() {
    console.log(
      "******************************************** called before ***********************************"
    );
    dispatch(logout());
  }
  useEffect(() => {
    function eventFromLegacy(event) {
      // if (event.origin === "http://localhost:3000") {
      if (
        event?.origin?.indexOf("leaseplan.com") !== -1 ||
        event?.origin?.indexOf("lpusa.net") !== -1
      ) {
        console.log(
          "********************************************  Posted Message ***********************************",
          event
        );
        if (event?.data?.event?.startsWith("Ticket")) {
          const data = event?.data?.event?.split("&");
          const request =
            data.length > 1 && data[1].startsWith("requestType")
              ? data[1]?.split("=")
              : "";
          const requestType = request.length > 1 ? request[1] : "";
          dispatch(
            showSidePanel({
              type: "Ticket",
              requestId: data.length > 0 ? data[0].substring(6) : "",
              requestType,
            })
          );
        } else if (
          (event?.data?.event?.startsWith("New") ||
            event?.data?.event?.startsWith("InProgress")) &&
          event?.data?.event?.includes("category")
        ) {
          const data = event?.data?.event?.split("&");
          const category =
            data.length === 2 && data[1].startsWith("category")
              ? data[1]?.split("=")
              : "";
          const subCategory =
            data.length === 3 && data[2].startsWith("subCategory")
              ? data[2]?.split("=")
              : "";

          if (event?.data?.event?.startsWith("New")) {
            dispatch(
              applyFilters({
                tab: 1,
                pageNumber: 1,
                category: category[1] !== undefined ? category[1] : "",
                subCategory: subCategory[1] !== undefined ? subCategory[1] : "",
                status: ["New", "Sending"].reduce(
                  (acc, item, index) => `${item},${acc}`,
                  ""
                ),
              })
            );
          } else {
            dispatch(
              applyFilters({
                tab: 1,
                pageNumber: 1,
                category: category[1] !== undefined ? category[1] : "",
                subCategory: subCategory[1] !== undefined ? subCategory[1] : "",
                status: ["In Progress"],
              })
            );
          }
        } else {
          switch (event.data.event) {
            case "ClientSelection":
              try {
                const params = event?.data?.message?.split("&");
                const clientId = params[1].replace("clientId=", "");
                if (
                  decodeURIComponent(clientId) !==
                  decodeURIComponent(sessionStorage.getItem("c"))
                ) {
                  dispatch(logout());
                  window.close();
                }
              } catch (e) {
                console.log("Error while parsing params", e);
                logFunctionError(e);
              }
              break;
            case "SessionExpired":
              dispatch(logout());
              window.close();
              break;
            case "New":
              dispatch(
                applyFilters({
                  tab: 1,
                  pageNumber: 1,
                  category: "",
                  subCategory: "",
                  status: ["New", "Sending"].reduce(
                    (acc, item, index) => `${item},${acc}`,
                    ""
                  ),
                })
              );

              break;
            case "InProgress":
              dispatch(
                applyFilters({
                  tab: 1,
                  pageNumber: 1,
                  category: "",
                  subCategory: "",
                  status: ["In Progress"],
                })
              );

              break;
            case "NewRequest":
              if (
                userData?.role != "CA" &&
                userData?.role != "CS" &&
                userData?.role != "FS" &&
                userData?.role != "LS"
              ) {
                isSidePanelShown
                  ? dispatch(setLegacyCommand({ legacyCommand: "close" }))
                  : dispatch(showSidePanel({ type: "NewRequest" }));
              }
              break;
            default:
              console.log("Default");
          }
        }
      }
    }
    window.onmessage = eventFromLegacy;
    return () => {
      window.removeEventListener("message", eventFromLegacy);
    };
  }, [isSidePanelShown]);

  useEffect(() => {
    dispatch(fetchPrismicData());
    try {
      const paramsArr = searchParams.split("&");
      const query = new URLSearchParams(searchParams);
      if (
        query.get("category") !== null &&
        query.get("subCategory") !== null &&
        query.get("category") !== undefined &&
        query.get("subCategory") !== undefined
      ) {
        dispatch(
          applyFilters({
            tab: 1,
            pageNumber: 1,
            category: query.get("category"),
            subCategory: query.get("subCategory"),
          })
        );
      } else {
        dispatch(
          applyFilters({
            tab: 1,
            pageNumber: 1,
          })
        );
      }

      if (
        paramsArr[0].indexOf(userStr) > -1 &&
        paramsArr[1].indexOf(clientStr) > -1 &&
        paramsArr[2].indexOf(tokenStr) > -1
      ) {
        const userId = paramsArr[0].slice(userStr.length, paramsArr[0].length);
        const clientId = paramsArr[1].slice(
          clientStr.length,
          paramsArr[1].length
        );
        const token = paramsArr[2].slice(tokenStr.length, paramsArr[2].length);

        if (paramsArr[3]) {
          const cltOnFleetViewStatus = paramsArr[3].slice(
            cltOnFleetStr.length,
            paramsArr[3].length
          );
          sessionStorage.setItem("v", cltOnFleetViewStatus);
        }

        sessionStorage.setItem("u", userId);
        sessionStorage.setItem("c", clientId);

        dispatch(
          login({
            userId: decodeURIComponent(userId),
            clientNo: decodeURIComponent(clientId),
            token: decodeURIComponent(token)
          })
        );
      } else {
        validate();
      }
    } catch (e) {
      console.log(e);
      validate();
    }
    if (localStorage.getItem("setActiveTime")) {
      const maxActiveTime = new Date(localStorage.getItem("setActiveTime"));
      const currentTime = new Date();
      if (maxActiveTime > currentTime) {
        localStorage.removeItem("setActiveTime");
        localStorage.removeItem("timeoutPopup");
      }
    }
  }, []);
  const windowUrl = window.location.href;
  const isLocal = windowUrl.match(/localhost/);
  return (
    <AppContainer>
      {isAuthenticated ? (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router>
            <Routes>
              <Route exact path="/login" element={isLocal ? <Login /> : null} />
              <Route exact path="/SSO" element={<SSO />} />
              <Route exact path="/" element={<PrivateRoute />}>
                <Route exact path="/" element={<Home />} />
              </Route>
            </Routes>
          </Router>
          {isSidePanelShown && <SidePanel />}
        </LocalizationProvider>
      ) : loading ? (
        <Loading />
      ) : isLoginFail === true ? (
        <InvalidAccess errorMessage={errorMessage} />
      ) : (
        <Unauthorizedaccess />
      )}
    </AppContainer>
  );
};

export default App;

const AppContainer = styled.div`
  width: 100%;
`;
