import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  GridItem,
  Textarea,
  Text,
  Select,
  Button as LPbutton,
  CloseIcon,
} from "@leaseplan/ui";
import RequestConfirmation from "../../RequestConfirmation";
import Drawer from "@mui/material/Drawer";
import { Colors, Spacing, ConvertSize } from "../../../utils/theme";
import Selectors from "../../Selectors";
import upArrow from "../../../assets/images/upArrow.png";
import downArrow from "../../../assets/images/downArrow.png";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  hideSidePanel,
  refreshCountData,
  refreshTableData,
} from "../../../store/commonStore/commonActionCreators";
import { connect } from "react-redux";
import Search from "../../Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Comment from "../Comment";
import InfiniteScroll from "react-infinite-scroll-component";

import Upload from "../UploadDetails";
import FileUpload from "../FileUpload";
import PreviewModal from "./PreviewModal";
import RequestCompleted from "./RequestCompleted";
import { getLabel } from "../../../utils/prismicUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  getRequestComments,
  getRequestAttachments,
  getRequestDetails,
  getRequestQueueDetails,
  getRequestAttachmentsDownload,
  getRequestPreview,
  getQueueRequestPreview,
  cancelRequest,
  cancelQueueRequest,
  cancelTransportationRequest,
  addComments,
  uploadAttachments,
  createRequest,
  deleteAttachment,
  getAttachments,
  getPreviewFromPresignedUrl,
} from "../../../store/request/requestApi";
import Loading from "../../Loading";
import {
  days_between,
  getMmDdYY,
  getMMDDYYYYWithTime,
  getMMDDYYYYWithTimeInSeconds,
  getMMMMDDYYYY,
  getTodayDateWithTime,
  getYYYY_MM_DD,
} from "../../../utils/calender";
import ShareRequest from "../../ShareRequest";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DownloadIcon } from "@leaseplan/ui";
import Completed from "../../requests/status/Completed";
import Pending from "../../requests/status/Pending";
import InReview from "../../requests/status/InReview";
import Sending from "../../requests/status/Sending";

import New from "../../requests/status/New";
import InProgress from "../../requests/status/InProgress";
import CancelledNotNeeded from "../../requests/status/CancelledNotNeeded";
import CancelledDuplicate from "../../requests/status/CancelledDuplicate";
import PendingAwaitingFeedback from "../../requests/status/PendingAwaitingFeedback";
import LinkedRequest from "../../requests/LinkedRequest";
import NavigateConfirmation from "../LinkedRequest/NavigateConfirmation";

import RequestsCard from "../../requests/RequestsCard";
import {
  showToaster,
  hideToaster,
} from "../../../store/toaster/toasterActions";
import Cancelled from "../../requests/status/Cancelled";
import { showSidePanel } from "../../../store/commonStore/commonActions";
import { isEmpty } from "lodash";
import eventLog from "../../../config/firebase";
import {
  CLICKED_CANCEL_REQUEST,
  DOWNLOAD_ATTACHMENT,
  OPEN_REQUEST,
  PREVIEW_ATTACHMENT,
} from "../../../helpers/logEvents";
import { logFunctionError } from "../../../config/errorLog";
import { storeFileIds } from "../../../store/request/requestActions";
const NewRequest = (props) => {
  const dispatch = useDispatch();
  const { requestId, requestType, refreshData } = useSelector(
    (state) => state.common
  );
  const [comments, setComments] = useState(null);
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requestID, setRequestID] = useState("");
  const [isCommentsLoading, setLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const [previewAttachments, setPreviewAttachments] = useState(null);
  const [previewModal, setPreviewModal] = useState(false);
  const [attachmentLoad, setAttachmentLoad] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [rerender, setRerender] = useState(true);
  const [CancelRequest, setCancelRequest] = useState(false);
  const [CancelStatus, setCancelStatus] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [CancelRequestStatus, setCancelRequestStatus] = useState(false);
  const [fileID, setFileID] = useState([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [attachmentClicked, setAttachmentClicked] = useState(false);
  const [viewComment, setViewComment] = useState(true);
  const [showError, setShowError] = useState(false);
  const [commentUpdated, setCommentUpdated] = useState(false);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [fileUploadFailed, setFileUploadFailed] = useState(null);
  const [commentID, setCommentID] = useState([]);
  const [status, setStatus] = useState(null);
  const extensions = ["ppt", "pptx", "doc"];
  const dayOfCreation =
    days_between(
      getYYYY_MM_DD(requestDetails?.closedDate),
      getTodayDateWithTime()
    ) > 30
      ? false
      : true;

  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );

  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );

  const returnComponent = (Status) => {
    switch (Status) {
      case "New":
        return <New />;
      case "In Progress":
        return <InProgress />;
      case "Completed":
        return <Completed />;
      case "Cancelled":
        return <Cancelled />;
      case "Pending":
        return <Pending />;
      case "Sending":
        return <Sending />;
      case "In Review":
        return <InReview />;
    }
  };

  const returnComponentStatus = (Status) => {
    switch (Status) {
      case "Cancelled - No Longer Needed":
        return <CancelledNotNeeded onClose={handleDrawerClose} />;
      case "Cancelled - Duplicate":
        return <CancelledDuplicate onClose={handleDrawerClose} />;
      case "Pending - Action Required by Customer":
        return <PendingAwaitingFeedback onClose={handleDrawerClose} />;
      default:
        <></>;
    }
  };
  useEffect(() => {
    props.status && setStatus(props.status);
  }, [props.status]);
  useEffect(() => {
    (async function () {
      if (commentUpdated) {
        setLoading(true);
        let commentID = [];
        const comments = await getRequestComments(requestId);
        setComments(comments?.data?.comments);
        comments?.data?.comments &&
          comments.data.comments.forEach((element) => {
            commentID.push(element.id);
          });

        const requestAttachments = await getAttachments(requestId, commentID);
        setUploadedData(requestAttachments);
        setLoading(false);
        setIsLoading(false);
        setCommentUpdated(false);
        setPreviewAttachments(false);
      }
    })();
  }, [commentUpdated]);

  const isStatusComponentDisplayed = (Status) => {
    switch (Status) {
      case "Cancelled - No Longer Needed":
      case "Cancelled - Duplicate":
      case "Pending - Action Required by Customer":
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      let commentID = [];
      if (props?.status && props.status == "Sending") {
        const requestDetails = await getRequestQueueDetails(
          requestType,
          requestId
        );
        if (
          (requestDetails && requestDetails?.data?.refreshData) ||
          !props.isRead
        ) {
          dispatch(
            showSidePanel({
              type: "Ticket",
              requestId: requestDetails?.data?.id,
            })
          );
          dispatch(
            refreshTableData({
              type: !refreshData,
              loading: !props.isRead ? false : true,
            })
          );
        }
        setRequestDetails(requestDetails?.data);
      } else {
        const requestDetails = await getRequestDetails(requestType, requestId);
        if (
          (requestDetails && requestDetails?.data?.refreshData) ||
          !props.isRead
        ) {
          dispatch(
            showSidePanel({
              type: "Ticket",
              requestId: requestDetails?.data?.id,
            })
          );
          dispatch(
            refreshTableData({
              type: !refreshData,
              loading: !props.isRead ? false : true,
            })
          );
        }
        setRequestDetails(requestDetails?.data);
        if (
          requestDetails?.data?.status == "Completed" ||
          requestDetails?.data?.status == "Cancelled - No Longer Needed" ||
          requestDetails?.data?.status == "Cancelled - Duplicate" ||
          requestDetails?.data?.status == "Cancelled"
        ) {
          setViewComment(false);
        }

        //get comments
        if (
          requestDetails &&
          requestType == "sc" &&
          isEmpty(comments) &&
          requestDetails?.data?.caseNumber?.charAt(0) !== "Q"
        ) {
          const comments = await getRequestComments(requestId);
          setComments(comments?.data?.comments);
          comments?.data?.comments &&
            comments.data.comments.forEach((element) => {
              commentID.push(element.id);
            });
          // const attachments = await getRequestAttachments(requestId);
          // setFiles(attachments.data.records);
        }
      }

      //get request details
      setCommentID(commentID);
      setLoading(false);

      // if (!props.isRead) {
      //   dispatch(refreshTableData({ type: !refreshData, loading: false }));
      // }
    })();
  }, [requestId, rerender]);

  useEffect(() => {
    (async function () {
      if (
        requestType !== "ep" &&
        isEmpty(uploadedData) &&
        requestDetails?.status !== "Sending" &&
        props.status !== "Sending" &&
        requestDetails !== null &&
        requestDetails !== undefined
      ) {
        const requestAttachments = await getAttachments(requestId, commentID);
        setUploadedData(requestAttachments);
      }
    })();
  }, [commentID]);

  const hideSidePanel = async (e) => {
    if (!isSubmitted && fileID.length > 0) {
      let deleteFiles = [];
      fileID.forEach(function (file) {
        deleteFiles.push({
          id: file.id,
        });
      });
      const deleteFileRes = await deleteAttachment(deleteFiles);
    }
    if (e?.type == 1) {
      dispatch(refreshTableData({ type: !refreshData, loading: true }));
    }
    props.hideSidePanel();
  };
  const clearFilesAttached = async (e) => {
    if (!isSubmitted && fileID.length > 0) {
      let deleteFiles = [];
      fileID.forEach(function (file) {
        deleteFiles.push({
          id: file.id,
        });
      });
      const deleteFileRes = await deleteAttachment(deleteFiles);
    }
  };
  const [anchorElReq, setAnchorElReq] = useState(null);
  const openReq = Boolean(anchorElReq);

  const handleSidePanel = async () => {
    if (fileID.length > 0) {
      setAnchorElReq(true);
    } else if (commentValue !== "") {
      setAnchorElReq(true);
    } else {
      if (!isSubmitted && fileID.length > 0) {
        let deleteFiles = [];
        fileID.forEach(function (file) {
          deleteFiles.push({
            id: file.id,
          });
        });
        const deleteFileRes = await deleteAttachment(deleteFiles);
      }
      props.hideSidePanel();
    }
  };

  const onClickLinkedRequest = (id, RequestType = "") => {
    RequestType === "related"
      ? eventLog({
          eventName: OPEN_REQUEST,
          additionalParams: {
            RequestType: "child request",
          },
        })
      : eventLog({
          eventName: OPEN_REQUEST,
          additionalParams: {
            Requesttype: "parent request",
          },
        });
    dispatch(
      showSidePanel({
        type: "Ticket",
        requestId: id,
        requestType: "sc",
      })
    );
  };
  const handleReqClose = (i) => {
    !CancelRequestStatus && setAnchorElReq(null);
    CancelRequest && i !== 1 && props.hideSidePanel();
    // setCancelRequest(false);
  };
  //   const handleSidePanel = async () => {
  //     if (fileID.length > 0) {
  //       setAnchorElReq(true);
  //     } else if (commentValue !== "") {
  //       setAnchorElReq(true);
  //     } else {
  //       props.hideSidePanel();
  //     }
  //   };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#044a5d",
      },
    },
  });
  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const settingsData = useSelector((state) => state.settings.settings);
  const userData = useSelector((state) => state.user.userData);
  const canCancelRequest =
    settingsData?.functionSetting["eplanignite/workspace/case/cancel"];
  const canEditRequest =
    settingsData?.functionSetting["eplanignite/workspace/case/edit"];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const fileDownload = (id, name) => {
    eventLog({
      eventName: DOWNLOAD_ATTACHMENT,
    });
    setAttachmentLoad({
      item: id,
      loading: true,
    });
    (async function () {
      var downloadURL;
      if (
        (props.status && props.status == "Sending") ||
        requestDetails?.caseNumber?.charAt(0) === "Q"
      ) {
        downloadURL = await getQueueRequestPreview(requestDetails.id, id);
      } else {
        const preSignedUrl = await getRequestPreview(id);
        downloadURL = await getPreviewFromPresignedUrl(preSignedUrl.data);
      }
      const base64String = convertTobase64(downloadURL.data);
      const linkSource = `data:${downloadURL.headers["content-type"]};base64,${base64String}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = name;
      downloadLink.click();
      setAttachmentLoad(false);
    })();
  };

  const onFileUpload = (fileID) => {
    setFileID(fileID);
    if (fileID.length == 0) {
      props.showPopupWarning("files", false);
    } else if (fileID.length > 0) {
      props.showPopupWarning("files", true);
    }
  };

  const fileUploadInProgress = (status) => {
    setUploadInProgress(status);
  };
  const fileDeletionInProgress = (status) => {
    setDeletionInProgress(status);
  };

  const cancelRequestID = () => {
    if (CancelStatus !== "Default") {
      (async function () {
        try {
          setLoading(true);

          if (requestDetails?.caseNumber?.charAt(0) === "Q") {
            const cancelRequestIDStatus = await cancelQueueRequest(
              requestDetails?.id,
              CancelStatus
            );
            if (cancelRequestIDStatus?.status === 200) {
              setCancelRequestStatus(true);
            }
          } else if (
            (requestDetails?.subType === "Book a transportation move" ||
              requestDetails?.subType === "Request a transportation quote") &&
            (clientSettingValue === "T" || clientSettingValue === "B") &&
            requestDetails?.caseNumber?.charAt(0) !== "Q"
          ) {
            const cancelRequestIDStatus = await cancelTransportationRequest(
              requestDetails?.id,
              CancelStatus,
              status === "Sending"
            );
            if (cancelRequestIDStatus?.status === 200) {
              setCancelRequestStatus(true);
            }
          } else {
            const cancelRequestIDStatus = await cancelRequest(
              requestDetails?.id,
              CancelStatus
            );
            if (cancelRequestIDStatus?.status === 200) {
              setCancelRequestStatus(true);
            }
          }
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setCancelRequestStatus(false);
          dispatch(
            showToaster({
              type: "WARNING",
              message: getLabel(
                "choose_reason_to_cancel_the_request",
                "Please choose reason to cancel the request",
                prismicData
              ),
            })
          );
          logFunctionError(e);
        }
      })();
    } else {
      dispatch(
        showToaster({
          type: "WARNING",
          message: getLabel(
            "choose_reason_to_cancel_the_request",
            "Please choose reason to cancel the request",
            prismicData
          ),
        })
      );
    }
  };
  const convertTobase64 = (data) => {
    const binaryString = Array.from(new Uint8Array(data), (v) =>
      String.fromCharCode(v)
    ).join("");
    const base64 = window.btoa(binaryString);
    return base64;
  };
  const filePreview = (item) => {
    eventLog({
      eventName: PREVIEW_ATTACHMENT,
    });
    setAttachmentLoad({
      item: item.id,
      loading: true,
    });

    (async function () {
      if (
        (props.status && props.status == "Sending") ||
        requestDetails?.caseNumber?.charAt(0) === "Q"
      ) {
        const downloadURL = await getQueueRequestPreview(
          requestDetails.id,
          item.id
        );
        setPreviewAttachments({
          data: downloadURL,
          subData: item,
        });
        setAttachmentLoad(false);

        setPreviewModal(true);
      } else {
        const preSignedUrl = await getRequestPreview(item.id);
        const downloadURL = await getPreviewFromPresignedUrl(preSignedUrl.data);
        item.fileExtension.toLowerCase() !== "pdf" &&
          item.fileExtension.toLowerCase() !== "txt" &&
          (downloadURL.data = convertTobase64(downloadURL.data));
        setPreviewAttachments({
          data: downloadURL,
          subData: item,
        });
        setAttachmentLoad(false);

        setPreviewModal(true);
      }

      // const newWindow = window.open(downloadURL.request.responseURL);
      // if (newWindow) newWindow.opener = null;
    })();
  };
  const showAttachment = () => {
    setAttachmentClicked(true);
  };
  const onSubmit = async () => {
    dispatch(hideToaster());
    if (!uploadInProgress && !deletionInProgress) {
      const requestBody = {
        comment: commentValue,
        attachments: fileID,
      };
      try {
        setAttachmentLoad(true);
        setRequestID(requestDetails.caseNumber);
        setLoading(true);
        const filesFailed = [];
        const commentRes = await addComments(requestId, requestBody);
        commentRes?.data?.attachments?.forEach((file) => {
          !file?.isUploaded && filesFailed.push(file?.fileName);
        });
        setFileUploadFailed(filesFailed);
        props.showPopupWarning("description", false);
        props.showPopupWarning("files", false);
        dispatch(storeFileIds([]));
        setCommentValue("");
        setAttachmentLoad(false);
        setIsSubmitted(true);
        setIsLoading(true);
        setRerender(!rerender);
        if (requestDetails.status == "Pending") {
          dispatch(refreshCountData());
        }
      } catch (e) {
        setAttachmentLoad(false);
        setLoading(false);
        setFileID([]);
        clearFilesAttached(e);
        props.showPopupWarning("files", false);
        props.showPopupWarning("description", false);
        console.log(e);
        logFunctionError(e);
      }
    } else {
      uploadInProgress
        ? dispatch(
            showToaster({
              type: "WARNING",
              message: getLabel(
                "file_upload_in_progress",
                "File upload in progress",
                prismicData
              ),
            })
          )
        : dispatch(
            showToaster({
              type: "WARNING",
              message: getLabel(
                "file_deletion_in_progress_warning",
                "File deletion in progress",
                prismicData
              ),
            })
          );
    }
  };
  const commentData = (data) => {
    if (/\S/.test(data)) {
      setCommentValue(data);
    }
    if (canEditRequest) {
      setShowError(false);
    }
    if (data.length == 0) {
      props.showPopupWarning("description", false);
    } else if (data.length > 0) {
      props.showPopupWarning("description", true);
    }
  };

  const testString = /Eplan/gi;

  const handleDrawerClose = () => {
    return requestDetails?.status == "Completed" ||
      requestDetails?.status == "Cancelled - No Longer Needed" ||
      requestDetails?.status == "Cancelled - Duplicate"
      ? hideSidePanel()
      : handleSidePanel();
  };
  const cancelRequestPopup = () => {
    dispatch(hideToaster());
    setCancelRequest(true);
    setAnchorElReq(true);
    eventLog({
      eventName: CLICKED_CANCEL_REQUEST,
    });
  };
  return (
    <>
      {!isLoading && returnComponentStatus(requestDetails?.statusDescription)}

      <DrawerInsideContainer>
        {isLoading === true ? (
          <span style={{ alignItems: "center" }}>
            {isCommentsLoading ? (
              <Loading />
            ) : (
              <RequestConfirmation
                requestId={requestID}
                isupdate
                requestIDref={requestDetails?.id}
                setCommentUpdated={setCommentUpdated}
                fileUploadFailed={fileUploadFailed}
              />
            )}
          </span>
        ) : (
          <>
            <>
              {isCommentsLoading ? (
                <Loading />
              ) : (
                requestDetails && (
                  <div style={{ opacity: CancelRequestStatus && "0" }}>
                    <Grid direction="column" className="sidebarScroll">
                      <GridItem>
                        {!isStatusComponentDisplayed(
                          requestDetails?.statusDescription
                        ) && (
                          <Header>
                            <CloseIconButton onClick={handleDrawerClose} />
                          </Header>
                        )}
                        <SubHeadRow>
                          <CustomText
                            color="petrolBlue"
                            size="m"
                            fontWeight="bold"
                          >
                            {requestDetails && requestDetails.type
                              ? (
                                  requestDetails.type.charAt(0).toUpperCase() +
                                  requestDetails.type.slice(1)
                                ).replace(testString, "ePlan")
                              : null}
                            :
                          </CustomText>

                          {/* <SubHeadButton> */}
                          {/* </SubHeadButton> */}
                        </SubHeadRow>

                        <SubHeadRow>
                          <CustomText
                            color="petrolBlue"
                            size="xl"
                            fontWeight="bold"
                          >
                            {requestDetails && requestDetails.subType
                              ? (
                                  requestDetails.subType
                                    .charAt(0)
                                    .toUpperCase() +
                                  requestDetails.subType.slice(1)
                                ).replace(testString, "ePlan")
                              : null}
                          </CustomText>
                          {/* <SubHeadButton> */}
                          {returnComponent(requestDetails?.status)}
                          {/* </SubHeadButton> */}
                        </SubHeadRow>
                      </GridItem>
                      {/* sub , requ, red */}
                      <QuickLinksContainer>
                        {requestDetails?.createDate && (
                          <DetailsWrapper>
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "table_column_submitted_title",
                                "Submitted",
                                prismicData
                              )}{" "}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {getMmDdYY(requestDetails?.createDate)}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {requestDetails?.createUserName && (
                          <DetailsWrapper>
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "table_column_request_by_title",
                                "Requested By",
                                prismicData
                              )}{" "}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.createUserName}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {requestDetails?.caseNumber &&
                        requestDetails?.caseNumber.charAt(0) != "Q" ? (
                          <DetailsWrapper>
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "reference_id_title",
                                "Reference ID",
                                prismicData
                              )}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.caseNumber}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        ) : (
                          requestDetails?.caseNumber.charAt(0) == "Q" && (
                            <DetailsWrapper className="pt-0">
                              <SubHead color="petrolBlue" size="xs">
                                {""}
                              </SubHead>
                              <div>
                                <SubHeadText color="petrolBlue">
                                  {""}{" "}
                                </SubHeadText>
                              </div>
                            </DetailsWrapper>
                          )
                        )}

                        {(!requestDetails?.createUserName ||
                          !requestDetails?.createDate ||
                          !requestDetails?.caseNumber) && (
                          <DetailsWrapper className="pt-0">
                            <SubHead color="petrolBlue" size="xs">
                              {""}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {""}{" "}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                      </QuickLinksContainer>
                      <QuickLinksContainer>
                        {requestDetails?.scUpdatedOnTimestamp && (
                          <DetailsWrapper className="pt-0">
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "last_internal_processing_title",
                                "Last update",
                                prismicData
                              )}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {getMmDdYY(
                                  requestDetails?.scUpdatedOnTimestamp
                                )}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {requestDetails?.closedDate && (
                          <DetailsWrapper>
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "completed_date_title",
                                "Completed Date",
                                prismicData
                              )}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {getMmDdYY(requestDetails?.closedDate)}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}

                        {/* {requestDetails?.eta && (
                   <DetailsWrapper className="pt-0">
                     <SubHead color="petrolBlue" size="xs">
                       {getLabel(
                         "targeted_date_title",
                         "Target Date",
                         prismicData
                       )}
                     </SubHead>
                     <div>
                       <SubHeadText color="petrolBlue">
                         {getMmDdYY(requestDetails?.eta)}
                       </SubHeadText>
                     </div>
                   </DetailsWrapper>
                 )} */}

                        <DetailsWrapper className="pt-0">
                          <SubHead color="petrolBlue" size="xs">
                            {""}
                          </SubHead>
                          <div>
                            <SubHeadText color="petrolBlue">{""} </SubHeadText>
                          </div>
                        </DetailsWrapper>
                      </QuickLinksContainer>
                      <QuickLinksContainer>
                        {requestDetails?.vehicle?.vehicleNumber && (
                          <DetailsWrapper>
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "vehicle_title",
                                "Vehicle",
                                prismicData
                              )}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.vehicle?.vehicleYear}{" "}
                                {requestDetails?.vehicle?.vehicleMake}{" "}
                                {requestDetails?.vehicle?.vehicleModel}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {requestDetails?.vehicle?.vehicleNumber && (
                          <DetailsWrapper>
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "slider_eplan_unit",
                                "ePlan Unit",
                                prismicData
                              )}{" "}
                              #
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.vehicle?.vehicleNumber}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {requestDetails?.vehicle?.clientUnitNumber && (
                          <DetailsWrapper className="pt-0">
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "slider_client_unit",
                                "Client Unit",
                                prismicData
                              )}{" "}
                              #
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.vehicle?.clientUnitNumber}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                      </QuickLinksContainer>
                      {requestDetails?.vehicle?.vin && (
                        <DetailsWrapper>
                          <SubHead color="petrolBlue" size="xs">
                            {getLabel("vin_title", "VIN", prismicData)}
                          </SubHead>
                          <div>
                            <SubHeadText color="petrolBlue">
                              {requestDetails?.vehicle?.vin}
                            </SubHeadText>
                          </div>
                        </DetailsWrapper>
                      )}
                      <QuickLinksContainer>
                        {requestDetails?.driver?.firstName && (
                          <DetailsWrapper>
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "slider_driver_name",
                                "Driver Name",
                                prismicData
                              )}{" "}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.driver?.firstName}{" "}
                                {requestDetails?.driver?.lastName}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {requestDetails?.driver?.driverId && (
                          <DetailsWrapper className="pt-0">
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "slider_driver_id",
                                "Driver ID",
                                prismicData
                              )}{" "}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.driver?.driverId}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {requestDetails?.driver?.classCodeDescription && (
                          <DetailsWrapper className="pt-0">
                            <SubHead color="petrolBlue" size="xs">
                              {getLabel(
                                "slider_driver_class",
                                "Class",
                                prismicData
                              )}{" "}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">
                                {requestDetails?.driver?.classCodeDescription}
                              </SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                        {(!requestDetails?.driver?.driverId ||
                          !requestDetails?.driver?.firstName ||
                          !requestDetails?.driver?.classCodeDescription) && (
                          <DetailsWrapper className="pt-0">
                            <SubHead color="petrolBlue" size="xs">
                              {""}
                            </SubHead>
                            <div>
                              <SubHeadText color="petrolBlue">{""}</SubHeadText>
                            </div>
                          </DetailsWrapper>
                        )}
                      </QuickLinksContainer>
                      <QuickLinksContainer>
                        {(requestDetails?.relatedRequests?.length > 0 ||
                          requestDetails?.parentId) && (
                          <DetailsWrapper className="pt-0">
                            {(requestDetails?.parentId ||
                              requestDetails?.relatedRequests?.length > 0) && (
                              <Text color="petrolBlue" size="xs">
                                {getLabel(
                                  "linked_request_title",
                                  "Linked Requests",
                                  prismicData
                                )}
                              </Text>
                            )}
                            {requestDetails?.parentId && (
                              <div style={{ cursor: "pointer" }}>
                                <Text
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                  color="lightOrange"
                                  size="xs"
                                  cursor="pointer"
                                  fontWeight="bold"
                                  onClick={() =>
                                    onClickLinkedRequest(
                                      requestDetails?.parentId
                                    )
                                  }
                                >
                                  {getMmDdYY(
                                    requestDetails?.parentCaseCreateDate
                                  )}
                                </Text>
                              </div>
                            )}
                            {requestDetails?.relatedRequests?.length > 0 && (
                              <div style={{ cursor: "pointer" }}>
                                <Text
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                  color="lightOrange"
                                  size="xs"
                                  fontWeight="bold"
                                  onClick={() =>
                                    onClickLinkedRequest(
                                      requestDetails?.relatedRequests[0].id,
                                      "related"
                                    )
                                  }
                                >
                                  {getMmDdYY(
                                    requestDetails?.relatedRequests[0]
                                      .createDate
                                  )}
                                </Text>
                              </div>
                            )}
                          </DetailsWrapper>
                        )}
                        {requestDetails?.statusDescription !== "New" &&
                          requestDetails?.statusDescription !== "Completed" && (
                            <DetailsWrapper>
                              <SubHead color="petrolBlue" size="xs">
                                {getLabel(
                                  "status_details",
                                  "Status Details",
                                  prismicData
                                )}
                              </SubHead>
                              <div>
                                <SubHeadText color="petrolBlue">
                                  {requestDetails?.statusDescription}
                                </SubHeadText>
                              </div>
                            </DetailsWrapper>
                          )}
                        <DetailsWrapper className="pt-0">
                          <SubHead color="petrolBlue" size="xs">
                            {""}
                          </SubHead>
                          <div>
                            <SubHeadText color="petrolBlue">{""}</SubHeadText>
                          </div>
                        </DetailsWrapper>
                      </QuickLinksContainer>
                      {requestDetails &&
                        requestDetails?.status !== "Sending" && (
                          <GridItem>
                            <UserInfo>
                              <UserChatIcon recordType="FROM_CLIENT" />
                              <UserName
                                color="petrolBlue"
                                size="xs"
                                fontWeight="bold"
                              >
                                {requestDetails?.createUserName}
                              </UserName>
                            </UserInfo>
                            <SubText
                              dangerouslySetInnerHTML={{
                                __html: requestDetails?.description,
                              }}
                              color="petrolBlue"
                            ></SubText>
                            <SubInfo color="petrolBlue">
                              {getLabel(
                                "slider_submitted",
                                "SUBMITTED",
                                prismicData
                              )}{" "}
                              {getMMDDYYYYWithTimeInSeconds(
                                requestDetails?.createDate
                              )}
                            </SubInfo>

                            {/* {requestDetails?.status !== "New" ||
                     requestDetails?.status == "In Progress" ? (
                       <>
                         {requestDetails?.attachment?.records.length > 0 && (
                           <FileSubHead
                             color="petrolBlue"
                             size="s"
                             fontWeight="bold"
                           >
                             {getLabel("slider_files", "Files", prismicData)}
                             <span style={{ fontWeight: "200" }}>
                               ({requestDetails?.attachment?.records.length})
                             </span>
                           </FileSubHead>
                         )}

                         <LineSeparator />
                       </>
                     ) : null} */}

                            {uploadedData?.data
                              ?.filter((i) => i.id === requestDetails.id)[0]
                              ?.attachments?.map((item) => {
                                return (
                                  <FlexWrapper>
                                    <FileSubHeadText color="petrolBlue">
                                      <FlexWrapper>
                                        <FileIcon /> {item.fileName}{" "}
                                      </FlexWrapper>
                                      {item.length && (
                                        <FileSize>
                                          {bytesToSize(item.length)}
                                        </FileSize>
                                      )}
                                    </FileSubHeadText>
                                    {attachmentLoad.item == item.id ? (
                                      <Loading type="downloadCSV" />
                                    ) : (
                                      <>
                                        {!extensions.includes(
                                          item.fileExtension
                                        ) && (
                                          <RemoveRedEyeIcon
                                            style={{
                                              marginRight: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => filePreview(item)}
                                          />
                                        )}
                                        <CustomDownloadIcon
                                          onClick={() =>
                                            fileDownload(item.id, item.fileName)
                                          }
                                        />
                                      </>
                                    )}
                                  </FlexWrapper>
                                );
                              })}
                          </GridItem>
                        )}
                      {requestDetails && requestDetails.status === "Sending" ? (
                        <StatusInfo>
                          {getLabel(
                            "sending_status_info_text",
                            "Once your request is received, you'll be able to add comments or attachments. if you need to make changes now, please cancel it and create a new request with the additional details.",
                            prismicData
                          )}
                        </StatusInfo>
                      ) : null}{" "}
                      {(requestDetails?.status == "Completed" ||
                        requestDetails?.status ==
                          "Cancelled - No Longer Needed" ||
                        requestDetails?.status == "Cancelled - Duplicate" ||
                        requestDetails?.status == "Cancelled") &&
                        comments?.length > 0 && (
                          <CustomCommentsButton
                            onClick={() => setViewComment(!viewComment)}
                          >
                            <Wrapper>
                              <Image src={viewComment ? downArrow : upArrow} />
                              {getLabel(
                                "view_conversation_history",
                                "VIEW CONVERSATION",
                                prismicData
                              )}{" "}
                            </Wrapper>
                          </CustomCommentsButton>
                        )}
                      {viewComment && comments?.length > 0 && (
                        <CommentsWrapper>
                          {comments?.length > 0 && (
                            <Comments id="scrollableDiv">
                              <InfiniteScroll
                                id=""
                                dataLength={comments?.length}
                                style={{
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                }}
                                inverse={true}
                                scrollableTarget="scrollableDiv"
                              >
                                {requestDetails?.status ==
                                  "Cancelled - No Longer Needed" ||
                                requestDetails?.status ==
                                  "Cancelled - Duplicate" ? (
                                  <UserChatInfo>
                                    <UserInfo>
                                      <UserChatIcon recordType="FROM_CLIENT" />
                                      <UserName
                                        color="petrolBlue"
                                        size="xs"
                                        fontWeight="bold"
                                      >
                                        {requestDetails?.cancelUserName}
                                      </UserName>
                                    </UserInfo>
                                    <SubTextCancel>
                                      {requestDetails?.status}
                                    </SubTextCancel>
                                    <SubInfo color="petrolBlue">
                                      {getMMDDYYYYWithTimeInSeconds(
                                        requestDetails?.closedDate
                                      )}
                                    </SubInfo>

                                    <LineSeparator />
                                  </UserChatInfo>
                                ) : (
                                  ""
                                )}

                                {comments?.map((item) => {
                                  return (
                                    <UserChatInfo>
                                      <UserInfo>
                                        <UserChatIcon
                                          recordType={item?.recordType}
                                        />
                                        <UserName
                                          color="petrolBlue"
                                          size="xs"
                                          fontWeight="bold"
                                        >
                                          {item?.recordType == "TO_CLIENT"
                                            ? item?.updateUserName
                                                .split(" ")
                                                .slice(0, -1)
                                                .join(" ") +
                                              " " +
                                              item?.updateUserName
                                                .split(" ")
                                                .slice(-1)
                                                .join(" ")
                                                .split(" ")
                                                .map((n) => n[0]) +
                                              "."
                                            : item?.createUserName}{" "}
                                          {item?.recordType == "TO_CLIENT" &&
                                            "| " +
                                              getLabel(
                                                "comment_lpusa_title",
                                                "LPUSA",
                                                prismicData
                                              )}
                                        </UserName>
                                      </UserInfo>
                                      <SubText
                                        dangerouslySetInnerHTML={{
                                          __html: item?.comment,
                                        }}
                                        color="petrolBlue"
                                      ></SubText>
                                      <SubInfo color="petrolBlue">
                                        SENT{" "}
                                        {getMMDDYYYYWithTimeInSeconds(
                                          item?.createDate
                                        )}
                                      </SubInfo>
                                      {item.attachments?.length > 0 && (
                                        <FileSubHead
                                          color="petrolBlue"
                                          size="s"
                                          fontWeight="bold"
                                        >
                                          {getLabel(
                                            "slider_files",
                                            "Files",
                                            prismicData
                                          )}
                                          <span style={{ fontWeight: "200" }}>
                                            ({item.attachments?.length})
                                          </span>
                                        </FileSubHead>
                                      )}

                                      <LineSeparator />
                                      {uploadedData.data &&
                                        uploadedData.data.map(
                                          (e) =>
                                            item.id == e.id &&
                                            e.attachments.map((item) => (
                                              <FlexWrapper>
                                                <FileSubHeadText color="petrolBlue">
                                                  <FlexWrapper>
                                                    <FileIcon /> {item.fileName}{" "}
                                                  </FlexWrapper>
                                                  {item.length && (
                                                    <FileSize>
                                                      {bytesToSize(item.length)}
                                                    </FileSize>
                                                  )}
                                                </FileSubHeadText>

                                                {attachmentLoad.item ==
                                                item.id ? (
                                                  <Loading type="downloadCSV" />
                                                ) : (
                                                  <>
                                                    {!extensions.includes(
                                                      item.fileExtension
                                                    ) && (
                                                      <RemoveRedEyeIcon
                                                        style={{
                                                          marginRight: "5px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          filePreview(item)
                                                        }
                                                      />
                                                    )}

                                                    <CustomDownloadIcon
                                                      onClick={() =>
                                                        fileDownload(
                                                          item.id,
                                                          item.fileName
                                                        )
                                                      }
                                                    />
                                                  </>
                                                )}
                                              </FlexWrapper>
                                            ))
                                        )}
                                    </UserChatInfo>
                                  );
                                })}
                              </InfiniteScroll>
                            </Comments>
                          )}
                        </CommentsWrapper>
                      )}
                      {(requestDetails?.status == "New" ||
                        requestDetails?.status == "In Progress" ||
                        requestDetails?.status == "In Review" ||
                        requestDetails?.statusDescription ==
                          "Pending - Action Required by Customer") &&
                      requestDetails?.requestType != "ep" &&
                      userData?.role != "CA" &&
                      userData?.role != "CS" &&
                      userData?.role != "FS" &&
                      userData?.role != "LS" &&
                      sessionStorage.getItem("v") != "true" ? (
                        <>
                          <GridItem>
                            <Comment
                              theme={theme}
                              onSubmit={onSubmit}
                              showAttachment={showAttachment}
                              setComment={commentData}
                              error={showError}
                            />
                          </GridItem>
                          <GridItem>
                            {attachmentClicked && (
                              <FileUpload
                                onFileUpload={onFileUpload}
                                fileUploadInProgress={fileUploadInProgress}
                                showPopupWarning={props.showPopupWarning}
                                fileDeletionInProgress={fileDeletionInProgress}
                              />
                            )}
                          </GridItem>

                          {/* {canCancelRequest && requestType != "ep" ? (
                     userData?.userId === requestDetails?.userId ||
                     userData?.role === "FA" ? (
                       <CancelRequestBtn
                         onClick={() => setCancelRequest(true)}
                       >
                         {getLabel(
                           "cancel_request_button_title",
                           "CANCEL REQUEST",
                           prismicData
                         )}
                       </CancelRequestBtn>
                     ) : null
                   ) : null} */}
                        </>
                      ) : null}
                      {requestDetails?.status == "Completed" && (
                        <RequestCompleted
                          requestId={requestId}
                          canCreateNewRequest={
                            (requestDetails?.relatedRequests?.length === 0 ||
                              requestDetails?.relatedRequests?.length ===
                                undefined) &&
                            dayOfCreation &&
                            requestDetails?.requestType != "ep" &&
                            userData?.role != "CA" &&
                            userData?.role != "CS" &&
                            userData?.role != "FS" &&
                            userData?.role != "LS" &&
                            sessionStorage.getItem("v") != "true"
                          }
                        />
                      )}
                      {requestDetails?.resolutionToClient && (
                        <>
                          <HeadBoldText>
                            {" "}
                            {getLabel(
                              "client_resolution_title",
                              "Resolution",
                              prismicData
                            )}
                            : {requestDetails?.resolutionToClient}
                          </HeadBoldText>
                        </>
                      )}
                      {(requestDetails?.status == "New" ||
                        requestDetails?.status == "In Progress" ||
                        requestDetails?.status == "In Review" ||
                        requestDetails?.status == "Sending" ||
                        requestDetails?.statusDescription ==
                          "Pending - Action Required by Customer") &&
                      requestDetails?.requestType != "ep" &&
                      userData?.role != "CA" &&
                      userData?.role != "CS" &&
                      userData?.role != "FS" &&
                      userData?.role != "LS" &&
                      sessionStorage.getItem("v") != "true" ? (
                        <ButtonWrapper>
                          {requestDetails?.status !== "In Review" &&
                          canCancelRequest &&
                          requestDetails?.requestType != "ep" ? (
                            ((requestDetails?.subType ===
                              "Book a transportation move" ||
                              requestDetails?.subType ===
                                "Request a transportation quote") &&
                              (clientSettingValue === "T" ||
                                clientSettingValue === "B") &&
                              userData?.role === "FM") ||
                            userData?.userId === requestDetails?.userId ||
                            userData?.role === "FA" ? (
                              <CancelButton
                                variant="secondary"
                                size="s"
                                onClick={cancelRequestPopup}
                              >
                                <u>
                                  {" "}
                                  {getLabel(
                                    "cancel_request_button_title",
                                    "CANCEL REQUEST",
                                    prismicData
                                  )}
                                </u>
                              </CancelButton>
                            ) : null
                          ) : null}

                          {requestDetails?.status !== "Sending" && (
                            <ContinueButton
                              size="s"
                              disabled={!canEditRequest}
                              onClick={() => {
                                if (canEditRequest) {
                                  let hasText = commentValue.replace(
                                    /^\s+|\s+$/gm,
                                    ""
                                  ).length;
                                  if (hasText >= 5) return onSubmit();
                                  else setShowError(true);
                                }
                              }}
                            >
                              {getLabel(
                                "send_button_title",
                                "Send",
                                prismicData
                              )}
                            </ContinueButton>
                          )}
                        </ButtonWrapper>
                      ) : null}
                    </Grid>
                  </div>
                )
              )}
              <NavigateConfirmation
                anchorEl={anchorElReq}
                open={anchorElReq}
                handleClose={handleReqClose}
                handleHideSidePanel={hideSidePanel}
                isLinkedReq={false}
                setCancelRequest={setCancelRequest}
                cancelReq={CancelRequest}
                CancelRequestStatus={CancelRequestStatus}
                setCancelStatus={setCancelStatus}
                CancelStatus={CancelStatus}
                cancelRequestID={cancelRequestID}
                isCommentsLoading={isCommentsLoading}
                status={status}
                requestDetails={requestDetails?.subType}
                requestStatus={requestDetails?.status}
              />
              <ShareRequest
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
              />
            </>
            {previewAttachments && (
              <PreviewModal
                open={previewModal}
                previewAttachments={previewAttachments}
              />
            )}
          </>
        )}
      </DrawerInsideContainer>
    </>
  );
};
const DrawerInsideContainer = styled.div`
  margin: ${Spacing.xxl};
`;
const ContinueButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  font-size: 12px;
  margin-top: ${Spacing.m};
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${Colors.gallery};
  margin-top: ${Spacing.m};
  margin-left: -${Spacing.m};
  margin-right: -${Spacing.m};
`;
const SubHeadRow = styled(Text)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StatusInfo = styled(Text)`
  margin-left: ${Spacing.xs};
  color: red;
  font-size: 12px;
  font-family: LeasePlan;
  margin-right: ${Spacing.xs};
`;
const CustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
`;
const CommentsWrapper = styled.div`
  height: 100%;
`;

const CancelRequestBtn = styled.p`
  border-radius: ${ConvertSize(4)};
  background: #f6f8fb;
  color: #044a5d;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  padding: ${ConvertSize(20)} ${ConvertSize(15)};
  cursor: pointer;
`;

const CustomCommentsButton = styled(LPbutton)`
  text-transform: none;
  border-radius: ${Spacing.xxxs};
  font-size: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${ConvertSize(34)};
  padding-top: ${Spacing.xs};
  padding-bottom: ${Spacing.xs};
  background: ${Colors.petrolBlue};
  margin-bottom: ${Spacing.xxs};
  margin-top: ${Spacing.xxs};
`;
const Image = styled.img`
  height: ${ConvertSize(30)};
  width: ${ConvertSize(30)};
  margin-right: ${Spacing.xxs};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${ConvertSize(14)};
  margin-left: -${ConvertSize(24)};
`;

const SubHeadText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  font-size: ${ConvertSize(12)};
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: ${ConvertSize(-5)};
`;

const HeadBoldText = styled(Text)`
  margin-top: ${Spacing.xs};
  font-size: ${ConvertSize(12)};
  display: flex;
  align-items: center;
  color: #004a5d;
  font-weight: bold;
`;

const SubText = styled.div`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.xxs};
  display: block;
  font-size: ${ConvertSize(10)};
  line-height: ${Spacing.m};
  color: #004a5d;
`;
const SubTextCancel = styled.div`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.xxs};
  display: block;
  font-size: ${ConvertSize(12)};
  line-height: ${Spacing.m};
  color: black;
`;

const UserName = styled(Text)`
  margin-bottom: 0px;
  margin-left: ${Spacing.xxxs};
  font-size: ${ConvertSize(11)};
  color: #004a5d;
`;
const SubHead = styled(Text)`
  margin-bottom: 0px;
`;
const SubInfo = styled.div`
  margin-top: ${Spacing.s};
  color: #6e6e6e;
  font-size: ${ConvertSize(10)};
  width: 100%;
`;
const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserChatInfo = styled.div`
  background: #f7f8fb;
  border-radius: ${Spacing.xxxs};
  padding: ${Spacing.m} ${ConvertSize(14)};
  margin-left: ${Spacing.xxs};
  margin-bottom: ${Spacing.xxs};
`;

const CancelButton = styled.button`
  width: ${ConvertSize(200)};
  align-self: flex-end;
  font-size: 12px;
  border: none;
  color: #f06400;
  background: transparent;
  padding: 12px 0px;
  font-weight: 600;
  cursor: pointer;
`;

const UserInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const FileIcon = styled(InsertDriveFileOutlinedIcon)`
  width: ${ConvertSize(12)} !important;
  height: ${ConvertSize(12)} !important;
  color: #2d2d2d;
  margin-right: 10px;
`;
const CustomDownloadIcon = styled(DownloadIcon)`
  width: ${ConvertSize(18)} !important;
  height: ${ConvertSize(18)} !important;
  color: white;
  margin-right: ${ConvertSize(10)};
  height: 25px;
  width: 25px;
  background: #fa7913;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
`;

const FileSubHeadText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  font-size: ${ConvertSize(12)};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
const FileSize = styled.span`
  margin-right: ${Spacing.s};
`;
const LineSeparator = styled.hr`
  height: ${ConvertSize(1)};
  background: #eeeeee;
  border: none;
  margin-top: ${Spacing.xxs};
  margin-bottom: ${Spacing.s};
`;
const FileSubHead = styled(Text)`
  margin-top: ${ConvertSize(10)};
  display: block;
  font-size: ${ConvertSize(11)};
`;
const DisplayFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
const CompleteRequestContent = styled.div`
  width: 100%;
  border: 1px solid #eeeeee;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 4px;
  margin-top: 4px;
`;
const UserIcon = styled(AccountCircleIcon)`
  width: ${ConvertSize(20)} !important;
  height: ${ConvertSize(20)} !important;
  color: grey !important;
`;
const UserChatIcon = styled(AccountCircleIcon)`
  width: ${ConvertSize(16)} !important;
  height: ${ConvertSize(16)} !important;
  ${({ recordType }) =>
    recordType == "FROM_CLIENT"
      ? `
  color: #000000 !important;
`
      : `color: #fa7913 !important;`}
`;

const Comments = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  height: 100%;
  &::-webkit-scrollbar {
    display: block;
  }
`;
const QuickLinksContainer = styled.div`
  flex-direction: row;
  justifycontent: "space-between", @media (min-width: 768px) {
    border-right: ${(props) =>
      (props.index == 0 || props.index == 1) &&
      `1px solid ${Colors.cancelled}`};
  }
  display: inline-block;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  flex: 1;
`;
const DetailsWrapper = styled.div`
  margin-left: ${Spacing.s};
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  height: ${Spacing.xl};
  margin-left: -${Spacing.l};
  margin-right: -${Spacing.xxl};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -${Spacing.xl};
`;

const CloseIconButton = styled(CloseIcon)`
  cursor: pointer;
  color: ${Colors.midOrange};
  font-size: ${ConvertSize(25)};
  margin-right: ${Spacing.xs};
  margin-top: ${Spacing.s};
`;

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(NewRequest);
