import styled from "styled-components";
import { Text } from "@leaseplan/ui";
import { Colors, Spacing, ConvertSize } from "../../utils/theme";
import LinkIcon from "@mui/icons-material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  showSidePanel,
  hideSidePanel,
} from "../../store/commonStore/commonActionCreators";
import { useSelector, useDispatch } from "react-redux";
import { getLabel } from "../../utils/prismicUtils";

export default function LinkedRequest({
  requestType = "",
  incidentNo = "",
  id = "",
}) {
  const dispatch = useDispatch();
  const prismicData = useSelector((state) => state.prismic.prismicData);

  const onClickLinkedRequest = () => {
    dispatch(
      showSidePanel({ type: "Ticket", requestId: id, requestType: "sc" })
    );
  };
  const testString = /Eplan/gi;

  return (
    <LinkedRequestContainer onClick={onClickLinkedRequest}>
      <LinkedRequestLeft>
        <LinkIcon fontSize="small" htmlColor={Colors.petrolBlue} />
        <Text
          color="petrolBlue"
          size="s"
          fontWeight="bold"
          style={{
            marginLeft: Spacing.xxs,
          }}
        >
          {getLabel("linked_request_link_title", "Linked", prismicData)}{" "}
        </Text>
      </LinkedRequestLeft>
      <LinkedRequestRight>
        <Text color="petrolBlue" size="s">
          {requestType && requestType
            ? (
                requestType.charAt(0).toUpperCase() + requestType.slice(1)
              ).replace(testString, "ePlan")
            : null}
        </Text>
        <Text
          style={{
            marginLeft: Spacing.s,
            textDecoration: "underline",
          }}
          color="lightOrange"
          size="s"
          fontWeight="bold"
        >
          {incidentNo}
        </Text>
        <NavigateNextIcon fontSize="large" htmlColor={Colors.petrolBlue} />
      </LinkedRequestRight>
    </LinkedRequestContainer>
  );
}

const LinkedRequestContainer = styled.div`
  width: 100%;
  border-radius: ${Spacing.xxs};
  display: flex;
  align-items: center;
  padding-left: ${Spacing.xl};
  padding-right: ${Spacing.xl};
  border: 2px solid #eeeeee;
  background-color: #f6f8fb;
  padding: ${Spacing.xxxs} ${Spacing.xs};
  cursor: pointer;
`;
const LinkedRequestLeft = styled.div`
  display: flex;
  align-items: center;
`;
const LinkedRequestRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
`;
