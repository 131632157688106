import {
  SHOW_TOASTER,
  HIDE_TOASTER,
  ERROR,
  WARNING,
  INFO,
  ACKNOWLEDGEMENT,
  ERROR_ICON,
  ERROR_TITLE,
  WARNING_ICON,
  INFO_ICON,
  ACKNOWLEDGEMENT_ICON,
  ERROR_COLOR,
  ACKNOWLEDGEMENT_COLOR,
  INFO_COLOR,
  WARNING_COLOR,
  WARNING_TITLE,
  INFO_TITLE,
  ACKNOWLEDGEMENT_TITLE,
} from "./toasterConstants";

const initialState = [];

let message = {
  icon: null,
  type: null,
  title: null,
  message: null,
  color: null,
};

const ToasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOASTER:
      if (action.payload.type == ERROR) {
        message.icon = ERROR_ICON;
        message.type = ERROR;
        message.color = ERROR_COLOR;
        message.title = action?.payload?.title
          ? action?.payload?.title
          : ERROR_TITLE;
      } else if (action.payload.type == WARNING) {
        message.icon = WARNING_ICON;
        message.type = WARNING;
        message.color = WARNING_COLOR;
        message.title = action?.payload?.title
          ? action?.payload?.title
          : WARNING_TITLE;
      } else if (action.payload.type == INFO) {
        message.icon = INFO_ICON;
        message.type = INFO;
        message.color = INFO_COLOR;
        message.title = action?.payload?.title
          ? action?.payload?.title
          : INFO_TITLE;
      } else if (action.payload.type == ACKNOWLEDGEMENT) {
        message.icon = ACKNOWLEDGEMENT_ICON;
        message.type = ACKNOWLEDGEMENT;
        message.color = ACKNOWLEDGEMENT_COLOR;
        message.title = action?.payload?.title
          ? action?.payload?.title
          : ACKNOWLEDGEMENT_TITLE;
      }
      message.message = action?.payload?.message;
      return [...state, message];
    case HIDE_TOASTER:
      state.splice(
        state.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(action.payload)
        ),
        1
      );
      return [...state];
    default:
      return state;
  }
};

export default ToasterReducer;
