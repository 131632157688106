export const SHOW_SIDE_PANEL = "SHOW_NEWREQUEST_DRAWER";
export const HIDE_SIDE_PANEL = "HIDE_NEWREQUEST_DRAWER";
export const SET_LEGACY_COMMAND = "SET_LEGACY_COMMAND";
export const REFRESH_TABLE_DATA = "REFRESH_MAINTABLE_DATA";
export const SHOW_TUTORIAL = "SHOW_TUTORIAL";
export const COUNT_REFRESH = "COUNT_REFRESH";
export const APPLY_FILTERS = "APPLY_FILTERS";

export const showSidePanel = (panelType) => {
  return {
    type: SHOW_SIDE_PANEL,
    panelType: panelType.type,
    requestId: panelType?.requestId,
    quickLinktype: panelType?.quickLinktype,
    status: panelType?.status,
    requestType: panelType?.requestType,
    isRead: panelType?.isRead,
    loadingTable: panelType?.loading,
  };
};

export const hideSidePanel = () => {
  return {
    type: HIDE_SIDE_PANEL,
  };
};

export const setLegacyCommand = (data) => {
  return {
    type: SET_LEGACY_COMMAND,
    legacyCommand:data.legacyCommand
  };
};

export const refreshTableData = (data) => {
  return {
    type: REFRESH_TABLE_DATA,
    refresh: data?.type,
    isRead: data?.isRead,
    loadingTable: data?.loading,
    tabType: data?.tabType,
  };
};

export const showTutorialData = (data) => {
  return {
    type: SHOW_TUTORIAL,
    show: data?.type,
  };
};

export const countRefresh = (data) => {
  return {
    type: COUNT_REFRESH,
    count: data,
  };
};
export const applyFilters = (data) => {
  return {
    type: APPLY_FILTERS,
    filters: data,
  };
};
