import * as RequestTypeSubtypeActions from "./requestTypeSubtypeActions";
import * as RequestTypeSubtypeApi from "./requestTypeSubtypeApi";
export const getRequestTypeSubtypeDetails = () => async (dispatch) => {
  dispatch(RequestTypeSubtypeActions.getRequestDropdownRequest());
  try {
    const requestDropdown =
      await RequestTypeSubtypeApi.getRequestDropdownDetails();
    const quicklinks = await RequestTypeSubtypeApi.getQuicklinksDetails();
    const res = {
      requestDropdown: requestDropdown.data,
      quicklinks: quicklinks.data,
    };

    dispatch(RequestTypeSubtypeActions.getRequestDropdownSuccess(res));
  } catch (e) {
    dispatch(RequestTypeSubtypeActions.getRequestDropdownFail());
  }
};
