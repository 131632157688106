import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import RequestHeader from "./RequestHeader";
import TableView from "./TableView";
import styled from "styled-components";
import { useDispatch, useSelector, connect } from "react-redux";
import { refreshTableData } from "../../store/commonStore/commonActionCreators";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import NoResults from "./NoResults";
import Pagination from "./Pagination";
import {
  getRequests,
  getRequestsUnread,
} from "../../store/request/requestThunk";
import moment from "moment";
import "moment-timezone";
import Loading from "../Loading";
import { getLabel } from "../../utils/prismicUtils";
import { isEmpty } from "lodash";
import Timestamp from "react-timestamp";
import { compareDates } from "../../utils/stringFunctions";
import { hideSidePanel } from "../../store/commonStore/commonActionCreators";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import eventLog from "../../config/firebase";
import { CLOSE_TAB_ACTIVE, OPEN_TAB_ACTIVE } from "../../helpers/logEvents";

const RequestsCard = (props) => {
  const { loading, requests, recordsInPage, totalRecords } = useSelector(
    (state) => state.request
  );

  const dispatch = useDispatch();
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const { refreshData, isRead, loadingTable, filters, isSidePanelShown } =
    useSelector((state) => state.common);
  const [searchParams, setSearchParams] = useSearchParams();
  const testing = useSelector((state) => state.common);
  const category = searchParams.get("category");
  const categoryArray = category?.split(";").filter((i) => !isEmpty(i));
  const subCategory = searchParams.get("subCategory");
  const subcategoryArray = subCategory?.split(";").filter((i) => !isEmpty(i));
  const pageNumber = parseInt(searchParams.get("pageNumber") || 1);
  const pageSize = parseInt(searchParams.get("pageSize") || 20);
  const orderField = searchParams.get("orderField") || "createDate";
  const order = searchParams.get("order") || "desc";
  const driverName = searchParams.get("driverName");
  const subject = searchParams.get("subject");
  const status = searchParams.get("status");
  const statusArray = status?.split(",").filter((i) => !isEmpty(i));
  const vehicleNumber = searchParams.get("vehicleNumber");
  const clientUnitNumber = searchParams.get("clientUnitNumber");
  const vin = searchParams.get("vin");
  const createUserName = searchParams.get("createUserName");
  const typeUnread = searchParams.get("typeUnread");
  const search = searchParams.get("search");
  const requestType = searchParams.get("requestType") || "all";
  const readStatus = searchParams.get("readStatus");
  const createDateStart =
    searchParams.get("createDateStart") &&
    decodeURIComponent(searchParams.get("createDateStart"));
  const createDateEnd =
    searchParams.get("createDateEnd") &&
    decodeURIComponent(searchParams.get("createDateEnd"));
  const caseNumber = searchParams.get("caseNumber");
  const repliedDateStart =
    searchParams.get("repliedDateStart") &&
    decodeURIComponent(searchParams.get("repliedDateStart"));
  const repliedDateEnd =
    searchParams.get("repliedDateEnd") &&
    decodeURIComponent(searchParams.get("repliedDateEnd"));
  const [tabValue, setTabValue] = useState("1");

  const tabSwitch = searchParams.get("tab");

  useEffect(() => {
    filters && updateSearchQuery(filters);
  }, [filters]);

  useEffect(() => {
    if (pageSize > 200 || pageSize <= 0) {
      alert("Page size should be between 1 - 200");
      updateSearchQuery({
        pageSize: 20,
      });
    } else {
      dispatch(
        getRequests({
          pageNumber,
          pageSize: pageSize <= 200 ? pageSize : 20,
          ...(orderField &&
            order && {
              orderField,
              order,
            }),
          ignorePagination: false,
          ...(driverName && { driverName }),
          ...(subject && { subject }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(vin && { vin }),
          ...(requestType && { requestType }),
          ...(readStatus && { readStatus }),
          ...(createUserName && { createUserName }),
          ...(search && { search }),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
          ...(repliedDateStart && { repliedDateStart }),
          ...(repliedDateEnd && { repliedDateEnd }),
          ...(categoryArray?.length > 0 && {
            category: categoryArray,
          }),
          ...(subcategoryArray?.length > 0 && {
            subCategory: subcategoryArray,
          }),
        })
      );
    }
    // loadingTable && setValue("1");
    // tabSwitch && setTabValue(tabSwitch);
  }, [
    pageNumber,
    pageSize,
    orderField,
    order,
    driverName,
    subject,
    status,
    vehicleNumber,
    clientUnitNumber,
    vin,
    createUserName,
    search,
    createDateStart,
    createDateEnd,
    caseNumber,
    refreshData,
    repliedDateStart,
    repliedDateEnd,
    tabValue,
    typeUnread,
    category,
    subCategory,
    requestType,
    readStatus,
  ]);

  useEffect(() => {
    setSearchParams({
      pageNumber,
      pageSize: pageSize <= 200 ? pageSize : 20,
      ...(orderField &&
        order && {
          orderField,
          order,
        }),
      ...(driverName && { driverName }),
      ...(subject && { subject }),
      ...(statusArray?.length > 0 && {
        status: statusArray.toString(),
      }),
      ...(categoryArray?.length > 0 && {
        category: categoryArray.join(";"),
      }),
      ...(subcategoryArray?.length > 0 && {
        subCategory: subcategoryArray.join(";"),
      }),
      ...(vehicleNumber && { vehicleNumber }),
      ...(clientUnitNumber && { clientUnitNumber }),
      ...(vin && { vin }),
      ...(requestType && { requestType }),
      ...(readStatus && { readStatus }),
      ...(createUserName && { createUserName }),
      ...(search && { search }),
      ...(createDateStart && { createDateStart }),
      ...(createDateEnd && { createDateEnd }),
      ...(caseNumber && { caseNumber }),
      ...(repliedDateStart && { repliedDateStart }),
      ...(repliedDateEnd && { repliedDateEnd }),
    });
  }, []);

  function hideSidePanel(e) {
    props.hideSidePanel();
  }

  const updateSearchQuery = (obj) => {
    if (isSidePanelShown) {
      hideSidePanel();
    }
    setSearchParams({
      pageNumber,
      pageSize: pageSize <= 200 ? pageSize : 20,
      ...(orderField &&
        order && {
          orderField,
          order,
        }),
      ...(driverName && { driverName }),
      ...(subject && { subject }),
      ...(statusArray?.length > 0 && {
        status: statusArray.toString(),
      }),
      ...(categoryArray?.length > 0 && {
        category: categoryArray.join(";"),
      }),
      ...(subcategoryArray?.length > 0 && {
        subCategory: subcategoryArray.join(";"),
      }),
      ...(vehicleNumber && { vehicleNumber }),
      ...(clientUnitNumber && { clientUnitNumber }),
      ...(vin && { vin }),
      ...(requestType && { requestType }),
      ...(readStatus && { readStatus }),
      ...(createUserName && { createUserName }),
      ...(search && { search }),
      ...(createDateStart && { createDateStart }),
      ...(createDateEnd && { createDateEnd }),
      ...(caseNumber && { caseNumber }),
      ...(repliedDateStart && { repliedDateStart }),
      ...(repliedDateEnd && { repliedDateEnd }),
      ...obj,
    });
  };

  const removeSearchQuery = (key) => {
    const params = {
      pageNumber,
      pageSize: pageSize <= 200 ? pageSize : 20,
      ...(orderField &&
        order && {
          orderField,
          order,
        }),
      ...(driverName && { driverName }),
      ...(subject && { subject }),
      ...(statusArray?.length > 0 && {
        status: statusArray.toString(),
      }),
      ...(categoryArray?.length > 0 && {
        category: categoryArray.join(";"),
      }),
      ...(subcategoryArray?.length > 0 && {
        subCategory: subcategoryArray.join(";"),
      }),
      ...(vehicleNumber && { vehicleNumber }),
      ...(clientUnitNumber && { clientUnitNumber }),
      ...(vin && { vin }),
      ...(requestType && { requestType }),
      ...(readStatus && { readStatus }),
      ...(createUserName && { createUserName }),
      ...(search && { search }),
      ...(createDateStart && { createDateStart }),
      ...(createDateEnd && { createDateEnd }),
      ...(caseNumber && { caseNumber }),
      ...(repliedDateStart && { repliedDateStart }),
      ...(repliedDateEnd && { repliedDateEnd }),
    };
    if (typeof key === "string") {
      delete params[key];
    } else if (Array.isArray(key)) {
      key.forEach((item) => {
        delete params[item];
      });
    }
    setSearchParams(params);
  };

  // useEffect(() => {
  //   init();
  // }, []);

  // const init = () => {
  //   dispatch(
  //     getRequests({
  //       pageNumber: 1,
  //       pageSize: 20,
  //     })
  //   );
  // };

  const tableRowHeaders = [
    getLabel("table_column_request_type", "I Need Help With", prismicData),
    getLabel("table_column_request_subtype", "Reason for Request", prismicData),
    getLabel("table_column_status_title", "Status", prismicData),
    getLabel("table_column_incident_number_title", "Reference ID", prismicData),

    getLabel("table_column_submitted_title", "Submitted", prismicData),
    getLabel("table_column_last_update_title", "Last Update", prismicData),
    getLabel("table_column_request_by_title", "Requested By", prismicData),
    getLabel("table_column_eplan_unit_title", "ePlan Unit", prismicData),
    getLabel("table_column_client_unit_title", "Client Unit", prismicData),
    getLabel("table_column_vin_number_title", "VIN", prismicData),
    getLabel("table_column_driver_title", "Driver", prismicData),
  ];

  const formatEDTTime = (time) => {
    if (time) {
      var lt = time?.split(/[- :]/);
      var formattedTime = new Date(
        Date.UTC(lt[0], lt[1] - 1, lt[2], lt[3], lt[4], lt[5])
      );
    }
    return moment(formattedTime).tz("America/New_York").format(`HH:mm z`);
  };
  const convertToUnderstandingTableCode = () => {
    let today = moment().format("MM/DD/YYYY");
    return requests?.map((item) => ({
      item: {
        [tableRowHeaders[0]]: item?.type,
        [tableRowHeaders[1]]: item?.subType,
        [tableRowHeaders[2]]: item?.status,
        [tableRowHeaders[3]]:
          item?.caseNumber.charAt(0) != "Q" && item?.caseNumber,
        [tableRowHeaders[4]]: moment(item?.createDate).format("MM/DD/YYYY"),
        [tableRowHeaders[5]]:
          item.repliedDate &&
          (moment(item?.repliedDate).format("MM/DD/YYYY") === today
            ? formatEDTTime(item?.repliedDate)
            : moment(item?.repliedDate)
                .tz("America/New_York")
                .format("MM/DD/YYYY")),
        [tableRowHeaders[6]]: item?.createUserName,
        [tableRowHeaders[7]]: item?.vehicle?.vehicleNumber,
        [tableRowHeaders[8]]: item?.vehicle?.clientUnitNumber,
        [tableRowHeaders[9]]:
          item?.vehicle?.vin && `...${item?.vehicle?.vin?.slice(-8)}`,
        [tableRowHeaders[10]]: `${item?.driver?.firstName || ""}
          ${!isEmpty(item?.driver?.firstName) ? " " : ""}
        ${item?.driver?.lastName || ""}`,
      },
      read: item?.read,
      requestId: item?.id,
      parentId: item?.parentId,
      parentCaseNumber: item?.parentCaseNumber,
      requestType: item?.requestType,
      relatedCaseNumber: item?.relatedCaseNumber,
      relatedCaseId: item?.relatedCaseId,
      requestUid: item?.requestUid,
      statusDescription: item?.statusDescription,
      status: item?.status,
      hasAttachment: item?.hasAttachment,
    }));
  };

  const handleChange = (event, newValue) => {
    let newTabValue = newValue == 4 ? "2" : newValue == 3 ? "1" : newValue;
    setTabValue(newTabValue);
    let pageNum = 1;
    setSearchParams({
      pageNum,
      pageSize: pageSize <= 200 ? pageSize : 20,
      ...(orderField &&
        order && {
          orderField,
          order,
        }),
    });
    dispatch(
      refreshTableData({
        type: !refreshData,
        loading: false,
        tabType: newValue,
      })
    );
    eventLog({
      eventName: newValue === 4 ? CLOSE_TAB_ACTIVE : OPEN_TAB_ACTIVE,
    });
  };

  return (
    <>
      <CardContainer>
        <CustomTabContext sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabValue}>
            <Box>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                indicatorColor="none"
              >
                <Tab
                  label="Open"
                  value={tabSwitch ? "3" : "1"}
                  style={{
                    backgroundColor: "white",
                    color:
                      (tabValue == 1 && !tabSwitch) || tabSwitch == 1
                        ? "#004a5d"
                        : "#336e7d",
                    fontWeight:
                      (tabValue == 1 && !tabSwitch) || tabSwitch == 1
                        ? "800"
                        : "normal",
                    textTransform: "none",
                    fontSize: "16px",
                    fontFamily: "LeasePlan",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.18))",
                    marginRight: "4px",
                  }}
                />
                <Tab
                  label="Closed"
                  value={tabSwitch ? "4" : "2"}
                  style={{
                    backgroundColor: "white",
                    color:
                      (tabValue == 2 && !tabSwitch) || tabSwitch == 2
                        ? "#004a5d"
                        : "#336e7d",
                    fontWeight:
                      (tabValue == 2 && !tabSwitch) || tabSwitch == 2
                        ? "800"
                        : "normal",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding: 0, overflowX: "auto" }}>
              {" "}
              <RequestWrapper>
                <RequestHeader
                  updateSearchQuery={updateSearchQuery}
                  removeSearchQuery={removeSearchQuery}
                  search={search}
                  orderField={orderField}
                  order={order}
                  // updateSearchQuery={updateSearchQuery}
                  // removeSearchQuery={removeSearchQuery}
                  statusArray={statusArray}
                  driverName={driverName}
                  vehicleNumber={vehicleNumber}
                  clientUnitNumber={clientUnitNumber}
                  vin={vin}
                  requestType={requestType}
                  categoryArray={categoryArray}
                  subcategoryArray={subcategoryArray}
                  createUserName={createUserName}
                  createDateStart={createDateStart}
                  createDateEnd={createDateEnd}
                  caseNumber={caseNumber}
                  totalRecords={loading ? 0 : totalRecords}
                  tabValue={tabSwitch ? tabSwitch : tabValue}
                  repliedDateEnd={repliedDateEnd}
                  repliedDateStart={repliedDateStart}
                  readStatus={readStatus}
                />
                <TableView
                  titles={tableRowHeaders}
                  data={convertToUnderstandingTableCode()}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  orderField={orderField}
                  order={order}
                  requestType={requestType}
                  updateSearchQuery={updateSearchQuery}
                  removeSearchQuery={removeSearchQuery}
                  statusArray={statusArray}
                  categoryArray={categoryArray}
                  subcategoryArray={subcategoryArray}
                  driverName={driverName}
                  vehicleNumber={vehicleNumber}
                  clientUnitNumber={clientUnitNumber}
                  vin={vin}
                  createUserName={createUserName}
                  createDateStart={createDateStart}
                  createDateEnd={createDateEnd}
                  caseNumber={caseNumber}
                  repliedDateEnd={repliedDateEnd}
                  repliedDateStart={repliedDateStart}
                  tabValue={tabSwitch ? tabSwitch : tabValue}
                  totalRecords={totalRecords}
                  dataLoading={loading}
                  requests={requests?.length}
                  readStatus={readStatus}
                />
              </RequestWrapper>
            </TabPanel>
            <TabPanel value="2" style={{ padding: 0, overflowX: "auto" }}>
              {" "}
              <RequestWrapper>
                <RequestHeader
                  updateSearchQuery={updateSearchQuery}
                  removeSearchQuery={removeSearchQuery}
                  search={search}
                  orderField={orderField}
                  order={order}
                  requestType={requestType}
                  categoryArray={categoryArray}
                  subcategoryArray={subcategoryArray}
                  // updateSearchQuery={updateSearchQuery}
                  // removeSearchQuery={removeSearchQuery}
                  statusArray={statusArray}
                  driverName={driverName}
                  vehicleNumber={vehicleNumber}
                  clientUnitNumber={clientUnitNumber}
                  vin={vin}
                  createUserName={createUserName}
                  createDateStart={createDateStart}
                  createDateEnd={createDateEnd}
                  caseNumber={caseNumber}
                  totalRecords={loading ? 0 : totalRecords}
                  tabValue={tabSwitch ? tabSwitch : tabValue}
                  repliedDateEnd={repliedDateEnd}
                  repliedDateStart={repliedDateStart}
                />

                <TableView
                  titles={tableRowHeaders}
                  data={convertToUnderstandingTableCode()}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  orderField={orderField}
                  order={order}
                  updateSearchQuery={updateSearchQuery}
                  removeSearchQuery={removeSearchQuery}
                  statusArray={statusArray}
                  requestType={requestType}
                  driverName={driverName}
                  vehicleNumber={vehicleNumber}
                  clientUnitNumber={clientUnitNumber}
                  vin={vin}
                  categoryArray={categoryArray}
                  subcategoryArray={subcategoryArray}
                  createUserName={createUserName}
                  createDateStart={createDateStart}
                  createDateEnd={createDateEnd}
                  caseNumber={caseNumber}
                  repliedDateEnd={repliedDateEnd}
                  repliedDateStart={repliedDateStart}
                  tabValue={tabSwitch ? tabSwitch : tabValue}
                  totalRecords={totalRecords}
                  dataLoading={loading}
                  requests={requests?.length}
                />
              </RequestWrapper>
            </TabPanel>
          </TabContext>
        </CustomTabContext>

        {loading ? (
          loadingTable && <Loading />
        ) : (
          <>{requests?.length == 0 && <NoResults />}</>
        )}
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          orderField={orderField}
          order={order}
          updateSearchQuery={updateSearchQuery}
          removeSearchQuery={removeSearchQuery}
          statusArray={statusArray}
          driverName={driverName}
          vehicleNumber={vehicleNumber}
          clientUnitNumber={clientUnitNumber}
          vin={vin}
          createUserName={createUserName}
          createDateStart={createDateStart}
          createDateEnd={createDateEnd}
          caseNumber={caseNumber}
          repliedDateEnd={repliedDateEnd}
          repliedDateStart={repliedDateStart}
        />
      </CardContainer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(RequestsCard);

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${Spacing.xxl};
  max-width: 100%;

  padding-bottom: 0;
  border-radius: ${Spacing.xxxs};
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 8%);

  min-height: ${ConvertSize(550)};
  @media (min-width: 768px) {
    margin: 0 ${Spacing.xxl} ${Spacing.xxl} ${Spacing.xxl};
  }
`;

const RequestWrapper = styled.div`
  background-color: white;
  padding: 20px;
`;

const CustomTabContext = styled(Box)``;
