//Caching prismic data
export const getCachedPrismicData = async () => {
  try {
    const attemps = await sessionStorage.getItem("@DATA_FROM_CMS");
    return attemps;
  } catch (err) {
    console.log(err);
  }
};

export const setCachedPrismicData = async (value) => {
  try {
    await sessionStorage.setItem("@DATA_FROM_CMS", value);
  } catch (err) {
    console.log(err);
  }
};

export const getLabel = (labelKey, defaultLabel, prismicData) => {
  let label = convertValueToPrismicKey(labelKey);
  if (
    prismicData &&
    Object.keys(prismicData).length > 0 &&
    prismicData[label] &&
    prismicData[label].length > 0
  ) {
    return prismicData[label][0].text;
  }
  return defaultLabel || labelKey;
};

export const convertValueToPrismicKey = (value) => {
  if (value) {
    // remove special character
    let newValue = value.replace(/[^a-zA-Z\d _]/g, "");
    // remove multiple continuous spaces
    newValue = newValue.replace(/  +/g, " ");
    return newValue.replace(/ /g, "_").toLowerCase();
  }
  return value;
};
