import React, { useState, useEffect } from "react";
import { Text, Button, Color, TextInput } from "@leaseplan/ui";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { styled as muistyle } from "@mui/material/styles";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { getLabel } from "../../utils/prismicUtils";

import "./SubCategoryFilter.scss";

const Accordion = muistyle((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = muistyle((props) => (
  <MuiAccordionSummary
    expandIcon={<img src={props.prismicData?.expand_icon?.url} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: " #f6f8fb",
  fontSize: "12px",
  fontWeight: "bold",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = muistyle(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "0px solid rgba(0, 0, 0, .125)",
  paddingLeft: "20px ",
}));

const subCategory = "subcategory_";
const SubCategoryFilter = ({
  anchorEl,
  handleClose,
  open,
  filterData,
  updateSearchQuery,
  removeSearchQuery,
  subcategoryArray,
  prismicData,
  categoryArray,
}) => {
  const [isChecked, setIsChecked] = useState({});
  const [searchText, setSearchText] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [expandedWithoutSearch, setExpandedWithoutSearch] = React.useState("");
  const [isCheckedApplied, setIsCheckedApplied] = useState(false);
  const [requestCategoryId, setRequestCategoryId] = useState([]);

  const onChangeTextInput = (event) => {
    setSearchText(event.target.value);
    setExpanded(true);
  };
  const handleOpen = (panel) => (event, newExpanded) => {
    setExpandedWithoutSearch(newExpanded ? panel : false);
  };

  const onClearSearch = () => {
    setSearchText("");
  };
  const onClickCheckBox = (uniqueId, requestCatId) => () => {
    setExpanded(false);

    let reqCatId = [];

    setIsChecked((prev) => {
      return { ...prev, [uniqueId]: !(prev[uniqueId] || false) };
    });

    setRequestCategoryId([...requestCategoryId, requestCatId]);
  };

  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );

  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );

  useEffect(() => {
    setExpanded(false);
    if (subcategoryArray) {
      subcategoryArray?.length > 0 &&
        setIsChecked(
          subcategoryArray?.reduce((acc, item) => {
            return { ...acc, [item]: true };
          }, {})
        );
    }
  }, [open]);

  useEffect(() => {
    setExpanded(false);

    if (subcategoryArray === undefined || subcategoryArray?.length == 0) {
      setIsChecked({});
      setRequestCategoryId([]);
    }
  }, [subcategoryArray]);
  useEffect(() => {
    setExpanded(false);

    // if (Object.values(isChecked).find((i) => i) == undefined) {
    // removeSearchQuery("subCategory");
    // }
  }, [isChecked]);
  useEffect(() => {
    setExpanded(false);

    if (!isCheckedApplied) {
      setIsChecked({});
    }
  }, [isCheckedApplied]);

  const isAnyOneSelected = Object.values(isChecked).find((i) => i);

  const onApplyFilter = () => {
    const checkedValues = Object.entries(isChecked).filter((i) => i[1]);
    let subcat = [Object.keys(isChecked)];
    let data = requestCategoryId.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });

    let categoryData = [];
    setIsCheckedApplied(true);
    if (subcat[0]?.length > 0) {
      filterData?.categories.map((items, i) => {
        filterData["subcategory_" + items.requestCategoryUid]?.map((item) => {
          data.map((category) => {
            if (items.requestCategoryUid === category) {
              categoryData.push(items.categoryDescription);
            }
          });
          //   subcat[0].map((category) => {
          //     if (
          //       item.categoryDescription === category &&
          //       items.requestCategoryUid === item.requestCategoryId
          //     ) {
          //       categoryData.push(items.categoryDescription);
          //     }
          //   });
          // });
        });
      });
    }

    const query = checkedValues.reduce(
      (acc, item, index) => `${item[0]};${acc}`,
      ""
    );
    if (categoryArray && categoryArray.length > 0) {
      categoryData = [...categoryArray, ...categoryData];
    }
    categoryData = categoryData.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
    const categoryquery = categoryData.join(";");

    updateSearchQuery({
      pageNumber: 1,
      subCategory: query,
      category: categoryquery,
    });
    setExpanded(false);
    setExpandedWithoutSearch(false);

    handleClose();
  };
  const handleCloseView = () => {
    setExpandedWithoutSearch(false);
    if (!isCheckedApplied) {
      setIsChecked({});
    }
    handleClose();
  };
  const onClickClear = () => {
    removeSearchQuery(["subCategory"]);
    removeSearchQuery(["category"]);
    setRequestCategoryId([]);

    subcategoryArray?.length > 0 &&
      setIsChecked(
        subcategoryArray?.reduce((acc, item) => {
          return { ...acc, [item]: false };
        }, {})
      );
    setExpandedWithoutSearch(false);

    handleClose();
  };
  var reg = new RegExp(searchText, "gi");

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleCloseView}
      PaperProps={{
        elevation: 0,
        position: "relative",
        sx: {
          padding: "10px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 70,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <span
        style={{
          marginBottom: "0px",
        }}
      >
        <TextInputWrapper>
          <SearchIcon htmlColor={Colors.petrolBlue} />
          <CustomTextInput
            name="search"
            value={searchText}
            autoComplete="off"
            onChange={(e) => onChangeTextInput(e)}
            placeHolder={getLabel(
              "reason_for_req_place_holder",
              "Search reason for request",
              prismicData
            )}
          />
          <Image
            src={prismicData?.close_icon?.url}
            onClick={() => onClearSearch()}
          />
        </TextInputWrapper>
      </span>
      <SearchWrapper>
        <SearchResults className="customScrollerSearch">
          {filterData?.categories.map((items, i) => {
            return (
              <>
                {filterData["subcategory_" + items.requestCategoryUid]?.find(
                  (item) =>
                    item.categoryDescription
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                      ? true
                      : false
                ) && (
                  <Accordion
                    style={{
                      marginTop: "5px",
                      backgroundColor: Colors.mist,
                      display:
                        clientSettingValue === "T" &&
                        items.categoryDescription !==
                          "My vehicles or drivers" &&
                        "none",
                    }}
                    defaultExpanded={false}
                    disabled={
                      categoryArray && categoryArray.length > 0
                        ? categoryArray &&
                          categoryArray.includes(items.categoryDescription)
                          ? false
                          : true
                        : false
                    }
                    expanded={
                      searchText?.length > 0
                        ? expanded
                        : expandedWithoutSearch === "panel" + i
                    }
                    onChange={handleOpen("panel" + i)}
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                      prismicData={prismicData}
                      // disabled={
                      //   categoryArray && categoryArray.length > 0
                      //     ? categoryArray &&
                      //       categoryArray.includes(items.categoryDescription)
                      //       ? false
                      //       : true
                      //     : false
                      // }
                    >
                      <Text
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                        color="petrolBlue"
                        size="s"
                      >
                        {items.categoryDescription}
                      </Text>
                    </AccordionSummary>
                    {filterData["subcategory_" + items.requestCategoryUid]
                      ?.filter(
                        (item) =>
                          items.categoryDescription !==
                            "Book a transportation move" &&
                          item.categoryDescription
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                      )
                      .map((item) => {
                        return (
                          <AccordionDetails
                            style={{
                              padding: "0px 0px 0px 20px",
                              backgroundColor: Colors.white,
                              display:
                                clientSettingValue === "T" &&
                                (item.categoryDescription ===
                                  "Book a transportation move" ||
                                  item.categoryDescription ===
                                    "Request a transportation quote")
                                  ? "block"
                                  : clientSettingValue === "T" && "none",
                            }}
                          >
                            <FormControlLabel
                              sx={{
                                padding: 1,
                              }}
                              control={
                                <CustomCheckbox
                                  checked={
                                    isChecked[item.categoryDescription] || false
                                  }
                                  onChange={onClickCheckBox(
                                    item.categoryDescription,
                                    item.requestCategoryId
                                  )}
                                  size="medium"
                                  style={{
                                    color: Colors.petrolBlue,
                                  }}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: Colors.petrolBlue,
                                    },
                                  }}
                                />
                              }
                              label={
                                // <Text
                                // 	size="xs"
                                // 	color="petrolBlue"
                                // 	style={{ fontSize: "12px" }}
                                // >
                                // 	{item.categoryDescription}
                                // </Text>
                                <Text
                                  size="xs"
                                  color="petrolBlue"
                                  style={{ fontSize: "12px", marginLeft: 10 }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.categoryDescription.replace(
                                      reg,
                                      function (str) {
                                        return "<b>" + str + "</b>";
                                      }
                                    )}`,
                                  }}
                                ></Text>
                              }
                            />
                          </AccordionDetails>
                        );
                      })}
                  </Accordion>
                )}
              </>
            );
          })}
        </SearchResults>
        <span style={{ float: "right", marginTop: "15px" }}>
          <CustomText
            color="petrolBlue"
            size="xs"
            fontWeight="bold"
            style={{ marginRight: "20px" }}
            onClick={onClickClear}
          >
            {getLabel("clear_all_title", "Clear All", prismicData)}{" "}
          </CustomText>
          <Button
            variant="secondary"
            size="s"
            disabled={!isAnyOneSelected}
            onClick={onApplyFilter}
            style={{
              color: isAnyOneSelected ? Colors.petrolBlue : Colors.lightGrey,
              borderColor: isAnyOneSelected
                ? Colors.petrolBlue
                : Colors.lightGrey,
              maxHeight: "22px",
              minWidth: "80px",
            }}
          >
            {getLabel("apply_filter_title", "Apply", prismicData)}{" "}
          </Button>
        </span>
      </SearchWrapper>
    </Menu>
  );
};

export default SubCategoryFilter;

const CustomCheckbox = styled(Checkbox)`
  color: ${Colors.petrolBlue};
  padding: 5px;
  :hover {
    color: ${Colors.petrolBlue};
    background-color: transparent;
  }
`;
const CustomText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

const TextInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Colors.steel20};
  height: ${ConvertSize(40)};
  font-size: ${ConvertSize(14)};
`;
const CustomTextInput = styled(TextInput)`
  margin: 2px;
  border-color: transparent;
  border-radius: ${Spacing.xxxs};
  height: ${ConvertSize(38)};
  font-size: ${ConvertSize(14)};
  box-shadow: none;
  :focus {
    border-color: transparent;
    box-shadow: none;
  }
  width: ${ConvertSize(280)};
`;

const Image = styled.img`
  height: ${ConvertSize(12)};
  width: ${ConvertSize(11)};
`;

const SearchResults = styled.div`
  margin-bottom: 0;
  padding-bottom: 0;
  max-height: ${ConvertSize(180)};
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchWrapper = styled.div`
  margin-top: ${Spacing.xxxs};
  margin-bottom: ${Spacing.xxs};
  align-items: flex-start;
  justify-content: space-between;
  width: ${ConvertSize(340)};
`;
