import React, { useEffect, useState, useRef } from "react";
import {
  TopNavigation,
  TopNavigationWidgets,
  TopNavigationLoginWidget,
  Text,
  CloseIcon,
} from "@leaseplan/ui";
import { logout } from "../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import user from "../assets/images/user.svg";
import Drawer from "@mui/material/Drawer";
import { Colors, Spacing, ConvertSize } from "../utils/theme";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getLabel } from "../utils/prismicUtils";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import AppBar from "@mui/material/AppBar";
import "../GlobalStyles.js";
import Tutorial from "./Tutorial";
import { refreshTableData } from "../store/commonStore/commonActions";
import eventLog from "../config/firebase";
import { PENDING_REQUEST } from "../helpers/logEvents";

const TopNav = (props) => {
  const dispatch = useDispatch();
  const { tabType, count } = useSelector((state) => state.common);

  const userData = useSelector((state) => state.user.userData);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("pageNumber") || 1;
  const pageSize = searchParams.get("pageSize") || 20;
  const orderField = searchParams.get("orderField");
  const order = searchParams.get("order") || "desc";
  const driverName = searchParams.get("driverName");
  const subject = searchParams.get("subject");
  const status = searchParams.get("status");
  const statusArray = status?.split(",").filter((i) => !isEmpty(i));
  const vehicleNumber = searchParams.get("vehicleNumber");
  const clientUnitNumber = searchParams.get("clientUnitNumber");
  const vin = searchParams.get("vin");
  const requestType = searchParams.get("requestType") || "all";
  const readStatus = searchParams.get("readStatus");
  const createUserName = searchParams.get("createUserName");
  const search = searchParams.get("search");
  const createDateStart =
    searchParams.get("createDateStart") &&
    decodeURIComponent(searchParams.get("createDateStart"));
  const createDateEnd =
    searchParams.get("createDateEnd") &&
    decodeURIComponent(searchParams.get("createDateEnd"));
  const caseNumber = searchParams.get("caseNumber");
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const { showTutorial } = useSelector((state) => state.common);
  const countProp = count ? count : props.count;

  const updateSearchQuery = (obj) => {
    setSearchParams({
      pageNumber,
      pageSize: pageSize <= 200 ? pageSize : 20,
      ...(orderField &&
        order && {
          orderField,
          order,
        }),
      ...(driverName && { driverName }),
      ...(subject && { subject }),
      ...{
        status:
          statusArray?.length > 0
            ? statusArray
            : tabType == "1"
            ? ["New", "In Progress", "Pending", "Sending", "In Review"]
            : ["Completed", "Cancelled"],
      },
      ...(vehicleNumber && { vehicleNumber }),
      ...(clientUnitNumber && { clientUnitNumber }),
      ...(vin && { vin }),
      ...(requestType && { requestType }),
      ...(readStatus && { readStatus }),
      ...(search && { search }),
      ...(createDateStart && { createDateStart }),
      ...(createDateEnd && { createDateEnd }),
      ...(caseNumber && { caseNumber }),
      ...obj,
    });
    eventLog({
      eventName: PENDING_REQUEST,
      additionalParams: {
        status: "viewed",
      },
    });
  };
  const tutorial = useRef();
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [showPendingRequest, setShowPendingRequest] = useState(true);

  // useEffect(() => {
  //   setX(tutorial.current.offsetLeft);
  //   setY(tutorial.current.offsetTop);
  // }, [showTutorial]);
  return (
    <>
      <AppBar
        style={{
          minHeight: "54px",
          backgroundColor: "white ",
          boxShadow: "0px 1px 4px rgba(0,0,0,0.1)",
          position: "absolute",
        }}
      >
        <span style={{ display: "flex" }}>
          {/* <Logo src={leaseplanlogo} hideUntil="lap" height="54px" /> */}

          <UserDetails>
            {props.iconVisible && (
              <>
                <LoginWidget src={user} hideUntil="lap" />

                {userData?.clientName && (
                  <div style={{ borderLeft: "1px solid #eeeeee" }}>
                    <ClientName>{userData?.userName}</ClientName>
                    <div>
                      <ClientNumber>{userData?.clientNumber}</ClientNumber>
                      <LeasePlanINC>{userData?.clientName}</LeasePlanINC>
                    </div>
                  </div>
                )}
              </>
            )}
          </UserDetails>
        </span>
      </AppBar>
      <Wrapper>
        {countProp?.pending > 0 &&
          showPendingRequest &&
          userData?.role != "CA" &&
          userData?.role != "CS" &&
          userData?.role != "FS" &&
          userData?.role != "LS" && (
            <>
              <WrapperText>
                <div
                  style={{
                    flexDirection: "column",
                    flex: 1,
                    display: "flex",
                  }}
                >
                  <div style={{ alignSelf: "center" }}>
                    <Text size="xs" color="petrolBlue" fontWeight="bold">
                      {getLabel(
                        "pending_request_count_title",
                        "Pending",
                        prismicData
                      )}{" "}
                    </Text>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      alignSelf: "center",
                    }}
                  >
                    <Text size="xs" color="petrolBlue">
                      {getLabel(
                        "pending_there_are_title",
                        "There are",
                        prismicData
                      )}{" "}
                    </Text>
                    <Text size="xs" color="petrolBlue" fontWeight="bold">
                      {countProp?.pending}{" "}
                    </Text>
                    <Text size="xs" color="petrolBlue">
                      {getLabel(
                        "pending_request_count_description",
                        "Pending requests that require your action.",
                        prismicData
                      )}{" "}
                    </Text>
                    <CustomText
                      size="xs"
                      color="petrolBlue"
                      onClick={() =>
                        updateSearchQuery({
                          pageNumber: 1,
                          status: "Pending",
                          tab: 1,
                        })
                      }
                    >
                      {getLabel(
                        "count_view_requests",
                        "View requests.",
                        prismicData
                      )}
                    </CustomText>
                  </div>
                </div>
                {/* {showTutorial && (
              <Tutorial
                x={x}
                y={y}
                w={15}
                mt={10}
                head={getLabel(
                  "pending_tutorial_title",
                  "Pending Action - Required by Customer",
                  prismicData
                )}
                description={getLabel(
                  "pending_tutorial_description",
                  " Click here to see all requests that have been sent back to you to take action on. These requests can not be resolved by LeasePlan without your action.",
                  prismicData
                )}
              />
            )} */}
              </WrapperText>
              <CloseIconButton
                onClick={() => {
                  eventLog({
                    eventName: PENDING_REQUEST,
                    additionalParams: {
                      status: "closed",
                    },
                  });
                  setShowPendingRequest(false);
                }}
              />
            </>
          )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${Colors.statusBackground};
  margin-top: ${ConvertSize(50)};
`;
const CustomText = styled(Text)`
  cursor: pointer;
  color: ${Colors.midOrange};
  text-decoration: underline;
`;
const CloseIconButton = styled(CloseIcon)`
  cursor: pointer;
  color: ${Colors.midOrange};
  font-size: ${ConvertSize(25)};
  margin: ${Spacing.s};
`;

const WrapperText = styled.div`
  padding: ${ConvertSize(5)};
  display: flex;
  flex: 1;
  align-items: center;
`;
const UserDetails = styled(TopNavigationWidgets)`
  border: ${ConvertSize(1)} solid #eeeeee;
  margin-right: ${ConvertSize(30)};
  padding: ${ConvertSize(8)} ${ConvertSize(12)};
  border-radius: ${ConvertSize(4)};
  font-size: 12px;
`;
const TextWrapper = styled(Text)`
  margin-right: ${Spacing.xxxs};
  background: ${Colors.midOrange};
  padding-right: ${ConvertSize(5)};
  padding-left: ${ConvertSize(7)};
  padding-top: ${ConvertSize(2)};
  padding-bottom: ${ConvertSize(2)};
  border-top-left-radius: ${ConvertSize(8)};
  border-top-right-radius: ${ConvertSize(8)};
  border-bottom-left-radius: ${ConvertSize(8)};
  border-bottom-right-radius: ${ConvertSize(8)};
`;
const LoginWidget = styled.img`
  width: ${ConvertSize(25)};
  margin-right: ${ConvertSize(12)} !important;
  height: ${ConvertSize(25)};
  margin: auto;
`;
const Logo = styled.img`
  width: 134px;
  height: auto;
`;
const ClientName = styled.p`
  font-size: ${ConvertSize(12)};
  color: #014a5d;
  text-decoration: none solid rgb(1, 74, 93);
  margin-left: ${ConvertSize(12)};
`;

const LeasePlanINC = styled.span`
  font-size: ${ConvertSize(12)};
  color: #014a5d;
  text-decoration: none solid rgb(1, 74, 93);
  margin-left: ${ConvertSize(6)};
`;
const ClientNumber = styled.span`
  font-size: ${ConvertSize(12)};
  color: #014a5d;
  text-decoration: none solid rgb(1, 74, 93);
  font-weight: bold;
  margin-left: ${ConvertSize(12)};
`;

export default TopNav;
