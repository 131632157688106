import { logAPIErrors } from "../store/request/requestApi";
import store from "../store/store";

const getUserData = () => {
  const { user } = store.getState();
  return user;
};

export const logError = async (error) => {
  try {
    const user = getUserData();
    const body = {
      requestSystem: "workspace/ui",
      userId: user.userData?.userId,
      clientNumber: user?.userData?.clientNumber,
      createdOnTimestamp: Date.now(),
      requestName: error?.request?.responseURL.replace(
        "https://dev.eplan.api.leaseplan.us/v1",
        ""
      ),
      responseCode: error?.request?.status,
    };
    const res = await logAPIErrors(body);
  } catch (err) {
    console.log("logEvent-err", err);
  }
};
export const logFunctionError = async (error) => {
  try {
    const user = getUserData();
    const body = {
      requestSystem: "workspace/ui",
      userId: user.userData?.userId,
      clientNumber: user?.userData?.clientNumber,
      createdOnTimestamp: Date.now(),
      requestError: JSON.stringify(error),
    };
    const res = await logAPIErrors(body);
  } catch (err) {
    console.log("logEvent-err", err);
  }
};
