import Switch from "@material-ui/core/Switch";
import styled from "styled-components";
import { Colors } from "../../utils/theme";

export const UnreadSwitch = styled(Switch).attrs(() => ({
  classes: {
    root: "root",
    switchBase: "switchBase",
    thumb: "thumb",
    track: "track",
    checked: "checked",
  },
  disableRipple: true,
  focusVisibleClassName: "focusVisible"
}))`
  &.root {
    width: 32px;
    height: 16px;
    padding: 0;
    margin: 2px;
  }

  .switchBase {
    padding: 1px;

    &.checked {
      transform: translateX(16px);
      color: white;
      & + .track {
        background-color: ${Colors.petrolBlue};
        opacity: 1;
        border: none;
      }
    }

    &.focusVisible &.thumb {
      color: #52d869;
      border: 6x sold #fff;
    }
  }

  .thumb {
    width: 14px;
    height: 14px;
  }

  & .track {
    border-radius: 13px;
    border: 1px solid #bdbdbd;
    background-color: #bbbbbb;
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
      border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

`;
