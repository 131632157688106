import {
  FETCH_SETTINGS_FAIL,
  FETCH_SETTINGS_START,
  FETCH_SETTINGS_SUCCESS,
  FETCH_CLIENT_SETTINGS_FAIL,
  FETCH_CLIENT_SETTINGS_START,
  FETCH_CLIENT_SETTINGS_SUCCESS,
} from "./settingsConstants";

export const fetchSettingsStart = () => {
  return {
    type: FETCH_SETTINGS_START,
  };
};

export const fetchSettingsSuccess = (settingsData) => {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: settingsData,
  };
};

export const fetchSettingsFail = (err) => {
  return {
    type: FETCH_SETTINGS_FAIL,
    payload: err,
  };
};

export const fetchClientSettingsStart = () => {
  return {
    type: FETCH_CLIENT_SETTINGS_START,
  };
};

export const fetchClientSettingsSuccess = (clientSettingsData) => {
  return {
    type: FETCH_CLIENT_SETTINGS_SUCCESS,
    payload: clientSettingsData,
  };
};

export const fetchClientSettingsFail = (err) => {
  return {
    type: FETCH_CLIENT_SETTINGS_FAIL,
    payload: err,
  };
};
