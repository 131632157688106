import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Button } from "@leaseplan/ui";

import ExitIcon from "../../assets/images/logout.svg";
import { getLabel } from "../../utils/prismicUtils";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import './Unauthorizedaccess.scss';

const Unauthorizedaccess = () => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  const onCloseWindow = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <UnAuthContainer>
      <Container className="sessionScreen">
        <Icon src={ExitIcon} />
        <HeadingText marginTop={Spacing.xl}>
          {getLabel(
            "session_end_heading_1",
            "Thank you for using",
            prismicData
          )}
        </HeadingText>
        <HeadingText marginBottom={Spacing.xl}>
          {getLabel("session_end_heading_2", "Workspace!", prismicData)}
        </HeadingText>
        <NormalText>
          {getLabel(
            "session_end_msg_1",
            "Your ePlan/Workspace session has ended.",
            prismicData
          )}
        </NormalText>
        <NormalText>
          {getLabel(
            "session_end_msg_2",
            "To access Workspace again, launch the app from ePlan dashboard.",
            prismicData
          )}
        </NormalText>
        <NormalText marginTop={Spacing.m} marginBottom={Spacing.m}>
          {getLabel(
            "session_end_msg_3",
            "You may now close this window.",
            prismicData
          )}
        </NormalText>
        <CloseButton onClick={onCloseWindow}>
          {getLabel("session_end_close_button", "CLOSE WINDOW", prismicData)}
        </CloseButton>
      </Container>
    </UnAuthContainer>
  );
};
export default Unauthorizedaccess;

const UnAuthContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadingText = styled.span`
  color: ${Colors.petrolBlue};
  font-weight: bold;
  font-size: ${ConvertSize(28)};
  text-align: center;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const NormalText = styled.p`
  color: ${Colors.petrolBlue};
  font-size: ${ConvertSize(18)};
  text-align: center;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const CloseButton = styled(Button)`
  margin-top: ${Spacing.l};
`;

const Icon = styled.img`
  height: ${ConvertSize(100)};
`;
