import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Grid,
  Textarea,
  Text,
  Button as LPbutton,
  CloseIcon,
} from "@leaseplan/ui";
import { useFormik } from "formik";
import eventLog from "../../config/firebase";
import _, { replace, set } from "lodash";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MailOutlineRounded from "@mui/icons-material/MailOutlineRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { showToaster, hideToaster } from "../../store/toaster/toasterActions";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import Select from "@mui/material/Select";
import Selectors from "../Selectors";
import { createTheme } from "@mui/material/styles";
import {
  hideSidePanel,
  refreshTableData,
  setLegacyCommand,
} from "../../store/commonStore/commonActionCreators";
import { connect, useSelector, useDispatch } from "react-redux";
import Search from "./Search";
import FileUpload from "./FileUpload";
import { useState } from "react";
import { isEmpty } from "../../utils/stringFunctions";
import { getLabel } from "../../utils/prismicUtils";
import {
  createRequest,
  getDrivers,
  getVehicles,
  deleteAttachment,
  getDriverAssignedVehicles,
} from "../../store/request/requestApi";
import {
  EXTERNAL_REQUEST,
  CATEGORY_CHANGE,
  DRIVER_SEARCH,
  VEHICLE_SEARCH,
  COMMENT_ADDED,
  CREATE_NEW_REQUEST,
  UNDO_DRIVER,
  UNDO_VEHICLE,
  REMOVE_SELECTED_DRIVER,
  REMOVE_SELECTED_VEHICLE,
  SUBCATEGORY_CHANGE,
} from "../../helpers/logEvents";
import RequestConfirmation from "../RequestConfirmation";
import Loading from "../Loading";
import { LEGACY_URL } from "../../utils/constants";
import RichTextEditor from "react-rte";
import NavigateConfirmation from "./LinkedRequest/NavigateConfirmation";
import SelectedVehicleCard from "./SelectedEntityCard";
import SelectedDriverCard from "./SelectedDriverCard";
import Path from "./../../assets/images/Path.svg";
import dompurify from "dompurify";
import uuid from "react-uuid";
import ExternalLink from "./ExternalLink.jsx";
import { logFunctionError } from "../../config/errorLog";
import { storeFileIds } from "../../store/request/requestActions";
const NewRequest = (props) => {
  const [richTextValue, setRichTextValue] = useState(
    RichTextEditor.createEmptyValue()
  );
  const { legacyCommand } = useSelector((state) => state.common);
  const OnChangeRichText = (value) => {
    setRichTextValue(value);
    var regex = /(<([^>]+)>)/gi;
    let hasText = !!value.toString("html").replace(regex, "").length;

    if (hasText) {
      setFieldValue("description", value.toString("html"));
    }
  };
  const sanitizer = dompurify.sanitize;

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  const clientNumber = useSelector((state) => state.user.userData.clientNumber);
  const types = useSelector((state) => state.requestDropdown.requestDropdown);
  const { refreshData } = useSelector((state) => state.common);
  const [fileUploadFailed, setFileUploadFailed] = useState(null);
  const dispatch = useDispatch();
  const hideSidePanel = async () => {
    if (!isSubmitted && fileID.length > 0) {
      let deleteFiles = [];
      fileID.forEach(function (file) {
        deleteFiles.push({
          id: file.id,
        });
      });
      const deleteFileRes = await deleteAttachment(deleteFiles);
    }
    props.hideSidePanel();
  };

  const onSubmit = async (results) => {
    const {
      description,
      type,
      subType,
      driverNumber,
      vehicleNumber,
      parentId,
    } = results;

    const requestBody = {
      description,
      type,
      subType,
      driverNumber,
      vehicleNumber,
      parentId,

      attachments: fileID,
    };

    if (action.actionAction == "CREATE_CASE") {
      eventLog({
        eventName: CREATE_NEW_REQUEST,
      });
      try {
        setIsLoading(true);
        const firstRes = await createRequest(requestBody);
        const filesFailed = [];
        firstRes?.data?.attachments?.forEach((file) => {
          !file?.isUploaded && filesFailed.push(file?.fileName);
        });
        setFileUploadFailed(filesFailed);
        dispatch(storeFileIds([]));
        props.showPopupWarning("description", false);
        props.showPopupWarning("files", false);
        setCaseNumber(firstRes.data?.caseNumber);
        setRequestIdRef(firstRes.data?.id);
        setIsLoading(false);
        setIsSubmitted(true);
        // firstRes && props.showPopupWarning("description", false);
        firstRes &&
          dispatch(refreshTableData({ type: !refreshData, loading: true }));
      } catch (e) {
        setIsLoading(false);
        clearFiles();
        setFileID([]);
        props.showPopupWarning("description", false);
        props.showPopupWarning("files", false);
        logFunctionError(e);
      }
    } else if (action.actionAction == "EXTERNAL") {
      eventLog({
        eventName: EXTERNAL_REQUEST,
        additionalParams: { subType },
      });
      props.hideSidePanel();
      try {
        window.open(action.actionLink, "_blank").focus();
      } catch (e) {
        logFunctionError(e);
      }
    } else {
      if (action.actionText1) {
        const params1 = action.actionText1.replace("[DRIVER]", driverNumber);
        const params2 = params1.replace("[CLIENT]", clientNumber);
        const params3 = params2.replace("[VEHICLE]", vehicleNumber);
        props.hideSidePanel();
        // window.opener.postMessage(
        //   `${action.actionLink}?${params3}`,
        //   LEGACY_URL
        // );
        // dispatch(
        //   showToaster({
        //     type: "INFO",
        //     message: "Please return to the ePlan application to continue",
        //   })
        // );
        window
          .open(LEGACY_URL + `${action.actionLink}?${params3}`, "_eplan")
          .focus();
      } else {
        // props.hideSidePanel();
        // window.opener.postMessage(`${action.actionLink}`, LEGACY_URL);
        // dispatch(
        //   showToaster({
        //     type: "INFO",
        //     message: "Please return to the ePlan application to continue",
        //   })
        // );
        props.hideSidePanel();
        window.open(LEGACY_URL + `${action.actionLink}`, "_eplan").focus();
      }
    }
  };
  const handleSidePanel = async () => {
    if (
      values?.vehicle?.length > 0 ||
      values?.driver?.length > 0 ||
      values?.description?.length > 0 ||
      fileID?.length > 0
    ) {
      setAnchorEl(true);
    } else if (legacyCommand !== "close") {
      props.hideSidePanel();
    }
  };

  useEffect(() => {
    if (legacyCommand === "close") {
      handleSidePanel();
      dispatch(setLegacyCommand({ legacyCommand: null }));
    }
  }, [legacyCommand]);

  const [attachmentClicked, setAttachmentClicked] = useState(false);
  const [subTypes, setSubTypes] = useState(null);
  const [externalLink, setExternalLink] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSearch, setLoadingSearch] = useState({
    status: false,
    type: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [caseNumber, setCaseNumber] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [requestIDref, setRequestIdRef] = useState(null);
  const [selectedVehicleRecord, setSelectedVehicleRecord] = useState([]);
  const [selectedDriverRecord, setSelectedDriverRecord] = useState([]);
  const [
    selectedDriverAssignedVehicleRecord,
    setSelectedDriverAssignedVehicleRecord,
  ] = useState([]);

  const [action, setAction] = useState({
    actionDisplay: "NONE",
    actionAction: "",
    actionLink: "",
    actionText1: "",
  });
  const getTypeSubType = async () => {
    const subTypes =
      types[`subcategory_${types?.categories[0].requestCategoryUid}`];
    setSubTypes(subTypes);
    setSubTypeReqValue(subTypes[0].requestCategoryUid);
    if (
      subTypes[0].actionAction === "EPLAN" ||
      subTypes[0].actionAction === "EXTERNAL"
    ) {
      if (reqSubTypeValue == "") {
        setExternalLink(true);
      } else {
        setExternalLink(false);
      }
    } else {
      setExternalLink(false);
    }
    setAction({
      actionDisplay: subTypes[0].actionDisplay,
      actionAction: subTypes[0].actionAction,
      actionLink: subTypes[0].actionLink,
      actionText1: subTypes[0].actionText1,
    });
    setFieldValue("type", types?.categories[0].categoryDescription);
    setFieldValue("typeValue", types?.categories[0].requestCategoryUid);
    setFieldValue("subType", subTypes[0].categoryDescription);
    setFieldValue("subTypeValue", subTypes[0].requestCategoryUid);
  };

  useEffect(() => {
    getTypeSubType();
  }, []);
  function deleteFile(id) {
    var newData = uploadedData.filter(function (item) {
      return item !== id;
    });
    setFileID(newData);
  }
  const validate = (results) => {
    const errors = {};
    if (action?.actionDisplay == "DRIVER") {
      if (isEmpty(results.driverNumber)) {
        errors.driver = "Driver is Required";
      }
    }
    if (action?.actionDisplay == "VEHICLE") {
      if (isEmpty(results.vehicleNumber)) {
        errors.vehicle = "Vehicle is Required";
      }
    }
    if (action?.actionAction == "CREATE_CASE") {
      if (isEmpty(results.description)) {
        errors.description = getLabel(
          "mandatory_description_message",
          "Please enter a description of 5 characters or more",
          prismicData
        );
      }
      var regex = /^\s+|\s+$/gm;
      let hasText = results.description
        .toString("html")
        .replace(regex, "").length;
      if (hasText >= 1 && hasText < 5) {
        errors.description = getLabel(
          "mandatory_description_message",
          "Please enter a description of 5 characters or more",
          prismicData
        );
      }
    }
    return errors;
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setErrors,
    setTouched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      type: null,
      subType: null,
      typeValue: null,
      subTypeValue: null,
      description: "",
      driverNumber: null,
      driverAssignedVehicle: null,
      driverAssignedVehicleNumber: null,
      driver: null,
      vehicleNumber: null,
      vehicle: null,
      parentId: null,
    },
    onSubmit,
    validate,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const clearFilesAttached = async (e) => {
    if (!isSubmitted && fileID.length > 0) {
      let deleteFiles = [];
      fileID.forEach(function (file) {
        deleteFiles.push({
          id: file.id,
        });
      });
      const deleteFileRes = await deleteAttachment(deleteFiles);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [searchedDrivers, setSearchedDrivers] = useState([]);
  const [searchedVehicles, setSearchedVehicles] = useState([]);
  const [driverassignedVehicles, setDriverassignedVehicles] = useState({});
  const [searchedVehiclesCurrentPage, setSearchedVehiclesCurrentPage] =
    useState(1);
  const [assignedVehiclesCurrentPage, setAssignedVehiclesCurrentPage] =
    useState(1);
  const [driverNumber, setDriverNumber] = useState(null);
  const [showSearchedDrivers, setShowSearchedDrivers] = useState(false);
  const [
    showSearchedDriversAssignedVehicle,
    setShowSearchedDriversAssignedVehicle,
  ] = useState(false);
  const [showSearchedVehicles, setShowSearchedVehicles] = useState(false);
  const [searchVehicleDisable, setSearchVehicleDisable] = useState(false);
  const [searchDriverDisable, setSearchDriverDisable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const prismicData = useSelector((state) => state.prismic.prismicData);
  const [uploadedData, setUploadedData] = useState([]);
  const [selectedVehicleData, setSelectedVehicleData] = useState({});
  const [selectedDriverData, setSelectedDriverData] = useState({});

  const [displaySelectedEntity, setDisplaySelectedEntity] = useState(false);
  const [displayVehicle, setDisplayVehicle] = useState(true);

  const [displaySelectedDriver, setDisplaySelectedDriver] = useState(false);
  const [loadMoreEnable, setLoadMoreEnable] = useState(false);
  const [loadMoreEnableAssignedVehicles, setLoadMoreEnableAssignedVehicles] =
    useState(false);
  const [reqTypeValue, setReqTypeValue] = useState(1);
  const [reqSubTypeValue, setSubTypeReqValue] = useState(1);

  const [fileID, setFileID] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const { files } = useSelector((state) => state.request);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#044a5d",
      },
    },
  });
  const onFileUpload = (fileID) => {
    setFileID(fileID);
    if (fileID.length == 0) {
      props.showPopupWarning("files", false);
    } else if (fileID.length > 0) {
      props.showPopupWarning("files", true);
    }
  };
  const fileUploadInProgress = (status) => {
    setUploadInProgress(status);
  };
  const fileDeletionInProgress = (status) => {
    setDeletionInProgress(status);
  };
  const clearFiles = () => {
    if (files.length !== 0) {
      let deleteFiles = [];
      files.forEach(function (id) {
        deleteFiles.push({
          id,
        });
      });
      deleteAttachment(deleteFiles);
    }
  };
  const onChangeRequestType = (e) => {
    setReqTypeValue("");
    setFieldValue("driverNumber", "");
    setFieldValue("driver", "");
    setFieldValue("vehicleNumber", "");
    setFieldValue("vehicle", "");
    setFieldValue("driverAssignedVehicle", "");
    setFieldValue("driverAssignedVehicleNumber", "");
    setDisplaySelectedEntity(false);
    setSelectedVehicleRecord([]);
    setSelectedVehicleData({});

    setDisplaySelectedDriver(false);
    setSelectedDriverRecord([]);
    setSelectedDriverData({});
    setDisplaySelectedDriver(false);

    const selectedIndex = e.target.selectedIndex;
    setFieldValue("type", e.target[selectedIndex].text);
    setFieldValue("typeValue", e.target.value);
    props.showPopupWarning("description", false);
    const subTypes = types[`subcategory_${e.target.value}`];
    setSubTypeReqValue(subTypes[0].requestCategoryUid);
    setFieldValue("subType", subTypes[0].categoryDescription);
    setFieldValue("subTypeValue", subTypes[0].requestCategoryUid);
    setSubTypes(subTypes);
    eventLog({
      eventName: CATEGORY_CHANGE,
    });
    // if (
    //   subTypes[0].categoryDescription == "New driver kit" ||
    //   subTypes[0].categoryDescription == "New Vehicle Maintenance Guide" ||
    //   subTypes[0].categoryDescription == "New insurance card" ||
    //   subTypes[0].categoryDescription == "New fuel card or EV card" ||
    //   subTypes[0].categoryDescription == "Terminate a vehicle" ||
    //   subTypes[0].categoryDescription == "Request a vehicle sale" ||
    //   subTypes[0].categoryDescription == "Terminate a service" ||
    //   subTypes[0].categoryDescription == "Add a driver" ||
    //   subTypes[0].categoryDescription == "Manage toll program" ||
    //   subTypes[0].categoryDescription == "Build out/startup dates"
    // ) {
    //   if (reqSubTypeValue == "") {
    //     setExternalLink(true);
    //   } else {
    //     setExternalLink(false);
    //   }
    // } else {
    //   setExternalLink(false);
    // }

    setExternalLink(false);
    setSearchDriverDisable(false);
    setSearchVehicleDisable(false);
    setAction({
      actionDisplay: subTypes[0].actionDisplay,
      actionAction: subTypes[0].actionAction,
      actionLink: subTypes[0].actionLink,
      actionText1: subTypes[0].actionText1,
    });
    setErrors({});
    setTouched({});
    props.showPopupWarning("description", false);
    props.showPopupWarning("files", false);
    clearFiles();
  };
  const onChangeRequestSubType = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setFieldValue("subType", e.target[selectedIndex].text);
    setFieldValue("subTypeValue", e.target.value);
    setSearchDriverDisable(false);
    setSearchVehicleDisable(false);
    props.showPopupWarning("description", false);
    props.showPopupWarning("files", false);
    const selectedItem = subTypes.filter((item) => {
      return item.requestCategoryUid == e.target.value;
    });
    if (
      selectedItem[0].actionAction === "EPLAN" ||
      selectedItem[0].actionAction === "EXTERNAL"
    ) {
      setExternalLink(true);
    } else {
      setExternalLink(false);
    }
    setSubTypeReqValue("");
    setFieldValue("driverNumber", "");
    setFieldValue("driver", "");
    setFieldValue("vehicleNumber", "");
    setFieldValue("vehicle", "");
    setDisplaySelectedEntity(false);
    setSelectedVehicleRecord([]);
    setSelectedVehicleData({});
    setDisplaySelectedDriver(false);
    setSelectedDriverRecord([]);
    setSelectedDriverData({});
    setDisplaySelectedDriver(false);

    setAction({
      actionDisplay: selectedItem[0].actionDisplay,
      actionAction: selectedItem[0].actionAction,
      actionLink: selectedItem[0].actionLink,
      actionText1: selectedItem[0].actionText1,
    });
    eventLog({
      eventName: SUBCATEGORY_CHANGE,
    });
    setErrors({});
    setTouched({});
  };

  const initialRender = useRef(true);
  const prevDriver = useRef(null);
  const prevVehicle = useRef(null);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    errors.vehicle = "";
    errors.driver = "";
    if (values.vehicle !== prevVehicle.current) {
      prevVehicle.current = values.vehicle;
      if (values.vehicle.length > 0) {
        onSearchVehicle(values.vehicle);
        props.showPopupWarning("description", true);
      } else if (values.driver.length == 0) {
        props.showPopupWarning("description", false);
      }
      return;
    }
    if (!initialRender.current) {
      if (values.driver !== prevDriver.current) {
        prevDriver.current = values.driver;
        if (values.driver.length > 0) {
          props.showPopupWarning("description", true);
          onSearchDriver(values.driver);
        } else if (values.vehicle.length == 0) {
          props.showPopupWarning("description", false);
        }
        return;
      }
    }
    initialRender.current = false;
  }, [debouncedValue]);

  const onSearchDriver = _.debounce(async (text, pageNumber) => {
    setSearchText(text);
    if (pageNumber) {
      loadMoreRef.current = true;
      setDataLoading(true);
    }
    const res = await getDrivers({
      keyword: text,
      pageNumber: pageNumber ? pageNumber : searchedVehiclesCurrentPage,
    });
    if (res.data.drivers && res.data.drivers.length == 6) {
      if (res.data.drivers.length == 6) {
        setLoadMoreEnable(true);
      } else {
        setLoadMoreEnable(false);
      }
      setSearchedDrivers(
        res.data.currentPage == 1
          ? res.data.drivers.slice(0, -1)
          : [...searchedDrivers, ...res.data.drivers.slice(0, -1)]
      );
    } else {
      if (res.data.drivers.length == 6) {
        setLoadMoreEnable(true);
      } else {
        setLoadMoreEnable(false);
      }
      setSearchedDrivers(
        res.data.currentPage == 1
          ? res.data.drivers
          : [...searchedDrivers, ...res.data.drivers]
      );
    }
    // setSearchedDrivers(
    //   res.data.currentPage == 1
    //     ? res.data.drivers
    //     : [...searchedDrivers, ...res.data.drivers]
    // );
    setShowSearchedDrivers(true);
    setLoadingSearch({
      status: false,
      type: "driver",
    });
    loadMoreRef.current = false;
    setDataLoading(false);
  }, 1000);

  const onSearchVehicle = _.debounce(async (text, pageNumber) => {
    setSearchText(text);

    if (pageNumber) {
      loadMoreRef.current = true;
      setDataLoading(true);
    }
    const res = await getVehicles({
      keyword: text,
      pageNumber: pageNumber ? pageNumber : searchedVehiclesCurrentPage,
    });
    if (res.data.vehicles && res.data.vehicles.length == 6) {
      if (res.data.vehicles.length == 6) {
        setLoadMoreEnable(true);
      } else {
        setLoadMoreEnable(false);
      }
      setSearchedVehicles(
        res.data.currentPage == 1
          ? res.data.vehicles.slice(0, -1)
          : [...searchedVehicles, ...res.data.vehicles.slice(0, -1)]
      );
    } else {
      if (res.data.vehicles.length == 6) {
        setLoadMoreEnable(true);
      } else {
        setLoadMoreEnable(false);
      }
      setSearchedVehicles(
        res.data.currentPage == 1
          ? res.data.vehicles
          : [...searchedVehicles, ...res.data.vehicles]
      );
    }
    setShowSearchedVehicles(true);
    setLoadingSearch({
      status: false,
      type: "vehicle",
    });
    loadMoreRef.current = false;
    setDataLoading(false);
  }, 100);

  const searchDriverData = _.debounce(() => {
    setSearchedVehiclesCurrentPage(1);
    setDebouncedValue(values.driver);
  }, 1500);

  const searchVehicleData = _.debounce(() => {
    setSearchedVehiclesCurrentPage(1);
    setDebouncedValue(values.vehicle);
  }, 1500);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: Colors.midOrange,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Colors.midOrange,
      padding: "10px",
      paddingRight: "25px",
      paddingLeft: "25px",
      fontSize: "12px ",
    },
  }));

  const MailIcon = ({ title }) => {
    return (
      <IconsWrapper>
        <BootstrapTooltip title={title} arrow placement="top">
          <MailOutlineRounded htmlColor={Colors.steel60} />
        </BootstrapTooltip>
      </IconsWrapper>
    );
  };
  const getDriverIcon = (status) => {
    switch (status) {
      case "ASSIGNED":
        return prismicData?.assigned?.url;
      case "HOUSEHOLD":
        return prismicData?.household?.url;
      case "UNASSIGNED":
        return prismicData?.unassigned?.url;
      case "TERMINATED":
        return prismicData?.terminated?.url;
    }
  };
  const getVehicleIcon = (status) => {
    switch (status) {
      case "O":
        return prismicData?.client_owned?.url;
      case "F":
        return prismicData?.fleet_management?.url;
      case "R":
        return prismicData?.fleet_management?.url;
      case "B":
        return prismicData?.leased_on_fleet_management?.url;
      case "S":
        return prismicData?.pnd?.url;
      case "P":
        return prismicData?.sale_in_progress?.url;
      case "N":
        return prismicData?.service_only?.url;
      default:
        return prismicData?.leased?.url;
    }
  };
  const _getStyledSuggestion = (suggestion, searchText) => {
    if (!suggestion.toLowerCase().startsWith(searchText)) {
      return <span>{suggestion}</span>;
    }
    const userInputLength = searchText.length;
    return (
      <span>
        <span style={{ fontWeight: "bold" }}>
          {suggestion.substring(0, userInputLength)}
        </span>
        {suggestion.substring(userInputLength)}
      </span>
    );
  };
  var reg = new RegExp(searchText, "gi");

  const renderDriverItems = (item, index) => {
    let replacekey = "";
    // return (
    // 	<TextContainer onClick={onClickDriver(item)}>
    // 		<Text size="s">{`${item.firstName} ${item.lastName}`}</Text>
    // 		<MailIcon title={item.email} />
    // 	</TextContainer>
    // );
    return (
      <tr onClick={() => onClickDriver(item)} class="tablerow">
        {/* <td>
					{boldText(item.vehicleYear)}
					{boldText(item.vehicleMake)}
					{boldText(item.vehicleModel)}
				</td> */}
        {/* <td class="searchCell-body">
					<img src={getDriverIcon(item.status)}></img>
					{`${item.driverId} `}
				</td>
				<td class="searchCell-body">{`${item.firstName} ${item.lastName}`}</td>
				<td class="searchCell-body"> {`${item.city}, ${item.state}`}</td>
				<img class="arrow" src={Path}></img> */}
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `<img src=${getDriverIcon(item.status)} />
            ${item.driverId.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`
            ),
          }}
        >
          {/* <img src={getDriverIcon(item.status)} />
          {_getStyledSuggestion(item.driverId.toString(), searchText)} */}
        </td>

        <td
          dangerouslySetInnerHTML={{
            __html: `${item.firstName.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
            ${item.lastName.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.firstName, searchText)}{" "}
          {_getStyledSuggestion(item.lastName, searchText)} */}
        </td>
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.city.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
            ${item.state.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`,
          }}
        >
          {/* {" "}
          {_getStyledSuggestion(item.city, searchText)}{" "}
          {_getStyledSuggestion(item.state, searchText)} */}
        </td>
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.email.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
            ${item.email.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`,
          }}
        ></td>
      </tr>
    );
  };
  const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  const onSearchClear = (field) => {
    if (field == "driver") {
      setFieldValue("driver", "");
    } else if (field === "driverAssignedVehicle") {
      setFieldValue("driverAssignedVehicle", "");
      setShowSearchedDriversAssignedVehicle(true);
      setFieldValue("vehicle", "");
      setDisplayVehicle(true);
      // setSelectedVehicleData({});
      // setDisplaySelectedEntity(false);
      // if (driverassignedVehicles) {
      //   setShowSearchedDriversAssignedVehicle(true);
      // }
      setDriverassignedVehicles(assignedVehicles);
    } else {
      setFieldValue("vehicle", "");
      setDisplayVehicle(true);
      setShowSearchedDriversAssignedVehicle(true);
    }
  };
  const undoActionOnVehicle = () => {
    eventLog({
      eventName: UNDO_VEHICLE,
    });
    selectedVehicleRecord.pop();
    setSelectedVehicleData(selectedVehicleRecord[0]);
    setFieldValue(
      "driverAssignedVehicle",
      `${selectedVehicleRecord[0].vehicleNumber} - ${selectedVehicleRecord[0].vehicleYear} ${selectedVehicleRecord[0].vehicleMake} ${selectedVehicleRecord[0].vehicleModel}`
    );
    setFieldValue(
      "vehicle",
      `${selectedVehicleRecord[0].vehicleNumber} - ${selectedVehicleRecord[0].vehicleYear} ${selectedVehicleRecord[0].vehicleMake} ${selectedVehicleRecord[0].vehicleModel}`
    );
    setFieldValue(
      "vehicleNumber",
      selectedVehicleRecord[0].vehicleNumber?.toString()
    );
  };
  const undoActionOnDriver = () => {
    eventLog({
      eventName: UNDO_DRIVER,
    });
    selectedDriverRecord.pop();
    setSelectedDriverData(selectedDriverRecord[0]);
    setFieldValue(
      "driver",
      `${selectedDriverRecord[0].firstName} ${selectedDriverRecord[0].lastName}`
    );
    setFieldValue(
      "driverNumber",
      selectedDriverRecord[0].driverNumber?.toString()
    );
    setAssignedVehiclesCurrentPage(1);
    onLoadDriverAssignedVehicles(selectedDriverRecord[0].driverNumber,1);
  };
  const renderVehicleItems = (item, type) => {
    return (
      <tr onClick={onClickVehicle(item)} class="tablerow">
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `<img src=${getVehicleIcon(item.vehicleType)} />
        		${item.vehicleYear?.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
              ${item.vehicleMake?.toString().replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}
                ${item.vehicleModel?.toString().replace(reg, function (str) {
                  return "<b>" + str + "</b>";
                })}`
            ),
          }}
        >
          {/* <img src={getVehicleIcon(item.vehicleType?.toString())} />
          {_getStyledSuggestion(item.vehicleYear?.toString(), searchText)}{" "}
          {_getStyledSuggestion(item.vehicleMake?.toString(), searchText)}{" "}
          {_getStyledSuggestion(item.vehicleModel?.toString(), searchText)}{" "} */}
        </td>
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.vehicleNumber
              ?.toString()
              .replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.vehicleNumber?.toString(), searchText)} */}
        </td>
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.clientUnitNumber
              ?.toString()
              .replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.clientUnitNumber?.toString(), searchText)} */}
        </td>{" "}
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `...${item.vin
                ?.slice(-8)
                .toString()
                .split(new RegExp(regEscape(searchText), "ig"))
                .join("<b>" + searchText.toUpperCase() + "</b>")} <br> `
            ),
          }}
        >
          {/* {" "} */}
          {/* {_getStyledSuggestion(item.vin?.toString(), searchText)} */}
        </td>{" "}
        <td
          dangerouslySetInnerHTML={{
            __html: `${item.driverName?.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.driverName, searchText)} */}
        </td>
        <img class="arrow" src={Path}></img>
      </tr>
    );
  };
  const renderDriverAssignedVehicleItems = (item, type) => {
    return (
      <tr onClick={onClickDriverAssignedVehicle(item)} class="tablerow">
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `<img src=${getVehicleIcon(item.vehicleType)} />
        		${item.vehicleYear?.toString().replace(reg, function (str) {
              return "<b>" + str + "</b>";
            })}
              ${item.vehicleMake?.toString().replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}
                ${item.vehicleModel?.toString().replace(reg, function (str) {
                  return "<b>" + str + "</b>";
                })}`
            ),
          }}
        >
          {/* <img src={getVehicleIcon(item.vehicleType?.toString())} />
          {_getStyledSuggestion(item.vehicleYear?.toString(), searchText)}{" "}
          {_getStyledSuggestion(item.vehicleMake?.toString(), searchText)}{" "}
          {_getStyledSuggestion(item.vehicleModel?.toString(), searchText)}{" "} */}
        </td>
       
          {/* {_getStyledSuggestion(item.driverName, searchText)} */}
        <td
          style={{ paddingLeft: "10px" }}
          dangerouslySetInnerHTML={{
            __html: `${item.vehicleStatus
              ?.toString()
              .replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.driverName, searchText)} */}
        </td>
        <td
          style={{ paddingLeft: "10px" }}
          dangerouslySetInnerHTML={{
            __html: `${item.vehicleNumber
              ?.toString()
              .replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.vehicleNumber?.toString(), searchText)} */}
        </td>
        <td
          style={{ paddingLeft: "10px" }}
          dangerouslySetInnerHTML={{
            __html: `${item.clientUnitNumber
              ?.toString()
              .replace(reg, function (str) {
                return "<b>" + str + "</b>";
              })}`,
          }}
        >
          {/* {_getStyledSuggestion(item.clientUnitNumber?.toString(), searchText)} */}
        </td>{" "}
        <td
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `...${item.vin
                ?.slice(-8)
                .toString()
                .split(new RegExp(regEscape(searchText), "ig"))
                .join("<b>" + searchText.toUpperCase() + "</b>")} <br> `
            ),
          }}
        >
          {/* {" "} */}
          {/* {_getStyledSuggestion(item.vin?.toString(), searchText)} */}
        </td>{" "}
      </tr>
    );
  };

  const onClickDriver = async (driver) => {
    setSelectedDriverRecord((arr) => [...arr, driver]);
    setSelectedDriverData(driver);
    setShowSearchedDrivers(false);
    prevDriver.current = `${driver.firstName} ${driver.lastName}`;

    setFieldValue("driver", `${driver.firstName} ${driver.lastName}`);
    setFieldValue("driverNumber", driver.driverNumber?.toString());
    setShowSearchedDrivers(false);
    setShowDropDown(false)
    setDriverNumber(driver.driverNumber);
    setAssignedVehiclesCurrentPage(1);
    onLoadDriverAssignedVehicles(driver.driverNumber, 1);
    if (driver) setDisplaySelectedDriver(true);
    else setDisplaySelectedDriver(false);
    eventLog({
      eventName: DRIVER_SEARCH,
    });
    // setSearchDriverDisable(true);
  };

  const deleteSearchText = (type) => {
    if (type == "vehicle") {
      // setFieldValue("vehicle", "");
      // setFieldValue("vehicleNumber", "");
      setShowSearchedVehicles(true);
      setSearchVehicleDisable(false);
      // setSelectedVehicleData({});
      setDisplaySelectedEntity(true);
    } else if (type == "driver") {
      // setFieldValue("driver", "");
      // setFieldValue("driverNumber", "");
      setShowSearchedDrivers(true);
      // setSelectedDriverData({});
      setSearchDriverDisable(false);
      setDisplaySelectedDriver(true);
    }
  };
  const showVehicles = () => {
    setShowSearchedVehicles(true);
  };
  const showDrivers = () => {
    setShowSearchedDrivers(true);
  };

  // onclick function for dropdown arrow in assigned vehicles
  const showDriverAssignedVehicles = () => {
    setShowDropDown(!showDropDown);
  };

  const clearSelectedEntityCard = (type) => {
    if (type == "vehicle") {
      eventLog({
        eventName: REMOVE_SELECTED_VEHICLE,
      });
      setDisplaySelectedEntity(false);
      setSelectedVehicleRecord([]);
      setFieldValue("vehicle", "");
      setFieldValue("vehicleNumber", "");
      setShowSearchedVehicles(true);
      setSearchVehicleDisable(false);
      setSelectedVehicleData({});
      setDisplayVehicle(true);
      if (values.driverAssignedVehicle && values.driver) {
        setFieldValue("driverAssignedVehicle", "");
        setShowSearchedDriversAssignedVehicle(true);
      } else if (values.driver && driverassignedVehicles.length > 0) {
        setShowSearchedDriversAssignedVehicle(true);
      } else {
        setFieldValue("driverAssignedVehicle", "");
        setShowSearchedDriversAssignedVehicle(false);
      }
    } else if (type == "driver") {
      eventLog({
        eventName: REMOVE_SELECTED_DRIVER,
      });
      setDisplaySelectedDriver(false);
      setSelectedDriverRecord([]);
      setFieldValue("driver", "");
      setFieldValue("driverNumber", "");
      setShowSearchedDrivers(true);
      setSearchDriverDisable(false);
      setSelectedDriverData({});
      setDisplaySelectedDriver(false);
      if (values.driverAssignedVehicle) {
        setShowSearchedDriversAssignedVehicle(true);
        setDisplayVehicle(false);
      } else {
        setFieldValue("driverAssignedVehicle", "");
        setDisplayVehicle(true);
        setShowSearchedDriversAssignedVehicle(false);
      }
    }
  };
  const onClickVehicle = (vehicle) => () => {
    setDisplayVehicle(true);
    setSelectedVehicleRecord((arr) => [...arr, vehicle]);

    setFieldValue(
      "vehicle",
      `${vehicle.vehicleNumber} - ${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
    );
    setFieldValue("vehicleNumber", vehicle.vehicleNumber?.toString());
    setShowSearchedVehicles(false);
    setSearchVehicleDisable(false);
    setSelectedVehicleData(vehicle);
    if (driverassignedVehicles) {
      setShowSearchedDriversAssignedVehicle(false);
    }
    if (vehicle) setDisplaySelectedEntity(true);
    else setDisplaySelectedEntity(false);
    eventLog({
      eventName: VEHICLE_SEARCH,
    });
  };
  const onClickDriverAssignedVehicle = (vehicle) => () => {
    setShowDropDown(false);
    setDisplayVehicle(false);
    setShowSearchedDriversAssignedVehicle(false);
    setFieldValue(
      "driverAssignedVehicle",
      `${vehicle.vehicleNumber} - ${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
    );
    setFieldValue(
      "driverAssignedVehicleNumber",
      vehicle.vehicleNumber?.toString()
    );
    setSelectedVehicleRecord((arr) => [...arr, vehicle]);

    // setFieldValue(
    //   "vehicle",
    //   `${vehicle.vehicleNumber} - ${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
    // );
    setFieldValue("vehicleNumber", vehicle.vehicleNumber?.toString());
    setShowSearchedVehicles(false);
    setSearchVehicleDisable(false);
    setSelectedVehicleData(vehicle);
    if (vehicle) setDisplaySelectedEntity(true);
    else setDisplaySelectedEntity(false);
    eventLog({
      eventName: VEHICLE_SEARCH,
    });

    setFieldValue(
      "vehicle",
      `${vehicle.vehicleNumber} - ${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
    );
    setFieldValue("vehicleNumber", vehicle.vehicleNumber?.toString());
    setShowSearchedVehicles(false);
    setSearchVehicleDisable(false);
    setSelectedVehicleData(vehicle);
    if (vehicle) setDisplaySelectedEntity(true);
    else setDisplaySelectedEntity(false);
    eventLog({
      eventName: VEHICLE_SEARCH,
    });
  };
  const onChangeComment = ({ value }) => {
    setFieldValue("description", value);
    value?.length > 0
      ? props.showPopupWarning("description", true)
      : props.showPopupWarning("description", false);

    eventLog({
      eventName: COMMENT_ADDED,
    });
  };

  //searching functionality for driver assigned vehicles field
  const searchAssignedVehicles = (value) => {
    setSearchText(value);
    setFieldValue("driverAssignedVehicle", value);
    setLoadingSearch({
      status: false,
      type: "driverAssignedVehicle",
    });
    const newVehicles = [];
    const newValue= value.toString().toLowerCase()
    assignedVehicles.forEach((vehicle) => {
      if (
        vehicle.vehicleNumber.toString().toLowerCase().includes(newValue) ||
        vehicle.driverName.toString().toLowerCase().includes(newValue) ||
        vehicle.vehicleMake.toString().toLowerCase().includes(newValue) ||
        vehicle.vehicleModel.toString().toLowerCase().includes(newValue) ||
        vehicle.vehicleYear.toString().toLowerCase().includes(newValue) ||
        vehicle.vin.toString().toLowerCase().includes(newValue) ||
        vehicle.clientUnitNumber.toString().toLowerCase().includes(newValue) ||
        vehicle.vehicleStatus.toString().toLowerCase().includes(newValue)
      )
        newVehicles.push(vehicle);
    });
    setDriverassignedVehicles(newVehicles);
  };
  const getUpdatedAssignedVehciles = (
    responseLength,
    responseData,
    orignalList = false
  ) => {
    let newAssignedVehiclesList;
    if (responseLength === 6) {
      setLoadMoreEnableAssignedVehicles(true);
      newAssignedVehiclesList =
        responseData.page == 1
          ? responseData.vehicles.slice(0, -1)
          : [
              ...(orignalList ? assignedVehicles : driverassignedVehicles),
              ...responseData.vehicles.slice(0, -1),
            ];
    } else {
      setLoadMoreEnableAssignedVehicles(false);
      newAssignedVehiclesList =
        responseData.page == 1
          ? responseData.vehicles
          : [
              ...(orignalList ? assignedVehicles : driverassignedVehicles),
              ...responseData.vehicles,
            ];
    }
    return newAssignedVehiclesList;
  };
  //load more functionality for driver assigned vehicles
  const onLoadDriverAssignedVehicles = async (driver, pageNumber) => {
    if (pageNumber && pageNumber !== 1 && driver) {
      setDataLoading(true);
    }
    const res = await getDriverAssignedVehicles({
      driverNumber: driver,
      pageNumber: pageNumber ? pageNumber : searchedVehiclesCurrentPage,
    });
    if (res?.data?.vehicles.length > 0) {
      setDriverassignedVehicles(
        getUpdatedAssignedVehciles(res.data.vehicles.length, {
          page: res.data.currentPage,
          vehicles: res.data.vehicles,
        })
      );
      setAssignedVehicles(
        getUpdatedAssignedVehciles(
          res.data.vehicles.length,
          {
            page: res.data.currentPage,
            vehicles: res.data.vehicles,
          },
          true
        )
      );
      setLoadingSearch({
        status: false,
        type: "driverAssignedVehicle",
      });
      setShowSearchedDriversAssignedVehicle(true);
    } else {
      setDriverassignedVehicles([]);
      setAssignedVehicles([]);
    }
    values.driverAssignedVehicle &&
      searchAssignedVehicles(values.driverAssignedVehicle);
    setDataLoading(false);
  };

  const loadMoreSearch = (type) => {
    setSearchedVehiclesCurrentPage(searchedVehiclesCurrentPage + 1);
    if (type == 1) {
      onSearchVehicle(values.vehicle, searchedVehiclesCurrentPage + 1);
    } else if (type === 2) {
      onLoadDriverAssignedVehicles(
        driverNumber,
        assignedVehiclesCurrentPage + 1
      );
      setAssignedVehiclesCurrentPage(assignedVehiclesCurrentPage + 1);
    } else {
      setDataLoading(true);
      onSearchDriver(values.driver, searchedVehiclesCurrentPage + 1);
    }
  };

  return (
    <DrawerInsideContainer>
      {isLoading ? (
        <Container>
          <Loading />
        </Container>
      ) : !isSubmitted ? (
        <Grid direction="column" alignContent="center">
          <Header>
            <CloseIconButton onClick={() => handleSidePanel()} />
          </Header>
          <CustomTextHeader color="petrolBlue" size="xl" fontWeight="bold">
            {getLabel("new_request_title", "New Request", prismicData)}
          </CustomTextHeader>
          <CustomText color="petrolBlue" size="s" fontWeight="bold">
            {getLabel("request_type_title", "Request Type", prismicData)}
            <span style={{ color: "red" }}>*</span>
          </CustomText>
          <Select
            native
            value={values.typeValue}
            inputProps={{
              id: "select-multiple-native",
            }}
            style={{ width: "100%" }}
            onChange={onChangeRequestType}
          >
            <option value={reqTypeValue} disabled>
              {getLabel("drop_down_placeholder", "Choose..", prismicData)}
            </option>
            {types?.categories?.map((item) => (
              <option key={uuid()} value={item.requestCategoryUid}>
                {item.categoryDescription}
              </option>
            ))}
          </Select>

          <CustomText color="petrolBlue" size="s" fontWeight="bold">
            {getLabel(
              "request_sub_type_title",
              "Request Sub Type",
              prismicData
            )}
            <span style={{ color: "red" }}>*</span>
          </CustomText>
          <Select
            native
            value={values.subTypeValue}
            inputProps={{
              id: "select-multiple-native",
            }}
            style={{ width: "100%" }}
            onChange={onChangeRequestSubType}
          >
            <option value={reqSubTypeValue} disabled>
              {getLabel("drop_down_placeholder", "Choose..", prismicData)}
            </option>
            {reqTypeValue == "" && (
              <>
                {subTypes?.map((item) => (
                  <option key={uuid()} value={item.requestCategoryUid}>
                    {item.categoryDescription}
                  </option>
                ))}
              </>
            )}
          </Select>

          {(action?.actionDisplay == "DRIVER" ||
            action?.actionDisplay === "OPTIONAL") &&
            reqSubTypeValue == "" && (
              <>
                <CustomText color="petrolBlue" size="s" fontWeight="bold">
                  {getLabel(
                    "select_driver_title",
                    "Select Driver",
                    prismicData
                  )}
                  {action?.actionDisplay !== "OPTIONAL" ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    <span
                      style={{
                        color: "#044a5d",
                        fontSize: "10px",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {getLabel(
                        "option_title_of_driver_and_vehicle",
                        "(optional)",
                        prismicData
                      )}
                    </span>
                  )}
                </CustomText>
                <CustomTextHint color="petrolBlue" size="s">
                  {getLabel(
                    "search_driver_by",
                    "Search for driver's name, ID, email, or class",
                    prismicData
                  )}
                </CustomTextHint>
                <Search
                  type="sidePanel"
                  placeHolder={getLabel(
                    "search_for_driver_hint",
                    "Search for driver",
                    prismicData
                  )}
                  name="driver"
                  openSearchResult={() => showDrivers()}
                  onChange={(e) => {
                    handleChange(e);
                    setLoadingSearch({
                      status: true,
                      type: "driver",
                    });
                    e.nativeEvent.inputType == "insertFromPaste"
                      ? setDebouncedValue(e.target.value)
                      : setDebouncedValue("");
                    // setFieldValue("driverAssignedVehicle");
                  }}
                  value={values.driver}
                  prismicData={prismicData}
                  onSearchClear={() => onSearchClear("driver")}
                  error={touched.driver && errors.driver}
                  deleteSearchText={deleteSearchText}
                  disabled={searchDriverDisable}
                  searchLoad={
                    isLoadingSearch.type == "driver" &&
                    values.driver.length > 0 &&
                    isLoadingSearch.status
                  }
                  onKeyDown={(e) => {
                    setDebouncedValue("");
                    searchDriverData(e);
                  }}
                />
                {showSearchedDrivers &&
                  !isEmpty(values.driver) &&
                  isLoadingSearch.type == "driver" &&
                  !isLoadingSearch.status && (
                    <Selectors
                      isMultiple={true}
                      data={searchedDrivers}
                      isSmartSearch
                      isDriver={true}
                      renderItem={renderDriverItems}
                      loadingMore={dataLoading}
                      loadMore={loadMoreEnable == true ? loadMoreSearch : null}
                    />
                  )}
                {isLoadingSearch.type == "driver" &&
                isLoadingSearch.status === true ? (
                  <SearchResult>
                    <p>
                      <small
                        className="loading"
                        style={{
                          display: "flex",
                          color: "#ee4c14",
                          padding: "5px",
                        }}
                      >
                        Loading{" "}
                      </small>
                    </p>
                  </SearchResult>
                ) : null}
              </>
            )}
          {((showSearchedDriversAssignedVehicle &&
            assignedVehicles.length > 0) ||
            values.driverAssignedVehicle) && (
            <>
              <CustomText color="petrolBlue" size="s" fontWeight="bold">
                {getLabel(
                  "select_driver_assigned_vehicle_title",
                  "Driver's assigned vehicles ",
                  prismicData
                )}
                {action?.actionDisplay !== "OPTIONAL" ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  <span
                    style={{
                      color: "#044a5d",
                      fontSize: "10px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {getLabel(
                      "option_title_of_driver_and_vehicle",
                      "(optional)",
                      prismicData
                    )}
                  </span>
                )}
              </CustomText>

              <Search
                type="sidePanel"
                placeHolder={getLabel(
                  "search_for_driver_assigned_vehicle_hint",
                  "Search for  assigned vehicle",
                  prismicData
                )}
                hideSearchIcon={true}
                name="driverAssignedVehicle"
                openSearchResult={() => showDriverAssignedVehicles()}
                onChange={(e) => {
                  searchAssignedVehicles(e.target.value);
                }}
                value={values.driverAssignedVehicle}
                prismicData={prismicData}
                onSearchClear={() => onSearchClear("driverAssignedVehicle")}
                deleteSearchText={deleteSearchText}
                onFocus={() => {
                  setLoadingSearch({
                    status: false,
                    type: "driverAssignedVehicle",
                  });
                  setShowDropDown(true);
                }}
                disabled={searchDriverDisable}
              />

              {showSearchedDriversAssignedVehicle &&
                showDropDown &&
                isLoadingSearch.type == "driverAssignedVehicle" &&
                !isLoadingSearch.status && (
                  <Selectors
                    isMultiple={true}
                    data={driverassignedVehicles}
                    isSmartSearch
                    isDriverAssignedVehicle={true}
                    isVehicle={true}
                    renderItem={renderDriverAssignedVehicleItems}
                    type="assignedVehicles"
                    loadingMore={dataLoading}
                    loadMore={
                      loadMoreEnableAssignedVehicles
                        ? () => loadMoreSearch(2)
                        : null
                    }
                  />
                )}
            </>
          )}
          {(action?.actionDisplay == "VEHICLE" ||
            action?.actionDisplay === "OPTIONAL") &&
            displayVehicle &&
            reqSubTypeValue == "" && (
              <>
                <CustomText color="petrolBlue" size="s" fontWeight="bold">
                  {getLabel(
                    "select_vehicle_title",
                    "Select Vehicle",
                    prismicData
                  )}
                  {action?.actionDisplay !== "OPTIONAL" ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    <span
                      style={{
                        color: "#044a5d",
                        fontSize: "10px",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {getLabel(
                        "option_title_of_driver_and_vehicle",
                        "(optional)",
                        prismicData
                      )}
                    </span>
                  )}
                </CustomText>
                <CustomTextHint color="petrolBlue" size="s">
                  {getLabel(
                    "search_vehicle_by",
                    "Search for vehicle make, model, VIN, unit #, ePlan #, or driver name",
                    prismicData
                  )}
                </CustomTextHint>
                <Search
                  type="sidePanel"
                  placeHolder={getLabel(
                    "search_for_vehicle_hint",
                    "Search for vehicle",
                    prismicData
                  )}
                  name="vehicle"
                  onChange={(e) => {
                    handleChange(e);
                    setLoadingSearch({
                      status: true,
                      type: "vehicle",
                    });
                    e.nativeEvent.inputType == "insertFromPaste"
                      ? setDebouncedValue(e.target.value)
                      : setDebouncedValue("");
                  }}
                  value={values.vehicle}
                  onSearchClear={() => onSearchClear("vehicle")}
                  openSearchResult={() => {
                    showVehicles();
                  }}
                  error={touched.vehicle && errors.vehicle}
                  prismicData={prismicData}
                  deleteSearchText={deleteSearchText}
                  disabled={searchVehicleDisable}
                  searchLoad={
                    isLoadingSearch.type == "vehicle" &&
                    values.vehicle.length > 0 &&
                    isLoadingSearch.status
                  }
                  onKeyDown={(e) => {
                    setDebouncedValue("");
                    searchVehicleData(e);
                  }}
                  onClick={(e) => {
                    setShowSearchedVehicles(true);
                  }}
                />

                {showSearchedVehicles &&
                  !isEmpty(values.vehicle) &&
                  isLoadingSearch.type == "vehicle" &&
                  !isLoadingSearch.status && (
                    <>
                      <Selectors
                        isMultiple={true}
                        isVehicle={true}
                        showDriverName={true}
                        data={searchedVehicles}
                        isSmartSearch
                        renderItem={renderVehicleItems}
                        loadingMore={dataLoading}
                        loadMore={
                          loadMoreEnable == true
                            ? () => loadMoreSearch(1)
                            : null
                        }
                      />
                    </>
                  )}
                {isLoadingSearch.type == "vehicle" &&
                isLoadingSearch.status === true ? (
                  <SearchResult>
                    <p>
                      <small
                        className="loading"
                        style={{
                          display: "flex",
                          color: "#ee4c14",
                          padding: "5px",
                        }}
                      >
                        Loading{" "}
                      </small>
                    </p>
                  </SearchResult>
                ) : null}
              </>
            )}
          {displaySelectedDriver === true ? (
            <SelectedDriverCard
              selectedDriver={selectedDriverData}
              name="driver"
              driverIcon={getDriverIcon}
              displayCard={displaySelectedDriver}
              deleteSearchText={clearSelectedEntityCard}
              enableUndo={selectedDriverRecord.length > 1 ? true : false}
              undoDriver={undoActionOnDriver}
            />
          ) : null}
          {displaySelectedEntity === true ? (
            <SelectedVehicleCard
              selectedVehicle={selectedVehicleData}
              name="vehicle"
              vehicleIcon={getVehicleIcon}
              displayCard={displaySelectedEntity}
              deleteSearchText={clearSelectedEntityCard}
              enableUndo={selectedVehicleRecord.length > 1 ? true : false}
              undoVehicle={undoActionOnVehicle}
            />
          ) : null}
          {action?.actionAction == "CREATE_CASE" && reqSubTypeValue == "" ? (
            <>
              <CustomText color="petrolBlue" size="s" fontWeight="bold">
                {getLabel(
                  "description_input_title",
                  "Describe what's going on",
                  prismicData
                )}
                <span style={{ color: "red" }}>*</span>
              </CustomText>

              <TextArea
                name="description"
                value={values.description}
                onChange={onChangeComment}
                className="customScroller"
                placeholder={getLabel(
                  "description_place_holder",
                  "Enter a description of 5 characters or more",
                  prismicData
                )}
                maxLength="4000"
              />
              {/* <RichTextEditor
                value={richTextValue}
                onChange={OnChangeRichText}
                toolbarConfig={toolbarConfig}
                className="RichTextEditor"
              /> */}
              {touched.description && errors.description && (
                <Text size="xs" style={{ color: "red" }}>
                  {errors.description}
                </Text>
              )}
              <Wrapper>
                <div
                  onClick={() => setAttachmentClicked(true)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <AttachFileIcon htmlColor={Colors.steel40} />
                  <AttachmentText>
                    {getLabel(
                      "slider_add_attachment",
                      "Add Attachment",
                      prismicData
                    )}
                  </AttachmentText>
                </div>
              </Wrapper>
              {attachmentClicked && (
                <>
                  <FileUpload
                    onFileUpload={onFileUpload}
                    fileUploadInProgress={fileUploadInProgress}
                    deleteFiles={deleteFile}
                    showPopupWarning={props.showPopupWarning}
                    fileDeletionInProgress={fileDeletionInProgress}
                  />
                </>
              )}
            </>
          ) : null}
          <CustomText color="petrolBlue" size="s">
            <span style={{ color: "red", marginTop: 10, marginLeft: 10 }}>
              {getLabel(
                "mandatory_field_message",
                "* required fields",
                prismicData
              )}
            </span>
          </CustomText>
          <ButtonWrapper>
            {/* <CancelButton variant="secondary" onClick={() => handleSidePanel()}>
              {getLabel("cancel_button_title", "Cancel", prismicData)}
            </CancelButton> */}
            {externalLink ? (
              <ExternalLink handleSubmit={handleSubmit} />
            ) : (
              <ContinueButton
                size="s"
                onClick={() =>
                  !uploadInProgress && !deletionInProgress
                    ? (dispatch(hideToaster()), handleSubmit())
                    : uploadInProgress
                    ? dispatch(
                        showToaster({
                          type: "WARNING",
                          message: getLabel(
                            "file_upload_in_progress",
                            "File upload in progress",
                            prismicData
                          ),
                        })
                      )
                    : dispatch(
                        showToaster({
                          type: "WARNING",
                          message: getLabel(
                            "file_deletion_in_progress_warning",
                            "File deletion in progress",
                            prismicData
                          ),
                        })
                      )
                }
                disabled={reqSubTypeValue == "" ? false : true}
              >
                {getLabel("send_button_title", "Send", prismicData)}
              </ContinueButton>
            )}
          </ButtonWrapper>
          <NavigateConfirmation
            anchorEl={anchorEl}
            open={anchorEl}
            handleClose={handleClose}
            handleHideSidePanel={hideSidePanel}
            isLinkedReq={false}
          />
        </Grid>
      ) : (
        <Container>
          <RequestConfirmation
            requestId={caseNumber}
            requestIDref={requestIDref}
            fileUploadFailed={fileUploadFailed}
          />
        </Container>
      )}
    </DrawerInsideContainer>
  );
};
const DrawerInsideContainer = styled.div`
  margin: ${Spacing.xxl};
  margin-top: ${Spacing.s};
`;
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.s};
  font-size: 12px;
`;
const CustomTextHeader = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.s};
`;

const CustomTextHint = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${ConvertSize(-6)};
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${Colors.gallery};
  margin-top: ${Spacing.m};
  margin-left: -${Spacing.m};
  margin-right: -${Spacing.m};
`;

const ContinueButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  font-size: 12px;
  margin-top: ${Spacing.m};
`;

const CancelButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  margin-top: ${Spacing.m};
  font-size: 12px;
  border: 1px solid ${Colors.petrolBlue};
  color: ${Colors.petrolBlue};
  :hover {
    color: ${Colors.white};
    background-color: ${Colors.petrolBlue};
  }
`;

const TextContainer = styled.div`
  margin-left: ${Spacing.s};
  margin-top: ${Spacing.xxs};
  justify-content: space-between;
  margin-right: ${Spacing.s};
  display: flex;
  cursor: pointer;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

const Wrapper = styled.div`
  margin-top: ${Spacing.l};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const AttachmentText = styled.label`
  color: ${Colors.steel40};
  margin-right: ${Spacing.s};
  cursor: pointer;
`;

const TextArea = styled(Textarea)`
  margin-left: ${ConvertSize(2)};
  margin-bottom: ${Spacing.xxs};
  border-color: ${Colors.cancelled};
  border-radius: 4px;
  font-family: LeasePlan;
`;

const Header = styled.div`
  height: ${Spacing.xl};
  margin-left: -${Spacing.m};
  margin-right: -${Spacing.m};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseIconButton = styled(CloseIcon)`
  cursor: pointer;
  color: ${Colors.midOrange};
  font-size: ${ConvertSize(25)};
  margin-right: ${Spacing.xs};
  margin-top: ${Spacing.s};
`;
const SearchResult = styled.div`
  background-color: ${Colors.white};
  border-radius: ${Spacing.xxxs};
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
  min-height: ${ConvertSize(25)};
  width: 100%;
  overflow: auto;
  font-family: LeasePlan;
`;

// const RichTextEditors = styled(RichTextEditor)`
//   max-height: 300px !important;
//   overflow: scroll !important;
// `;

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(NewRequest);
