import { createGlobalStyle } from "styled-components";
import { Colors, Spacing, ConvertSize } from "./utils/theme";

export default createGlobalStyle`
html,
body,
#app {
	min-height: 100vh;
	background-color: #f6f8fb;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: auto; /* Firefox 64 */

}

* {
  margin: 0;
  font-family: "LeasePlan", Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#app {
	background: #f6f8fb;
}

.MuiTabPanel-root::-webkit-scrollbar {
  display:none;
}
.px-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
 }
button{
  :focus {
    outline: 0 !important;
  }
}
.RichTextEditor .public-DraftEditor-content {
  height: ${ConvertSize(150)};
}
.ag-center-cols-container {
  width: 100% !important;
}

.customScroller::-webkit-scrollbar {
  width: 10px;
  display: inline;

}

.customScroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customScroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.customScrollerSearch::-webkit-scrollbar {
  width: 3px;
  display: inline;
  border-radius: 50px;

}

.customScrollerSearch::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

.customScrollerSearch::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.MuiDrawer-root .MuiDrawer-paperAnchorRight::-webkit-scrollbar {
  width: 10px;
  display: inline;

}

.MuiDrawer-root .MuiDrawer-paperAnchorRight::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.MuiDrawer-root .MuiDrawer-paperAnchorRight::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.RichTextEditor .public-DraftEditor-content::-webkit-scrollbar {
  width: 5px;
  display: inline;

}

.RichTextEditor .public-DraftEditor-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.RichTextEditor .public-DraftEditor-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.MuiInputBase-input {
  font-family: "LeasePlan" !important;
}
.MuiPaper-root-MuiAppBar-root {
  width: 1440px;
height: 54px;
fill: #ffffff;
box-shadow: 0px 1px 4px rgba(0,0,0,0.1) !important;
}
.react-contextmenu-wrapper::-webkit-scrollbar {
  display:none;
}
.react-contextmenu-wrapper{
  overflow:scroll;
  scrollbar-width: none;
  
}
.react-contextmenu-wrapper span::-webkit-scrollbar{
  display:none;
}
#navigation-popup .MuiBackdrop-invisible + div {
  background: #dedede;
  width: 35%;
  height: 100vh;
  position: absolute;
  right: 0;
  opacity: 0.6;
}
#navigation-popup .MuiPaper-root {
  top: 30% !important;
  left: 73% !important;
}

#cancel-request-popup .MuiBackdrop-invisible + div {
  background: #dedede;
  width: 100%;
  height: 100vh;
  position: absolute;
  right: 0;
  opacity: 0.6;
}
@media (min-width: 1025px) {
  #cancel-request-popup .MuiBackdrop-invisible + div {
    width: 35% !important;

  }
}
@media (min-width: 768px) {
  #cancel-request-popup .MuiBackdrop-invisible + div {
    width: 50%;
  }
}
#cancel-request-popup .MuiPaper-root {
  top: 20% !important;
  left: 70% !important;
  height: 65vh;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;
