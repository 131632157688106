import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Heading, Text, TextInput, Button as LPbutton } from "@leaseplan/ui";

import { Colors, ConvertSize, Spacing } from "../../../utils/theme";
import dompurify from "dompurify";
import { getLabel } from "../../../utils/prismicUtils";
import uuid from "react-uuid";

const VIN = ({
  vinSearch,
  onClickSearch,
  searchText,
  _getStyledSuggestion,
  prismicData,
}) => {
  const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  const sanitizer = dompurify.sanitize;
  return (
    <>
      <Table>
        <THead>
          <TH>
            <ThWrapper>
              {getLabel("vin_search_header_title", "VIN", prismicData)}
            </ThWrapper>
          </TH>
          <TH>
            <ThWrapper>
              {getLabel(
                "client_unit_search_header_title",
                "Client Unit",
                prismicData
              )}
            </ThWrapper>
          </TH>
          <TH>
            <ThWrapper>
              {getLabel(
                "eplan_unit_search_vehicle_header_title",
                "Vehicle",
                prismicData
              )}
            </ThWrapper>
          </TH>
        </THead>
        <TBody>
          {vinSearch?.map((item, index) => {
            return (
              <TR key={uuid()} index={index} onClick={onClickSearch(item?.vin)}>
                <>
                  <TD
                    key={uuid()}
                    index={index + 1}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(
                        `${item.vin
                          .toString()
                          .split(new RegExp(regEscape(searchText), "ig"))
                          .join(
                            "<b>" + searchText.toUpperCase() + "</b>"
                          )} <br> `
                      ),
                    }}
                  >
                    {/* {" "}
										{_getStyledSuggestion(item.vin.toString(), searchText)} */}
                  </TD>
                  <TD key={uuid()} index={index + 1}>
                    {item?.clientUnitNumber}
                  </TD>
                  <TD key={uuid()} index={index + 1}>
                    {item?.vehicle}
                  </TD>
                </>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </>
  );
};

export default VIN;

const TBody = styled.tbody``;
const TD = styled.td`
  max-width: ${ConvertSize(121)};
  font-size: ${ConvertSize(12)};
  text-align: left;
  color: ${Colors.petrolBlue};
`;
const TH = styled.th`
  height: ${ConvertSize(15)};
  width: ${ConvertSize(352)};
`;

const ThWrapper = styled(Text)`
  display: flex;
  padding-top: 2px;
  font-size: ${ConvertSize(10)};
  text-align: left;
  font-weight: bold;
  color: ${Colors.steel60};
  margin-left: -5px;
`;

const TR = styled.tr`
  background-color: ${(props) => props.read && Colors.viewedRow};

  :hover {
    cursor: pointer;
  }
`;
const THead = styled.thead`
  ${TR} {
    background-color: ${Colors.tableHeader};
  }
`;
const CustomText = styled(Text)`
  font-size: ${ConvertSize(12)};
`;

const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`;
