import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, Button, Color, TextInput } from "@leaseplan/ui";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import { getLabel } from "../../utils/prismicUtils";

import SearchIcon from "@mui/icons-material/Search";
import "./CategoryFilter.scss";
const CategoryFilter = ({
  anchorEl,
  handleClose,
  open,
  filterData,
  updateSearchQuery,
  removeSearchQuery,
  categoryArray,
  prismicData,
  allData,
  subcategoryArray,
}) => {
  const [isChecked, setIsChecked] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isCheckedApplied, setIsCheckedApplied] = useState(false);
  const [disabledCategory, setDisabledCategory] = useState([]);
  const onClickCheckBox = (uniqueId) => () => {
    setIsChecked((prev) => {
      return { ...prev, [uniqueId]: !(prev[uniqueId] || false) };
    });
  };
  useEffect(() => {
    let data = [];
    if (subcategoryArray != undefined || subcategoryArray?.length != 0) {
      allData?.categories.map((items, i) => {
        allData["subcategory_" + items.requestCategoryUid]?.map((item) => {
          subcategoryArray?.map((category) => {
            if (item.categoryDescription === category) {
              setIsChecked((prev) => {
                return {
                  ...prev,
                  [items.categoryDescription]: true,
                };
              });
            }
          });
        });
      });

      if (categoryArray && categoryArray.length > 0)
        data = [...data, ...categoryArray];
      data = data.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) == index;
      });
    }
    setDisabledCategory(data);
  }, [subcategoryArray]);
  useEffect(() => {
    if (categoryArray) {
      categoryArray?.length > 0 &&
        setIsChecked(
          categoryArray?.reduce((acc, item) => {
            return { ...acc, [item]: true };
          }, {})
        );
    }
  }, [open]);

  const onChangeTextInput = (event) => {
    setSearchText(event.target.value);
  };
  const onClearSearch = () => {
    setSearchText("");
  };
  useEffect(() => {
    if (categoryArray === undefined || categoryArray?.length == 0) {
      setIsChecked({});
    }
  }, [categoryArray]);
  // useEffect(() => {
  //   if (Object.values(isChecked).find((i) => i) == undefined) {
  //     removeSearchQuery("category");
  //     handleClose();
  //   }
  // }, [isChecked]);
  useEffect(() => {
    if (!isCheckedApplied) {
      setIsChecked({});
    }
  }, [isCheckedApplied]);
  const isAnyOneSelected = Object.values(isChecked).find((i) => i);

  const onApplyFilter = () => {
    const checkedValues = Object.entries(isChecked).filter((i) => i[1]);
    setIsCheckedApplied(true);
    const query = checkedValues.reduce(
      (acc, item, index) => `${item[0].replace(",", ",")};${acc}`,
      ""
    );

    let category = [];
    checkedValues.map((cat) => category.push(cat[0]));

    if (
      subcategoryArray &&
      subcategoryArray.length > 0 &&
      subcategoryArray !== undefined
    ) {
      let subCaterequid;
      let index;
      let filtercatarr = [];
      let filtersubcatarr = [];

      filtercatarr = allData.categories.filter((item) =>
        category.includes(item.categoryDescription)
      );
      filtercatarr?.map((items, i) => {
        allData["subcategory_" + items.requestCategoryUid]?.map((item) => {
          filtersubcatarr.push(item.categoryDescription);
        });
      });

      subcategoryArray = filtersubcatarr.filter((item) =>
        subcategoryArray.includes(item)
      );
      const subcategoryquery = subcategoryArray.join(";");

      updateSearchQuery({
        pageNumber: 1,
        category: query,
        subCategory: subcategoryquery,
      });
      handleClose();
    } else {
      updateSearchQuery({
        pageNumber: 1,
        category: query,
      });
      handleClose();
    }
  };
  // const onClickClear = () => {
  //   removeSearchQuery(["category"]);
  //   categoryArray = [];
  //   if (subcategoryArray !== undefined || subcategoryArray?.length != 0) {
  //     removeSearchQuery(["subCategory"]);
  //     subcategoryArray = [];
  //   }
  //   categoryArray?.length > 0 &&
  //     setIsChecked(
  //       categoryArray?.reduce((acc, item) => {
  //         return { ...acc, [item]: false };
  //       }, {})
  //     );
  //   handleClose();
  // };
  const onClickClear = () => {
    removeSearchQuery(["subCategory"]);
    removeSearchQuery(["category"]);
    setDisabledCategory([]);

    categoryArray?.length > 0 &&
      setIsChecked(
        categoryArray?.reduce((acc, item) => {
          return { ...acc, [item]: false };
        }, {})
      );
    handleClose();
  };
  const handleCloseView = () => {
    if (!isCheckedApplied) {
      setIsChecked({});
    }
    handleClose();
  };
  var reg = new RegExp(searchText, "gi");

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleCloseView}
      PaperProps={{
        elevation: 0,
        sx: {
          padding: "10px 20px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 200,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <TextInputWrapper>
        <SearchIcon htmlColor={Colors.petrolBlue} />
        <CustomTextInput
          name="search"
          value={searchText}
          autoComplete="off"
          onChange={(e) => onChangeTextInput(e)}
          placeHolder={getLabel(
            "i_need_help_with_place_holder",
            "Search help categories",
            prismicData
          )}
        />
        <Image
          src={prismicData?.close_icon?.url}
          onClick={() => onClearSearch()}
        />
      </TextInputWrapper>
      <SearchWrapper>
        <SearchResults className="customScrollerSearch">
          <FormGroup>
            {filterData
              ?.filter((item) =>
                item.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((item) => {
                return (
                  <FormControlLabel
                    sx={{
                      padding: 1.2,
                    }}
                    disabled={
                      disabledCategory && disabledCategory.length > 0
                        ? disabledCategory && disabledCategory.includes(item)
                          ? false
                          : true
                        : false
                    }
                    control={
                      <CustomCheckbox
                        checked={isChecked[item] || false}
                        onChange={onClickCheckBox(item)}
                        size="medium"
                        style={{
                          color: Colors.petrolBlue,
                        }}
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.petrolBlue,
                          },
                        }}
                      />
                    }
                    label={
                      <Text
                        size="xs"
                        color="petrolBlue"
                        style={{ marginLeft: 10 }}
                        dangerouslySetInnerHTML={{
                          __html: `${item
                            ?.toString()
                            .replace(reg, function (str) {
                              return "<b>" + str + "</b>";
                            })}`,
                        }}
                      ></Text>
                    }
                  />
                );
              })}
          </FormGroup>
        </SearchResults>
        <span style={{ float: "right" }}>
          <CustomText
            color="petrolBlue"
            size="xs"
            fontWeight="bold"
            style={{ marginRight: "20px" }}
            onClick={onClickClear}
          >
            {getLabel("clear_all_title", "Clear All", prismicData)}{" "}
          </CustomText>
          <Button
            variant="secondary"
            size="s"
            disabled={!isAnyOneSelected}
            onClick={onApplyFilter}
            style={{
              color: isAnyOneSelected ? Colors.petrolBlue : Colors.lightGrey,
              borderColor: isAnyOneSelected
                ? Colors.petrolBlue
                : Colors.lightGrey,
              maxHeight: "22px",
              minWidth: "80px",
            }}
          >
            {getLabel("apply_filter_title", "Apply", prismicData)}{" "}
          </Button>
        </span>
      </SearchWrapper>
    </Menu>
  );
};

export default CategoryFilter;

const CustomCheckbox = styled(Checkbox)`
  padding: 5px;
  :hover {
    color: ${Colors.petrolBlue};
    background-color: transparent;
  }
`;
const CustomText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;
const TextInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Colors.steel20};
  height: ${ConvertSize(40)};
  font-size: ${ConvertSize(14)};
`;
const CustomTextInput = styled(TextInput)`
  margin: 2px;
  border-color: transparent;
  border-radius: ${Spacing.xxxs};
  height: ${ConvertSize(38)};
  font-size: ${ConvertSize(14)};
  box-shadow: none;
  :focus {
    border-color: transparent;
    box-shadow: none;
  }
  width: ${ConvertSize(280)};
`;

const Image = styled.img`
  height: ${ConvertSize(12)};
  width: ${ConvertSize(11)};
`;
const SearchResults = styled.div`
  margin-bottom: 0;
  padding-bottom: 0;
  max-height: ${ConvertSize(164)};
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchWrapper = styled.div`
  margin-top: ${Spacing.xxxs};
  margin-bottom: ${Spacing.xxs};
  align-items: flex-start;
  justify-content: space-between;
  width: ${ConvertSize(340)};
`;
