import React from "react";
import styled from "styled-components";
import { Colors, ConvertSize } from "../../../utils/theme";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Text } from "@leaseplan/ui";
import Cancelled from "./Cancelled";
import Completed from "./Completed";
import Pending from "./Pending";
import New from "./New";
import Sending from "./Sending";
import InProgress from "./InProgress";
import InReview from "./InReview";
const StatusTypeHover = ({ status, statusDescription }) => {
  const returnComponent = (Status) => {
    switch (Status) {
      case "New":
        return <New />;
      case "In Progress":
        return <InProgress />;
      case "Completed":
        return <Completed />;
      case "Cancelled":
        return <Cancelled />;
      case "Pending":
        return <Pending />;
      case "Sending":
        return <Sending />;
      case "In Review":
        return <InReview />;
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: Colors.midOrange,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Colors.midOrange,
    },
  }));
  return (
    <>
      {status === "Pending" ||
      status === "Cancelled" ||
      status === "Sending" ||
      status === "In Progress" ? (
        <BootstrapTooltip
          title={
            <>
              <Text size="xs" color="white">
                {statusDescription}
              </Text>
            </>
          }
          arrow
          placement="top"
        >
          <NewConatiner>{returnComponent(status)}</NewConatiner>
        </BootstrapTooltip>
      ) : (
        <NewConatiner>{returnComponent(status)}</NewConatiner>
      )}
    </>
  );
};

export default StatusTypeHover;

const NewConatiner = styled.div`
  width: ${ConvertSize(61)};
  height: ${ConvertSize(15)};
  border-radius: ${ConvertSize(2)};
  //   border: 1px solid ${Colors.petrolBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
`;
