import React from "react";
import { useEffect, useState } from "react";

import styled from "styled-components";
import {
  Text,
  Button,
  Spacing,
  Grid,
  GridItem,
  Textarea,
  Select,
  Button as LPbutton,
  TextInput,
} from "@leaseplan/ui";
import Menu from "@mui/material/Menu";
import carReturn from "../../../assets/images/carReturn.svg";
import { Colors, ConvertSize } from "../../../utils/theme";
import {
  setLegacyCommand,
  showSidePanel,
} from "../../../store/commonStore/commonActionCreators";
import { useSelector, useDispatch } from "react-redux";
import { hideSidePanel } from "../../../store/commonStore/commonActionCreators";
import { getLabel } from "../../../utils/prismicUtils";
import { connect } from "react-redux";
import { deleteAttachment } from "../../../store/request/requestApi";
import { PropertyKeys } from "ag-grid-community";
import checkCar from "../../../assets/images/checkCar.svg";
import cancelRequestTransportation from "../../../assets/images/cancelRequestTransportation.svg";

import cancelRequestImage from "../../../assets/images/cancelRequest.svg";
import Loading from "../../Loading";
import "./NavigateConfirmation.scss";
import { storeFileIds } from "../../../store/request/requestActions";
const NavigateConfirmation = ({
  anchorEl,
  handleClose,
  open,
  isLinkedReq,
  hideSidePanel,
  handleHideSidePanel,
  fileFlag,
  clearFiles,
  details,
  cancelReq,
  setCancelRequest,
  CancelRequestStatus,
  setCancelStatus,
  CancelStatus,
  cancelRequestID,
  isCommentsLoading,
  requestDetails,
  requestStatus,
  status,
}) => {
  const { requestId, requestType } = useSelector((state) => state.common);
  const { files } = useSelector((state) => state.request);
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const [filesSaved, setFiles] = useState(details);
  const [commentValue, setCommentValue] = useState("");
  const [showError, setShowError] = useState(false);
  const OnChangeRichText = (event) => {
    setCancelStatus(event.value);
    let hasText = commentValue.replace(/^\s+|\s+$/gm, "").length;
    if (hasText >= 5) setShowError(false);
    else setShowError(true);
  };

  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );

  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );

  const dispatch = useDispatch();

  const onClickProceed = () => {
    if (isLinkedReq === true) {
      handleClose();
      dispatch(
        showSidePanel({
          type: "Ticket",
          requestId: requestId,
          requestType: requestType,
        })
      );
    } else if (isLinkedReq === false) {
      if (fileFlag) {
        clearFiles();
        hideSidePanel();
      }
      if (files) {
        clearFilesAttached();
        hideSidePanel();
      }
      hideSidePanel();
    }
    dispatch(setLegacyCommand({ legacyCommand: null }));
  };
  const clearFilesAttached = async (e) => {
    if (files.length !== 0) {
      let deleteFiles = [];
      files.forEach(function (id) {
        deleteFiles.push({
          id,
        });
      });
      const deleteFileRes = await deleteAttachment(deleteFiles);
      dispatch(storeFileIds([]));
    }
  };
  const onClickCancel = () => {
    handleClose(1);
    setCancelStatus && setCancelStatus("");
    dispatch(setLegacyCommand({ legacyCommand: null }));
  };

  const cancelRequest = () => {
    if (files.length > 0) {
      clearFilesAttached();
    }
    cancelRequestID();
  };

  return (
    <>
      <div></div>
      <Menu
        id={cancelReq ? "cancel-request-popup" : "navigation-popup"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        //onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: "10px 20px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            alignSelf: "center",
            width: ConvertSize(385),
            height: ConvertSize(280),
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,

              width: 10,
              height: 10,

              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {cancelReq ? (
          isCommentsLoading ? (
            <Loading type="cancelReq" />
          ) : (
            <div style={{ paddingBottom: "10px" }}>
              {CancelRequestStatus ? (
                <RequestCanceled>
                  <Grid style={{ justifyContent: "center !important" }}>
                    <GridItem span={12}>
                      <div style={{ textAlign: "center" }}>
                        {(clientSettingValue === "T" ||
                          clientSettingValue === "B") &&
                        (requestDetails === "Book a transportation move" ||
                          requestDetails ===
                            "Request a transportation quote") &&
                        status !== "Sending" ? (
                          <>
                            <img src={cancelRequestTransportation}></img>
                            <BoldHeading>
                              {getLabel(
                                "requested_transportation_cancelled_confirmation_title1",
                                "Request Sent!",
                                prismicData
                              )}
                            </BoldHeading>
                            <CancelSubHead>
                              {getLabel(
                                "requested_transportation_cancelled_confirmation_message1",
                                "We will work to determine the current status of the transportation move and respond accordingly.",
                                prismicData
                              )}
                            </CancelSubHead>
                          </>
                        ) : (
                          <>
                            <img src={checkCar}></img>
                            <BoldHeading>
                              {getLabel(
                                "requested_cancelled_confirmation_title",
                                "Request Cancelled!",
                                prismicData
                              )}
                            </BoldHeading>
                            <CancelSubHead>
                              {getLabel(
                                "requested_cancelled_confirmation_message",
                                "Your request has been cancelled successfully.",
                                prismicData
                              )}
                            </CancelSubHead>
                          </>
                        )}
                      </div>
                    </GridItem>
                    <GridItem span={12}>
                      <div style={{ textAlign: "center" }}>
                        <CustomButton
                          style={{ width: "20em" }}
                          onClick={() => handleHideSidePanel({ type: 1 })}
                        >
                          {getLabel(
                            "back_to_workspace_text",
                            "Back to Workspace",
                            prismicData
                          )}
                        </CustomButton>
                      </div>
                    </GridItem>
                  </Grid>
                </RequestCanceled>
              ) : (
                <Grid>
                  {(clientSettingValue === "T" || clientSettingValue === "B") &&
                  (requestDetails === "Book a transportation move" ||
                    requestDetails === "Request a transportation quote") ? (
                    <GridItem>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={cancelRequestImage}
                          alt="cancelRequestImage"
                        ></img>
                        <BoldHeading>
                          {getLabel(
                            "transportation_cancel_request_confirmation_title",
                            "Do you want to cancel this transportation request? ",
                            prismicData
                          )}
                        </BoldHeading>
                        <CancelSubHead>
                          <strong>Note - </strong>
                          {getLabel(
                            "transportation_cancel_request_confirmation_description",
                            " Depending on the status of this transport, we cannot guarantee cancelation.",
                            prismicData
                          )}
                        </CancelSubHead>
                      </div>
                    </GridItem>
                  ) : (
                    <GridItem>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={cancelRequestImage}
                          alt="cancelRequestImage"
                        ></img>
                        <BoldHeading>
                          {getLabel(
                            "cancel_request_confirmation_title",
                            "Do you want to cancel this request",
                            prismicData
                          )}
                        </BoldHeading>
                        <CancelSubHead>
                          {getLabel(
                            "cancel_request_confirmation_description",
                            "Canceling a request will stop all active work being performed by a LeasePlan Agent.",
                            prismicData
                          )}
                        </CancelSubHead>
                      </div>
                    </GridItem>
                  )}

                  {(clientSettingValue === "T" || clientSettingValue === "B") &&
                  (requestDetails === "Book a transportation move" ||
                    requestDetails === "Request a transportation quote") ? (
                    <ProvideRequired>
                      <small>
                        Provide Reason{" "}
                        <span style={{ color: Colors.midOrange }}>
                          (Required)
                        </span>{" "}
                      </small>
                    </ProvideRequired>
                  ) : (
                    <SelectRequired>
                      <small>
                        <strong>Select Reason (Required)</strong>
                      </small>
                    </SelectRequired>
                  )}

                  <GridItem span={12}>
                    {(clientSettingValue === "T" ||
                      clientSettingValue === "B") &&
                    (requestDetails === "Book a transportation move" ||
                      requestDetails === "Request a transportation quote") &&
                    requestStatus !== "Sending" ? (
                      <TextArea
                        value={CancelStatus}
                        onChange={(e) => OnChangeRichText(e)}
                        className="customScroller"
                        placeholder={getLabel(
                          "comment_place_holder",
                          "Enter a reply of 5 or more characters",
                          prismicData
                        )}
                        maxLength="4000"
                      />
                    ) : (
                      <Select
                        value={CancelStatus}
                        onChange={(e) => setCancelStatus(e.value)}
                      >
                        <option value="" selected>
                          {getLabel(
                            "cancel_request_drop_down_title",
                            "Select One",
                            prismicData
                          )}
                        </option>
                        <option value="DUPLICATE">
                          {getLabel(
                            "cancel_request_drop_down_duplicate_option",
                            "Duplicate",
                            prismicData
                          )}
                        </option>

                        <option value="NOT_AN_ISSUE">
                          {getLabel(
                            "cancel_request_no_longer_needed_option",
                            "No Longer Needed",
                            prismicData
                          )}
                        </option>
                      </Select>
                    )}
                  </GridItem>
                  <FlexEnd>
                    <CustomBackButton
                      size="s"
                      className="no"
                      variant="secondary"
                      onClick={onClickCancel}
                    >
                      No, Go Back
                    </CustomBackButton>
                    <CustomButton
                      size="s"
                      className="yes"
                      onClick={cancelRequest}
                      disabled={
                        (CancelStatus == "Default" ||
                          CancelStatus == "" ||
                          CancelStatus?.replace(/^\s+|\s+$/gm, "")?.length <=
                            5) &&
                        "disabled"
                      }
                    >
                      Yes, Cancel
                    </CustomButton>
                  </FlexEnd>
                </Grid>
              )}
            </div>
          )
        ) : (
          <Grid alignItems="center" direction="column">
            <CustomGridItem>
              <img src={prismicData?.carreturn?.url}></img>
            </CustomGridItem>
            <CustomGridItem>
              <CustomHeading size="s" fontWeight="bold">
                {getLabel(
                  "navigation_confirmation_title",
                  "Are you sure ?",
                  prismicData
                )}
              </CustomHeading>
            </CustomGridItem>
            <CustomCenterGridItem>
              <CustomHeading size="s">
                {getLabel(
                  "navigation_confirmation_description",
                  "If you close the Request, all of your inputs will be lost.",
                  prismicData
                )}
              </CustomHeading>
            </CustomCenterGridItem>
            <CustomGridItem className="containerForButtons">
              <ButtonCustom
                className="no"
                onClick={onClickCancel}
                variant="secondary"
                size="s"
              >
                {getLabel(
                  "navigation_confirmation_cancel_button_title",
                  "No, Go Back",
                  prismicData
                )}
              </ButtonCustom>
              <Button className="yes" size="s" onClick={onClickProceed}>
                {getLabel(
                  "navigation_confirmation_proceed_button_title",
                  "Yes, Cancel",
                  prismicData
                )}
              </Button>
            </CustomGridItem>
          </Grid>
        )}
      </Menu>
    </>
  );
};

const ButtonCustom = styled(Button)`
  color: ${Colors.petrolBlue};
  border-color: ${Colors.petrolBlue};
  font-weight: bold;
  align-self: center;
  align-items: center;
  align-content: center;
  margin-right: 10px;
`;

const CustomGridItem = styled(GridItem)`
  padding: 0 !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
`;

const CustomCenterGridItem = styled(GridItem)`
  padding: 0 !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: center;
`;

const CustomLabel = styled.label`
  color: ${Colors.petrolBlue};
  font-size: 14px;
  font-weight: bold;
  position: relative;
  top: -10px;
`;
const CustomHeading = styled(Text)`
  color: ${Colors.petrolBlue};
  font-size: 14px;
`;

const RequestCanceled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BoldHeading = styled.p`
  color: #014a5d;
  font-size: ${ConvertSize(18)};
  font-weight: bold;
  margin-top: ${ConvertSize(12)};
`;
const CancelSubHead = styled.p`
  color: #4a4a4a;
  font-size: ${ConvertSize(14)};
  margin-top: ${ConvertSize(12)};
  // margin-bottom: ${ConvertSize(36)};
`;
const CustomButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(160)};
  align-self: flex-end;
`;
const FlexEnd = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  bottom: 25px;
  border-top: 1px solid #eeeeee;
  margin-top: 20px;
  padding-top: 20px;
`;
const CustomBackButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(170)};
  align-self: flex-end;
  margin-top: ${Spacing.xxl};
  border: 1px solid ${Colors.petrolBlue};
  color: ${Colors.petrolBlue};
  :hover {
    color: ${Colors.white};
    background-color: ${Colors.petrolBlue};
  }
`;
const SelectRequired = styled.p`
  margin-top: 15px;
  color: #014a5d;
  text-align: center;
  width: 100%;
`;
const ProvideRequired = styled.p`
  margin-top: 15px;
  color: #014a5d;
  text-align: left;
  width: 100%;
  margin-left: 12px;
`;

const TextArea = styled(Textarea)`
  margin-left: ${ConvertSize(2)};
  margin-bottom: ${Spacing.xxs};
  border-color: ${Colors.cancelled};
  border-radius: 4px;
  font-family: LeasePlan;
`;

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(
  NavigateConfirmation
);
