import React from "react";
import styled from "styled-components";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import markAsRead from "../../assets/images/markAsRead.svg";
import markAsUnread from "../../assets/images/markAsUnread.svg";
import { Colors } from "../../utils/theme";
import { Spacing } from "@leaseplan/ui";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Colors.midOrange,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.midOrange,
    padding: "10px",
    paddingRight: "25px",
    paddingLeft: "25px",
    fontSize: "12px",
  },
}));

const MarkAsReadUnRead = ({ readCount, onClick, unReadCount }) => {
  const MarkAsRead = `Mark ${readCount} as read`;
  const MarkAsUnread = `Mark ${unReadCount} as unread`;
  return (
    <IconsWrapper>
      <BootstrapTooltip
        title={MarkAsRead}
        arrow
        placement="top"
        onClick={() => onClick(true)}
      >
        <Image1 src={markAsRead} />
      </BootstrapTooltip>
      <BootstrapTooltip
        title={MarkAsUnread}
        arrow
        placement="top"
        onClick={() => onClick(false)}
      >
        <Image2 src={markAsUnread} />
      </BootstrapTooltip>
    </IconsWrapper>
  );
};

export default MarkAsReadUnRead;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

const Image1 = styled.img`
  width: 21px;
  height: 17px;
  margin-right: 15px;
  cursor: pointer;
`;

const Image2 = styled.img`
  width: 21px;
  height: 17px;
  margin-bottom: 3px;
  cursor: pointer;
`;
