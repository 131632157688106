import React from "react";
import styled from "styled-components";
import { Text } from "@leaseplan/ui";
import { Icon } from "@velocity/ui.Icon";
import { Spacing } from "../../utils/theme";
import sedanCircle from "../../assets/images/sedanCircle.png";
import { useSelector, useDispatch } from "react-redux";
import { getLabel } from "../../utils/prismicUtils";
const NoResults = () => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  return (
    <Container>
      <img src={sedanCircle} width="71" height="60"></img>
      <Text color="petrolBlue">
        {getLabel(
          "no_result_text",
          " We couldn't find any results.",
          prismicData
        )}{" "}
        {/* from{" "}
        <Text fontWeight="bold" color="petrolBlue">
          "448A34"
        </Text> */}
      </Text>
    </Container>
  );
};

export default NoResults;

const Container = styled.div`
  width: 100%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.xxl};
`;
