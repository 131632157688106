import moment from "moment";
import "moment-timezone";

export function isEmpty(s) {
  return (
    s === undefined ||
    s === null ||
    s.length === 0 ||
    (typeof s == "string" && s.trim().length === 0)
  );
}
export function days_between(date1, date2) {
  const dateFormat1 = new Date(date1);
  const dateFormat2 = new Date(date2);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = dateFormat2.getTime() - dateFormat1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}
export function getMmDdYY(dateString) {
  // Input: 2020-09-23T12:27:00.000-0400
  // Output: 9/23/20
  if (isEmpty(dateString)) return "";
  return moment(dateString).format("MM/DD/YYYY");
}

export function getTodayDateWithTime() {
  return moment().format("YYYY-MM-DD");
}

export function getMMMMDDYYYY(dateString) {
  // Input: 2017-01-17
  // Output: Jan 17, 202 0
  if (isEmpty(dateString)) return "";
  return moment(dateString).format("MMM DD, YYYY");
}

export function getMMDDYYYYWithTime(dateString) {
  if (isEmpty(dateString)) return "";
  return moment(dateString).format(`MM/DD/YYYY HH:mm a`);
}

export function getMMDDYYYYWithTimeInSeconds(dateString) {
  if (isEmpty(dateString)) return "";

  return moment
    .utc(dateString)
    .tz("America/New_York")
    .format(`MM/DD/YYYY HH:mm:ss  z`);
}

export function getYYYY_MM_DD(dateString) {
  if (isEmpty(dateString)) return "";
  return moment(dateString).format("YYYY-MM-DD");
}

export function formatMMDDYYYY(dateString) {
  if (isEmpty(dateString)) return "";
  const date = dateString.toString();
  const y = date.substring(0, 4);
  const m = date.substring(4, 6);
  const d = date.substring(6, 8);
  return `${m}/${d}/${y}`;
}

export function getDateFromToday(years) {
  let date = new Date();
  const pastYear = date.getFullYear() - years;
  date.setFullYear(pastYear);
  return date;
}
