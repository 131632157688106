import React, { useState } from "react";
import { Textarea, Text, Button as LPbutton } from "@leaseplan/ui";
import { Colors, Spacing, ConvertSize } from "../../utils/theme";
import { Button } from "@mui/material";
import styled from "styled-components";
import { ThemeProvider } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RichTextEditor from "react-rte";
import { getLabel } from "../../utils/prismicUtils";
import { useSelector } from "react-redux";

const Comment = (props) => {
  const [commentValue, setCommentValue] = useState("");
  // const [showError, setShowError] = useState(false);
  const prismicData = useSelector((state) => state.prismic.prismicData);

  const OnChangeRichText = (event) => {
    if (canEditRequest) {
      setCommentValue(event.value);
      props.setComment(event.value);
      if (props.onChange) {
        props.onChange(event.value);
      }
    }
  };

  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const settingsData = useSelector((state) => state.settings.settings);
  const canEditRequest =
    settingsData?.functionSetting["eplanignite/workspace/case/edit"];
  return (
    <>
      {props.type == "NewRequest" ? (
        <TextArea className="customScroller" />
      ) : (
        <>
          <CustomText color="petrolBlue" size="s" fontWeight="bold">
            {getLabel("comments_title", "Reply with a comment", prismicData)}
            <span style={{ color: "red" }}>*</span>
          </CustomText>
          <TextArea
            value={commentValue}
            onChange={(e) => OnChangeRichText(e)}
            className="customScroller"
            disabled={!canEditRequest}
            placeholder={getLabel(
              "comment_place_holder",
              "Enter a reply of 5 or more characters",
              prismicData
            )}
            maxLength="4000"
          />

          {props.error && (
            <Text size="xs" style={{ color: "red" }}>
              {getLabel(
                "empty_comment_box_error",
                "Please enter a comment of 5 characters or more",
                prismicData
              )}
            </Text>
          )}
        </>
      )}

      <Wrapper>
        <AttachFileIcon htmlColor={Colors.steel40} />
        <AttachmentText onClick={() => props.showAttachment()}>
          {" "}
          {getLabel("slider_add_attachment", "Add Attachment", prismicData)}
        </AttachmentText>
        {/* <ContinueButton
          disabled={!canEditRequest}
          size="s"
          onClick={() => {
            if (canEditRequest) {
              let hasText = commentValue.length;
              if (hasText >= 5) return props.onSubmit(commentValue);
              else setShowError(true);
            }
          }}
        >
          {getLabel("slider_submit", "send", prismicData)}
        </ContinueButton> */}
      </Wrapper>
      <ReqCustomText color="petrolBlue" size="s">
        <CustomTextInner>
          {getLabel(
            "mandatory_field_message",
            "* required fields",
            prismicData
          )}
        </CustomTextInner>
      </ReqCustomText>
    </>
  );
};
const Wrapper = styled.div`
  margin-top: ${Spacing.l};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const AttachmentText = styled.label`
  color: ${Colors.steel40};
  margin-right: ${Spacing.xl};
  cursor: pointer;
`;
const TextArea = styled(Textarea)`
  margin-left: ${ConvertSize(2)};
  margin-bottom: ${Spacing.xxs};
  border-color: ${Colors.cancelled};
  border-radius: 4px;
  font-family: LeasePlan;
`;
const SubmitButton = styled(LPbutton)`
  width: ${ConvertSize(120)};
  height: ${ConvertSize(0)};
  align-self: flex-end;
  // margin-top: ${Spacing.m};
  border: 1px solid ${Colors.petrolBlue};
  color: ${Colors.white};
  background-color: ${Colors.petrolBlue};
`;
const CustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.s};
  font-size: 12px;
`;
const ContinueButton = styled(LPbutton)`
  width: ${ConvertSize(140)};
  align-self: flex-end;
  font-size: 12px;
`;
const ReqCustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
`;
const CustomTextInner = styled.span`
  margin-left: 10px;
  position: relative;
  top: 10px;
  color: red;
`;
export default Comment;
