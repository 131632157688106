import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Grid,
  Textarea,
  GridItem,
  TextInput,
  Text,
  Button as LPbutton,
} from "@leaseplan/ui";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MailOutlineRounded from "@mui/icons-material/MailOutlineRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button } from "@mui/material";
import { showToaster } from "../../../store/toaster/toasterActions";
import { Colors, ConvertSize, Spacing } from "../../../utils/theme";
import Select from "@mui/material/Select";
import Selectors from "../../Selectors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { hideSidePanel } from "../../../store/commonStore/commonActionCreators";
import { connect, useSelector, useDispatch } from "react-redux";
import Search from "../Search";
import Comment from "../Comment";
import Upload from "../UploadDetails";
import FileUpload from "../FileUpload";
import { useState } from "react";
import { isEmpty } from "../../../utils/stringFunctions";
import { getLabel } from "../../../utils/prismicUtils";
import RichTextEditor from "react-rte";

import {
  createRequest,
  getDrivers,
  getVehicles,
  getRequestDetails,
  uploadAttachments,
  getRequestTypeSubType,
  deleteAttachment,
} from "../../../store/request/requestApi";
import RequestConfirmation from "./RequestConfirmation";
import Loading from "../../Loading";
import { LEGACY_URL } from "../../../utils/constants";
import NavigateConfirmation from "./NavigateConfirmation";
import { showSidePanel } from "../../../store/commonStore/commonActions";
import uuid from "react-uuid";
import { storeFileIds } from "../../../store/request/requestActions";

const NewLinkedRequest = (props) => {
  const dispatch = useDispatch();
  const clientNumber = useSelector((state) => state.user.userData.clientNumber);
  const { requestId, requestType } = useSelector((state) => state.common);
  const types = useSelector((state) => state.requestDropdown.requestDropdown);
  const [fileUploadFailed,setFileUploadFailed] = useState(null);
  const [richTextValue, setRichTextValue] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [reqDetails, setReqDetails] = useState({
    id: "",
    caseNo: "",
  });
  const [uploadInProgress, setUploadInProgress] = useState(false);
  // props.showPopupWarning("files", true);
  const hideSidePanel = async () => {
    if (!isSubmitted && fileID.length > 0) {
      let deleteFiles = [];
      fileID.forEach(function (file) {
        deleteFiles.push({
          id: file.id,
        });
      });
      const deleteFileRes = await deleteAttachment(deleteFiles);
    }
    setAnchorEl(true);
  };
  const handleSidePanel = async () => {};
  const onSubmit = async (results) => {
    const {
      description,
      type,
      subType,
      driverNumber,
      vehicleNumber,
      parentId,
    } = results;

    const requestBody = {
      description,
      type,
      subType,
      driverNumber,
      vehicleNumber,
      parentId,
      attachments: fileID,
    };

    if (action.actionAction == "CREATE_CASE") {
      try {
        setIsLoading(true);
        const firstRes = await createRequest(requestBody);
        const filesFailed=[]
        firstRes?.data?.attachments?.forEach(file=>{
          !file?.isUploaded && filesFailed.push(file?.fileName)
        })
        setFileUploadFailed(filesFailed)
        dispatch(storeFileIds([]))
        props.showPopupWarning("description", false)
        props.showPopupWarning('files',false);
        setFileID([])
        setReqDetails({
          id: firstRes.data.id,
          caseNo: firstRes.data.caseNumber,
        });
        setIsLoading(false);
        setIsSubmitted(true);
      } catch (e) {
        setIsLoading(false);
        setFileID([]);
        props.showPopupWarning("description", false)
        props.showPopupWarning("files", false);
        console.log(e);
      }
    } else if (action.actionAction == "EXTERNAL") {
      window.open(action.actionLink, "_blank");
    } else {
      const params1 = action.actionText1.replace("[DRIVER]", driverNumber);
      const params2 = params1.replace("[CLIENT]", clientNumber);
      const params3 = params2.replace("[VEHICLE]", vehicleNumber);
      window.open(`${LEGACY_URL}${action.actionLink}?${params3}`);
    }
  };

  const [attachmentClicked, setAttachmentClicked] = useState(false);
  const [linkedRequest, setLinkedRequest] = useState({
    requestType: "",
    incidentNo: "",
  });
  const [subTypes, setSubTypes] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [action, setAction] = useState({
    actionDisplay: "NONE",
    actionAction: "",
    actionLink: "",
    actionText1: "",
  });
  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  const getTypeSubType = async () => {
    setIsLoading(true);
    const requestDetails = await getRequestDetails(requestType, requestId);

    const type = requestDetails.data.type;
    const subtype = requestDetails.data.subType;
    const vehicleNumber = requestDetails.data.vehicle.vehicleNumber;
    const vehicle = `${requestDetails.data.vehicle.vehicleNumber} - ${requestDetails.data.vehicle.vehicleYear} ${requestDetails.data.vehicle.vehicleMake} ${requestDetails.data.vehicle.vehicleModel}`;
    const driverNumber = requestDetails.data.driver.driverNumber;
    const driver = `${requestDetails.data.driver.firstName} ${requestDetails.data.driver.lastName}`;
    setIsLoading(false);

    setLinkedRequest({
      requestType: requestDetails.data.type,
      incidentNo: requestDetails.data.caseNumber,
    });
    const getTypeValue = types.categories.filter((item) => {
      return item?.categoryDescription.toLowerCase() == type.toLowerCase();
    });

    const typeValue = getTypeValue[0]?.requestCategoryUid;

    const getSubtypeList = types[`subcategory_${typeValue}`];
    const getSubtypeValue = getSubtypeList.filter((item) => {
      return item?.categoryDescription.toLowerCase() == subtype.toLowerCase();
    });

    const subtypeValue = getSubtypeValue[0].requestCategoryUid;

    setSubTypes(getSubtypeList);

    setAction({
      actionDisplay: getSubtypeValue[0].actionDisplay,
      actionAction: getSubtypeValue[0].actionAction,
      actionLink: getSubtypeValue[0].actionLink,
      actionText1: getSubtypeValue[0].actionText1,
    });
    setFieldValue("type", type);
    setFieldValue("typeValue", typeValue);
    setFieldValue("subType", subtype);
    setFieldValue("subTypeValue", subtypeValue);
    if (getSubtypeValue[0].actionDisplay == "DRIVER") {
      setFieldValue("driverNumber", driverNumber);
      setFieldValue("driver", driver);
    }
    if (getSubtypeValue[0].actionDisplay == "VEHICLE") {
      setFieldValue("vehicleNumber", vehicleNumber);
      setFieldValue("vehicle", vehicle);
    }
    if (getSubtypeValue[0].actionDisplay == "OPTIONAL") {
      setFieldValue("driverNumber", driverNumber);
      setFieldValue("driver", driver);
      setFieldValue("vehicleNumber", vehicleNumber);
      setFieldValue("vehicle", vehicle);
    }
    setFieldValue("parentId", requestId);
  };

  useEffect(() => {
    getTypeSubType();
  }, []);

  const validate = (results) => {
    const errors = {};
    if (action?.actionDisplay == "DRIVER") {
      if (isEmpty(results.driver)) {
        errors.driver = "Driver is Required";
      }
    }
    if (action?.actionDisplay == "VEHICLE") {
      if (isEmpty(results.vehicle)) {
        errors.vehicle = "Vehicle is Required";
      }
    }
    if (isEmpty(results.description)) {
      errors.description = getLabel(
        "mandatory_description_message",
        "Please enter a description of 5 characters or more",
        prismicData
      );
    } else if (!isEmpty(results.description)) {
      var regex = /^\s+|\s+$/gm;
      let hasText = results.description
        .toString("html")
        .replace(regex, "").length;
      if (hasText >= 1 && hasText < 5) {
        errors.description = getLabel(
          "mandatory_description_message",
          "Please enter a description of 5 characters or more",
          prismicData
        );
      }
    }
    return errors;
  };

  const { values, touched, errors, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        type: null,
        subType: null,
        typeValue: null,
        subTypeValue: null,
        description: null,
        driverNumber: null,
        driver: null,
        vehicleNumber: null,
        vehicle: null,
        parentId: null,
      },
      onSubmit,
      validate,
    });

  const [searchedDrivers, setSearchedDrivers] = useState([]);
  const [searchedVehicles, setSearchedVehicles] = useState([]);

  const [showSearchedDrivers, setShowSearchedDrivers] = useState(false);
  const [showSearchedVehicles, setShowSearchedVehicles] = useState(false);

  const prismicData = useSelector((state) => state.prismic.prismicData);
  const [uploadedData, setUploadedData] = useState([]);
  const [fileID, setFileID] = useState([]);
  const [deletionInProgress,setDeletionInProgress]= useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#044a5d",
      },
    },
  });

  const onFileUpload = (fileID) => {
    setFileID(fileID);
    if (fileID.length == 0) {
      props.showPopupWarning("files", false);
    } else if (fileID.length > 0) {
      props.showPopupWarning("files", true);
    }
  };
  const fileUploadInProgress = (status) => {
    setUploadInProgress(status);
  };
  const fileDeletionInProgress = (status) => {
    setDeletionInProgress(status);
  };
  const onChangeRequestType = (e) => {
    setFieldValue("driverNumber", "");
    setFieldValue("driver", "");
    setFieldValue("vehicleNumber", "");
    setFieldValue("vehicle", "");
    const selectedIndex = e.target.selectedIndex;
    setFieldValue("type", e.target[selectedIndex].text);
    setFieldValue("typeValue", e.target.value);

    const subTypes = types[`subcategory_${e.target.value}`];
    setSubTypes(subTypes);
    setAction({
      actionDisplay: subTypes[0].actionDisplay,
      actionAction: subTypes[0].actionAction,
      actionLink: subTypes[0].actionLink,
      actionText1: subTypes[0].actionText1,
    });
  };

  const onChangeRequestSubType = (e) => {
    setFieldValue("driverNumber", "");
    setFieldValue("driver", "");
    setFieldValue("vehicleNumber", "");
    setFieldValue("vehicle", "");
    const selectedIndex = e.target.selectedIndex;
    setFieldValue("subType", e.target[selectedIndex].text);
    setFieldValue("subTypeValue", e.target.value);

    const selectedItem = subTypes.filter((item) => {
      return item.requestCategoryUid == e.target.value;
    });
    setAction({
      actionDisplay: selectedItem[0].actionDisplay,
      actionAction: selectedItem[0].actionAction,
      actionLink: selectedItem[0].actionLink,
      actionText1: selectedItem[0].actionText1,
    });
  };

  const initialRender = useRef(true);
  const prevDriver = useRef(null);
  const prevVehicle = useRef(null);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: Colors.midOrange,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Colors.midOrange,
      padding: "10px",
      paddingRight: "25px",
      paddingLeft: "25px",
      fontSize: "12px",
    },
  }));

  const MailIcon = ({ title }) => {
    return (
      <IconsWrapper>
        <BootstrapTooltip title={title} arrow placement="top">
          <MailOutlineRounded htmlColor={Colors.steel60} />
        </BootstrapTooltip>
      </IconsWrapper>
    );
  };
  const onChangeComment = ({ value }) => {
    setFieldValue("description", value);
  };

  const renderDriverItems = (item, index) => {
    return (
      <TextContainer onClick={onClickDriver(item)}>
        <Text size="s">{`${item.firstName} ${item.lastName}`}</Text>
        <MailIcon title={item.email} />
      </TextContainer>
    );
  };

  const renderVehicleItems = (item, index) => {
    return (
      <TextContainer onClick={onClickVehicle(item)}>
        <Text size="s">{`${item.vehicleNumber} - ${item.vehicleYear} ${item.vehicleMake} ${item.vehicleModel}`}</Text>
        <MailIcon />
      </TextContainer>
    );
  };

  const onClickDriver = (driver) => () => {
    setFieldValue("driver", `${driver.firstName} ${driver.lastName}`);
    setFieldValue("driverNumber", driver.driverNumber?.toString());
    setShowSearchedDrivers(false);
  };

  const onClickVehicle = (vehicle) => () => {
    setFieldValue(
      "vehicle",
      `${vehicle.vehicleNumber} - ${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
    );
    setFieldValue("vehicleNumber", vehicle.vehicleNumber?.toString());
    setShowSearchedVehicles(false);
  };
  const OnChangeRichText = (value) => {
    setRichTextValue(value);
    var regex = /(<([^>]+)>)/gi;
    let hasText = !!value.toString("html").replace(regex, "").length;

    if (hasText) {
      setFieldValue("description", value.toString("html"));
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClickLinkedRequest = (id) => {
    dispatch(
      showSidePanel({
        type: "Ticket",
        requestId: id,
        requestType: "sc",
      })
    );
  };
  return (
    <DrawerInsideContainer>
      {isLoading ? (
        <Container>
          <Loading />
        </Container>
      ) : !isSubmitted ? (
        <>
          <Grid direction="column" alignContent="center">
            <CustomText color="petrolBlue" size="xl" fontWeight="bold">
              {getLabel("new_request_title", "New Request", prismicData)}
            </CustomText>
            <LinkedRequest onClick={(e) => onClickLinkedRequest(requestId)}>
              <LinkedRequestContent>
                <Text color="petrolBlue" size="m" fontWeight="bold">
                  {getLabel(
                    "new_linked_request_create_title",
                    "Linked Request",
                    prismicData
                  )}
                </Text>
                <div>
                  <CustomText color="petrolBlue" size="s">
                    {linkedRequest.requestType}
                  </CustomText>
                  <CustomText
                    style={{
                      marginLeft: Spacing.s,
                      textDecoration: "underline",
                    }}
                    color="lightOrange"
                    size="s"
                    fontWeight="bold"
                  >
                    {linkedRequest.incidentNo}
                  </CustomText>
                </div>
              </LinkedRequestContent>
              <Next>
                <NavigateNextIcon
                  fontSize="large"
                  htmlColor={Colors.petrolBlue}
                />
              </Next>
            </LinkedRequest>
            <CustomText color="petrolBlue" size="s" fontWeight="bold">
              {getLabel("request_type_title", "Request Type", prismicData)}
            </CustomText>
            <Select
              size="small"
              disabled
              native
              value={values.typeValue}
              inputProps={{
                id: "select-multiple-native",
              }}
              style={{ width: "100%", backgroundColor: `${Colors.disabled}` }}
              onChange={onChangeRequestType}
            >
              {types?.categories?.map((item) => (
                <option key={uuid()} value={item.requestCategoryUid}>
                  {item.categoryDescription}
                </option>
              ))}
            </Select>

            <CustomText color="petrolBlue" size="s" fontWeight="bold">
              {getLabel(
                "request_sub_type_title",
                "Request Sub Type",
                prismicData
              )}
            </CustomText>
            <Select
              size="small"
              disabled
              native
              value={values.subTypeValue}
              inputProps={{
                id: "select-multiple-native",
              }}
              style={{ width: "100%", backgroundColor: `${Colors.disabled}` }}
              onChange={onChangeRequestSubType}
            >
              {subTypes?.map((item) => (
                <option key={uuid()} value={item.requestCategoryUid}>
                  {item.categoryDescription}
                </option>
              ))}
            </Select>

            {(action?.actionDisplay == "DRIVER" ||
              action?.actionDisplay == "OPTIONAL") &&
              values.driverNumber && (
                <>
                  <CustomText color="petrolBlue" size="s" fontWeight="bold">
                    {getLabel(
                      "select_driver_title",
                      "Select Driver",
                      prismicData
                    )}
                  </CustomText>
                  <Search
                    disabled
                    isLinked
                    type="sidePanel"
                    placeHolder={getLabel(
                      "search_for_driver_hint",
                      "Search for driver",
                      prismicData
                    )}
                    name="driver"
                    onChange={handleChange}
                    value={values.driver}
                    error={touched.driver && errors.driver}
                  />
                  {showSearchedDrivers &&
                    !isEmpty(values.driver) &&
                    searchedDrivers.length > 0 && (
                      <Selectors
                        isMultiple={true}
                        data={searchedDrivers}
                        isSmartSearch
                        renderItem={renderDriverItems}
                      />
                    )}
                </>
              )}

            {(action?.actionDisplay == "VEHICLE" ||
              action?.actionDisplay == "OPTIONAL") &&
              values.vehicleNumber && (
                <>
                  <CustomText color="petrolBlue" size="s" fontWeight="bold">
                    {getLabel(
                      "select_vehicle_title",
                      "Select Vehicle",
                      prismicData
                    )}
                  </CustomText>
                  <Search
                    disabled
                    isLinked
                    type="sidePanel"
                    placeHolder={getLabel(
                      "search_for_vehicle_hint",
                      "Search for vehicle",
                      prismicData
                    )}
                    name="vehicle"
                    onChange={handleChange}
                    value={values.vehicle}
                    error={touched.vehicle && errors.vehicle}
                  />
                  {showSearchedVehicles &&
                    !isEmpty(values.vehicle) &&
                    searchedVehicles.length > 0 && (
                      <Selectors
                        isMultiple={true}
                        data={searchedVehicles}
                        isSmartSearch
                        renderItem={renderVehicleItems}
                      />
                    )}
                </>
              )}

            <CustomText color="petrolBlue" size="s" fontWeight="bold">
              {getLabel("comments_title", "Comments", prismicData)}
            </CustomText>
            <TextArea
              name="description"
              value={values.description}
              onChange={onChangeComment}
              className="customScroller"
              maxLength="4000"
            />
            {/* <RichTextEditor
              value={richTextValue}
              onChange={OnChangeRichText}
              toolbarConfig={toolbarConfig}
              className="RichTextEditor"
            /> */}
            {touched.description && errors.description && (
              <Text size="xs" style={{ color: "red" }}>
                {errors.description}
              </Text>
            )}
            <Wrapper>
              <div
                onClick={() => setAttachmentClicked(true)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <AttachFileIcon htmlColor={Colors.steel40} />
                <AttachmentText>
                  {getLabel(
                    "slider_add_attachment",
                    "Add Attachment",
                    prismicData
                  )}
                </AttachmentText>
              </div>
            </Wrapper>
            {attachmentClicked && (
              <>
                {/* <Upload /> */}
                <FileUpload
                  onFileUpload={onFileUpload}
                  fileUploadInProgress={fileUploadInProgress}
                  fileDeletionInProgress={fileDeletionInProgress}
                />
              </>
            )}

            <ButtonWrapper>
              <CancelButton
                size="s"
                variant="secondary"
                onClick={() => onClickLinkedRequest(requestId)}
              >
                {getLabel("back_button_title", "Go Back", prismicData)}
              </CancelButton>
              <ContinueButton
                size="s"
                onClick={() =>
                  !uploadInProgress && !deletionInProgress
                    ? handleSubmit()
                    : (uploadInProgress? dispatch(
                        showToaster({
                          type: "WARNING",
                          message: getLabel(
                            "file_upload_in_progress",
                            "File upload in progress",
                            prismicData
                          ),
                        })
                      ):dispatch(
                        showToaster({
                          type: "WARNING",
                          message: getLabel(
                            "file_deletion_in_progress_warning",
                            "File deletion in progress",
                            prismicData
                          ),
                        })
                      ))
                }
              >
                {getLabel("send_button_title", "Send", prismicData)}
              </ContinueButton>
            </ButtonWrapper>
          </Grid>
          <NavigateConfirmation
            anchorEl={anchorEl}
            open={anchorEl}
            handleClose={handleClose}
            handleHideSidePanel={hideSidePanel}
            isLinkedReq={false}
          />
        </>
      ) : (
        <Container>
          <RequestConfirmation
            caseNo={reqDetails.caseNo}
            requestId={reqDetails.id}
            fileUploadFailed={fileUploadFailed}          
            />
        </Container>
      )}
    </DrawerInsideContainer>
  );
};
const DrawerInsideContainer = styled.div`
  margin: ${Spacing.xxl};
`;
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkedRequest = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 2px solid #eeeeee;
  background-color: #f6f8fb;
  padding: ${Spacing.s};
  cursor: pointer;
`;

const LinkedRequestContent = styled.div`
  display: flex;
  flex-direction: column;
  //margin-left: ${Spacing.m};
`;

const Next = styled.div``;

const CustomText = styled(Text)`
  margin-bottom: ${Spacing.xxxs};
  margin-top: ${Spacing.s};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${Colors.gallery};
`;
const CancelButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  margin-top: ${Spacing.m};
  font-size: 12px;
  border: 1px solid ${Colors.petrolBlue};
  color: ${Colors.petrolBlue};
  :hover {
    color: ${Colors.white};
    background-color: ${Colors.petrolBlue};
  }
`;
const ContinueButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  font-size: 12px;
  margin-top: ${Spacing.m};
`;
const TextContainer = styled.div`
  margin-left: ${Spacing.s};
  margin-top: ${Spacing.xxs};
  justify-content: space-between;
  margin-right: ${Spacing.s};
  display: flex;
  cursor: pointer;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

const Wrapper = styled.div`
  margin: ${Spacing.xxl};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;
const AttachmentText = styled.label`
  color: ${Colors.steel40};
  margin-right: ${Spacing.xl};
  cursor: pointer;
`;

const TextArea = styled(Textarea)`
  margin-left: ${ConvertSize(2)};
  margin-bottom: ${Spacing.xxs};
  border-color: ${Colors.cancelled};
  border-radius: 4px;
`;

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(NewLinkedRequest);
