export const FETCH_REQUEST_TYPESUBTYPE_SUCCESS =
  "FETCH_REQUEST_TYPESUBTYPE_SUCCESS";
export const FETCH_REQUEST_TYPESUBTYPE_FAIL = "FETCH_REQUEST_TYPESUBTYPE_FAIL";
export const FETCH_REQUEST_TYPESUBTYPE_REQUEST =
  "FETCH_REQUEST_TYPESUBTYPE_REQUEST";

export const getRequestDropdownRequest = () => {
  return {
    type: FETCH_REQUEST_TYPESUBTYPE_REQUEST,
  };
};

export const getRequestDropdownSuccess = (res) => {
  return {
    type: FETCH_REQUEST_TYPESUBTYPE_SUCCESS,
    quicklinks: res.quicklinks,
    requestDropdown: res.requestDropdown,
  };
};

export const getRequestDropdownFail = () => {
  return {
    type: FETCH_REQUEST_TYPESUBTYPE_FAIL,
  };
};
