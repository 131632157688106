import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Spacing, ConvertSize } from "../../utils/theme";
import FileUploadIcon from "../../assets/images/fileUploadIcon.svg";
import failedIcon from "../../assets/images/failed.svg";
import Upload from "./UploadDetails";
import { Grid, GridItem, Button as LPbutton } from "@leaseplan/ui";
import { getLabel } from "../../utils/prismicUtils";
import { getFileList } from "../../utils/stringFunctions";
import { useSelector, useDispatch } from "react-redux";
import {
  uploadAttachments,
  deleteAttachment,
  uploadAttachmentToS3,
  scanUploadedFiles,
} from "../../store/request/requestApi";
import { showToaster } from "../../store/toaster/toasterActions";
import eventLog from "../../config/firebase";
import {
  REMOVE_ATTACHMENTS,
  UPLOAD_ATTACHMENTS,
} from "../../helpers/logEvents";
import { storeFileIds } from "../../store/request/requestActions";

const FileUpload = ({ fileUploadInProgress, onFileUpload, deleteFiles,fileDeletionInProgress}) => {
  const [uploadedData, setUploadedData] = useState([]);
  const [status, setStatus] = useState(null);
  const [deleteFileID, setDeleteFileID] = useState(null);
  const [fileID, setFileID] = useState([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadErrorData, setUploadErrorData] = useState([]);
  const [uploadSizeData, setUploadSizeData] = useState([]);
  const [loaderList, setLoaderList] = useState([]);
  const [deletedFile, setDeletedFile] = useState(false);
  const { files: fileIds } = useSelector((state) => state.request);
  const dispatch = useDispatch();

  const getFileExtension = (filename) => {
    if (!filename || filename.length == 0) {
      return null;
    }
    return filename?.split(".")?.pop();
  };

  const isAllowed = (filename) => {
    const allowedFilesExt = [
      "png",
      "pdf",
      "jpg",
      "jpeg",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "txt",
      "csv",
      "PDF",
      "JPEG",
      "PNG",
      "JPG",
      "TXT",
      "DOC",
      "DOCX",
      "PPT",
      "PPTX",
      "CSV",
      "XLS",
      "XLSX",
    ];
    return allowedFilesExt.indexOf(getFileExtension(filename)) > -1;
  };
  const [namesList, setNamesList] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadQueue, setUploadQueue] = useState([]);
  const fileNames = useMemo(() => namesList, [namesList]);

  const checkForSameFileNames = (filename) => {
    let sameName = false;
    fileNames.forEach((file) => {
      if (file.name === filename) {
        sameName = true;
      }
    });
    return sameName;
  };
  const removeFileName = (filenames) => {
    setNamesList((prevNamesList) =>
      prevNamesList.filter((file) => !filenames.includes(file.name))
    );
  };

  useEffect(() => {
    if (files.length > 0) {
      setUploadQueue((prevQueue) => [...prevQueue, ...files]);
      setFiles([]);
    }
  }, [files]);

  useEffect(() => {
    if (!uploadInProgress && uploadQueue.length > 0) {
      uploadedFile();
    }
  }, [uploadInProgress, uploadQueue]);

  const handleChange = (event) => {
    const data = [];
    let uploadError = [];
    if (uploadedData.length + event.target.files.length > 5) {
      dispatch(
        showToaster({
          type: "ERROR",
          message: getLabel(
            "total_file_upload_error_message",
            "Total 5 number of files can be attached",
            prismicData
          ),
        })
      );
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) {
      if (!isAllowed(event.target?.files?.[i]?.name)) {
        uploadError.push({
          fileName: event.target.files[i]?.name,
          error: `${getLabel(
            "invalid_file_format",
            "Invalid file format. File with",
            prismicData
          )} ${getFileExtension(event.target?.files?.[i]?.name)} ${getLabel(
            "extention_not_allowed",
            "extention not allowed",
            prismicData
          )}`,
        });
        continue;
      }
      if (checkForSameFileNames(event.target?.files?.[i]?.name)) {
        uploadError.push({
          fileName: event.target.files[i]?.name,
          error: getLabel(
            "file_with_same_name_already_exist_error",
            "File with same name already exist",
            prismicData
          ),
        });
        continue;
      }
      if (event.target.files[i].size < 20971520 && event.target.files[i].size > 0) {
        data.push(event.target.files[i]);
      } else {
        uploadError.push({
          fileName: event.target.files[i]?.name,
          error: event.target.files[i].size > 0 ? getLabel(
            "file_size_limit",
            "File length exceed to 1.5 MB",
            prismicData
          ) : getLabel(
            "file_empty",
            "File length should be greater than 0 bytes",
            prismicData
          ),
        });
      }
    }
    setFiles((prevFiles) => [...prevFiles, ...data]);
    setNamesList((prevNamesList)=>[...prevNamesList, ...data]);
    event.target.files.length == 1
      ? setUploadErrorData(uploadError)
      : setUploadSizeData(uploadError);
    setUploadedData((prev) => [...getFileList(prev, data)]);
    event.target.value = "";
  };
  useEffect(() => {
    let errorData = [...uploadSizeData, ...uploadErrorData];
    const results = uploadedData.filter(
      ({ name: id1 }) =>
       !uploadErrorData.some(
          ({ fileName: id2 }) => id2 === id1 && !checkForSameFileNames(id2)
          
        )
      
    );
    setUploadedData(results);
    if (errorData?.length > 0) {
      let errorMessage = "";
      errorData.forEach((e) => {
        !e?.awsError &&
          (errorMessage += `<p style="margin-bottom: 2px;"> ${e?.fileName} - ${e?.error} </p>`);
      });
      errorMessage !== "" &&
        dispatch(
          showToaster({
            type: "ERROR",
            message: errorMessage,
          })
        );
    }
  }, [uploadErrorData]);

  const deleteMultipleFiles = async (ids) => {
    await deleteAttachment(ids);
  };

  const uploadedFile = async () => {
    fileUploadInProgress(true);
    setUploadInProgress(true);
    const batchSize = uploadQueue.length;
    const newFilesUpload = uploadQueue.slice(0, batchSize);
    const filesUploaded = [];
    Object.values(newFilesUpload).forEach(function (file, index) {
      file.size < 20971520 &&
        filesUploaded.push({
          id: index + 1,
          fileName: file.name,
          contentType: file.type,
        });
    });
    const secondRes = await uploadAttachments(filesUploaded);
    if (secondRes?.status === 200) {
      let uploadedFiles = [];
      let uploadError = [];
      let namesToBeRemoved = [];
      let preSignedUrlFiles = secondRes?.data?.requestFiles;
      const filesToUpload = [];
      newFilesUpload?.forEach((file, i) => {
        if (preSignedUrlFiles[i]?.isPreSignedUrlGenerated) {
          filesToUpload.push({
            file,
            uuid: preSignedUrlFiles[i].uuid,
            url: preSignedUrlFiles[i].url,
          });
        } else {
          uploadError.push({
            fileName: preSignedUrlFiles[i].fileName,
            error: preSignedUrlFiles[i].error,
          });
          namesToBeRemoved.push(preSignedUrlFiles[i].fileName)
        }
      });
      
      const results = await Promise.allSettled(
        filesToUpload.map(uploadAttachmentToS3)
      );
      results.forEach((result, i) => {
        if (result?.value?.status === 200) {
          uploadedFiles.push({
            id: filesToUpload[i]?.uuid,
            fileName: filesToUpload[i]?.file?.name,
            contentType: filesToUpload[i]?.file?.type,
          });
        } else {
          const errorMssg = result?.value?.response?.data?.match(
            /<Code>([^<]*)<\/Code>/
          );
          const errorCode =
            errorMssg === null
              ? result?.value?.code
              : errorMssg.length > 1 && errorMssg[1];
          dispatch(
            showToaster({
              type: "ERROR",
              message:
                getLabel(
                  "aws_error_message_file_upload",
                  "File upload failed with error code:",
                  prismicData
                ) +
                " " +
                errorCode,
            })
          );
          uploadError.push({
            fileName: filesToUpload[i]?.file?.name,
            awsError: true,
          });
          namesToBeRemoved.push(filesToUpload[i]?.file?.name);
        }
      });
      let ids = uploadedFiles.map((file) => file?.id);
      if (uploadedFiles.length !== 0) {
        const fileScanningRes = await scanUploadedFiles(uploadedFiles);
        if (fileScanningRes && fileScanningRes?.status === 200) {
          const idsForDeletion = [];
          fileScanningRes?.data?.forEach((file, index) => {
            if (!file?.success) {
              idsForDeletion.push({
                id: uploadedFiles[index]?.id,
              });
              uploadedFiles.splice(index, 1);
              ids = ids.splice(index, 1);
              namesToBeRemoved.push(file?.fileName)
              uploadError.push({
                fileName: file?.fileName,
                error: file?.error,
              });
            }
          });
          idsForDeletion.length > 0 && deleteMultipleFiles(idsForDeletion);
        } else {
          uploadedFiles.forEach(file=>{
            namesToBeRemoved.push(file?.fileName)
             uploadError.push({
              fileName:file?.fileName,
              error:getLabel(
                "scanning_error",
                "Error occured during scanning",
                prismicData
              )
            }
             )

          })
          await deleteMultipleFiles(
            ids.map((id) => {
              return { id };
            })
          );
          ids = [];
          uploadedFiles = [];
        }
      }
      removeFileName(namesToBeRemoved);
      dispatch(storeFileIds([...fileIds, ...ids]));
      setLoaderList((prevLoaderList) => [...prevLoaderList, ...uploadedFiles]);
      setUploadQueue((prevQueue) => prevQueue.slice(batchSize));
      fileUploadInProgress(false);
      setUploadInProgress(false);
      setFileID((prevFileID)=>[...prevFileID, ...uploadedFiles]);
      onFileUpload([...fileID, ...uploadedFiles]);
      setUploadErrorData(uploadError);
      uploadedFiles.length > 0 &&
        eventLog({
          eventName: UPLOAD_ATTACHMENTS,
        });
    }
  };
  useMemo(() => {
    if (
      !uploadInProgress &&
      fileID.length !== 0 &&
      status === 0 &&
      deletedFile
    ) {
      const newFileID = fileID.filter((ele) => {
        if (ele && ele.id !== fileID[deleteFileID]?.id) return ele;
      });
      setFileID(newFileID);
      onFileUpload(newFileID);
      setStatus(1);
      removeFileName([uploadedData[deleteFileID].name]);
      setLoaderList((prevLoaderList) =>
        prevLoaderList.filter(
          (file) => file.fileName !== uploadedData[deleteFileID].name
        )
      );
      setUploadedData(
        uploadedData.filter(function (item, index) {
          return index !== deleteFileID;
        })
      );
      setDeleteFileID(null);
      const newFileIds = fileIds.filter((id) => {
        if (id !== fileID[deleteFileID]?.id) {
          return id;
        }
      });
      setDeletedFile(false);
      fileDeletionInProgress(false);
      dispatch(storeFileIds(newFileIds));
    }
  }, [uploadInProgress, fileID, deletedFile]);

  useEffect(() => {
    const fileDeleteAsync = async () => {
      if (status == 0 && fileID[deleteFileID]?.id) {
        await deleteAttachment([
          {
            id: fileID[deleteFileID].id,
          },
        ]);
        setDeletedFile(true);
      }
    };
    fileDeleteAsync();
  }, [deleteFileID, status]);

  function deleteFile(id) {
    deleteFiles && deleteFiles(id);
    setDeleteFileID(uploadedData.indexOf(id));
    fileDeletionInProgress(true);
    setStatus(0);
    eventLog({
      eventName: REMOVE_ATTACHMENTS,
    });
  }

  const prismicData = useSelector((state) => state.prismic.prismicData);
  return (
    <Grid>
      <GridItem span={5}>
        <form style={{ position: "relative" }}>
          <FileInput
            id="file-upload"
            type="file"
            onChange={handleChange}
            multiple
            accept=".png,.pdf,.jpg,.jpeg ,.doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv"
          />
          <FileDrag for="file-upload ">
            <img style={{ paddingTop: 0 }} src={FileUploadIcon} />
            <p style={{ marginTop: "18px" }}>
              {/* {uploadStatus == 0
                ? getLabel(
                    "slider_upload_failed",
                    "File upload has failed",
                    prismicData
                  ) */}
              {getLabel(
                "slider_drag_and_drop",
                "Drag and drop files here",
                prismicData
              )}
              {/* } */}
            </p>
            <SubText>or</SubText>
            <UploadButton>
              {getLabel("slider_browse", "browse", prismicData)}
            </UploadButton>
          </FileDrag>
        </form>
      </GridItem>
      <GridItem span={7}>
        <Upload
          details={uploadedData}
          deleteFile={deleteFile}
          uploadInProgress={uploadInProgress}
          loaderList={loaderList}
          deleteFileID={deleteFileID}
        />
      </GridItem>
    </Grid>
  );
};

export default FileUpload;
const FileDrag = styled.label`
  border: 2px dashed #bbbbbb;
  border-radius: ${ConvertSize(7)};
  color: #044a5d;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin: ${Spacing.m} 0;
  padding: ${Spacing.m} ${Spacing.xxl};
  text-align: center;
  background-color: f7f8fc;
  transition: background 0.3s, color 0.3s;
  height: auto;
`;

const SubText = styled.div`
  color: #bbbbbb;
  margin: ${Spacing.xxs} 0px ${Spacing.xxl};
`;
const UploadButton = styled.div`
  margin: auto;
  border: ${ConvertSize(1)} solid;
  border-radius: ${ConvertSize(0)};
  padding: ${ConvertSize(10)};
`;

const FileInput = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
`;
