import React from "react";
import styled from "styled-components";
import {
  Text,
  Button,
  Spacing,
  Grid,
  GridItem,
  TextInput,
} from "@leaseplan/ui";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { getLabel } from "../utils/prismicUtils";

import { Colors, ConvertSize } from "../utils/theme";

const ShareRequest = ({ anchorEl, handleClose, open }) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      //onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          padding: "10px 20px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          width: ConvertSize(301),
          height: ConvertSize(260),
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-40%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    >
      <Grid direction="column">
        <GridItem>
          <CustomHeading size="s">
            {getLabel(
              "share_request_text",
              "Share this request via email. Enter below an email address or multiple emails comma separated",
              prismicData
            )}
          </CustomHeading>
        </GridItem>
        <GridItem>
          <CustomLabel>
            {" "}
            {getLabel("email_address_text", "Email Address", prismicData)}
          </CustomLabel>
          <TextInput
            name="default"
            placeholder="Enter Email Address"
            autoFocus
          />
        </GridItem>
        <ButtonCustom variant="secondary" size="s">
          {getLabel("send_text", "SEND", prismicData)}{" "}
        </ButtonCustom>
      </Grid>
    </Menu>
  );
};

const ButtonCustom = styled(Button)`
  color: ${Colors.petrolBlue};
  border-color: ${Colors.petrolBlue};
  font-weight: bold;
  align-self: center;
  align-items: center;
  align-content: center;
`;
const CustomLabel = styled.label`
  color: ${Colors.petrolBlue};
  font-size: 14px;
  font-weight: bold;
  position: relative;
  top: -10px;
`;
const CustomHeading = styled(Text)`
  color: ${Colors.petrolBlue};
  font-size: 14px;
`;

export default ShareRequest;
