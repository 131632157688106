import React from "react";
import { Text, Button } from "@leaseplan/ui";
import requestCompleted from "../../../assets/images/requestCompleted.svg";
import styled from "styled-components";
import { Colors, ConvertSize, Spacing } from "../../../utils/theme";
import { getLabel } from "../../../utils/prismicUtils";
import { useSelector, useDispatch } from "react-redux";
import { showSidePanel } from "../../../store/commonStore/commonActionCreators";

const RequestCompleted = ({ requestId, canCreateNewRequest }) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const dispatch = useDispatch();

  const onClickButton = (e) => {
    dispatch(showSidePanel({ type: "NewLinkedRequest", requestId: requestId }));
  };

  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );

  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );

  return (
    <>
      {canCreateNewRequest ? (
        <CustomButton onClick={onClickButton}>
          <Wrapper>
            <Image src={requestCompleted} />
            {getLabel(
              "slider_request_completed",
              "REQUEST COMPLETED",
              prismicData
            )}
          </Wrapper>
          {!clientSettingValue && (
            <Text size="xs" color="white">
              {getLabel("not_resolved_title", "Not Resolved?", prismicData)}{" "}
              <CustomText size="xs" color="white">
                {getLabel(
                  "not_resolved_click_here_button_title",
                  "click here",
                  prismicData
                )}
              </CustomText>
            </Text>
          )}
        </CustomButton>
      ) : (
        <CustomButtonCompleted>
          <Wrapper style={{ marginLeft: ConvertSize(34) }}>
            <Image src={requestCompleted} />
            <Text size="s" color="white" fontWeight="bold">
              {getLabel(
                "slider_request_completed",
                "REQUEST COMPLETED",
                prismicData
              )}
            </Text>
          </Wrapper>
        </CustomButtonCompleted>
      )}
    </>
  );
};

export default RequestCompleted;
const CustomButtonCompleted = styled.div`
  text-transform: none;
  border-radius: ${Spacing.xxxs};
  font-size: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${ConvertSize(34)};
  padding-top: ${Spacing.xs};
  padding-bottom: ${Spacing.xs};
  background: ${Colors.midOrange};
  margin-bottom: ${Spacing.xxs};
  margin-top: ${Spacing.xxs};
  font-weight: bold;
  color: ${Colors.white};
`;
const CustomButton = styled(Button)`
  text-transform: none;
  border-radius: ${Spacing.xxxs};
  font-size: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${ConvertSize(34)};
  padding-top: ${Spacing.xs};
  padding-bottom: ${Spacing.xs};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: ${ConvertSize(22)};
  width: ${ConvertSize(21)};
  margin-right: ${Spacing.s};
  margin-left: -${ConvertSize(18)};
`;

const CustomText = styled(Text)`
  text-decoration: underline;
`;
