import React from "react";
import styled from "styled-components";
import { Button, Text } from "@leaseplan/ui";
import CarPolicy from "../../../assets/images/carPolicy.svg";
import {
  hideSidePanel,
  refreshTableData,
} from "../../../store/commonStore/commonActionCreators";
import { useSelector, useDispatch, connect } from "react-redux";
import { getLabel } from "../../../utils/prismicUtils";
import { showSidePanel } from "../../../store/commonStore/commonActions";
import { Colors } from "../../../utils/theme";
import InfoIcon from "@mui/icons-material/Info";

const RequestConfirmation = (props) => {
  const { refreshData } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const prismicData = useSelector((state) => state.prismic.prismicData);

  function hideSidePanel(e) {
    if (e?.type == 1) {
      dispatch(refreshTableData({ type: !refreshData, loading: true }));
    }
    props.hideSidePanel();
  }
  const onClickLinkedRequest = (id) => {
    dispatch(
      showSidePanel({
        type: "Ticket",
        requestId: id,
        requestType: "sc",
      })
    );
  };
  return (
    <ConfirmationContainer>
      <ConfirmationWrapper>
        <img src={CarPolicy} width="61" height="60"></img>
      </ConfirmationWrapper>
      <ConfirmationWrapper>
        <BoldText color="petrolBlue">
          {getLabel("requested_created_title", "Request Created!", prismicData)}
        </BoldText>
      </ConfirmationWrapper>
      <ConfirmationWrapper>
        <Text size="s" color="black">
          {getLabel(
            "requested_created_description_one",
            "Your new request",
            prismicData
          )}{" "}
          <TextLink
            size="s"
            color="lightOrange"
            onClick={() => onClickLinkedRequest(props.requestId)}
          >
            {props.caseNo}
          </TextLink>{" "}
          {getLabel(
            "request_created_description_two",
            "has been successfully created and linked to your previous request.",
            prismicData
          )}
        </Text>
      </ConfirmationWrapper>
      <br />
      <ConfirmationWrapper>
        <Button onClick={() => hideSidePanel({ type: 1 })}>
          {getLabel(
            "back_to_workspace_button",
            "Back to Workspace",
            prismicData
          )}
        </Button>
      </ConfirmationWrapper>
      {props.fileUploadFailed && props.fileUploadFailed.length !== 0 && (
        <FailedFileUpload>
          <InfoIcon
            style={{
              marginTop: "0.19rem",
              color: Colors.bloodOrange,
            }}
          />
          <MessageContainer>
            <Text size="m">
              {getLabel(
                "file_upload_failed_heading",
                "Failed File Upload.",
                prismicData
              )}
            </Text>
            <Text size="s">
              {getLabel(
                "file_upload_failed_error_message",
                "There was a problem uploading file(s) XXXfilenamesXXX. Please try again later.",
                prismicData
              ).replace("XXXfilenamesXXX", props.fileUploadFailed.toString())}
            </Text>
          </MessageContainer>
        </FailedFileUpload>
      )}
    </ConfirmationContainer>
  );
};
const BoldText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  margin-right: 5px;
`;

const ConfirmationContainer = styled.div`
  width: 400px;
`;
const ConfirmationWrapper = styled.div`
  margin-top: 5px;
  text-align: center;
`;
const TextLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

const FailedFileUpload = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  border: 2.5px solid ${Colors.error};
  background-color: ${Colors.lightDanger};
  border-radius: 5px;
  padding: 10px;
`;
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(RequestConfirmation);
