import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spacing, ConvertSize } from "../../utils/theme";
import failedIcon from "../../assets/images/failed.svg";
import Upload from "./UploadDetails";
import {
  Grid,
  GridItem,
  Text,
  Select,
  Button as LPbutton,
} from "@leaseplan/ui";
import { getLabel } from "../../utils/prismicUtils";
import { getFileList } from "../../utils/stringFunctions";
import carMissing from "../../assets/images/carMissing.svg";
import { useSelector } from "react-redux";
import { hideSidePanel } from "../../store/commonStore/commonActionCreators";
import {
  cancelRequest,
  getRequestDetails,
} from "../../store/request/requestApi";
const CancelRequest = (props) => {
  const [uploadStatus, setUploadStatus] = useState(1);
  const [uploadedData, setUploadedData] = useState([]);
  const [CancelStatus, setCancelStatus] = useState("NOT_AN_ISSUE");
  const { requestId, requestType } = useSelector((state) => state.common);
  const [requestDetails, setRequestDetails] = useState(null);
  const prismicData = useSelector((state) => state.prismic.prismicData);

  useEffect(() => {
    (async function () {
      //get request details
      const requestDetails = await getRequestDetails(requestType, requestId);
      setRequestDetails(requestDetails.data);
    })();
  }, []);

  const handleChange = (e) => {
    (async function () {
      const status = await cancelRequest(requestDetails?.id, CancelStatus);
    })();
  };
  function hideSidePanel() {
    props.hideSidePanel();
  }
  return (
    <DrawerInsideContainer>
      <Grid>
        <GridItem>
          <div style={{ textAlign: "center" }}>
            <img src={carMissing}></img>
            <BoldHeading>
              {" "}
              {getLabel(
                "cancel_request_confirmation_title",
                "Do you want to cancel this request?",
                prismicData
              )}
            </BoldHeading>
            <SubHead>
              {getLabel(
                "cancel_request_confirmation_description",
                "Canceling a request will stop all active work being performed by a LeasePlan Agent.",
                prismicData
              )}
            </SubHead>
          </div>
        </GridItem>
        <GridItem span={12}>
          <Select
            value={CancelStatus}
            onChange={(e) => setCancelStatus(e.target.value)}
          >
            <option value="" disabled>
              {getLabel(
                "cancel_request_drop_down_title",
                "Select One",
                prismicData
              )}
            </option>
            <option value="DUPLICATE">
              {getLabel(
                "cancel_request_drop_down_duplicate_option",
                "Duplicate",
                prismicData
              )}
            </option>
            <option value="NOT_AN_ISSUE">
              {getLabel(
                "cancel_request_no_longer_needed_option",
                "No Longer Needed",
                prismicData
              )}
            </option>
          </Select>
        </GridItem>
        <CustomButton onClick={() => hideSidePanel()}>
          {getLabel("go_back_button_title", "Go Back", prismicData)}
        </CustomButton>
        <CustomButton onClick={handleChange}>
          {getLabel("confirm_button", "Confirm", prismicData)}
        </CustomButton>
      </Grid>
    </DrawerInsideContainer>
  );
};

export default CancelRequest;
const DrawerInsideContainer = styled.div`
  margin: ${Spacing.xxl};
`;
const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BoldHeading = styled.p`
  color: #014a5d;
  font-size: ${ConvertSize(18)};
  font-weight: bold;
  margin-top: ${ConvertSize(12)};
`;
const SubHead = styled.p`
  color: #4a4a4a;
  font-size: ${ConvertSize(14)};
  margin-top: ${ConvertSize(12)};
`;
const CustomButton = styled(LPbutton)`
  margin-right: ${Spacing.xl};
  width: ${ConvertSize(140)};
  align-self: flex-end;
  margin-top: ${Spacing.xxl};
`;
const FileDrag = styled.label`
  border: ${(props) =>
    props.uploadStatus == 0 ? "2px dashed #e7401e" : "2px dashed #bbbbbb"};
  border-radius: ${ConvertSize(7)};
  color: #044a5d;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin: ${Spacing.m} 0;
  padding: ${Spacing.m} ${Spacing.xxl};
  text-align: center;
  background-color: ${(props) =>
    props.uploadStatus == 0 ? "#e7401e05" : "f7f8fc"};
  transition: background 0.3s, color 0.3s;
  height: ${ConvertSize(245)};
`;

const SubText = styled.div`
  color: #bbbbbb;
  margin: ${Spacing.xxs} 0px ${Spacing.xxl};
`;
const UploadButton = styled.div`
  margin: auto;
  border: ${ConvertSize(1)} solid;
  border-radius: ${ConvertSize(0)};
  padding: ${ConvertSize(10)};
`;

const FileInput = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
`;
