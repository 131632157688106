import React, { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import _ from "lodash";
import { Colors, ConvertSize, Spacing } from "../utils/theme";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import CloseIcon from "../assets/images/close_icon.svg";
import "../GlobalStyles.js";
const Search = ({
  type,
  placeHolder,
  width = 500,
  onChange,
  search,
  name,
  value,
  onCancel,
  searchDataClear,
  iconClick,
}) => {
  const [searchString, setSearchString] = useState("");
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const handleSearchStringChange = (event) => {
    setSearchString(event.target.value);
    onChange(event);
  };

  const removeSearch = () => {
    setSearchString("");
    onCancel();
  };

  useEffect(() => {
    if (search) {
      setSearchString(search);
    }
  }, [search]);
  useEffect(() => {
    if (searchDataClear) {
      setSearchString("");
      onCancel();
    }
  }, [searchDataClear]);
  // const searchData = _.debounce((event)=> {
  //     setSearchString(event.target.value);
  // }, 2000);

  return (
    <SearchWrapper type={type == "sidePanel" ? true : false}>
      <TableSearch type={type == "sidePanel" ? true : false}>
        <SearchIcon
          style={{ cursor: "pointer" }}
          onClick={iconClick}
          htmlColor={Colors.petrolBlue}
        />
        <SearchTextInput
          name={name}
          value={searchString}
          onChange={handleSearchStringChange}
          inputProps={{ maxLength: 100 }}
          placeholder={placeHolder}
          width={width}
        />
        {searchString && (
          <img
            src={CloseIcon}
            style={{ cursor: "pointer" }}
            onClick={removeSearch}
          ></img>
        )}
      </TableSearch>
    </SearchWrapper>
  );
};

const TableSearch = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: ${Spacing.l};
  padding: 0px ${Spacing.l} 0px;
  margin: 0px ${Spacing.xs} 0px 0px;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  justify-content: flex-start;

  ${({ type }) =>
    type &&
    `
  border: 1px solid #bdbdbd;
  border-radius: ${Spacing.xxxs};
  margin: 0px;
`}
`;

const SearchTextInput = styled(InputBase)`
  margin-left: ${Spacing.xs};
  padding: ${Spacing.xxxs};
  font-family: LeasePlan;
  box-sizing: border-box;
  width: ${ConvertSize(200)} !important;

  @media (min-width: 600px) {
    width: ${({ width }) => width && `${ConvertSize(width)} !important`};
  }
`;

const SearchWrapper = styled.div`
  margin: ${Spacing.xxxs} 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-family: LeasePlan;

  max-width: 100%;

  @media (min-width: 600px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0px ${Spacing.s} 0px;
  }
  ${({ type }) =>
    type &&
    `
  @media (min-width: 600px) {
    margin: ${Spacing.xxxs} 0px;
  }
`}
`;

export default Search;
