import {
  SHOW_SIDE_PANEL,
  HIDE_SIDE_PANEL,
  REFRESH_TABLE_DATA,
  SHOW_TUTORIAL,
  COUNT_REFRESH,
  APPLY_FILTERS,
  SET_LEGACY_COMMAND,
} from "./commonActions";

const initialState = {
  isSidePanelShown: false,
  panelType: null,
  requestId: null,
  quickLinktype: null,
  status: null,
  requestType: null,
  isRead: null,
  loadingTable: true,
  tabType: 1,
  count: null,
  filters: null,
  legacyCommand: null,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIDE_PANEL:
      return {
        ...state,
        isSidePanelShown: true,
        panelType: action.panelType,
        requestId: action.requestId,
        quickLinktype: action.quickLinktype,
        status: action.status,
        requestType: action.requestType,
        isRead: action.isRead,
      };

    case HIDE_SIDE_PANEL:
      return {
        ...state,
        isSidePanelShown: initialState.isSidePanelShown,
        panelType: initialState.panelType,
        requestId: initialState.requestId,
        quickLinktype: initialState.quickLinktype,
        requestType: initialState.requestType,
        status: action.status,
        legacyCommand: initialState.legacyCommand,
      };
    case SET_LEGACY_COMMAND:
      return {
        ...state,
        legacyCommand: action.legacyCommand,
      };
    case REFRESH_TABLE_DATA:
      return {
        ...state,
        refreshData: action.refresh,
        loadingTable: action.loadingTable,
        tabType: action.tabType,
      };
    case SHOW_TUTORIAL:
      return {
        ...state,
        showTutorial: action.show,
      };

    case COUNT_REFRESH:
      return {
        ...state,
        count: action.count,
      };

    case APPLY_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.filters },
      };
    default:
      return state;
  }
};

export default commonReducer;
