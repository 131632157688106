import React from "react";
import styled from "styled-components";
import { getLabel } from "../../../utils/prismicUtils";
import { Colors, ConvertSize } from "../../../utils/theme";

const New = (prismicData) => {
  return (
    <NewConatiner>
      <Text>
        {" "}
        {getLabel("sending_request_count_title", "Sending", prismicData)}
      </Text>
    </NewConatiner>
  );
};

export default New;

const NewConatiner = styled.div`
  min-width: ${ConvertSize(61)};
  min-height: ${ConvertSize(15)};
  border-radius: ${ConvertSize(2)};
  border: 1px solid ${Colors.petrolBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #004a5d;
  color: white;
  @media (max-width: ${ConvertSize(500)}) {
    padding-left: ${ConvertSize(6)};
    padding-right: ${ConvertSize(6)};
  }
`;

const Text = styled.p`
  font-size: ${ConvertSize(8)};
  color: white;
  font-weight: bold;
`;
