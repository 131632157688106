import {
	FETCH_REQUESTS_FAIL,
	FETCH_REQUESTS_START,
	FETCH_REQUESTS_SUCCESS,
	FETCH_FILEUPLOAD_FAIL,
	FETCH_FILEUPLOAD_START,
	FETCH_FILEUPLOAD_SUCCESS,
	STORE_FILE_ID,
} from "./requestActions";

const initialState = {
	loading: false,
	totalRecords: null,
	currentPage: null,
	totalPages: null,
	recordsInPage: null,
	requests: [],
	error: null,
	files: [],
};

const RequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_REQUESTS_START:
			return {
				...state,
				requests: [],
				loading: true,
			};
		case FETCH_REQUESTS_SUCCESS:
			return {
				...state,
				loading: false,
				requests: action.requests,
				totalRecords: action.totalRecords,
				currentPage: action.currentPage,
				totalPages: action.totalPages,
				recordsInPage: action.recordsInPage,
			};
		case FETCH_REQUESTS_FAIL:
			return {
				...state,
				loading: false,
				requests: [],
				error: action.payload,
				totalRecords: null,
				currentPage: null,
				totalPages: null,
				recordsInPage: null,
			};
		case FETCH_FILEUPLOAD_START:
			return {
				...state,
				files: [],
				loading: true,
			};
		case FETCH_FILEUPLOAD_SUCCESS:
			return {
				...state,
				files: action.fileId,
				loading: false,
			};
		case FETCH_FILEUPLOAD_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case STORE_FILE_ID:
			return {
				...state,
				files: action.payload
			}
		default:
			return state;
	}
};

export default RequestReducer;
