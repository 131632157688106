import store from "../store/store";

export function isEmpty(s) {
  return (
    s === undefined ||
    s === null ||
    s.length === 0 ||
    (typeof s == "string" && s.trim().length === 0)
  );
}

export function getFileList(previousState, data) {
  const previousData = [...previousState];

  for (let i = 0; i <= data.length; i++) {
    if (data[i] != undefined) previousData.push(data[i]);
  }
  return previousData;
}

export function getErrorMessage(errorStatus) {
  const prismicData = GetprismicData();
  let errorMessage = prismicData?.error_types?.find(
    (item) => item.error_status[0].text === errorStatus
  ).error_message[0].text;
  if (!errorMessage) {
    errorMessage = "Something Went Wrong!";
  }
  return errorMessage;
}
function GetprismicData() {
  const prismicData = store?.getState()?.prismic?.prismicData;
  return prismicData;
}

export function compareDates(createdDate, updatedDate) {
  var d1 = Date.parse(createdDate);
  var d2 = Date.parse(updatedDate);
  if (d1 === d2) return true;
  else return false;
}
