import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, Grid, GridItem, Button, TextInput } from "@leaseplan/ui";
import { login } from "../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TopNav from "../components/TopNav";
import Loading from "../components/Loading";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isLoginFail,
    loading,
    idToken,
    accessToken,
    refreshToken,
  } = useSelector((state) => state.auth);

  const [userid, setUserID] = useState(null);
  const [clientno, setClientNo] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.setItem("idToken", idToken);
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      navigate("/", { replace: true });
    }
  }, [isAuthenticated]);

  const onPressLogin = () => {
    dispatch(login({ userId: userid, clientNo: clientno }));
  };
  return (
    <>
      <TopNav iconVisible={false} />
      {loading ? (
        <Loading />
      ) : (
        <LoginContainer>
          <Grid>
            <GridItem span={12}>
              <h1>Login to ePlan</h1>
            </GridItem>
            <GridItem span={12}>
              <TextInput
                name="default"
                label="User ID"
                placeholder="Enter User ID"
                autoFocus
                value={userid}
                onChange={(e) => setUserID(e.target.value)}
              />
            </GridItem>
            <GridItem span={12}>
              <TextInput
                name="default"
                label="Client Number"
                placeholder="Enter Client Number"
                value={clientno}
                onChange={(e) => setClientNo(e.target.value)}
              />
            </GridItem>
            <GridItem span={12}>
              <Button onClick={onPressLogin}>Login</Button>
            </GridItem>
            {isLoginFail && (
              <GridItem span={12}>
                <Text color="errorDark">Invalid credentials</Text>
              </GridItem>
            )}
          </Grid>
        </LoginContainer>
      )}
    </>
  );
};

export default Login;

const LoginContainer = styled.div`
  height: 100vh;
  width: 70%;
  padding-top: 6vh;
  margin: auto;
  @media (min-width: 780px) {
    width: 50%;
  }
  @media (min-width: 1025px) {
    width: 30%;
  }
`;

const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;
