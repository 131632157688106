import * as RequestActions from "./requestActions";
import * as RequestApi from "./requestApi";
export const getRequests = (params) => async (dispatch) => {
  dispatch(RequestActions.fetchRequestStart());
  try {
    const res = await RequestApi.getRequests(params);
    dispatch(RequestActions.fetchRequestSuccess(res.data));
  } catch (e) {
    console.log(e);
    dispatch(RequestActions.fetchRequestFail());
  }
};
export const uploadAttachments = (params) => async (dispatch) => {
  dispatch(RequestActions.fetchFileUploadStart());
  try {
    const res = await RequestApi.uploadAttachments(params);
    dispatch(RequestActions.fetchFileUploadSuccess(res));
  } catch (e) {
    console.log(e);
    dispatch(RequestActions.fetchFileUploadFail());
  }
};

// export const getRequestsUnread = (params) => async (dispatch) => {
//   dispatch(RequestActions.fetchRequestStart());
//   try {
//     const res = await RequestApi.getRequestsUnread(params);
//     dispatch(RequestActions.fetchRequestSuccess(res.data));
//   } catch (e) {
//     console.log(e);
//     dispatch(RequestActions.fetchRequestFail());
//   }
// };
