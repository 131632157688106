import React from "react";
import styled from "styled-components";
import { Button, Text } from "@leaseplan/ui";
import CarPolicy from "../assets/images/carPolicy.svg";
import { hideSidePanel } from "../store/commonStore/commonActionCreators";
import { useSelector, useDispatch, connect } from "react-redux";
import { getLabel } from "../utils/prismicUtils";
import { showSidePanel } from "../store/commonStore/commonActions";
import { Colors } from "../utils/theme";
import InfoIcon from "@mui/icons-material/Info";

const RequestConfirmation = (props) => {
  const dispatch = useDispatch();
  const prismicData = useSelector((state) => state.prismic.prismicData);

  function hideSidePanel(e) {
    props.hideSidePanel();
  }
  const onClickRequestId = (id) => {
    props.setCommentUpdated && props.setCommentUpdated(true);

    dispatch(
      showSidePanel({
        type: "Ticket",
        requestId: id,
        requestType: "sc",
      })
    );
  };
  return (
    <ConfirmationContainer
      style={{
        width: props.isupdate ? "auto" : "400px",
        marginTop: props.isupdate ? "150px" : "inherit",
      }}
    >
      <ConfirmationWrapper>
        <img src={CarPolicy} width="61" height="60"></img>
      </ConfirmationWrapper>
      <ConfirmationWrapper>
        <BoldText color="petrolBlue">
          {getLabel("request_title", "Request", prismicData)}{" "}
          {props.isupdate ? "Updated!" : "Created!"}
        </BoldText>
      </ConfirmationWrapper>
      <ConfirmationWrapper>
        {props.requestId.charAt(0) == "Q" ? (
          <Text size="s" color="black">
            {getLabel(
              "queue_request_created",
              "This request has been added to the queue and will be automatically submitted once all required information has been updated",
              prismicData
            )}
          </Text>
        ) : (
          <Text size="s" color="black">
            {props.isupdate ? "The request  " : "Your new request "}
            <TextLink
              size="s"
              color="lightOrange"
              onClick={() => onClickRequestId(props.requestIDref)}
            >
              {props.requestId}
            </TextLink>{" "}
            {getLabel(
              "has_been_success_text",
              "has been successfully",
              prismicData
            )}{" "}
            {props.isupdate ? "updated" : "created"}
          </Text>
        )}
      </ConfirmationWrapper>
      <br />
      <ConfirmationWrapper>
        <Button onClick={() => hideSidePanel({ type: 1 })}>
          {getLabel("back_to_workspace_text", "Back to Workspace", prismicData)}
        </Button>
      </ConfirmationWrapper>
      {props.fileUploadFailed && props.fileUploadFailed.length !== 0 && (
        <FailedFileUpload>
          <InfoIcon
            style={{
              marginTop: "0.19rem",
              color: Colors.bloodOrange,
            }}
          />
          <MessageContainer>
            <Text size="m">
              {getLabel(
                "file_upload_failed_heading",
                "Failed File Upload.",
                prismicData
              )}
            </Text>
            <Text size="s">
              {getLabel(
                "file_upload_failed_error_message",
                "There was a problem uploading file(s) XXXfilenamesXXX. Please try again later.",
                prismicData
              ).replace("XXXfilenamesXXX", props.fileUploadFailed.toString())}
            </Text>
          </MessageContainer>
        </FailedFileUpload>
      )}
    </ConfirmationContainer>
  );
};
const BoldText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  margin-right: 5px;
`;

const ConfirmationContainer = styled.div`
  width: 400px;
`;
const ConfirmationWrapper = styled.div`
  margin-top: 5px;
  text-align: center;
`;
const TextLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;
const FailedFileUpload = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  border: 2.5px solid ${Colors.error};
  background-color: ${Colors.lightDanger};
  border-radius: 5px;
  padding: 10px;
`;
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
export default connect(mapStateToProps, { hideSidePanel })(RequestConfirmation);
