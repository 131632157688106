import axios from "axios";
import server from "../../config/server";

export const getRequests = async (body) => {
  const res = await server.post("/support/request/search", body);
  return res;
};

// export const getRequestsUnread = async (body) => {
//   const res = await server.post("/support/request/unread", body);
//   return res;
// };

export const getRequestDetails = async (requestType, requestId) => {
  const res = await server.get(`/support/request/${requestType}/${requestId}`);
  return res;
};
export const getRequestQueueDetails = async (requestType, requestId) => {
  const res = await server.get(`/support/request/queue/${requestId}`);
  return res;
};

export const getRequestComments = async (requestId) => {
  const res = await server.get(`/support/request/${requestId}/comment`);
  return res;
};

export const getRequestAttachments = async (requestId) => {
  const res = await server.get(`/support/request/${requestId}/attachment`);
  return res;
};

export const getRequestAttachmentsDownload = async (requestId) => {
  const res = await server.get(`/support/attachment/${requestId}/download`);
  return res;
};

export const getRequestPreview = async (requestId) => {
  const res = await server.get(
    `/support/attachment/${requestId}/presigned-url`
  );

  return res;
};

export const getPreviewFromPresignedUrl = async (preSignedUrl) => {
  const res = await axios.get(preSignedUrl, { responseType: "arraybuffer" });
  return res;
};

export const getRequestCount = async () => {
  const res = await server.get("/support/request/count");
  return res;
};

export const getRequestDownload = async (type, body) => {
  const res = await server.post(`/support/request/download/${type}`, body);
  return res;
};

export const getSearchResults = async (body) => {
  const res = await server.post("/support/request/smart-search", body);
  return res;
};

export const createRequest = async (body) => {
  const res = await server.post("/support/request", body);
  if (res?.data?.messages?.[0]?.code == "QUEUE_REQUIRED") {
    const quesRes = await server.post("/support/request/queue", body);
    return quesRes;
  } else return res;
};

export const uploadAttachmentToS3 = async (fileData) => {
  try {
    const file = fileData.file;
    const buffer = await file.arrayBuffer();
    const bytes = new Uint8Array(buffer);
    const res = await axios.put(
      fileData.url,

      bytes,
      {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      }
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadAttachments = async (files) => {
  const res = await server.post(
    `/support/attachment/presigned-url`,
    { requestFiles: files },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res;
};

export const cancelRequest = async (requestId, CancelStatus) => {
  const res = await server.patch(`/support/request/${requestId}/cancel`, {
    status: CancelStatus,
  });
  return res;
};
export const cancelTransportationRequest = async (
  requestId,
  CancelStatus,
  sendingStatus = false
) => {
  const res = await server.patch(`/support/request/${requestId}/cancel`, {
    ...(sendingStatus
      ? { status: CancelStatus }
      : { comment: CancelStatus }),
  });
  return res;
};

export const cancelQueueRequest = async (requestId, CancelStatus) => {
  const res = await server.patch(`/support/request/queue/${requestId}/cancel`, {
    status: CancelStatus,
  });
  return res;
};

export const getRequestTypeSubType = async (params) => {
  const res = await server.get("/support/request/request-type");
  return res;
};

export const getDrivers = async (params) => {
  const res = await server.get("/driver/smart-search", {
    params,
  });
  return res;
};

export const getVehicles = async (params) => {
  const res = await server.get("/vehicle/smart-search", {
    params,
  });
  return res;
};

export const getDriverAssignedVehicles = async (params) => {
  const res = await server.post("/vehicle/search", {
    ...params,
  });
  return res;
};
export const addComments = async (Id, body) => {
  const res = await server.post(`/support/request/${Id}/comment`, body);
  return res;
};

export const deleteAttachment = async (body) => {
  const res = await server.delete(`/support/attachment`, {
    data: body,
  });

  return res;
};

export const markReadUnRead = async (data) => {
  const res = await server.patch(`/support/request`, {
    requests: data,
  });
};

export const getAttachments = async (requestId, commentID) => {
  const res = await server.get(
    `/support/request/attachment?caseId=${requestId}&commentId=${commentID.toString()}`
  );
  return res;
};
export const getQueueRequestPreview = async (requestId, attachmentId) => {
  const res = await server.get(
    `/support/request/queue/${requestId}/attachment/${attachmentId}/preview`
  );

  return res;
};

export const logAPIErrors = async (body) => {
  const res = await server.post("/logger", body);
};
export const scanUploadedFiles = async (files) => {
  const res = server.post(`/support/attachment/scan`, files, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
};
