import React, { useEffect, useState } from "react";
import { CloseIcon, Heading, Icon, Text } from "@leaseplan/ui";
import styled from "styled-components";
import { Spacing, ConvertSize, Colors } from "../../utils/theme";
import { useDispatch } from "react-redux";
import { hideToaster } from "../../store/toaster/toasterActions";
import uuid from "react-uuid";

const GlobalToaster = (props) => {
  const dispatch = useDispatch();
  const { messages } = props;
  const [toasts, setToasts] = useState(messages);

  useEffect(() => {
    setToasts([...messages]);
  }, [messages]);

  useEffect(() => {
    if (messages.length > 1) {
      hideToasterMessage(messages[0]);
    }
  }, [messages, toasts]);

  const hideToasterMessage = (message) => {
    dispatch(hideToaster(message));
  };

  return (
    <Wrapper>
      {toasts.map((toast, index) => (
        <ToastWrapper key={uuid()}>
          <BarView color={toast.color} />
          <IconWrapper>
            <img src={toast.icon}></img>
          </IconWrapper>
          <TextWrapper>
            <Text size="m" color="white" fontWeight="bold">
              {toast.title}
            </Text>
            <InfoText
              size="xs"
              color="white"
              dangerouslySetInnerHTML={{ __html: toast.message }}
            ></InfoText>
          </TextWrapper>
          <CloseIconWrapper>
            <CloseIconButton onClick={() => hideToasterMessage(toast)} />
          </CloseIconWrapper>
        </ToastWrapper>
      ))}
    </Wrapper>
  );
};

export default GlobalToaster;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  top: 55px;
  right: 12px;
`;

const ToastWrapper = styled.div`
  height: auto;
  width: 430px;
  display: flex;
  flex-direction: row;
  transition: 0.3s ease;
  position: relative;
  background: rgb(45, 45, 45);
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 10px;
  transition: transform 0.5s ease-in-out;
  animation: toast-in-right 0.5s;
  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 14px;
  padding: 5px ${Spacing.xs};
`;

const InfoText = styled(Text)`
  line-height: 1.4;
`;

const IconWrapper = styled.div`
  padding: 5px 0px ${Spacing.xxs} ${Spacing.xxs};
  padding-top: ${Spacing.xs};
  margin-top: 5px;
`;

const CloseIconWrapper = styled(IconWrapper)`
  padding: 0px 8px 0px 0px;
  display: flex;
  justify-content: center;
  color: #fff;
`;

const CloseIconButton = styled(CloseIcon)`
  cursor: pointer;
`;

const BarView = styled.div.attrs((props) => ({
  color: props.color,
}))`
  width: ${ConvertSize(6)};
  background: ${(props) => props.color};
`;
