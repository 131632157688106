import React from "react";
import { connect } from "react-redux";
import { Route, Navigate, Outlet } from "react-router-dom";
import Loading from "./Loading";

const PrivateRoute = ({ children, auth, ...rest }) => {
  const { isAuthenticated, loading } = auth;
  if (loading) return <Loading />;
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/Unauthorized"></Navigate>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(PrivateRoute);
