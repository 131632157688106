import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getLabel } from "../../../utils/prismicUtils";
import { Colors, ConvertSize } from "../../../utils/theme";

const InProgress = () => {
  const prismicData = useSelector((state) => state.prismic.prismicData);

  return (
    <InProgressConatiner>
      <Text>
        {getLabel("inprogress_request_count_title", "In Progress", prismicData)}
      </Text>
    </InProgressConatiner>
  );
};

export default InProgress;

const InProgressConatiner = styled.div`
  min-width: ${ConvertSize(61)};
  min-height: ${ConvertSize(15)};
  border-radius: ${ConvertSize(2)};
  border: 1px solid ${Colors.bloodOrange};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  @media (max-width: ${ConvertSize(500)}) {
    padding-left: ${ConvertSize(6)};
    padding-right: ${ConvertSize(6)};
  }
`;

const Text = styled.p`
  font-size: ${ConvertSize(8)};
  color: ${Colors.bloodOrange};
  font-weight: bold;
`;
