import * as PrismicActions from "./prismicActions";
import { client } from "../../prismic-configuration";
import {
  getCachedPrismicData,
  setCachedPrismicData,
  clearCachedPrismicData,
} from "../../utils/prismicUtils";

export const fetchPrismicData = () => async (dispatch) => {
  try {
    dispatch(PrismicActions.prismicFetchRequest());
    const dataFromCache = await getCachedPrismicData();
    if (
      JSON.parse(dataFromCache) &&
      new Date().getTime() - JSON.parse(dataFromCache).cachedDate < 900000
    ) {
      dispatch(
        PrismicActions.prismicFetchSuccess(
          JSON.parse(dataFromCache).prismicData
        )
      );
    } else {
      let prismicData = await client.query("", {
        pageSize: 100,
      });
      if (
        prismicData &&
        prismicData.results &&
        prismicData.results.length > 0
      ) {
        prismicData = formatPrismicData(prismicData.results);
        await setCachedPrismicData(
          JSON.stringify({
            cachedDate: new Date().getTime(),
            prismicData,
          })
        );
        dispatch(PrismicActions.prismicFetchSuccess(prismicData));
      } else {
        dispatch(
          PrismicActions.prismicFetchFail(
            "Error while fetching data from prismic"
          )
        );
      }
    }
  } catch (err) {
    dispatch(PrismicActions.prismicFetchFail(err));
  }
};

const formatPrismicData = (prismicData) => {
  let formattedData = {};
  for (let i = 0; i < prismicData.length; i++) {
    formattedData[prismicData[i]["uid"]] = {};
    const prismicKeys = Object.keys(prismicData[i].data);
    for (let j = 0; j < prismicKeys.length; j++) {
      formattedData[prismicData[i]["uid"]][prismicKeys[j]] =
        prismicData[i]["data"][prismicKeys[j]];
    }
  }

  return formattedData.eplan_ignite_workspace_en;
};
