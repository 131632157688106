import React, { useState } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import _ from "lodash";
import { ConvertSize, Spacing, Colors } from "../../utils/theme";
import { Text } from "@leaseplan/ui";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const Search = ({
  type,
  placeHolder,
  width = 500,
  onChange,
  name,
  value,
  disabled,
  error,
  prismicData,
  onSearchClear,
  deleteSearchText,
  openSearchResult,
  searchLoad,
  onKeyDown,
  hideSearchIcon,
  isLinked,
  ...props
}) => {
  return (
    <>
      <SearchWrapper type={type == "sidePanel" ? true : false}>
        <TableSearch
          isDisabled={disabled}
          hideSearchIcon={hideSearchIcon}
          type={type == "sidePanel" ? true : false}
        >
          <SearchIcon
            htmlColor={Colors.petrolBlue}
            onClick={openSearchResult}
          />

          <SearchTextInput
            name={name}
            disabled={disabled}
            onChange={onChange}
            inputProps={{ maxLength: 100 }}
            placeholder={placeHolder}
            width={width}
            value={value}
            autoComplete="off"
            onKeyDown={onKeyDown}
            {...props}
          />
          {type == "sidePanel" && !isLinked
            ? value && (
                <Image
                  style={{ cursor: "pointer", marginLeft: "auto" }}
                  src={prismicData?.close_icon?.url}
                  onClick={onSearchClear}
                />
              )
            : null}
          {hideSearchIcon ? (
            <ArrowDropDownIcon
              style={{ marginLeft: "10px", cursor: "pointer" }}
              htmlColor={"#545656"}
              onClick={openSearchResult}
            />
          ) : null}
          {/* {searchLoad && <SearchLoading type="downloadCSV" />} */}
        </TableSearch>
      </SearchWrapper>
      {error && (
        <Text size="xs" style={{ color: "red" }}>
          {error}
        </Text>
      )}
    </>
  );
};

const TableSearch = styled.div`
  background-color: ${({ isDisabled }) =>
    isDisabled ? `${Colors.disabled}` : `${Colors.white}`};
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: ${Spacing.l};
  padding: ${({ hideSearchIcon }) =>
    hideSearchIcon
      ? `0px ${Spacing.xs} 0px ${Spacing.l}`
      : `0px ${Spacing.l} 0px`};
  margin: 0px ${Spacing.xs} 0px 0px;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  justify-content: flex-start;

  ${({ type }) =>
    type &&
    `
  border: 1px solid #bdbdbd;
  border-radius: ${Spacing.xxxs};
  margin: 0px;
`}
`;

const SearchTextInput = styled(InputBase)`
  margin-left: ${Spacing.xs};
  padding: ${Spacing.xxxs};
  box-sizing: border-box;
  width: ${ConvertSize(200)} !important;

  @media (min-width: 600px) {
    width: ${({ width }) => width && `${ConvertSize(width)} !important`};
  }
`;

const SearchWrapper = styled.div`
  margin: ${Spacing.xxxs} 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  @media (min-width: 600px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0px ${Spacing.s} 0px;
  }
  ${({ type }) =>
    type &&
    `
  @media (min-width: 600px) {
    margin: ${Spacing.xxxs} 0px;
  }
`}
`;

const Image = styled.img`
  height: ${ConvertSize(12)};
  width: ${ConvertSize(11)};
`;
export default Search;
