import * as AuthActions from "./authActions";
import * as AuthApi from "./authApi";

export const login = (request) => async (dispatch) => {
  dispatch(AuthActions.loginRequest());
  try {
    const res = await AuthApi.login({
      userId: request.userId,
      clientNumber: request.clientNo,
      token: request.token
    });
    if (res?.status === 200) {
      dispatch(AuthActions.login(res?.data));
    }
  } catch (e) {
    console.log(e);
    dispatch(AuthActions.loginFail(e));
  }
};

export const logout = () => async (dispatch) => {
  try {
    const refToken = sessionStorage.getItem("refreshToken");
    if (refToken) {
      const res = await AuthApi.logout(refToken);
    }
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("idToken");
    dispatch(AuthActions.logout());
  } catch (e) {
    console.log(e);
  }
};

export const validateToken = (token) => async (dispatch) => {
  dispatch(AuthActions.loginRequest());
  try {
    const res = await AuthApi.validateToken(token);
    const idToken = sessionStorage.getItem("idToken");
    const accessToken = sessionStorage.getItem("accessToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    dispatch(AuthActions.login({ idToken, accessToken, refreshToken }));
    console.log(res, "Validate token");
  } catch (e) {
    console.log(e);
    const refreshToken = sessionStorage.getItem("refreshToken");
    getNewSession(token, refreshToken);
  }
};

export const getNewSession =
  (
    token = sessionStorage.getItem("idToken"),
    refreshToken = sessionStorage.getItem("refreshToken")
  ) =>
    async (dispatch) => {
      dispatch(AuthActions.loginRequest());
      try {
        const res = await AuthApi.getNewSession(token, refreshToken);
        sessionStorage.setItem("idToken", res.data.idToken);
        sessionStorage.setItem("accessToken", res.data.accessToken);
        sessionStorage.setItem("refreshToken", res.data.refreshToken);
        dispatch(AuthActions.login(res.data));
      } catch (e) {
        console.log(e);
        dispatch(AuthActions.logout());
      }
    };
