import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Heading, Text, TextInput, Button as LPbutton } from "@leaseplan/ui";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ReferenceFilter from "./TableFilters/ReferenceFilter";

import { getRequests, getSearchResults } from "../../store/request/requestApi";
import CloseIcon from "@mui/icons-material/Close";

import { Colors, ConvertSize, Spacing } from "../../utils/theme";
import { getLabel } from "../../utils/prismicUtils";

import debounce from "lodash/debounce";
import Loading from "../Loading";
import RequestedBy from "./TableFilters/RequestedBy";
import EplanUnit from "./TableFilters/EplanUnit";
import ClientUnit from "./TableFilters/ClientUnit";
import VIN from "./TableFilters/VIN";
import { isEmpty, set } from "lodash";
import { DebounceInput } from "react-debounce-input";
const SearchMenu = ({
  anchorEl,
  handleClose,
  open,
  search,
  updateSearchQuery,
  removeSearchQuery,
  driverName,
  vehicleNumber,
  clientUnitNumber,
  vin,
  createUserName,
  statusArray,
  createDateStart,
  createDateEnd,
  caseNumber,
  prismicData,
  tabValue,
  requestType,
  readStatus,
}) => {
  const { totalRecords } = useSelector((state) => state.request);
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState(null);
  const [requestBysearch, setRequestBysearch] = useState(null);
  const [driverSearch, setDriverSearch] = useState(null);
  const [vehicleNumberSearch, setVehicleNumberSearch] = useState(null);
  const [clientUnitNumberSearch, setClientUnitNumberSearch] = useState(null);
  const [vinSearch, setVinSearch] = useState(null);
  const [caseNumberSearch, setCaseNumberSearch] = useState(null);
  const [responseLength, setResponseLength] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [loading, setLoading] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("");
  const [searchDescription, setSearchDescription] = useState("");

  const updateGlobalFilter = (type, key) => {
    removeSearchQuery(type);
    handleClose();
  };
  const onClickSearch = (item) => () => {
    if (search == "Driver") {
      setSearchText(item);
      updateSearchQuery({ pageNumber: 1, driverName: item });
      setResponseLength(0);
      handleClose();
    } else if (search == "ePlan Unit") {
      setSearchText(item);
      updateSearchQuery({ pageNumber: 1, vehicleNumber: item });
      setResponseLength(0);
      handleClose();
    } else if (search == "Client Unit") {
      setSearchText(item);
      updateSearchQuery({ pageNumber: 1, clientUnitNumber: item });
      setResponseLength(0);
      handleClose();
    } else if (search == "VIN") {
      setSearchText(item);
      updateSearchQuery({ pageNumber: 1, vin: item });
      setResponseLength(0);
      handleClose();
    } else if (search == "Requested By") {
      setSearchText(item);
      updateSearchQuery({ pageNumber: 1, createUserName: item });
      setResponseLength(0);
      handleClose();
    } else if (search == "Reference ID") {
      setSearchText(item);
      updateSearchQuery({ pageNumber: 1, caseNumber: item });
      setResponseLength(0);
      handleClose();
    }
  };
  const filterType = () => {
    if (search == "Driver") {
      return { value: driverName, key: "driverName" };
    } else if (search == "ePlan Unit") {
      return { value: vehicleNumber, key: "vehicleNumber" };
    } else if (search == "Client Unit") {
      return { value: clientUnitNumber, key: "clientUnitNumber" };
    } else if (search == "VIN") {
      return { value: vin, key: "vin" };
    } else if (search == "Requested By") {
      return { value: createUserName, key: "createUserName" };
    } else if (search == "Reference ID") {
      return { value: caseNumber, key: "caseNumber" };
    }
  };

  const onClearSearch = () => {
    if (search == "Driver") {
      setSearchText("");
      setDriverSearch("");
      setResponseLength(0);
    } else if (search == "ePlan Unit") {
      setSearchText("");
      setVehicleNumberSearch("");
      setResponseLength(0);
    } else if (search == "Client Unit") {
      setSearchText("");
      setClientUnitNumberSearch("");
      setResponseLength(0);
    } else if (search == "VIN") {
      setSearchText("");
      setVinSearch("");
      setResponseLength(0);
    } else if (search == "Requested By") {
      setSearchText("");
      setRequestBysearch("");
      setResponseLength(0);
    } else if (search == "Reference ID") {
      setSearchText("");
      setCaseNumberSearch("");
      setResponseLength(0);
    }
  };

  const loadMore = async () => {
    if (search == "Client Unit") {
      if (!isEmpty(searchText)) {
        const res = await getSearchResults({
          clientUnitNumber: searchText,
          type: "clientUnitNumber",
          pageSize: 6,
          ignorePagination: false,
          offSet: offSet == 0 ? offSet + 5 : offSet,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(driverName && { driverName }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(vin && { vin }),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);
        setResponseLength(res?.data?.length);

        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.clientUnitNumber) === -1) {
            acc.push({
              clientUnitNumber: item.clientUnitNumber,
              vehicleNumber: item.vehicleNumber,
              vehicle: item.vehicleYear + item.vehicleMake,
            });
          }
          return acc;
        }, []);
        setLoading(false);
        setOffSet(offSet + 5);
        setClientUnitNumberSearch([...clientUnitNumberSearch, ...filteredData]);
      }
    }
    if (search == "Reference ID") {
      if (!isEmpty(searchText)) {
        const res = await getSearchResults({
          caseNumber: searchText,
          type: "caseNumber",
          pageSize: 6,
          offSet: offSet == 0 ? offSet + 5 : offSet,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vin && { vin }),
          ...(vehicleNumber && { vehicleNumber }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(driverName && { driverName }),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
        });
        setResponseLength(res?.data?.length);
        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.caseNumber) === -1) {
            acc.push({
              caseNumber: item.caseNumber,
              createDate: item.createDate,
              subType: item.subType,
            });
          }
          return acc;
        }, []);
        setLoading(false);
        setOffSet(offSet + 5);
        setCaseNumberSearch([...caseNumberSearch, ...filteredData]);
      }
    }
    if (search == "Requested By") {
      if (!isEmpty(searchText)) {
        const res = await getSearchResults({
          createUserName: searchText,
          type: "createUserName",
          pageSize: 6,
          offSet: offSet == 0 ? offSet + 5 : offSet,
          ...(driverName && { driverName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);
        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.name) === -1) {
            acc.push({ name: item.name, driverId: item.driverId });
          }
          return acc;
        }, []);
        setLoading(false);
        setOffSet(offSet + 5);
        setRequestBysearch([...requestBysearch, ...filteredData]);
      }
    }

    if (search == "ePlan Unit") {
      if (!isEmpty(searchText)) {
        const res = await getSearchResults({
          vehicleNumber: searchText,
          type: "vehicleNumber",
          pageSize: 6,
          offSet: offSet == 0 ? offSet + 5 : offSet,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(driverName && { driverName }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);

        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.vehicleNumber) === -1) {
            acc.push({
              clientUnitNumber: item.clientUnitNumber,
              vehicleNumber: item.vehicleNumber,
              vehicle: item.vehicleYear + " " + item.vehicleMake,
            });
          }
          return acc;
        }, []);
        setLoading(false);
        setOffSet(offSet + 5);
        setVehicleNumberSearch([...vehicleNumberSearch, ...filteredData]);
      }
    }
    if (search == "VIN") {
      if (!isEmpty(searchText)) {
        const res = await getSearchResults({
          vin: searchText,
          type: "vin",
          pageSize: 6,
          offSet: offSet == 0 ? offSet + 5 : offSet,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(driverName && { driverName }),
          ...(createDateStart && { createDateStart }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);

        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.vin) === -1) {
            acc.push({
              vin: item.vin,
              clientUnitNumber: item.clientUnitNumber,
              vehicle: item.vehicleYear + " " + item.vehicleMake,
            });
          }
          return acc;
        }, []);
        setLoading(false);
        setOffSet(offSet + 5);
        setVinSearch([...vinSearch, ...filteredData]);
      }
    }
    if (search == "Driver") {
      if (!isEmpty(searchText)) {
        const res = await getSearchResults({
          driverName: searchText,
          type: "driverName",
          pageSize: 6,
          offSet: offSet == 0 ? offSet + 5 : offSet,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);

        const filteredData = res.data?.map((item) => {
          return { name: item.name, driverId: item.driverId };
        });
        setLoading(false);
        setOffSet(offSet + 5);
        setDriverSearch([...driverSearch, ...filteredData]);
      }
    }
  };
  useEffect(() => {
    if (open) {
      if (search == "Driver") {
        if (driverName) {
          setSearchText(driverName);
        } else {
          setPlaceHolder(
            getLabel("driver_search_place_holder", "Driver", prismicData)
          );
          setSearchDescription(
            getLabel(
              "driver_search_description",
              "Search for any part of the driver's first or last name",
              prismicData
            )
          );
          setSearchText(null);
          setOffSet(0);
          setResponseLength(0);
          setDriverSearch(null);
        }
      } else if (search == "ePlan Unit") {
        if (vehicleNumber) {
          setSearchText(vehicleNumber);
        } else {
          setPlaceHolder(
            getLabel(
              "eplan_unit_search_place_holder",
              "ePlan Unit",
              prismicData
            )
          );
          setSearchDescription(
            getLabel(
              "eplan_unit_search_description",
              "Search for any part of the ePlan unit number",
              prismicData
            )
          );

          setSearchText(null);
          setOffSet(0);
          setResponseLength(0);
          setVehicleNumberSearch(null);
        }
      } else if (search == "Client Unit") {
        if (clientUnitNumber) {
          setSearchText(clientUnitNumber);
        } else {
          setPlaceHolder(
            getLabel(
              "client_unit_search_place_holder",
              "Client Unit",
              prismicData
            )
          );
          setSearchDescription(
            getLabel(
              "client_unit_search_description",
              "Search for any part of the client unit number",
              prismicData
            )
          );

          setSearchText(null);
          setOffSet(0);
          setResponseLength(0);
          setClientUnitNumberSearch(null);
        }
      } else if (search == "VIN") {
        if (vin) {
          setSearchText(vin);
        } else {
          setPlaceHolder(
            getLabel("vin_number_search_place_holder", "VIN", prismicData)
          );
          setSearchDescription(
            getLabel(
              "vin_number_search_description",
              "Search for any part of the vehicle identification number",
              prismicData
            )
          );
          setSearchText(null);
          setOffSet(0);
          setResponseLength(0);
          setVinSearch(null);
        }
      } else if (search == "Requested By") {
        if (createUserName) {
          setSearchText(createUserName);
        } else {
          setPlaceHolder(
            getLabel(
              "requested_by_search_place_holder",
              "Requested By",
              prismicData
            )
          );
          setSearchDescription(
            getLabel(
              "requested_by_search_description",
              "Search for any part of the requestor's first or last name",
              prismicData
            )
          );
          setSearchText(null);
          setOffSet(0);
          setResponseLength(0);
          setRequestBysearch(null);
        }
      } else if (search == "Reference ID") {
        if (caseNumber) {
          setSearchText(caseNumber);
        } else {
          setPlaceHolder(
            getLabel(
              "reference_id_search_place_holder",
              "Reference ID",
              prismicData
            )
          );
          setSearchDescription(
            getLabel(
              "reference_id_search_description",
              "Search for any part of the request reference ID number",
              prismicData
            )
          );
          setSearchText(null);
          setOffSet(0);
          setResponseLength(0);
          setCaseNumberSearch(null);
        }
      }
    }
  }, [open]);

  const _getStyledSuggestion = (suggestion, searchText) => {
    if (!suggestion.toString().toLowerCase().startsWith(searchText)) {
      return <span>{suggestion}</span>;
    } else {
      const userInputLength = searchText.length;

      return (
        <span>
          <span style={{ fontWeight: "bold" }}>
            {suggestion.toString().substring(0, userInputLength)}
          </span>
          {suggestion.toString().substring(userInputLength)}
        </span>
      );
    }
  };
  const onChangeRequestByText = debounce(async (e) => {
    try {
      if (e.target.value != "") {
        const res = await getSearchResults({
          createUserName: e.target.value,
          type: "createUserName",
          pageSize: 6,
          offSet: 0,
          ...(driverName && { driverName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);

        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.name) === -1) {
            acc.push({ name: item.name, driverId: item.driverId });
          }
          return acc;
        }, []);

        setLoading(false);
        setRequestBysearch(filteredData);
      } else {
        setLoading(false);
        setRequestBysearch(null);
      }
    } catch (e) {}
  }, 1000);

  const onChangeDriverText = debounce(async (e) => {
    try {
      if (e.target.value != "") {
        const res = await getSearchResults({
          driverName: e.target.value,
          type: "driverName",
          pageSize: 6,
          offSet: 0,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);

        const filteredData = res.data?.map((item) => {
          return { name: item.name, driverId: item.driverId };
        });
        setLoading(false);

        setDriverSearch(filteredData);
      } else {
        setLoading(false);
        setDriverSearch(null);
      }
    } catch (e) {}
  }, 1000);

  const onChangeVehicleNumberText = debounce(async (e) => {
    try {
      if (e.target.value != "") {
        const res = await getSearchResults({
          vehicleNumber: e.target.value,
          type: "vehicleNumber",
          pageSize: 6,
          offSet: 0,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(driverName && { driverName }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);
        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.vehicleNumber) === -1) {
            acc.push({
              clientUnitNumber: item.clientUnitNumber,
              vehicleNumber: item.vehicleNumber,
              vehicle: item.vehicleYear + " " + item.vehicleMake,
            });
          }
          return acc;
        }, []);
        setLoading(false);
        setVehicleNumberSearch(filteredData);
      } else {
        setLoading(false);
        setVehicleNumberSearch(null);
      }
    } catch (e) {}
  }, 1000);

  const onChangeClientUnitNumberText = debounce(async (e) => {
    try {
      if (e.target.value != "") {
        const res = await getSearchResults({
          clientUnitNumber: e.target.value,
          type: "clientUnitNumber",
          pageSize: 6,
          offSet: 0,
          ignorePagination: false,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(driverName && { driverName }),
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);
        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.clientUnitNumber) === -1) {
            acc.push({
              clientUnitNumber: item.clientUnitNumber,
              vehicleNumber: item.vehicleNumber,
              vehicle: item.vehicleYear + " " + item.vehicleMake,
            });
          }
          return acc;
        }, []);
        setLoading(false);
        setClientUnitNumberSearch(filteredData);
      } else {
        setLoading(false);
        setClientUnitNumberSearch(null);
      }
    } catch (e) {}
  }, 1000);

  const onChangeCaseNumberText = debounce(async (e) => {
    try {
      if (e.target.value != "") {
        const res = await getSearchResults({
          caseNumber: e.target.value,
          type: "caseNumber",
          pageSize: 6,
          offSet: 0,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vin && { vin }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(driverName && { driverName }),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
        });
        setResponseLength(res?.data?.length);
        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.caseNumber) === -1) {
            acc.push({
              caseNumber: item.caseNumber,
              createDate: item.createDate,
              subType: item.subType,
            });
          }
          return acc;
        }, []);
        setLoading(false);

        setCaseNumberSearch(filteredData);
      } else {
        setLoading(false);
        setCaseNumberSearch(null);
      }
    } catch (e) {}
  }, 1000);

  const onChangeVinText = debounce(async (e) => {
    try {
      if (e.target.value != "") {
        const res = await getSearchResults({
          vin: e.target.value,
          type: "vin",
          pageSize: 6,
          offSet: 0,
          ...(createUserName && { createUserName }),
          ...{
            status:
              statusArray?.length > 0
                ? statusArray
                : tabValue == "1"
                ? ["New", "In Progress", "Pending", "Sending", "In Review"]
                : ["Completed", "Cancelled"],
          },
          ...(vehicleNumber && { vehicleNumber }),
          ...(clientUnitNumber && { clientUnitNumber }),
          ...(driverName && { driverName }),
          ...(requestType && {requestType}),
          ...(readStatus && {readStatus}),
          ...(createDateStart && { createDateStart }),
          ...(createDateEnd && { createDateEnd }),
          ...(caseNumber && { caseNumber }),
        });
        setResponseLength(res?.data?.length);
        const filteredData = res.data?.reduce((acc, item) => {
          if (acc.indexOf(item.vin) === -1) {
            acc.push({
              vin: item.vin,
              clientUnitNumber: item.clientUnitNumber,
              vehicle: item.vehicleYear + " " + item.vehicleMake,
            });
          }
          return acc;
        }, []);
        setLoading(false);

        setVinSearch(filteredData);
      } else {
        setLoading(false);
        setVinSearch(null);
      }
    } catch (e) {}
  }, 1000);

  const onChangeTextInput = (e) => {
    setLoading(true);
    setSearchText(e.target.value);
    if (search == "Requested By") {
      onChangeRequestByText(e);
    } else if (search == "Driver") {
      onChangeDriverText(e);
    } else if (search == "ePlan Unit") {
      onChangeVehicleNumberText(e);
    } else if (search == "Client Unit") {
      onChangeClientUnitNumberText(e);
    } else if (search == "VIN") {
      onChangeVinText(e);
    } else if (search == "Reference ID") {
      onChangeCaseNumberText(e);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      //onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingBottom: "10px",
          overflow: "visible",
          filter: "drop-shadow(0px 1px 4px rgba(0,0,0,0.12))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {/* <Heading color="petrolBlue" size="xxs">
        Search {search}
      </Heading> */}
      <TextInputWrapper>
        <SearchIcon htmlColor={Colors.petrolBlue} />
        <CustomTextInput
          name="search"
          onChange={onChangeTextInput}
          minLength={2}
          debounceTimeout={1000}
          placeholder={placeHolder}
          autoFocus={true}
        />
        <Image
          src={prismicData?.close_icon?.url}
          onClick={() => onClearSearch()}
        />
      </TextInputWrapper>

      <Text style={{ fontSize: ConvertSize(12), color: Colors.petrolBlue }}>
        {searchDescription}
      </Text>

      <SearchWrapper>
        <div>
          {searchText && (
            <>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                  paddingTop: -1,
                  width: ConvertSize(340),
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <Text
                    style={{
                      fontSize: ConvertSize(12),
                      color: Colors.petrolBlue,
                      margin: ConvertSize(10),
                      fontWeight: "bold",
                    }}
                  >
                    "{searchText}"
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: ConvertSize(10),
                      justifyContent: "flex-end",
                    }}
                  >
                    <CustomButton
                      variant="secondary"
                      size="s"
                      uppercase={false}
                      onClick={onClickSearch(searchText)}
                      disabled={responseLength > 0 ? false : true}
                    >
                      {getLabel("apply_filter_title", "Apply", prismicData)}{" "}
                    </CustomButton>
                  </div>
                  {filterType()?.value && (
                    <FilterItem>
                      "{filterType().value}"
                      <CloseFilterIcon
                        onClick={() =>
                          updateGlobalFilter(filterType()?.key, filterType())
                        }
                      >
                        <CloseIcon sx={{ fontSize: 11 }} />
                      </CloseFilterIcon>
                    </FilterItem>
                  )}
                </div>
                {filterType()?.value && (
                  <CustomTextClear
                    color="petrolBlue"
                    size="xs"
                    fontWeight="bold"
                    onClick={() =>
                      updateGlobalFilter(filterType()?.key, filterType())
                    }
                  >
                    {getLabel("clear_all_title", "Clear All", prismicData)}{" "}
                  </CustomTextClear>
                )}
              </div>
            </>
          )}

          {search == "Requested By" && requestBysearch && (
            <SmartSearchContainer>
              <Text
                style={{
                  fontSize: ConvertSize(10),
                  color: Colors.midOrange,
                  fontWeight: "bold",
                }}
              >
                {requestBysearch?.length > 0 ? "Top Results" : "No results"}
              </Text>
              {requestBysearch?.length > 0 && (
                <SearchResults className="customScrollerSearch">
                  <RequestedBy
                    requestBysearch={
                      responseLength == 6
                        ? requestBysearch.slice(0, -1)
                        : requestBysearch
                    }
                    onClickSearch={onClickSearch}
                    _getStyledSuggestion={_getStyledSuggestion}
                    searchText={searchText}
                    prismicData={prismicData}
                  ></RequestedBy>
                </SearchResults>
              )}
              {responseLength == 6 && (
                <CustomText size="xs" onClick={loadMore}>
                  {getLabel("load_more_text", "Load More", prismicData)}{" "}
                </CustomText>
              )}
            </SmartSearchContainer>
          )}
          {search == "Driver" && driverSearch && (
            <SmartSearchContainer>
              <Text
                style={{
                  fontSize: ConvertSize(10),
                  color: Colors.midOrange,
                  fontWeight: "bold",
                }}
              >
                {driverSearch?.length > 0 ? "Top Results" : "No results"}
              </Text>
              {driverSearch?.length > 0 && (
                <SearchResults className="customScrollerSearch">
                  <RequestedBy
                    requestBysearch={
                      responseLength == 6 || driverSearch?.length == 6
                        ? driverSearch.slice(0, -1)
                        : driverSearch
                    }
                    driver
                    onClickSearch={onClickSearch}
                    _getStyledSuggestion={_getStyledSuggestion}
                    searchText={searchText}
                    prismicData={prismicData}
                    search={search}
                  ></RequestedBy>
                </SearchResults>
              )}
              {responseLength == 6 && (
                <CustomText size="xs" onClick={loadMore}>
                  {getLabel("load_more_text", "Load More", prismicData)}{" "}
                </CustomText>
              )}
            </SmartSearchContainer>
          )}

          {search == "ePlan Unit" && vehicleNumberSearch && (
            <SmartSearchContainer>
              <Text
                style={{
                  fontSize: ConvertSize(10),
                  color: Colors.midOrange,
                  fontWeight: "bold",
                }}
              >
                {vehicleNumberSearch?.length > 0 ? "Top Results" : "No results"}
              </Text>
              {vehicleNumberSearch?.length > 0 && (
                <SearchResults className="customScrollerSearch">
                  <EplanUnit
                    vehicleNumberSearch={
                      responseLength == 6 || vehicleNumberSearch?.length == 6
                        ? vehicleNumberSearch.slice(0, -1)
                        : vehicleNumberSearch
                    }
                    onClickSearch={onClickSearch}
                    _getStyledSuggestion={_getStyledSuggestion}
                    searchText={searchText}
                    prismicData={prismicData}
                  ></EplanUnit>
                </SearchResults>
              )}
              {responseLength == 6 && (
                <CustomText size="xs" onClick={loadMore}>
                  {getLabel("load_more_text", "Load More", prismicData)}{" "}
                </CustomText>
              )}
            </SmartSearchContainer>
          )}
          {search == "Client Unit" && clientUnitNumberSearch && (
            <SmartSearchContainer>
              <Text
                style={{
                  fontSize: ConvertSize(10),
                  color: Colors.midOrange,
                  fontWeight: "bold",
                }}
              >
                {clientUnitNumberSearch?.length > 0
                  ? "Top Results"
                  : "No results"}
              </Text>
              {clientUnitNumberSearch?.length > 0 && (
                <SearchResults className="customScrollerSearch">
                  <ClientUnit
                    clientUnitNumberSearch={
                      responseLength == 6 || clientUnitNumberSearch?.length == 6
                        ? clientUnitNumberSearch.slice(0, -1)
                        : clientUnitNumberSearch
                    }
                    onClickSearch={onClickSearch}
                    _getStyledSuggestion={_getStyledSuggestion}
                    searchText={searchText}
                    prismicData={prismicData}
                  ></ClientUnit>
                </SearchResults>
              )}
              {responseLength == 6 && (
                <CustomText size="xs" onClick={loadMore}>
                  {getLabel("load_more_text", "Load More", prismicData)}{" "}
                </CustomText>
              )}
            </SmartSearchContainer>
          )}
          {search == "VIN" && vinSearch && (
            <SmartSearchContainer>
              <Text
                autoFocused
                style={{
                  fontSize: ConvertSize(10),
                  color: Colors.midOrange,
                  fontWeight: "bold",
                }}
              >
                {vinSearch?.length > 0 ? "Top Results" : "No results"}
              </Text>
              {vinSearch?.length > 0 && (
                <SearchResults className="customScrollerSearch">
                  <VIN
                    vinSearch={
                      responseLength == 6 || vinSearch?.length == 6
                        ? vinSearch.slice(0, -1)
                        : vinSearch
                    }
                    onClickSearch={onClickSearch}
                    _getStyledSuggestion={_getStyledSuggestion}
                    searchText={searchText}
                    prismicData={prismicData}
                  ></VIN>
                </SearchResults>
              )}
              {responseLength == 6 && (
                <CustomText size="xs" onClick={loadMore}>
                  {getLabel("load_more_text", "Load More", prismicData)}{" "}
                </CustomText>
              )}
            </SmartSearchContainer>
          )}
          {search == "Reference ID" && caseNumberSearch && (
            <SmartSearchContainer>
              <Text
                style={{
                  fontSize: ConvertSize(10),
                  color: Colors.midOrange,
                  fontWeight: "bold",
                }}
              >
                {caseNumberSearch?.length > 0 ? "Top Results" : "No results"}
              </Text>
              {caseNumberSearch?.length > 0 && (
                <SearchResults className="customScrollerSearch">
                  <ReferenceFilter
                    caseNumberSearch={
                      responseLength == 6 || caseNumberSearch?.length == 6
                        ? caseNumberSearch.slice(0, -1)
                        : caseNumberSearch
                    }
                    searchText={searchText}
                    _getStyledSuggestion={_getStyledSuggestion}
                    onClickSearch={onClickSearch}
                    prismicData={prismicData}
                  ></ReferenceFilter>
                </SearchResults>
              )}
              {responseLength == 6 && (
                <CustomText size="xs" onClick={loadMore}>
                  {getLabel("load_more_text", "Load More", prismicData)}{" "}
                </CustomText>
              )}
            </SmartSearchContainer>
          )}
        </div>
      </SearchWrapper>
    </Menu>
  );
};

export default SearchMenu;

const FilterItem = styled.div`
  background: ${Colors.petrolBlue};

  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xs};
  margin-right: ${Spacing.xxxs};
  margin-left: ${Spacing.xs};
  font-size: ${Spacing.xs};
  border-radius: 30px;
  color: white;
  display: inline-block;
  padding-top: ${ConvertSize(5)};
  padding-bottom: ${ConvertSize(5)};
  padding-left: ${ConvertSize(12)};
  padding-right: ${ConvertSize(22)};
  text-align: center;
  position: relative;
  cursor: default;
`;

const TR = styled.tr`
  background-color: ${(props) => props.read && Colors.viewedRow};

  :hover {
    cursor: pointer;
  }
`;
const THead = styled.thead`
  ${TR} {
    background-color: ${Colors.tableHeader};
  }
`;
const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`;

const TextInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Colors.steel20};
  height: ${ConvertSize(40)};
  font-size: ${ConvertSize(14)};
`;

const CustomTextInput = styled(DebounceInput)`
  margin: 2px;
  border-color: transparent;
  border-radius: ${Spacing.xxxs};
  height: ${ConvertSize(38)};
  font-size: ${ConvertSize(14)};
  box-shadow: none;
  outline: 0;
  :focus {
    border-color: transparent;
    box-shadow: none;
  }
  width: ${ConvertSize(280)};
`;

const SmartSearchContainer = styled.div``;

const SearchResults = styled.div`
  margin-bottom: 0;
  padding-bottom: 0;
  max-height: ${ConvertSize(165)};
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchWrapper = styled.div`
  margin-top: ${Spacing.xxxs};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: ${ConvertSize(340)};
`;

const CustomText = styled(Text)`
  cursor: pointer;
  color: ${Colors.midOrange};
  font-weight: bold;
  text-decoration: underline;
  margin
`;
const CustomTextClear = styled(Text)`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  margin-top: ${Spacing.xs};
`;
const CustomButton = styled(LPbutton)`
  textTransform: "none"
  border-radius: ${Spacing.xxl};
  font-size: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${Spacing.xs};
  padding-left: ${Spacing.xs};
  padding-top: ${Spacing.xs};
  padding-bottom: ${Spacing.xs};
  height: ${ConvertSize(2)};
  color: ${Colors.petrolBlue};
  border-color: ${Colors.petrolBlue};
  font-size: ${ConvertSize(10)};
`;
const CloseFilterIcon = styled.span`
  position: absolute;
  right: 6px;
  cursor: pointer;
`;
const Image = styled.img`
  height: ${ConvertSize(12)};
  width: ${ConvertSize(11)};
`;
