import React, { useState, useEffect } from "react";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { styled as styledMUI } from "@mui/material/styles";
import { Text, Button as LPbutton, Color, Spacing } from "@leaseplan/ui";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import calender from "../../assets/images/calendar.svg";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Colors, ConvertSize } from "../../utils/theme";
import { get } from "lodash";
import { getMmDdYY } from "../../utils/calender";
import { getLabel } from "../../utils/prismicUtils";
import { RainbowThemeContainer, DatePicker } from "react-rainbow-components";
const GlobalFilterMenu = ({
  anchorEl,
  handleClose,
  open,
  filterData,
  updateSearchQuery,
  removeSearchQuery,
  statusArray = [],
  createDateStart,
  createDateEnd,
  dateTitle,
  repliedDateEnd,
  repliedDateStart,
  prismicData,
}) => {
  const [isChecked, setIsChecked] = useState({});
  const [createStartDateValue, setCreateStartDateValue] = useState(null);
  const [createEndDateValue, setCreateEndDateValue] = useState(null);

  const [value, setValue] = useState(moment());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [focusValue, setFocusValue] = useState(false);
  const [repliedDateStartValue, setRepliedDateStartValue] = useState(null);
  const [repliedDateEndValue, setRepliedDateEndValue] = useState(null);
  const onClickCheckBox = (uniqueId) => () => {
    setIsChecked((prev) => {
      return { ...prev, [uniqueId]: !(prev[uniqueId] || false) };
    });
  };

  useEffect(() => {
    if (open) {
      statusArray?.length > 0 &&
        setIsChecked(
          statusArray?.reduce((acc, item) => {
            return { ...acc, [item]: true };
          }, {})
        );
      setCreateStartDateValue(createDateStart ? moment(createDateStart) : null);
      setCreateEndDateValue(createDateEnd ? moment(createDateEnd) : null);
      setRepliedDateStartValue(
        repliedDateStart ? moment(repliedDateStart) : null
      );
      setRepliedDateEndValue(repliedDateEnd ? moment(repliedDateEnd) : null);
    }
  }, [open]);

  useEffect(() => {
    if (Object.values(isChecked).find((i) => i) == undefined) {
      // removeSearchQuery("status");

      handleClose();
    }
  }, [isChecked]);

  const isAnyOneSelected = Object.values(isChecked).find((i) => i);

  const onApplyFilter = () => {
    const checkedValues = Object.entries(isChecked).filter((i) => i[1]);
    const query = checkedValues.reduce(
      (acc, item, index) => `${item[0]},${acc}`,
      ""
    );
    if (dateTitle === "Last Update Range") {
      updateSearchQuery({
        pageNumber: 1,
        ...(!isEmpty(query) && { status: query }),
        ...(repliedDateStartValue && {
          repliedDateStart: moment(repliedDateStartValue).format(
            "YYYY-MM-DD HH:MM:SS"
          ),
        }),
        ...(repliedDateEndValue && {
          repliedDateEnd: moment(repliedDateEndValue).format(
            "YYYY-MM-DD HH:MM:SS"
          ),
        }),
      });
      handleClose();
    } else
      updateSearchQuery({
        pageNumber: 1,
        ...(!isEmpty(query) && { status: query }),
        ...(createStartDateValue && {
          createDateStart: moment(createStartDateValue).format(
            "YYYY-MM-DD HH:MM:SS"
          ),
        }),
        ...(createEndDateValue && {
          createDateEnd: moment(createEndDateValue).format(
            "YYYY-MM-DD 23:59:59"
          ),
        }),
      });
    handleClose();
  };

  const onClickClear = () => {
    if (dateTitle === "Last Update Range")
      removeSearchQuery(["status", "repliedDateStart", "repliedDateEnd"]);
    else removeSearchQuery(["status", "createDateEnd", "createDateStart"]);

    statusArray?.length > 0 &&
      setIsChecked(
        statusArray?.reduce((acc, item) => {
          return { ...acc, [item]: false };
        }, {})
      );
    handleClose();
  };
  const theme = {
    rainbow: {
      palette: {
        brand: "#f06400",
      },
    },
  };
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          padding: "10px 20px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "5px",
        }}
      >
        <Text color="midOrange" size="xs" fontWeight="bold">
          {getLabel("date_range_title", "Date Range", prismicData)}{" "}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "min-content",
          marginBottom: ConvertSize(10),
        }}
      >
        {/* <SingleDatePicker
          numberOfMonths={1}
          onDateChange={(date) => {
            console.log(date);
            setValue(date);
          }}
          focused={focusValue}
          date={value}
          onFocusChange={({ focused }) => {
            console.log(focused);
            setFocusValue(focused);
          }}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        /> */}
        <RainbowThemeContainer theme={theme}>
          <DatePicker
            maxDate={
              repliedDateEndValue || createEndDateValue
                ? dateTitle === "Last Update Range"
                  ? new Date(repliedDateEndValue)
                  : new Date(createEndDateValue)
                : new Date()
            }
            style={{ width: "200px" }}
            value={
              dateTitle === "Last Update Range"
                ? repliedDateStartValue
                : createStartDateValue
            }
            variant="error"
            placeholder="MM/DD/YYYY"
            onChange={(newValue) => {
              dateTitle === "Last Update Range"
                ? setRepliedDateStartValue(newValue)
                : setCreateStartDateValue(newValue);
            }}
            icon={<img src={calender}></img>}
            formatStyle="medium"
            locale={"en-US"}
          />
        </RainbowThemeContainer>

        <RainbowThemeContainer theme={theme}>
          <DatePicker
            style={{ width: "200px", marginLeft: "10px" }}
            maxDate={new Date()}
            minDate={
              repliedDateStartValue || createStartDateValue
                ? dateTitle === "Last Update Range"
                  ? new Date(repliedDateStartValue)
                  : new Date(createStartDateValue)
                : null
            }
            value={
              dateTitle === "Last Update Range"
                ? repliedDateEndValue
                : createEndDateValue
            }
            variant="error"
            placeholder="MM/DD/YYYY"
            onChange={(newValue) => {
              dateTitle === "Last Update Range"
                ? setRepliedDateEndValue(newValue)
                : setCreateEndDateValue(newValue);
            }}
            icon={<img src={calender}></img>}
            formatStyle="medium"
            locale={"en-US"}
          />
        </RainbowThemeContainer>
      </div>
      {createStartDateValue &&
        createEndDateValue &&
        dateTitle !== "Last Update Range" && (
          <FilterItem>
            {getLabel(
              "date_submitted_title",
              "date submitted title",
              prismicData
            )}{" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {getMmDdYY(createStartDateValue)} -{" "}
              {getMmDdYY(createEndDateValue)}
            </span>
            <CloseFilterIcon onClick={() => onClickClear()}>
              <CloseIcon sx={{ fontSize: 11 }} />
            </CloseFilterIcon>
          </FilterItem>
        )}

      {repliedDateStartValue &&
        repliedDateEndValue &&
        dateTitle === "Last Update Range" && (
          <FilterItem>
            {getLabel("date_submitted_title", "Date Submitted:", prismicData)}{" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {getMmDdYY(repliedDateStartValue)} -{" "}
              {getMmDdYY(repliedDateEndValue)}
            </span>
            <CloseFilterIcon onClick={() => onClickClear()}>
              <CloseIcon sx={{ fontSize: 11 }} />
            </CloseFilterIcon>
          </FilterItem>
        )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: ConvertSize(10),
          justifyContent: "flex-end",
        }}
      >
        <CustomText
          color="petrolBlue"
          size="xs"
          fontWeight="bold"
          onClick={onClickClear}
        >
          {getLabel("clear_all_title", "Clear All", prismicData)}
        </CustomText>
        <CustomButton
          disabled={
            dateTitle === "Last Update Range"
              ? repliedDateStartValue && repliedDateEndValue
                ? false
                : true
              : createStartDateValue && createEndDateValue
              ? false
              : true
          }
          variant="secondary"
          size="s"
          onClick={onApplyFilter}
        >
          {getLabel("apply_filter_title", "Apply", prismicData)}{" "}
        </CustomButton>
      </div>

      {/* <LPbutton
        variant="secondary"
        size="s"
        disabled={
          !(isAnyOneSelected || createStartDateValue || createEndDateValue)
        }
        onClick={onApplyFilter}
        style={{
          color:
            isAnyOneSelected || createStartDateValue || createEndDateValue
              ? Colors.petrolBlue
              : Colors.lightGrey,
          borderColor:
            isAnyOneSelected || createStartDateValue || createEndDateValue
              ? Colors.petrolBlue
              : Colors.lightGrey,
        }}
      >
        APPLY FILTER
      </LPbutton> */}
    </Menu>
  );
};

export default GlobalFilterMenu;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const CustomText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

const CustomTextFilter = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;
const FilterItem = styled.div`
  background: ${Colors.petrolBlue};
  margin-top: ${Spacing.s};
  margin-bottom: ${Spacing.xs};
  margin-right: ${Spacing.xxxs};
  margin-left: ${Spacing.xs};
  font-size: 10px;
  border-radius: 30px;
  color: white;
  display: inline-block;
  padding-top: ${ConvertSize(5)};
  padding-bottom: ${ConvertSize(5)};
  padding-left: ${ConvertSize(12)};
  padding-right: ${ConvertSize(22)};
  text-align: center;
  font-family: LeasePlan;
  position: relative;
  cursor: default;
`;
const CloseFilterIcon = styled.span`
  position: absolute;
  right: 6px;
  cursor: pointer;
`;

const CustomButton = styled(LPbutton)`
  textTransform: "none"
  border-radius: ${Spacing.xxl};
  font-size: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${ConvertSize(16)};
  padding-left:${ConvertSize(16)};
  padding-top: ${ConvertSize(6)};
  padding-bottom: ${ConvertSize(6)};
  margin-top: ${Spacing.xxs};
  color: ${Colors.petrolBlue};
  border-color: ${Colors.petrolBlue};
  font-size: ${ConvertSize(8)};
  margin-left: ${ConvertSize(18)};

`;
