import { SHOW_TOASTER, HIDE_TOASTER } from "./toasterConstants";

export const showToaster = (toastMessage) => {
  return {
    type: SHOW_TOASTER,
    payload: toastMessage
  };
};

export const hideToaster = (toastMessage) => {
  return {
    type: HIDE_TOASTER,
    payload: toastMessage
  };
};

