import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Heading, Text } from "@leaseplan/ui";
import { Colors, ConvertSize, Spacing } from "../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { getLabel } from "../utils/prismicUtils";
import { LEGACY_URL } from "../utils/constants";
import { showSidePanel } from "../store/commonStore/commonActionCreators";
import Tutorial from "./Tutorial";
import uuid from "react-uuid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { EXTERNAL_REQUEST } from "../helpers/logEvents";
import logEventFirebase from "../config/firebase";
import { logFunctionError } from "../config/errorLog";
const QuickLinks = (props) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const { showTutorial } = useSelector((state) => state.common);
  const { loading, requests, recordsInPage } = useSelector(
    (state) => state.request
  );
  const tutorial = useRef();
  const settingsData = useSelector((state) => state.settings.settings);
  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );
  const quicklinksData = useSelector(
    (state) => state.requestDropdown.quicklinks
  );
  // ?.keyName["client/transportation/setting"]
  // const quickLinks = useSelector((state) => {
  //   return state.requestDropdown.quicklinks?.filter((val) => {
  //     if (
  //       val?.subcategory?.actionText2?.toLowerCase() ===
  //       "Manufacturer Dates".toLowerCase()
  //     ) {
  //       return val;
  //     }
  //   });
  // });
  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );

  const checkQuickLinks = (value) => {
    let returnValue = false;
    quicklinksData?.filter((val) => {
      if (
        val?.subcategory?.actionText2?.toLowerCase() === value.toLowerCase()
      ) {
        returnValue = true;
      }
    });
    return returnValue;
  };
  const externalLink = [
    "Authorize service",
    // "What's new at LPUSA",
    "License & Title in progress",
    // "Manufacturer dates",
  ];
  const displayForm = settingsData?.functionSetting["fleet/termination/form"]
    ? true
    : false;
  const authServiceUrl =
    LEGACY_URL +
    getLabel(
      "auth_service_url",
      "/authorization/pendingAuthorizations.lp",
      prismicData
    );
  const whatsNew = getLabel(
    "whats_new_url",
    "https://www.leaseplan.com/en-us/customer-pulse-newsletter/",
    prismicData
  );
  const licenseURL =
    LEGACY_URL +
    getLabel("license_url", "/service/license/workInProcess.lp", prismicData);
  const displayNewVendor = settingsData?.functionSetting[
    "fleet/forms/access/replacement/newvendor"
  ]
    ? true
    : false;

  const displayServiceForm = settingsData?.functionSetting[
    "fleet/termination/service/form"
  ]
    ? true
    : false;

  const displaySettingApproval = settingsData?.functionSetting[
    "admin/settings/approval"
  ]
    ? true
    : false;

  const displaySettingAccess = settingsData?.functionSetting[
    "admin/settings/access"
  ]
    ? true
    : false;

  const displaySalesForm = settingsData?.functionSetting["fleet/sales/form"]
    ? true
    : false;

  const displayDriverCreation = settingsData?.functionSetting["driver/creation"]
    ? true
    : false;

  const displayLicenseandtitle = settingsData?.functionSetting[
    "incident/licenseandtitle"
  ]
    ? true
    : false;

  const displayTollManagement = settingsData?.functionSetting[
    "fleet/toll_management/view"
  ]
    ? true
    : false;

  const displayTransportation = settingsData?.functionSetting[
    "fleet/forms/transportation/view"
  ]
    ? true
    : false;

  const linksData = [
    {
      category:
        displayNewVendor === true
          ? getLabel(
              "heading_quicklink_request_new",
              "Request New",
              prismicData
            )
          : "",
      title: [
        ...(displayNewVendor === true &&
        checkQuickLinks("Driver kit") &&
        clientSettingValue !== "T"
          ? ["Driver kit"]
          : ""),
        ...(displayNewVendor === true &&
        checkQuickLinks("Insurance card") &&
        clientSettingValue !== "T"
          ? ["Insurance card"]
          : ""),
        ...(displayNewVendor === true &&
        checkQuickLinks("Fuel or EV RFID card") &&
        clientSettingValue !== "T"
          ? ["Fuel or EV RFID card"]
          : ""),
        ...(displayNewVendor === true &&
        checkQuickLinks("Maintenance guide") &&
        clientSettingValue !== "T"
          ? ["Maintenance guide"]
          : ""),
        ...(displayTransportation === true &&
        checkQuickLinks("Request a transportation quote") &&
        clientSettingValue === "T"
          ? ["Request a transportation quote"]
          : ""),
        ...(displayTransportation === true &&
        checkQuickLinks("Book a transportation move") &&
        clientSettingValue === "T"
          ? ["Book a transportation move"]
          : ""),
      ],
    },
    {
      category: getLabel(
        "heading_quicklink_update_services",
        "Update Services",
        prismicData
      ),
      title: [
        ...((displaySettingAccess === true ||
          displaySettingApproval === true) &&
        clientSettingValue !== "T"
          ? ["Authorize service"]
          : ""),

        ...(displayForm === true &&
        checkQuickLinks("Terminate vehicle") &&
        clientSettingValue !== "T"
          ? ["Terminate vehicle"]
          : ""),
        ...(displaySalesForm === true &&
        checkQuickLinks("Request a vehicle sale") &&
        clientSettingValue !== "T"
          ? ["Request a vehicle sale"]
          : ""),
        ...(displayServiceForm == true &&
        checkQuickLinks("Terminate service") &&
        clientSettingValue !== "T"
          ? ["Terminate service"]
          : ""),
      ],
    },

    {
      category: getLabel(
        "heading_quicklink_other_resources",
        "Other Resources",
        prismicData
      ),
      title: [
        ...(displayDriverCreation === true &&
        checkQuickLinks("Add new driver") &&
        clientSettingValue !== "T"
          ? ["Add new driver"]
          : ""),
        // "Manufacturer dates",
        ...(displayLicenseandtitle === true && clientSettingValue !== "T"
          ? ["License & Title in progress"]
          : ""),
        // "What's new at LPUSA",
        ...(displayTollManagement === true &&
        checkQuickLinks("Toll management") &&
        clientSettingValue !== "T"
          ? ["Toll management"]
          : ""),
      ],
    },
  ];

  const dispatch = useDispatch();

  const onPressItem = (quickLinktype) => {
    logEventFirebase({
      eventName: EXTERNAL_REQUEST,
      additionalParams: { quickLinktype },
    });
    try {
      if (quickLinktype === "Authorize service") {
        window.open(authServiceUrl, "_eplan");
      } else if (quickLinktype === "License & Title in progress")
        window.open(licenseURL, "_eplan");
      // else if (quickLinktype === "Manufacturer dates") {
      //   window.open(quickLinks[0]?.subcategory?.actionLink);
      // }
      else
        dispatch(
          showSidePanel({ type: "quickLinks", quickLinktype: quickLinktype })
        );
    } catch (e) {
      logFunctionError(e);
    }
  };

  const [x, setX] = useState();
  const [y, setY] = useState();

  useEffect(() => {
    setX(tutorial.current.offsetLeft);
    setY(tutorial.current.offsetTop);
  }, [showTutorial]);
  return (
    <>
      <QuickLinksMainContainer ref={tutorial}>
        {showTutorial &&
          clientSettingValue !== "T" &&
          sessionStorage.getItem("v") != "true" && (
            <Tutorial
              x={x}
              y={y}
              w={15}
              head={getLabel(
                "quicklink_tutorial_title",
                "Quick Links",
                prismicData
              )}
              description={getLabel(
                "quicklink_tutorial_description",
                "Use these links to quickly access some of the most frequently used",
                prismicData
              )}
            />
          )}

        {sessionStorage.getItem("v") != "true" &&
          linksData.map((item, index) => {
            return (
              <React.Fragment key={uuid()}>
                {item.title.length !== 0 && (
                  <QuickLinksWrapperContainer index={index} key={uuid()}>
                    <Text
                      size="s"
                      color="petrolBlue"
                      fontWeight="bold"
                      style={{ fontSize: "12px" }}
                    >
                      {item.category}
                    </Text>

                    <QuickLinksContainer
                      index={index}
                      clientSettingValue={clientSettingValue}
                    >
                      {item.title.map((link, index) => {
                        return (
                          <LinkWrapper key={uuid()}>
                            <Link
                              size="xs"
                              onClick={() => onPressItem(link)}
                              style={{ fontSize: "12px", marginRight: "10px" }}
                            >
                              {link}
                            </Link>
                            {externalLink.find((item) => item === link) && (
                              <OpenInNewIcon
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "10px",
                                  color: Colors.petrolBlue,
                                }}
                              />
                            )}
                          </LinkWrapper>
                        );
                      })}
                    </QuickLinksContainer>
                  </QuickLinksWrapperContainer>
                )}
              </React.Fragment>
            );
          })}
      </QuickLinksMainContainer>
    </>
  );
};

export default QuickLinks;

const QuickLinksMainContainer = styled.div`
  margin: ${Spacing.xxl};
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-contnet: center;

  @media (min-width: 768px) {
    margin: 0 ${Spacing.xxl} ${Spacing.m} ${Spacing.xxl};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`;

const QuickLinksWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${Spacing.m};
  @media (min-width: 768px) {
    width: 33.3%;
    margin-left: ${(props) =>
      (props.index == 1 || props.index == 2) && Spacing.l};
  }
  @media (min-width: 1025px) {
    width: 20%;
    margin-left: ${(props) =>
      (props.index == 1 || props.index == 2) && Spacing.l};
  }
`;

const QuickLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (min-width: 768px) {
    border-right: ${(props) =>
      (props.index == 0 || props.index == 1) &&
      props.clientSettingValue !== "T" &&
      `1px solid ${Colors.cancelled}`};
  }
`;
const LinkWrapper = styled.div`
  width: 50%;
  margin: ${Spacing.xxxs} 0;
`;

const Link = styled.span`
  font-size: ${ConvertSize(11)};
  color: ${Colors.petrolBlue};
  text-decoration: underline;
  cursor: pointer;
`;
