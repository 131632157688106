import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Text } from "@leaseplan/ui";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Attachment from "../../assets/images/attachment.png";

import StatusTypeHover from "./status/StatusTypeHover";
import InProgress from "./status/InProgress";
import SubCategoryTooltip from "./SubCategoryTooltip";
import CategoryTooltip from "./CategoryTooltip";

import eventLog from "../../config/firebase";
import { OPEN_REQUEST, TABLE_COLUMN_OPERATION } from "../../helpers/logEvents";
import { useDispatch, useSelector } from "react-redux";
import { showSidePanel } from "../../store/commonStore/commonActionCreators";

import { Colors, Spacing, ConvertSize } from "../../utils/theme";
import SubCategoryFilter from "./SubCategoryFilter";
import CategoryFilter from "./CategoryFilter";
import FilterMenu from "./FilterMenu";
import SearchMenu from "./SearchMenu";
import ItemSelect from "./ItemSelect";
import SelectAll from "./SelectAll";
import MarkAsReadUnRead from "./MarkAsReadUnRead";

import EPIcon from "../../assets/images/ep.png";
import SCIcon from "../../assets/images/sc.png";
import { getLabel } from "../../utils/prismicUtils";

import Tutorial from "../Tutorial";
import { markReadUnRead } from "../../store/request/requestApi";
import { refreshTableData } from "../../store/commonStore/commonActionCreators";
import Loading from "../Loading";
import GlobalFilterMenu from "./GlobalFilterMenu";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Colors.midOrange,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.midOrange,
    padding: "10px",
    paddingRight: "25px",
    paddingLeft: "25px",
    fontSize: "12px",
  },
}));

const returnIcon = (
  ColumnName,
  handleClick,
  orderField,
  order,
  statusArray,
  driverName,
  vehicleNumber,
  clientUnitNumber,
  vin,
  createUserName,
  prismicData,
  caseNumber,
  createDateEnd,
  createDateStart,
  dateTitle,
  repliedDateEnd,
  repliedDateStart,
  categoryArray,
  subcategoryArray,
  incidentsSelected
) => {
  switch (ColumnName) {
    case getLabel(
      "table_column_request_subtype",
      "Reason for Request",
      prismicData
    ):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "subCategory" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "subCategory", "sort");
                }}
              />
            )}

            {orderField == "subCategory" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "subCategory", "sort");
                }}
              />
            )}
            <FilterAltIcon
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
              onClick={(e) => {
                handleClick(e, "Reason for Request", "filter");
              }}
            />
            {subcategoryArray?.length > 0 && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_request_type", "I Need Help With", prismicData):
      // if incidents are selected then hide filter and sort icons
      if (incidentsSelected) {
        return null;
      }
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "category" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "category", "sort");
                }}
              />
            )}

            {orderField == "category" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "category", "sort");
                }}
              />
            )}
            <FilterAltIcon
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
              onClick={(e) => {
                handleClick(e, "I Need Help With", "filter");
              }}
            />
            {categoryArray?.length > 0 && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_status_title", "Status", prismicData):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "status" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "status", "sort");
                }}
              />
            )}

            {orderField == "status" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "status", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "Status", "filter");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {statusArray?.length > 0 && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel(
      "table_column_incident_number_title",
      "Reference ID",
      prismicData
    ):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "caseNumber" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "caseNumber", "sort");
                }}
              />
            )}

            {orderField == "caseNumber" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "caseNumber", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "Reference ID", "search");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {caseNumber && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_submitted_title", "Submitted", prismicData):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "createDate" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "createDate", "sort");
                }}
              />
            )}

            {orderField == "createDate" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "createDate", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "Date Submitted Range", "date");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {createDateEnd && createDateStart && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_last_update_title", "Last Update", prismicData):
      return (
        // <IconWrapper
        //   onClick={(e) => {
        //     handleClick(e, "updateDate", "sort");
        //   }}
        // >

        // </IconWrapper>
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "repliedDate" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "repliedDate", "sort");
                }}
              />
            )}

            {orderField == "repliedDate" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "repliedDate", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "Last Update Range", "date");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />

            {repliedDateEnd && repliedDateStart && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_request_by_title", "Requested By", prismicData):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "createUserName" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "createUserName", "sort");
                }}
              />
            )}

            {orderField == "createUserName" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "createUserName", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "Requested By", "search");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {createUserName && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_eplan_unit_title", "ePlan Unit", prismicData):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "vehicleNumber" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "vehicleNumber", "sort");
                }}
              />
            )}

            {orderField == "vehicleNumber" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "vehicleNumber", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "ePlan Unit", "search");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {vehicleNumber && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_client_unit_title", "Client Unit", prismicData):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "clientUnitNumber" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "clientUnitNumber", "sort");
                }}
              />
            )}

            {orderField == "clientUnitNumber" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "clientUnitNumber", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "Client Unit", "search");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {clientUnitNumber && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_vin_number_title", "VIN", prismicData):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "vi" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "vi", "sort");
                }}
              />
            )}

            {orderField == "vi" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "vi", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "VIN", "search");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {vin && <Circle />}
          </div>
        </IconWrapper>
      );
    case getLabel("table_column_driver_title", "Driver", prismicData):
      return (
        <IconWrapper>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {orderField == "driverName" && order == "asc" && (
              <Image
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "driverName", "sort");
                }}
              />
            )}

            {orderField == "driverName" && order == "desc" && (
              <Image
                style={{ transform: "rotate(180deg)" }}
                src={prismicData?.sort_icon?.url}
                onClick={(e) => {
                  handleClick(e, "driverName", "sort");
                }}
              />
            )}
            <FilterAltIcon
              onClick={(e) => {
                handleClick(e, "Driver", "search");
              }}
              sx={{ fontSize: 12 }}
              htmlColor={Colors.petrolBlue}
            />
            {driverName && <Circle />}
          </div>
        </IconWrapper>
      );
    default:
      return null;
  }
};

const TableView = ({
  titles,
  data,
  updateSearchQuery,
  removeSearchQuery,
  orderField,
  order,
  statusArray,
  driverName,
  vehicleNumber,
  clientUnitNumber,
  vin,
  createUserName,
  createDateStart,
  createDateEnd,
  caseNumber,
  repliedDateEnd,
  repliedDateStart,
  subcategoryArray,
  categoryArray,
  tabValue,
  totalRecords,
  dataLoading,
  requests,
  requestType,
  readStatus,
}) => {
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const { showTutorial, loadingTable } = useSelector((state) => state.common);
  const [prevData, setPrevData] = useState(data);
  const [loadTable, setLoadTable] = useState(true);
  const dispatch = useDispatch();
  const tutorial = useRef();
  const onPressItem = (
    requestId,
    requestType,
    isRead,
    requestUid,
    statusDescription,
    status,
    parentId = null
  ) => {
    dispatch(
      showSidePanel({
        type: "Ticket",
        requestId: requestId,
        requestType: requestType,
        isRead: isRead,
        statusDescription: statusDescription,
        status: status,
      })
    );
    setLoadTable(false);
    parentId
      ? eventLog({
          eventName: OPEN_REQUEST,
          additionalParams: {
            type: "child request",
          },
        })
      : eventLog({
          eventName: OPEN_REQUEST,
        });
  };
  const types = useSelector((state) => state.requestDropdown.requestDropdown);
  const { refreshData } = useSelector((state) => state.common);
  const [isMarkedLoading, setMarkedLaoding] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElCategoryFilter, setAnchorElCategoryFilter] = useState(null);
  const [anchorElSubCategoryFilter, setAnchorElSubCategoryFilter] =
    useState(null);

  const [dateTitle, setDateTitle] = useState(null);

  const [anchorElFilterDate, setAnchorElFilterDate] = useState(null);
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filterCategoryData, setFilterCategoryData] = useState(null);
  const [filterSubCategoryData, setFilterSubCategoryData] = useState(null);
  const openCategoryFilter = Boolean(anchorElCategoryFilter);
  const openSubCategoryFilter = Boolean(anchorElSubCategoryFilter);
  const [search, setSearch] = useState(null);
  const [markedData, setMarkData] = useState({});
  const [selectedIncidents, setSelectedIncidents] = useState({});
  const [allIncidents, setAllIncidents] = useState(false);
  const [rightClickData, setRightClickData] = useState({
    read: false,
    id: 0,
  });
  const openFilterDate = Boolean(anchorElFilterDate);
  const openFilter = Boolean(anchorElFilter);
  const openSearch = Boolean(anchorElSearch);
  const getSortTitle = (sortTitle) => {
    if (
      sortTitle == getLabel("table_column_driver_title", "Driver", prismicData)
    ) {
      return "driverName";
    } else if (
      sortTitle ==
      getLabel("table_column_eplan_unit_title", "ePlan Unit", prismicData)
    ) {
      return "vehicleNumber";
    } else if (
      sortTitle ==
      getLabel("table_column_client_unit_title", "Client Unit", prismicData)
    ) {
      return "clientUnitNumber";
    } else if (
      sortTitle == getLabel("table_column_vin_number_title", "VIN", prismicData)
    ) {
      return "vi";
    } else if (
      sortTitle ==
      getLabel("table_column_request_by_title", "Requested By", prismicData)
    ) {
      return "createUserName";
    } else if (
      sortTitle ==
      getLabel(
        "table_column_incident_number_title",
        "Reference ID",
        prismicData
      )
    ) {
      return "caseNumber";
    } else if (
      sortTitle ==
      getLabel("table_column_request_type", "I Need Help With", prismicData)
    ) {
      return "category";
    } else if (
      sortTitle ==
      getLabel(
        "table_column_request_subtype",
        "Reason for Request",
        prismicData
      )
    ) {
      return "subCategory";
    } else if (
      sortTitle == getLabel("table_column_status_title", "Status", prismicData)
    ) {
      return "status";
    } else if (
      sortTitle ==
      getLabel("table_column_submitted_title", "Submitted", prismicData)
    ) {
      return "createDate";
    } else if (
      sortTitle ==
      getLabel("table_column_last_update_title", "Last Update", prismicData)
    ) {
      return "repliedDate";
    }
  };

  const clientSettingsData = useSelector(
    (state) => state.settings.clientSettings
  );

  const checkClientSetting = (value) => {
    let returnValue = false;
    clientSettingsData?.filter((val) => {
      if (val?.keyName?.toLowerCase() === value.toLowerCase()) {
        returnValue = val?.keyValue;
      }
    });
    return returnValue;
  };
  const clientSettingValue = checkClientSetting(
    "client/transportation/setting"
  );

  const handleClick = (event, title, type) => {
    eventLog({
      eventName: TABLE_COLUMN_OPERATION,
      additionalParams: {
        operation_type: type,
        column_name: title,
      },
    });
    if (type == "filter") {
      // const filteredData = data?.reduce((acc, item) => {
      //   if (acc.indexOf(item.item[title]) === -1) {
      //     acc.push(item.item[title]);
      //   }
      //   return acc;
      // }, []);
      if (title == "Status") {
        const filteredData =
          tabValue == "1"
            ? ["New", "In Progress", "Pending", "Sending", "In Review"]
            : ["Completed", "Cancelled"];
        setFilterData(filteredData);
        setAnchorElFilter(event.currentTarget);
      }
      if (title == "I Need Help With") {
        let category = [];

        {
          if (clientSettingValue === "T")
            types?.categories?.map(
              (item) =>
                item.categoryDescription === "My vehicles or drivers" &&
                category.push(item.categoryDescription)
            );
          else {
            types?.categories?.map((item) =>
              category.push(item.categoryDescription)
            );
          }
        }
        // types.`subcategory_${i.requestCategoryUid}`.map((i, item) => console.log(item));
        types?.categories?.map((item) => {
          types[`subcategory_${item.requestCategoryUid}`].map(
            (items) => items.categoryDescription
          );
        });
        setFilterCategoryData(category);
        setAnchorElCategoryFilter(event.currentTarget);
      }
      if (title == "Reason for Request") {
        let category = [];
        {
          types?.categories?.map((item) =>
            category.push(item.categoryDescription)
          );
        }
        // types.`subcategory_${i.requestCategoryUid}`.map((i, item) => console.log(item));
        types?.categories?.map((item) => {
          types[`subcategory_${item.requestCategoryUid}`].map(
            (items) => items.categoryDescription
          );
        });
        setFilterSubCategoryData(types);
        setAnchorElSubCategoryFilter(event.currentTarget);
      }
    } else if (type == "sort") {
      if (title === orderField) {
        updateSearchQuery({
          orderField: `${order === "desc" ? title : ""}`,
          order: `${order === "desc" ? "asc" : "desc"}`,
        });
      } else {
        updateSearchQuery({
          orderField: title,
          order: "desc",
        });
      }
    } else if (type === "date") {
      setDateTitle(title);
      setAnchorElFilterDate(event.currentTarget);
    } else if (type == "search") {
      setSearch(title);
      setAnchorElSearch(event.currentTarget);
    }
  };
  const handleRightClick = async (data, e) => {
    if (e !== undefined) {
      setMarkedLaoding(true);
      let checkListData = [];
      for (var i = 0; i <= data.length - 1; i++) {
        checkListData.push({
          id: data[i]?.id,
          requestType: data[i]?.requestType,
          read: e,
        });
      }
      const markReadUnReadRes = await markReadUnRead(checkListData);
      setMarkedLaoding(false);

      dispatch(refreshTableData({ type: !refreshData, loading: true }));
      setAllIncidents(false);
      setSelectedIncidents({});
    } else {
      setMarkedLaoding(true);

      const markReadUnReadRes = await markReadUnRead(data);
      setMarkedLaoding(false);
      dispatch(refreshTableData({ type: !refreshData, loading: true }));
      setAllIncidents(false);
      setSelectedIncidents({});
    }
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const handleCloseCategoryFilter = () => {
    setAnchorElCategoryFilter(null);
  };
  const handleCloseSubCategoryFilter = () => {
    setAnchorElSubCategoryFilter(null);
  };
  const handleCloseFilterDate = () => {
    setAnchorElFilterDate(null);
  };

  const handleCloseSearch = () => {
    setAnchorElSearch(null);
  };

  const onClickCheckBox = (uniqueId) => {
    setSelectedIncidents((prev) => {
      return { ...prev, [uniqueId]: !(prev[uniqueId] || false) };
    });
    const incidentsSelected = {
      ...selectedIncidents,
      [uniqueId]: !(selectedIncidents[uniqueId] || false),
    };
    getMarkedData(incidentsSelected);
  };

  function getMarkedData(incidentsSelected) {
    let deleteFiles = [];
    let checkedData = [];
    Object.entries(incidentsSelected).forEach(([key, value]) => {
      if (value) {
        deleteFiles.push({
          id: key,
          isChecked: value,
        });
      }

      data.find((item) =>
        item.requestId === key
          ? value
            ? checkedData.push({
                id: item.requestUid,
                requestType: item.requestType,
                read: !item.read,
              })
            : null
          : null
      );
    });
    setMarkData(checkedData);
  }

  const onClickAllCheckBox = () => {
    if (allIncidents) {
      setAllIncidents(false);
      setSelectedIncidents({});
      getMarkedData(allIncidents);
    } else {
      setAllIncidents(true);
      setSelectedIncidents(
        data.reduce((acc, item) => {
          return { ...acc, [item.requestId]: true };
        }, {})
      );
      var selectedIncidents = {};

      for (var i = 0; i <= data.length - 1; i++) {
        let newData = { [data[i]?.requestId]: true };

        Object.assign(selectedIncidents, newData);
      }
      getMarkedData(selectedIncidents);
    }
  };

  const [x, setX] = useState();
  const [y, setY] = useState();

  useEffect(() => {
    setX(tutorial.current.offsetLeft);
    setY(tutorial.current.offsetTop);
  }, [showTutorial]);

  useEffect(() => {
    if (data.length >= 1) {
      setPrevData(data);
    }
    setAllIncidents(false);
    setSelectedIncidents({});
  }, [data]);
  const testString = /Eplan/gi;
  return (
    <>
      <ContextMenuTrigger id="TableRightClickMenu">
        <TableWrapper totalRecords={totalRecords} dataLoading={dataLoading}>
          <Table>
            <THead>
              <TR>
                <THCheckBox index={0}>
                  <SelectAll
                    onClickAllCheckBox={onClickAllCheckBox}
                    allIncidents={allIncidents}
                  />
                </THCheckBox>
                {titles?.map((title, index) => (
                  <TH
                    key={index}
                    index={index + 1}
                    onContextMenu={() => {
                      setRightClickData([]);
                    }}
                    titles={getSortTitle(title)}
                    orderField={orderField}
                  >
                    <ThWrapper>
                      {title ==
                      getLabel(
                        "table_column_request_type",
                        "I Need Help With",
                        prismicData
                      ) ? (
                        <>
                          {Object.values(selectedIncidents).filter((i) => i)
                            .length > 0 ? (
                            // <Tooltip title="Mark as read" arrow placement="top">
                            //   <p>
                            //     {
                            //       Object.values(selectedIncidents).filter((i) => i)
                            //         .length
                            //     }
                            //   </p>
                            // </Tooltip>
                            <MarkAsReadUnRead
                              onClick={(e) => handleRightClick(markedData, e)}
                              readCount={
                                Object.values(markedData).filter(
                                  (i) => i.read == true
                                ).length
                              }
                              unReadCount={
                                Object.values(markedData).filter(
                                  (i) => i.read == false
                                ).length
                              }
                              d
                            />
                          ) : (
                            <Text
                              size="xs"
                              color="petrolBlue"
                              fontWeight="bold"
                              ref={tutorial}
                              style={{ width: "max-content" }}
                              onClick={(e) => {
                                handleClick(e, getSortTitle(title), "sort");
                              }}
                            >
                              {title}
                              {showTutorial && (
                                <Tutorial
                                  x={x}
                                  y={y}
                                  mt={55}
                                  w={165}
                                  head={getLabel(
                                    "user_request_tutorial_title",
                                    "Your Requests",
                                    prismicData
                                  )}
                                  description={getLabel(
                                    "user_request_tutorial_description",
                                    "Quickly glance at your requests or click to expand and view more details such as latest updates, file upload, messages and more.",
                                    prismicData
                                  )}
                                />
                              )}
                            </Text>
                          )}
                          {returnIcon(
                            title,
                            handleClick,
                            orderField,
                            order,
                            statusArray,
                            driverName,
                            vehicleNumber,
                            clientUnitNumber,
                            vin,
                            createUserName,
                            prismicData,
                            caseNumber,
                            createDateEnd,
                            createDateStart,
                            dateTitle,
                            repliedDateEnd,
                            repliedDateStart,
                            categoryArray,
                            subcategoryArray,
                            selectedIncidents !== null &&
                              Object.values(selectedIncidents).filter((i) => i)
                                .length > 0
                          )}
                        </>
                      ) : (
                        <>
                          <Text
                            size="xs"
                            color="petrolBlue"
                            fontWeight="bold"
                            ref={tutorial}
                            style={{ width: "max-content" }}
                            data-tip
                            onClick={(e) => {
                              handleClick(e, getSortTitle(title), "sort");
                            }}
                          >
                            {title}
                            {title == "ePlan unit" && showTutorial && (
                              <Tutorial
                                x={x}
                                y={y}
                                mt={10}
                                w={165}
                                head={getLabel(
                                  "filter_tutorial_title",
                                  "Filters",
                                  prismicData
                                )}
                                description={getLabel(
                                  "filter_tutorial_description",
                                  "Search, sort and select features are available in your workspace view below. Use them to quickly find drivers, vehicles, or see when was the latest update to your requests.",
                                  prismicData
                                )}
                              />
                            )}

                            {title == "Status" && showTutorial && (
                              <Tutorial
                                x={x}
                                y={y}
                                mt={55}
                                w={165}
                                head={getLabel(
                                  "user_request_status_tutorial__title",
                                  "Status",
                                  prismicData
                                )}
                                description={getLabel(
                                  "user_request_status_tutorial_description",
                                  "In Progress, Completed, or Pending something? Check your request status at a glance in the new workspace.",
                                  prismicData
                                )}
                              />
                            )}
                          </Text>
                          {returnIcon(
                            title,
                            handleClick,
                            orderField,
                            order,
                            statusArray,
                            driverName,
                            vehicleNumber,
                            clientUnitNumber,
                            vin,
                            createUserName,
                            prismicData,
                            caseNumber,
                            createDateEnd,
                            createDateStart,
                            dateTitle,
                            repliedDateEnd,
                            repliedDateStart,
                            categoryArray,
                            subcategoryArray,
                            selectedIncidents !== null &&
                              Object.values(selectedIncidents).filter((i) => i)
                                .length > 0
                          )}
                        </>
                      )}
                    </ThWrapper>
                  </TH>
                ))}
              </TR>
            </THead>

            {!isMarkedLoading && requests != 0 && (
              <TBody>
                {(data.length == 0 && !loadingTable ? prevData : data)?.map(
                  (item, index) => (
                    <TR
                      key={index}
                      index={index}
                      read={item.read}
                      onClick={() => {
                        onPressItem(
                          item.requestId,
                          item.requestType,
                          item.read,
                          item.requestUid,
                          item.statusDescription,
                          item.status,
                          item?.parentId
                        );
                      }}
                      onContextMenu={() =>
                        setRightClickData([
                          {
                            read: !item.read,
                            id: item.requestUid,
                            requestType: item.requestType,
                          },
                        ])
                      }
                    >
                      <TDCheckBox index={0}>
                        <ItemSelect
                          uniqueID={item.requestId}
                          onClickCheckBox={onClickCheckBox}
                          selectedIncidents={selectedIncidents}
                        />
                      </TDCheckBox>
                      {titles?.map((title, index) => (
                        <TD key={index + 1} index={index + 1}>
                          <div
                            {...((index === 0 || index === 3) && {
                              style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              },
                            })}
                          >
                            {title == "Status" ? (
                              <StatusTypeHover
                                status={item.item[title]}
                                statusDescription={item.statusDescription}
                              />
                            ) : title == "Reason for request" ? (
                              <SubCategoryTooltip
                                subCat={item.item[title]}
                                read={item.read}
                                showTutorial={showTutorial}
                              />
                            ) : item.read ? (
                              <Text
                                size="xs"
                                color="petrolBlue"
                                {...((index === 0 || index === 1) && {
                                  style: {
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1,
                                    overflow: "auto",
                                    wordBreak: "break-all",
                                    width: "max-content",
                                    scrollbarWidth: "none",
                                  },
                                })}
                              >
                                {title ==
                                getLabel(
                                  "table_column_request_type",
                                  "I Need Help With",
                                  prismicData
                                ) ? (
                                  <CategoryTooltip
                                    category={item.item[title]}
                                    read={item.read}
                                    showTutorial={showTutorial}
                                    isLinked={
                                      item?.parentCaseNumber ||
                                      item?.relatedCaseNumber
                                        ? true
                                        : false
                                    }
                                  />
                                ) : (
                                  item.item[title]
                                )}{" "}
                              </Text>
                            ) : (
                              <Text
                                size="xs"
                                color="petrolBlue"
                                {...((index === 0 || index === 1) && {
                                  style: {
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1,
                                    overflow: "auto",
                                    wordBreak: "break-all",
                                    width: "max-content",
                                    scrollbarWidth: "none",
                                  },
                                })}
                              >
                                {title ==
                                getLabel(
                                  "table_column_request_type",
                                  "I Need Help With",
                                  prismicData
                                ) ? (
                                  <CategoryTooltip
                                    category={item.item[title]}
                                    read={item.read}
                                    showTutorial={showTutorial}
                                    isLinked={
                                      item?.parentCaseNumber ||
                                      item?.relatedCaseNumber
                                        ? true
                                        : false
                                    }
                                  />
                                ) : (
                                  item.item[title]
                                )}{" "}
                              </Text>
                            )}

                            {/* {title == "Reason for request" && (
                            <div style={{ marginLeft: Spacing.xxs }}>
                              <Tooltip
                                title="Add"
                                placement="top-start"
                              ></Tooltip>
                            </div>
                          )} */}
                            <div
                              style={{ flexDirection: "row", display: "flex" }}
                            >
                              {title == "Reference ID" &&
                                item?.hasAttachment == 1 && item?.status!=="Sending"  && (
                                  <div
                                    style={{
                                      marginRight: Spacing.s,
                                      marginLeft: Spacing.xs,
                                    }}
                                  >
                                    <BootstrapTooltip
                                      title={
                                        <>
                                          <Text size="xs" color="white">
                                            {getLabel(
                                              "file_attachment_title",
                                              "File attachment(s)",
                                              prismicData
                                            )}
                                          </Text>
                                        </>
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <img
                                        src={Attachment}
                                        width={ConvertSize(150)}
                                        height={ConvertSize(240)}
                                      ></img>
                                    </BootstrapTooltip>
                                  </div>
                                )}

                              {title == "Reference ID" &&
                                (item?.parentCaseNumber ||
                                  item?.relatedCaseNumber) && (
                                  <div style={{ marginRight: Spacing.s }}>
                                    <BootstrapTooltip
                                      title={
                                        <>
                                          <Text size="xs" color="white">
                                            {getLabel(
                                              "linked_request_tool_tip",
                                              "Linked request",
                                              prismicData
                                            )}
                                          </Text>
                                        </>
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <LinkIcon
                                        fontSize="small"
                                        htmlColor={Colors.petrolBlue}
                                      />
                                    </BootstrapTooltip>
                                  </div>
                                )}

                              {title == "Reference ID" &&
                                !(
                                  item?.parentCaseNumber ||
                                  item?.relatedCaseNumber
                                ) && (
                                  <div style={{ marginRight: Spacing.m }}>
                                    <LinkIcon
                                      fontSize="small"
                                      htmlColor={Colors.petrolBlue}
                                      style={{ opacity: 0 }}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </TD>
                      ))}
                    </TR>
                  )
                )}
              </TBody>
            )}
          </Table>
        </TableWrapper>
      </ContextMenuTrigger>

      <ContextMenu id="TableRightClickMenu">
        {rightClickData[0] && (
          <CustomMenuItem data={rightClickData}>
            {!rightClickData[0]?.read ? (
              <>
                <MarkEmailUnreadIcon
                  htmlColor="#004a5d"
                  onClick={() => handleRightClick(rightClickData)}
                />
                <MenuText onClick={() => handleRightClick(rightClickData)}>
                  {getLabel(
                    "mark_request_as_unread_text",
                    " Mark request as unread",
                    prismicData
                  )}{" "}
                </MenuText>
              </>
            ) : (
              <>
                <MarkEmailReadIcon
                  htmlColor="#004a5d"
                  onClick={() => handleRightClick(rightClickData)}
                />

                <MenuText onClick={() => handleRightClick(rightClickData)}>
                  {getLabel(
                    "mark_request_as_read_text",
                    " Mark request as read",
                    prismicData
                  )}{" "}
                </MenuText>
              </>
            )}
          </CustomMenuItem>
        )}
      </ContextMenu>
      <FilterMenu
        anchorEl={anchorElFilter}
        open={openFilter}
        handleClose={handleCloseFilter}
        filterData={filterData}
        updateSearchQuery={updateSearchQuery}
        removeSearchQuery={removeSearchQuery}
        statusArray={statusArray}
        prismicData={prismicData}
      />
      <CategoryFilter
        anchorEl={anchorElCategoryFilter}
        open={openCategoryFilter}
        handleClose={handleCloseCategoryFilter}
        filterData={filterCategoryData}
        updateSearchQuery={updateSearchQuery}
        removeSearchQuery={removeSearchQuery}
        statusArray={statusArray}
        prismicData={prismicData}
        categoryArray={categoryArray}
        allData={types}
        subcategoryArray={subcategoryArray}
      />
      <SubCategoryFilter
        anchorEl={anchorElSubCategoryFilter}
        open={openSubCategoryFilter}
        handleClose={handleCloseSubCategoryFilter}
        filterData={filterSubCategoryData}
        updateSearchQuery={updateSearchQuery}
        removeSearchQuery={removeSearchQuery}
        statusArray={statusArray}
        prismicData={prismicData}
        categoryArray={categoryArray}
        allData={types}
        subcategoryArray={subcategoryArray}
      />
      <SearchMenu
        anchorEl={anchorElSearch}
        open={openSearch}
        handleClose={handleCloseSearch}
        search={search}
        updateSearchQuery={updateSearchQuery}
        removeSearchQuery={removeSearchQuery}
        driverName={driverName}
        vehicleNumber={vehicleNumber}
        clientUnitNumber={clientUnitNumber}
        vin={vin}
        subcategoryArray={subcategoryArray}
        createUserName={createUserName}
        statusArray={statusArray}
        createDateStart={createDateStart}
        createDateEnd={createDateEnd}
        caseNumber={caseNumber}
        prismicData={prismicData}
        tabValue={tabValue}
        requestType={requestType}
        readStatus={readStatus}
      />
      <GlobalFilterMenu
        anchorEl={anchorElFilterDate}
        open={openFilterDate}
        handleClose={handleCloseFilterDate}
        filterData={filterData}
        updateSearchQuery={updateSearchQuery}
        removeSearchQuery={removeSearchQuery}
        statusArray={statusArray}
        prismicData={prismicData}
        createDateStart={createDateStart}
        createDateEnd={createDateEnd}
        dateTitle={dateTitle}
        repliedDateStart={repliedDateStart}
        repliedDateEnd={repliedDateEnd}
      />
      {isMarkedLoading && <Loading />}
    </>
  );
};
export default TableView;

const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`;
const TR = styled.tr`
  background-color: ${(props) => (props.read ? Colors.viewedRow : "white")};


	:hover {
		cursor: pointer;
		background-color: ${(props) => (props.read ? Colors.viewedRow : "white")}};
	}
`;
const THead = styled.thead`
  ${TR} {
    background-color: ${Colors.tableHeader};
  }
`;

const TableWrapper = styled.div`
  min-height: ${(props) =>
    props.totalRecords >= 1 && !props.dataLoading ? "30vh" : "6vh"};
`;
const TBody = styled.tbody``;

const TH = styled.th`
  text-align: left;
  @media (max-width: 1490px) {
    padding: 10px 10px;
  }

  padding-top: ${Spacing.xxxs};
  width: ${(props) => (props.index == 1 || props.index == 2) && "0%"};
  background: #ccdbdf;
  border-right: 2px solid #5b9bad;
  border-bottom: ${(props) =>
    props.titles == props.orderField ? `1px solid ${Colors.petrolBlue}` : null};
`;

const TD = styled.td`
  border-bottom: 1px solid ${Colors.chatReceiver};
  padding-bottom: ${Spacing.xs};
  padding-top: ${Spacing.xs};
  padding-left: ${Spacing.xxxs};
  border-collapse: collapse;
  overflow: clip;
  word-wrap: break-word;
  max-width: ${ConvertSize(121)};
  word-wrap: break-word;
`;
const TDCheckBox = styled.td`
  border-bottom: 1px solid ${Colors.chatReceiver};
  padding-bottom: ${Spacing.xs};
  padding-top: ${Spacing.xs};
  padding-left: ${Spacing.xxxs};
  // grid-column: ${(props) => (props.index == 1 ? "span 2" : "span 1")};
`;
const THCheckBox = styled.div`
  text-align: left;
  background: #ccdbdf;
  padding-top: ${Spacing.xs};
  padding-bottom: ${Spacing.xs};
  padding-left: ${Spacing.xxxs};
`;
const Circle = styled.div`
  display: flex;
  width: 5px;
  height: 5px;
  background-color: ${Colors.midOrange};
  border-radius: 50%;
  margin-left: -4px;
  margin-left: -4px;
`;
const FilterWrapper = styled.div`
  flex-direction: row,
  display: flex,
  margin-left: 10,
  margin-bottom: 10,`;

const ThWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  text-align: left;
  margin-bottom: -10px;
`;

const Icon = styled.img`
  margin-right: ${ConvertSize(20)};
`;

const IconWrapper = styled.div`
  margin-left: ${Spacing.xxxs};
  padding: ${Spacing.xxxs};
  align-self: center;
`;

const MenuText = styled(Text)`
  font-size: ${Spacing.s};
  padding-left: ${Spacing.xxs};
`;

const CustomMenuItem = styled(MenuItem)`
  border-radius: ${Spacing.xxxs};
  background: #ffffff;
  box-shadow: 0px ${ConvertSize(2)} ${ConvertSize(6)} rgb(0 0 0 / 15%);
  display: flex;
  align-items: center;
  width: ${ConvertSize(202)};
  height: ${ConvertSize(48)};
  justify-content: center;
  cursor: pointer;
`;
const Image = styled.img`
  height: ${ConvertSize(5)};
  width: ${ConvertSize(8)};
  margin-right: ${ConvertSize(2)};
  margin-top: ${ConvertSize(2)};
`;
