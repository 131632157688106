import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Heading, Text, TextInput, Button as LPbutton } from "@leaseplan/ui";
import uuid from "react-uuid";
import { getMmDdYY } from "../../../utils/calender";
import { Colors, ConvertSize, Spacing } from "../../../utils/theme";
import dompurify from "dompurify";
import { getLabel } from "../../../utils/prismicUtils";

const ReferenceFilter = ({
  caseNumberSearch,
  onClickSearch,
  searchText,
  _getStyledSuggestion,
  prismicData,
}) => {
  var reg = new RegExp(searchText, "gi");
  const sanitizer = dompurify.sanitize;

  return (
    <>
      <Table>
        <THead>
          <TH>
            <ThWrapper>
              {getLabel(
                "reference_id_search_table_header_title",
                "Reference ID",
                prismicData
              )}
            </ThWrapper>
          </TH>
          <TH>
            <ThWrapper>
              {getLabel(
                "reference_id_search_reason_header_title",
                "Reason for request",
                prismicData
              )}
            </ThWrapper>
          </TH>
          <TH>
            <ThWrapper>
              {getLabel(
                "reference_id_search_submitted_header_title",
                "Submitted",
                prismicData
              )}
            </ThWrapper>
          </TH>
        </THead>
        <TBody>
          {caseNumberSearch?.map((item, index) => {
            return (
              <TR
                key={uuid()}
                index={index}
                onClick={onClickSearch(item?.caseNumber)}
              >
                <>
                  <TD
                    key={uuid()}
                    index={index + 1}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(
                        `${item.caseNumber
                          .toString()
                          .replace(reg, function (str) {
                            return "<b>" + str + "</b>";
                          })} <br> `
                      ),
                    }}
                  >
                    {/* {_getStyledSuggestion(
                      item.caseNumber.toString(),
                      searchText
                    )} */}
                  </TD>
                  <TD key={uuid()} index={index + 1}>
                    {item?.subType}
                  </TD>
                  <TD key={uuid()} index={index + 1}>
                    {getMmDdYY(item?.createDate)}
                  </TD>
                </>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </>
  );
};

export default ReferenceFilter;

const TBody = styled.tbody``;
const TD = styled.td`
  max-width: ${ConvertSize(121)};
  font-size: ${ConvertSize(12)};
  text-align: left;
  color: ${Colors.petrolBlue};
`;
const TH = styled.th`
  height: ${ConvertSize(15)};
  width: ${ConvertSize(352)};
`;

const ThWrapper = styled(Text)`
  display: flex;
  padding-top: 2px;
  font-size: ${ConvertSize(10)};
  text-align: left;
  font-weight: bold;
  color: ${Colors.steel60};
  margin-left: -5px;
`;

const TR = styled.tr`
  background-color: ${(props) => props.read && Colors.viewedRow};

  :hover {
    cursor: pointer;
  }
`;
const THead = styled.thead`
  ${TR} {
    background-color: ${Colors.tableHeader};
  }
`;
const CustomText = styled(Text)`
  font-size: ${ConvertSize(12)};
`;

const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`;
