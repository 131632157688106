import {
  FETCH_REQUEST_TYPESUBTYPE_SUCCESS,
  FETCH_REQUEST_TYPESUBTYPE_FAIL,
  FETCH_REQUEST_TYPESUBTYPE_REQUEST,
} from "./requestTypeSubtypeActions";

const initialState = {
  loading: true,
  requestDropdown: null,
  quicklinks: null,
  isGetDropdownDataFail: null,
};

const RequestTypeSubtypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUEST_TYPESUBTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REQUEST_TYPESUBTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        requestDropdown: action.requestDropdown,
        quicklinks: action.quicklinks,
        isGetDropdownFail: false,
      };
    case FETCH_REQUEST_TYPESUBTYPE_FAIL:
      return {
        ...state,
        loading: false,
        requestDropdown: null,
        quicklinks: null,
        isGetDropdownFail: true,
      };
    default:
      return state;
  }
};

export default RequestTypeSubtypeReducer;
