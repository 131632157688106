import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { getLabel } from "../../utils/prismicUtils";

import "./ToggleButton.scss";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    height: "20px",
    margin: "1px",
    padding: "5px",
    textTransform: "none",
    "&.Mui-disabled": {
      border: 1,
    },
    "&:not(:first-of-type)": {
      borderRadius: "100px",
    },
    "&:first-of-type": {
      borderRadius: "50px",
    },
  },
}));

export default function ToggleButtonView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const requestType = searchParams.get("requestType") || "all";
  const [requestTypes, setRequestTypes] = React.useState(requestType);
  const pageNumber = searchParams.get("pageNumber") || 1;
  const pageSize = searchParams.get("pageSize") || 20;
  const orderField = searchParams.get("orderField");
  const order = searchParams.get("order") || "desc";
  const driverName = searchParams.get("driverName");
  const subject = searchParams.get("subject");
  const status = searchParams.get("status");
  const statusArray = status?.split(",").filter((i) => !isEmpty(i));
  const vehicleNumber = searchParams.get("vehicleNumber");
  const clientUnitNumber = searchParams.get("clientUnitNumber");
  const vin = searchParams.get("vin");
  const search = searchParams.get("search");
  const readStatus = searchParams.get("readStatus");
  const createDateStart =
    searchParams.get("createDateStart") &&
    decodeURIComponent(searchParams.get("createDateStart"));
  const createDateEnd =
    searchParams.get("createDateEnd") &&
    decodeURIComponent(searchParams.get("createDateEnd"));
  const caseNumber = searchParams.get("caseNumber");
  const prismicData = useSelector((state) => state.prismic.prismicData);
  const handleRequestTypeChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setRequestTypes(newAlignment);
    }
  };

  const updateSearchQuery = (obj) => {
    setSearchParams({
      pageNumber,
      pageSize: pageSize <= 200 ? pageSize : 20,
      ...(orderField &&
        order && {
          orderField,
          order,
        }),
      ...(driverName && { driverName }),
      ...(subject && { subject }),
      ...(statusArray?.length > 0 && {
        status: statusArray.toString(),
      }),
      ...(vehicleNumber && { vehicleNumber }),
      ...(requestType && { requestType }),
      ...(readStatus && { readStatus }),
      ...(clientUnitNumber && { clientUnitNumber }),
      ...(vin && { vin }),

      ...(search && { search }),
      ...(createDateStart && { createDateStart }),
      ...(createDateEnd && { createDateEnd }),
      ...(caseNumber && { caseNumber }),
      ...obj,
    });
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          margin: "10px",
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: "wrap",
          fontWeight: "20x",
          borderRadius: "100px",
        }}
      >
        <StyledToggleButtonGroup
          value={requestTypes}
          exclusive
          onChange={handleRequestTypeChange}
          aria-label="text alignment"
        >
          <ToggleButton
            style={{ fontSize: "10px",fontWeight:"normal",width:'70px' }}
            value="all"
            aria-label="all"
            onClick={() =>
              updateSearchQuery({
                requestType: "all",
              })
            }
          >
             {getLabel(
                  "view_all_title",
                  "View All",
                  prismicData
                )}
          </ToggleButton>
          <ToggleButton
            style={{ fontSize: "10px",fontWeight:"normal",width:'70px' }}
            value="service"
            aria-label="service"
            onClick={() =>
              updateSearchQuery({
                requestType: "service",
              })
            }
          >
                {getLabel(
                  "services_title",
                  "Services",
                  prismicData
                )}
          </ToggleButton>
          <ToggleButton
            style={{ fontSize: "10px",fontWeight:"normal",width:'70px' }}
            value="case"
            aria-label="case"
            onClick={() =>
              updateSearchQuery({
                requestType: "case",
              })
            }
          >
              {getLabel(
                  "cases_title",
                  "Cases",
                  prismicData
                )}{" "}
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}
// view_all_title,services_title,cases_title

