import React from "react";
import styled from "styled-components";
import { Colors, ConvertSize } from "../utils/theme";

const Loading = (props) => {
  return (
    <LoadingContainer type={props.type}>
      <Loader type={props.type}></Loader>
    </LoadingContainer>
  );
};

export default Loading;

const LoadingContainer = styled.div`
  height: ${({ type }) =>
    type == "downloadCSV"
      ? ` fit-content`
      : type == "cancelReq"
      ? "50vh"
      : ` 80vh;`};
  width: ${({ type }) => (type == "downloadCSV" ? ` auto` : ` 100%;`)};
  display: flex;
  align-items: center;
  justify-content: ${({ type }) =>
    type == "downloadCSV" ? ` flex-end` : ` center;`};
`;

const Loader = styled.div`
  border: 0.4rem solid #f3f3f3;
  border-top: 0.4rem solid ${Colors.midOrange};
  border-radius: 50%;
  width: ${({ type }) =>
    type == "downloadCSV" ? ConvertSize(24) : ConvertSize(48)};
  height: ${({ type }) =>
    type == "downloadCSV" ? ConvertSize(24) : ConvertSize(48)};
  animation: spin 2s linear infinite;
  margin-top: ${({ type }) =>
    type == "downloadCSV"
      ? ` 0rem`
      : type == "cancelReq"
      ? "-3rem"
      : ConvertSize(32)};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
