import {
  fetchSettingsStart,
  fetchSettingsSuccess,
  fetchSettingsFail,
  fetchClientSettingsStart,
  fetchClientSettingsSuccess,
  fetchClientSettingsFail,
} from "./settingsActions";
import server from "../../config/server";
export const getSettings = () => async (dispatch) => {
  dispatch(fetchSettingsStart());
  try {
    const res = await server.get("/security/setting");
    dispatch(fetchSettingsSuccess(res.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchSettingsFail(e));
  }
};

export const getClientSettings = () => async (dispatch) => {
  dispatch(fetchClientSettingsStart());
  try {
    const res = await server.get("/security/client-key-setting");
    dispatch(fetchClientSettingsSuccess(res.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchClientSettingsFail(e));
  }
};
